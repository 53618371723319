import React, { FC, memo, useCallback, Dispatch } from 'react'
import { PalletTypesQuery } from '../../../../../../../api/orderApi'
import { useAppSelector } from '../../../../../../../app/redux/hooks'
import { newUserOrderSelector } from '../../../NewUserOrderSlicer'
import s from './PalletsSelectModal.module.scss'

type PropTypes = {
    onSelect:(val:{id:string, name:string, length:number, width:number})=>void;
    onCancel:()=>void;
}

export const PalletsSelectModal: FC<PropTypes> = memo(({ onSelect, onCancel }) => {

    const { palletTypes } = useAppSelector(newUserOrderSelector)
    console.log('palletTypes', palletTypes);

    return (
        <div className={s.palletSelectWrapper}>

            <div className={s.body}>
                <div className={s.header}>
                    Тип паллета
                </div>
                <div className={s.text}>
                    Груз предварительно уложен на паллетах
                </div>
                <div className={s.list}>
                    {palletTypes.map(x => <div key={x.id} className={s.item}  onClick={() => onSelect({id:x.id, name: x.name, length:x.length, width:x.width})}>
                                                <span>{x.name}</span><span>{!x.manual && ` ${x.length} х ${x.width} м`}</span>
                                           </div>)
                    }
                </div>
            </div>
            <div className={s.layout} onClick={()=>onCancel()}  />
        </div>
    )
})



