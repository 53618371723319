import styled from 'styled-components'
import { IStyledLabelProps } from './Label.types'

export const StyledLabel = styled.label<IStyledLabelProps>`
  color: ${props => (props.color ? props.color : '#000000')}};
  font-weight:  ${props => (props.fontWeight ? props.fontWeight : '500')}};
  font-size:  ${props => (props.fontSize ? props.fontSize : '18px')}};
  display: inline-block;
  margin-bottom: 15px;
`
