import React, { FC, memo, useCallback, useState, Dispatch, SetStateAction, useEffect } from 'react'
import { useAppSelector } from '../../../../../../../app/redux/hooks'
import { newUserOrderSelector } from '../../../NewUserOrderSlicer'
import { SelectedItemType, TypeOfCargo } from '../VehicleSelectAuto'
import s from './CargoItem.module.scss'
import {CargoPackegesType, CargoPalletsType, CargoPlaceType} from "../../../../../../../api/orderApi";

type PropTypes = {
    item:  CargoPalletsType | CargoPackegesType | CargoPlaceType
    cargo_type: TypeOfCargo
    cargo_name: string
    onClick:  () => void
    is_selected: boolean
}
export const CargoItem: FC<PropTypes> = memo(({  item, cargo_type, cargo_name, onClick, is_selected }) => {

    const { palletTypes, packageTypes } = useAppSelector(newUserOrderSelector);

    return (
        <div className={s.cargoItemWrapper} onClick={()=> onClick()}>
            <div className={s.quantity}>
                {item.quantity ? item.quantity : ''}
            </div>
            <div className={`${s.params} ${is_selected ? s.selectedItem : ''}`}>
                <div style={{ textAlign: 'left' }}>
                    <div className={s.itemBlockHeader}>
                        {cargo_type === TypeOfCargo.pallets ? 'Паллет' : cargo_type === TypeOfCargo.packages ? 'Упаковка' : ''}
                    </div>
                    <div className={s.itemBlockParam}>
                        {cargo_type === TypeOfCargo.pallets
                            ? palletTypes.filter(x => x.id === cargo_name)[0].name
                            : cargo_type === TypeOfCargo.packages ? packageTypes.filter(x => x.id === cargo_name)[0].name : 'Груз'}
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div className={s.itemBlockHeader}>
                        ДШВ
                    </div>
                    <div className={s.itemBlockParam}>
                        {`${Math.round(item.size.length )} X ${Math.round(item.size.width  )} X ${Math.round(item.size.height  )} м`}
                    </div>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <div className={s.itemBlockHeader}>
                        Вес
                    </div>
                    <div className={s.itemBlockParam}>
                        {`${item.size.weight} КГ`}
                    </div>
                </div>
            </div>
        </div>
    )
});



