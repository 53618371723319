import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { apiOrderUrl } from "./api.constants"

export type GetIsAuthResponse = {
        client_id: string
        full_name: string
        status: string
        error_message: string
}

export const homeApi = createApi({
    reducerPath: 'homeApi',
    baseQuery: fetchBaseQuery({
      baseUrl: apiOrderUrl
    }),
    endpoints: builder => ({
      getIsAuth: builder.query<GetIsAuthResponse, string>({
        query: client_id => `auth/me?client_id=${client_id}`
      })
    })
  })

  export const { useGetIsAuthQuery } = homeApi;