import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface userState {
  clientId: string | undefined
  fullName: string
}

const initialState: userState = {
  clientId: undefined,
  fullName: ''
}
export const userSelector = (state: RootState) => state.user

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setClientId (state, action) {
      const clientId = action.payload
      state.clientId = clientId
    },
    setFullName (state, action) {
      state.fullName = action.payload
    },
    logout (state) {
      state.clientId = undefined
      state.fullName = ''
    }
  }
})

export const { setClientId, setFullName, logout } = userSlice.actions

export default userSlice.reducer
