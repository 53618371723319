import moment from 'moment'
import React, { FC, memo } from 'react'
import { OrderRoute } from '../../../../../../api/orderApi'
import { useAppSelector } from '../../../../../../app/redux/hooks'
import { newUserOrderSelector } from '../../NewUserOrderSlicer'
import s from './HeaderWithAddresses.module.scss'
import HeaderAddressesOrder from '../../../../../../assets/svg/HeaderAddressesOrder.svg'
import {RouteAddress} from "../../CreateOrderType";

export const AddressItem: FC<PropTypes> = memo(({ item, index, type }) => {
    
    const { newOrderCalcResponse } = useAppSelector(newUserOrderSelector)

    const address = index ? `${item.adress.split(',')[0].length > 9 
                                    ? item.adress.split(',')[0].slice(0, 9) + '...'
                                    : item.adress.split(',')[0]}` 
                                        : `${item.adress.split(',')[0].length > 12 
                                            ? item.adress.split(',')[0].slice(0, 12) + '...'
                                            : item.adress.split(',')[0]}`
    console.log('newOrderCalcResponse', newOrderCalcResponse);
    
    return (
        <div className={`${s.headerAddressItem} ${!index && s.firstAddress}`}>
            <div>
                <div className={`${s.address} ${!type && s.addressCompletion}`}>
                    <span className={s.street}>{address}</span><span className={s.house}>{item.adress.split(',')[1].split(' ')[2]}</span>
                </div>
                {/*<div className={s.time}>*/}
                {/*    <span>{!index && moment(newOrderCalcResponse.points.filter(x => x.arrivalDate)[index].arrivalDate).locale("ru").format("DD MMM")}</span>*/}
                {/*    {type*/}
                {/*    ? <> <span>{moment(newOrderCalcResponse.points.filter(x => x.arrivalDate)[index].arrivalDate).format("hh:mm")}</span> - */}
                {/*    <span>{moment(newOrderCalcResponse.points.filter(x => x.arrivalDate)[index].departureDate).format("hh:mm")}</span></>*/}
                {/*    : <span>{moment(newOrderCalcResponse.points.filter(x => x.arrivalDate)[index].arrivalDate).format("hh:mm")}</span>*/}
                {/*    }*/}
                {/*    */}
                {/*</div>*/}
            </div>
            <img alt="" src={HeaderAddressesOrder} />
        </div>
    )
})


type PropTypes = {
    item: RouteAddress
    index: number
    type: boolean
}
