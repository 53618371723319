import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/redux/store'

export interface LoaderState {
  isOpened: boolean
}

const initialState: LoaderState = {
  isOpened: false
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    openLoader (state) {
      state.isOpened = true
    },
    closeLoader (state) {
      state.isOpened = false
    }
  }
})

export const loaderSelector = (state: RootState) => state.loader

export const { openLoader, closeLoader } = loaderSlice.actions

export default loaderSlice.reducer
