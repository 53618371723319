import { EditClientsFormField } from '../EditClientsForm/editClientsFormSlice'
import {
  getFirstUser,
  IProfile,
  IProfileItem,
  ProfileItemType
} from './Profile.types'
const profileItems = {
  phone: {
    key: EditClientsFormField.phone,
    editClientsFormField: EditClientsFormField.phone,
    smallText: 'Моб. телефон',
    text: 'Номер телефона',
    value: '+79996665214',
    type: ProfileItemType.user,
    editClientsFormTitle: 'Мобильный телефон'
  },
  email: {
    key: EditClientsFormField.email,
    editClientsFormField: EditClientsFormField.email,
    smallText: 'Email',
    text: 'Добавить Email',
    value: '',
    type: ProfileItemType.user,
    editClientsFormTitle: 'Email'
  }
}

const profileAdditionalItems = {
  addCompany: {
    key: 'addCompany',
    editClientsFormField: EditClientsFormField.addCompany,
    smallText: '',
    text: 'Создать профиль компании',
    value: '',
    type: ProfileItemType.addButton,
    editClientsFormTitle: 'Профиль компании'
  },
  logout: {
    key: 'logout',
    editClientsFormField: EditClientsFormField.button,
    smallText: '',
    text: 'Выйти из профиля',
    value: null,
    type: ProfileItemType.logout
  }
}

const profileItemsWithCompany: { [id: string]: IProfileItem } = {
  phone: {
    key: EditClientsFormField.phone,
    editClientsFormField: EditClientsFormField.phone,
    smallText: 'Моб. телефон пользователя',
    text: 'Номер телефона',
    value: '+79996665214',
    type: ProfileItemType.user,
    editClientsFormTitle: 'Мобильный телефон пользователя'
  },
  workPhone: {
    key: 'workPhone',
    editClientsFormField: EditClientsFormField.phone,
    smallText: 'Рабочий телефон',
    text: 'Добавить рабочий телефон',
    value: '',
    type: ProfileItemType.user,
    editClientsFormTitle: 'Рабочий телефон'
  },
  email: {
    key: EditClientsFormField.email,
    editClientsFormField: EditClientsFormField.email,
    smallText: 'Email',
    text: 'Добавить Email',
    value: '',
    type: ProfileItemType.user,
    editClientsFormTitle: 'Email пользователя'
  },
  position: {
    key: 'position',
    editClientsFormField: EditClientsFormField.position,
    smallText: 'Должность',
    text: 'Указать должность',
    type: ProfileItemType.user,
    value: '',
    isRequire: true,
    editClientsFormTitle: 'Должность'
  },
  logout: {
    key: 'logout',
    editClientsFormField: EditClientsFormField.button,
    smallText: '',
    text: 'Выйти из профиля',
    value: null,
    type: ProfileItemType.logout
  },
  empty: {
    key: 'empty',
    editClientsFormField: EditClientsFormField.empty,
    smallText: '',
    text: '',
    value: null,
    type: ProfileItemType.empty,
    isNotNeedBorder: true
  },
  tin: {
    key: 'inn',
    editClientsFormField: EditClientsFormField.inn,
    smallText: 'ИНН компании',
    text: 'Введите ИНН',
    value: null,
    type: ProfileItemType.company,
    isNotNeedBorder: true
  },
  contract: {
    key: 'contract',
    editClientsFormField: EditClientsFormField.contract,
    smallText: '',
    text: 'Договор с компанией',
    value: '',
    type: ProfileItemType.contract,
    isNotEditable: true
  },
  requisites: {
    key: 'requisites',
    editClientsFormField: EditClientsFormField.requisites,
    smallText: '',
    text: 'Реквизиты компании',
    value: '',
    type: ProfileItemType.requisites
  },
  address: {
    key: 'companyAddress',
    editClientsFormField: EditClientsFormField.address,
    smallText: 'Почтовый адрес компании',
    text: 'Добавить почтовый адрес компании',
    value: 'г Томск, ул Мичурина, д 88',
    type: ProfileItemType.company,
    isRequire: true,
    editClientsFormTitle: 'Почтовый адрес компании'
  },
  
  companyPhone: {
    key: 'companyPhone',
    editClientsFormField: EditClientsFormField.phone,
    smallText: 'Телефон компании',
    text: 'Добавить телефон компании',
    value: '',
    type: ProfileItemType.company,
    editClientsFormTitle: 'Телефон компании'
  },
  companyEmail: {
    key: 'companyEmail',
    editClientsFormField: EditClientsFormField.email,
    smallText: 'Email компании',
    text: 'Добавить Email компании',
    value: '',
    type: ProfileItemType.company,
    editClientsFormTitle: 'Email компании'
  },
  site: {
    key: 'site',
    editClientsFormField: EditClientsFormField.site,
    smallText: 'Сайт компании',
    text: 'Добавить сайт компании',
    value: '',
    type: ProfileItemType.company,
    editClientsFormTitle: 'Сайт компании'
  },
  addEmployee: {
    key: EditClientsFormField.addEmployee,
    editClientsFormField: EditClientsFormField.addEmployee,
    smallText: '',
    text: 'Добавить сотрудника',
    value: '',
    type: ProfileItemType.addButton,
    isNotEditable: true
  }
}
export const getProfileItemsWithCompany = (
  profile: IProfile,
  companyInn: string | undefined
): IProfileItem[] => {
  let items: IProfileItem[] = []
  if (!companyInn) return items

  const { users, companies } = profile
  const copyProfileItems = JSON.parse(JSON.stringify(profileItemsWithCompany))

  const user = users[0]
  const findIndex = companies.findIndex(el => el.tin === companyInn)
  const company = companies[findIndex]
  for (const item in copyProfileItems) {
    let data = copyProfileItems[item] as IProfileItem
    const field = data.editClientsFormField
    data.key = item
    switch (data.type) {
      case ProfileItemType.user:
        data.value = user?.[item]
        break
      case ProfileItemType.company:
        data.value = company.hasOwnProperty(item)
          ? company[item]
          : company.hasOwnProperty(field)
          ? company[field]
          : data.value
        break
    }
    items.push(data)
  }
  return items
}

export const getProfileItems = (profile: IProfile | null): IProfileItem[] => {
  let items: IProfileItem[] = []
  const copyProfileItems = JSON.parse(JSON.stringify(profileItems))
  if (!profile) {
    return items
  }
  const { users, companies } = profile
  if (!users || !users.length) {
    return items
  }
  const user = getFirstUser(profile)
  for(const item in copyProfileItems){
    let data = copyProfileItems[item] as IProfileItem ;
    data.key = item;
    switch(data.type){
      case ProfileItemType.user:
        data.value = user?.[item]
        break
    }
    items.push(data);
  }
    companies.map(({ id, tin, name }) => {
    const newItem: IProfileItem = {
      key: id,
      editClientsFormField: EditClientsFormField.inn,
      text: tin,
      value: tin,
      type: ProfileItemType.company,
      smallText: name,
      editClientsFormTitle: 'Профиль компании'
    }
   return items.push(newItem)
  })
  items.push(profileAdditionalItems.addCompany)
  items.push(profileAdditionalItems.logout)

  return items
}
