import { IStyledOrderRowCarProps } from '../Order/Order.types'
import { StyledOrderRowCar } from '../OrderCar/OrderCar.styled'
import styled from 'styled-components'

export const StyledOrderRowAmount = styled(StyledOrderRowCar)<
  IStyledOrderRowCarProps
>`
  color: ${props => (props.isGrey ? 'var(--grey)' : 'var(--black)')};
  font-weight: 400;
  font-size: 17px;
  padding: ${props =>
    props.isGrey && props.isFullHeightAmount
      ? '21px 20px 0 26px'
      : '21px 20px 21px 26px'};
  border: none;
  min-height: ${props =>
    props.isGrey && props.isFullHeightAmount ? '15vh' : 'auto'};
  align-items: ${props => (props.isGrey ? 'baseline' : 'center')};
`
export const StyledOrderAmount = styled.div`
  font-weight: 700;
  font-size: 20px;
  & > svg {
    margin-right: 10px;
  }
`
