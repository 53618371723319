import { checkIsValid } from '../../../../components/PhoneInput/phoneHelper'
import {
  checkIsValidEmail,
  checkIsValidInn,
  MAX_LENGTH_INN
} from '../RegistrationForm/registrationFormHelper'
import { EditClientsFormField } from './editClientsFormSlice'

export const DEFAULT_MAX_LENGTH = 50
export const getMaxLengthValue = (
  value: string,
  editClientsFormField: string
) => {
  switch (editClientsFormField) {
    case EditClientsFormField.email:
      return DEFAULT_MAX_LENGTH
    case EditClientsFormField.inn:
      return MAX_LENGTH_INN
  }
  return DEFAULT_MAX_LENGTH
}

export const checkIsValidValue = (
  value: string,
  editClientsFormField: string
) => {
  let isValid = false
  switch (editClientsFormField) {
    case EditClientsFormField.phone:
      isValid = checkIsValid(value) || false
      break
    case EditClientsFormField.email:
      isValid = checkIsValidEmail(value) || false
      break
    case EditClientsFormField.inn:
    case EditClientsFormField.addCompany:
      isValid = checkIsValidInn(value) || false
      break
    default:
      isValid = true
  }
  return isValid && value !== ''
}
