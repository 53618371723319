import React, {FC} from "react";

import {
    ButtonBackgroundColor,
    ButtonColor,
    ButtonHeight,
    ButtonTypeEvent
} from "../../../../components/Button/Button.types";
import {StyledButtonBottomBlock} from "../../../../components/Button/Button.styled";
import {Button} from "../../../../components/Button/Button";
import './ImageViewWindow.css'

interface IProps {
    file:Blob
    onDelete?:()=>void
    onClose:()=>void
    title:string
}
const ImageViewWindow:FC<IProps> = ({file, onDelete, onClose, title}) => {
    return (<div className="image_view_window">
                <div className="image_view_window__title">{title}</div>
                <div className="image_view_window__photo">
                    <img  src={URL.createObjectURL(file)} />
                </div>
                <div className="image_view_window__buttons">

                    <StyledButtonBottomBlock style={{ paddingBottom:'33px'}}>
                        <Button
                            buttonTypeEvent={ButtonTypeEvent.BUTTON}
                            buttonBackgroundColor={ButtonBackgroundColor.BLUE}
                            buttonColor={ButtonColor.WHITE}
                            buttonHeight={ButtonHeight.MID_HEIGHT}
                            children='Закрыть'
                            onClick={  onClose}
                        />

                    </StyledButtonBottomBlock>
                    {onDelete && <div className='image_view_window__delete' onClick={onDelete}>Удалить данное изображение</div>}
                </div>
            </div>)
}

export default ImageViewWindow;
