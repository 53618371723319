import styled from 'styled-components'

const StyledSvg = styled.svg`
  cursor: pointer;
`
interface IArrowLeftIconProps {
  onClick?: () => void;
}
export const ArrowLeftIcon = ({onClick}:IArrowLeftIconProps) => {
  return (
    <StyledSvg
      width='15'
      height='24'
      viewBox='0 0 15 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        d='M13 22L3 12L13 2'
        stroke='#888E99'
        strokeWidth='3'
        strokeLinecap='round'
      />
    </StyledSvg>
  )
}
