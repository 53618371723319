import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/redux/store";

export interface AuthState {
  token: string | undefined;
  isAuth: boolean;
}

const initialState: AuthState = {
  token: "dfg",
  isAuth: false
};

export const authorizationSelector = (state: RootState) => state.auth

export const authorizationSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setNonAuth(state) {
      state.isAuth = false
    },
    setIsAuth(state) {
      state.isAuth = true
    }
  }
})

export const {
  setNonAuth,
  setIsAuth
} = authorizationSlice.actions;

export default authorizationSlice.reducer;
