import React, {FC, memo, useCallback, useEffect, useState} from 'react'
import s from './NewOrderHeader.module.scss'
import TimeSelectionIcon from '../../../../../assets/icons/TimeSelectionIcon.svg'
import { useDispatch } from 'react-redux'
import { newUserOrderSelector, setShowCalendar, setUrgency } from '../NewUserOrderSlicer'
import { useAppSelector } from '../../../../../app/redux/hooks'
import moment from 'moment'
import 'moment/locale/ru'
import {DatePickerWindow} from "../DatePickerWindow/DatePickerWindow";
import {setOrderDate} from "../CreateOrderSlicer";



const short_months = [
    'Янв',
    'Фев',
    'Мaр',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек',
];
interface IProps {
    date:string
    wasError?:boolean
}
export const NewOrderHeader: FC<IProps> = ({date, wasError }) => {

    const dispatch = useDispatch()

    const [isShowCalendar, setShowCalendar] = useState<boolean>(false);
    const { newOrder, selectedDateFromCalendar } = useAppSelector(newUserOrderSelector)



    const onTodayButtonClick = useCallback(() => {
        dispatch(setUrgency('today'));
        dispatch(setOrderDate(moment((new Date()).setHours((new Date()).getHours() + 3) ).format("YYYY-MM-DDTHH:mm:00")))
    }, [])

    const onUrgentButtonClick = useCallback(() => {
        dispatch(setUrgency('urgent'));
        dispatch(setOrderDate(moment((new Date())).format("YYYY-MM-DDTHH:mm:00")))

    }, [])

    const getDateString = () => {
        let arr = date.split('T');
        let val = '';
        if (arr.length) {
            val = (new Date(arr[0])).getDate() +' ' + short_months[(new Date(arr[0])).getMonth()];
        }
        if (arr.length > 1) {
            val += ' c '+ arr[1].substring(0,5);
            if (arr.length > 2)
                val += ' до ' + arr[2].substring(0,5);
            else
                val += ' до ' + arr[1].substring(0,5);
        }
        return val;

    };


    const onSetDataButtonClick = useCallback(() => {
        dispatch(setUrgency('active'));
        setShowCalendar(true)
    }, [])
    console.log('isShowCalendar', isShowCalendar, date)
    return (
        <>
            <div className={s.newOrderHeaderWrapper +' new_order_header ' + (wasError && !date  ? s.error : '')}>
                <img alt='Выберите срочность доставки' src={TimeSelectionIcon} />
                {(selectedDateFromCalendar.fromDate || selectedDateFromCalendar.toDate) && <button onClick={onSetDataButtonClick} className={`${newOrder.urgency !== 'today' && newOrder.urgency !== 'urgent' && s.activeDate}`}>{`${moment(selectedDateFromCalendar.date).locale("ru").format("DD MMM")} c ${selectedDateFromCalendar.fromDate} до ${selectedDateFromCalendar.toDate}`}</button>}
                <button onClick={onTodayButtonClick} className={`${newOrder.urgency === 'today' && s.active}`}>Сегодня</button>
                <button onClick={onUrgentButtonClick} className={`${newOrder.urgency === 'urgent' && s.active}`}>Срочно</button>
                {!(selectedDateFromCalendar.fromDate || selectedDateFromCalendar.toDate) &&
                <button onClick={onSetDataButtonClick} className={` ${newOrder.urgency !== 'today' && newOrder.urgency !== 'urgent' && s.active}   ${s.nowrap}`}>
                    {date ? getDateString() : 'Настроить дату' }
                </button>}
            </div>
            {isShowCalendar &&
                <DatePickerWindow
                    currentValue={date ? date : moment(new Date()).format("YYYY-MM-DDTHH:mm:00")}
                    onSave={(value)=>{ dispatch(setOrderDate(value)); setShowCalendar(false); } }
                    onCancel={()=>setShowCalendar(false)}
                />}
       </>
    )
}
