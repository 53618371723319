const Category1 = () =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="32"
      fill="none"
      viewBox="0 0 90 32"
    >
      <path
        fill="#000"
        d="M64 31c2.761 0 5-2.686 5-6s-2.239-6-5-6-5 2.686-5 6 2.239 6 5 6zM49.5 31c2.485 0 4.5-2.462 4.5-5.5S51.985 20 49.5 20 45 22.462 45 25.5s2.015 5.5 4.5 5.5zM39 31c2.761 0 5-2.686 5-6s-2.239-6-5-6-5 2.686-5 6 2.239 6 5 6zM25.5 31c2.485 0 4.5-2.462 4.5-5.5S27.985 20 25.5 20 21 22.462 21 25.5s2.015 5.5 4.5 5.5z"
      ></path>
      <path
        fill="url(#paint0_linear)"
        d="M63.4 15.1C61.6 12.6 56.3 6 49 6H23.3c-1.3 0-2.5.9-2.9 2.2C18.8 14 18 20 18 26.1c0 1 .8 1.9 1.9 1.9H69c.6 0 1-.4 1-1v-1c0-2.7-.9-10.3-6.6-10.9z"
      ></path>
      <path
        fill="#000"
        d="M69.1 28H21.9c-.5 0-.9-.4-.9-1h49c0 .6-.4 1-.9 1z"
        opacity="0.15"
      ></path>
      <path
        fill="#000"
        d="M55 7.5S53.1 7 50.1 7c-3.1 0-8.1.5-8.1.5l4 7.5h17.3c-1.2-1.8-4.2-5.4-8.3-7.5zM52 25.5h-8V28h8v-2.5z"
      ></path>
      <path
        fill="#000"
        d="M47.5 31c2.485 0 4.5-2.462 4.5-5.5S49.985 20 47.5 20 43 22.462 43 25.5s2.015 5.5 4.5 5.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M46.5 29.5c1.38 0 2.5-1.567 2.5-3.5s-1.12-3.5-2.5-3.5S44 24.067 44 26s1.12 3.5 2.5 3.5z"
      ></path>
      <path
        fill="#5364FF"
        d="M30 9h-4c-1.7 0-3 1.6-3 3.5s1.3 3.5 3 3.5h4c1.7 0 3-1.6 3-3.5S31.7 9 30 9z"
      ></path>
      <path
        fill="#000"
        d="M35.5 15.5v-7c0-.8.7-1.5 1.5-1.5h4.2l.1.1c2 1.4 4.1 4.5 5.2 7.8l.2.7H35.5v-.1z"
      ></path>
      <path
        fill="#C6CAD2"
        d="M41 7.5c2 1.4 4 4.5 5 7.5H36V8.5c0-.6.4-1 1-1h4zm.3-1H37c-1.1 0-2 .9-2 2V16h12.4l-.4-1.3c-1.1-3.4-3.3-6.6-5.4-8l-.3-.2z"
      ></path>
      <path
        fill="#000"
        d="M75 31H14c0-.6.4-1 1-1h59c.6 0 1 .4 1 1zM28 25.5h-8V28h8v-2.5z"
      ></path>
      <path
        fill="#000"
        d="M23.5 31c2.485 0 4.5-2.462 4.5-5.5S25.985 20 23.5 20 19 22.462 19 25.5s2.015 5.5 4.5 5.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M22.5 29.5c1.38 0 2.5-1.567 2.5-3.5s-1.12-3.5-2.5-3.5S20 24.067 20 26s1.12 3.5 2.5 3.5z"
      ></path>
      <path
        fill="#fff"
        d="M67.1 17H54c0 1.1.9 2 2 2h12.5c-.4-.7-.8-1.4-1.4-2z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="18"
          x2="70"
          y1="17"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A2AAB4"></stop>
          <stop offset="0.348" stopColor="#C6CAD2"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
export default Category1;

