import { useGetOrderFilesByIdMutation } from '../../../../api/orderApi'
import { useAppDispatch } from '../../../../app/redux/hooks'
import { StartIcon } from '../../../../assets/icons/StartIcon'
import {
  closeLoader,
  openLoader
} from '../../../../components/Loader/loaderSlice'
import { getFile } from '../../../../helper'
import CameraIcon from '../../../../assets/svg/camera.svg'
import {
    StyledDriverBlock,
    StyledDriverButton,
    StyledDriverItem, StyledDriverItemContainer,
    StyledDriverItemStar,
    StyledDriverItemTitle, StyledDriverPhotoImage,
    StyledDriverRow, StyledImgPhoto, StyledLoadingPhoto, StyledPhotoContainer
} from './Driver.styled'
import { IDriverProps } from './Driver.types'
import {useEffect, useState} from "react";
import {ThreeDots} from "react-loader-spinner";
import ImageViewWindow from "../ImageViewWindow/ImageViewWindow";

export const Driver = ({ driver }: IDriverProps) => {
  const { full_name, license, passport } = driver;
  const [getFileById] = useGetOrderFilesByIdMutation();

  const [passportBlobs, setPassportBlobs]= useState<Blob[]>([]);
  const [certificateBlobs, setCertificateBlobs]= useState<Blob[]>([]);
  const [isLoadingBlobs, setLoadingBlobs]= useState<boolean>(false);
  const [selectedPhoto, setSelectedPhoto]= useState<{title:string, blob:Blob}|null>(null);

  const loadFiles = async () => {
      setLoadingBlobs(true);
      let lBlobs = [];
      for (let i =0; i< driver.passport.files.length; i++){
        let blob = await getFileById(driver.passport.files[i]).unwrap();
        const fileObj = getFile(blob)
        if (fileObj)  lBlobs.push(fileObj)
      }
      setPassportBlobs(lBlobs);
      lBlobs = [];
      for (let i =0; i< driver.license.files.length; i++){
          let blob = await getFileById(driver.license.files[i]).unwrap();
          const fileObj = getFile(blob)
          if (fileObj)  lBlobs.push(fileObj)
      }
      setCertificateBlobs(lBlobs)
      setLoadingBlobs(false)
  }
  useEffect(()=>{
        if (!driver) {
            setPassportBlobs([]);
            setCertificateBlobs([])
        } else
            loadFiles()

  }, [driver])

    if (selectedPhoto)
        return(<ImageViewWindow  title={selectedPhoto.title} onClose={()=>setSelectedPhoto(null)}  file={selectedPhoto.blob} />);

    return (
    <StyledDriverBlock className="driver_tsx">
      <StyledDriverRow>
        <StyledDriverItem>
          <StyledDriverItemTitle>Имя водителя</StyledDriverItemTitle>
          <span>{full_name}</span>
        </StyledDriverItem>
        <StyledDriverItem>
          <StyledDriverItemTitle>Рейтинг</StyledDriverItemTitle>
          <StyledDriverItemStar>
            <span>
              <StartIcon />
            </span>
            5.0
          </StyledDriverItemStar>
        </StyledDriverItem>
      </StyledDriverRow>
      <StyledDriverRow>
        <StyledDriverItemContainer>
          <StyledDriverPhotoImage src={CameraIcon}/>
          <StyledDriverItem>
              <StyledDriverItemTitle>Паспорт</StyledDriverItemTitle>
              <span>{`${passport.series} ${passport.number}`}</span>
          </StyledDriverItem>
        </StyledDriverItemContainer>
        <StyledDriverItemContainer>
            <StyledDriverPhotoImage src={CameraIcon}/>
            <StyledDriverItem>
              <StyledDriverItemTitle>Вод. удостоврение</StyledDriverItemTitle>
              <span>{`${license.series} ${license.number}`}</span>
            </StyledDriverItem>
        </StyledDriverItemContainer>
      </StyledDriverRow>
      <StyledDriverRow>
          {isLoadingBlobs && <StyledLoadingPhoto>Загрузка фото <ThreeDots wrapperStyle={{marginLeft:'10px'}} width={20} height={10} color={'#888e99'} /></StyledLoadingPhoto>}
          {!isLoadingBlobs && <>
                <StyledPhotoContainer style={{paddingRight:'5px'}} >
                    {passportBlobs.map((picture, index) => (
                        <StyledImgPhoto key={'camera_image_p_' + index} alt=""
                             src={URL.createObjectURL(picture)}
                             onClick={()=>setSelectedPhoto({title:'Фото паспорта ', blob:picture })}
                        />
                    ))}
                </StyledPhotoContainer>
                <StyledPhotoContainer  style={{paddingLeft:'5px'}}>
                    {certificateBlobs.map((picture, index) => (
                        <StyledImgPhoto key={'camera_image_c_' + index} alt=""
                             src={URL.createObjectURL(picture)}
                             onClick={()=>setSelectedPhoto({title:'Фото водительского удостоверения ', blob:picture })}
                        />
                    ))}
                </StyledPhotoContainer>
              </>
          }
      </StyledDriverRow>
    </StyledDriverBlock>
  )
}

export default Driver;
