import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/redux/store'
import { IArticleItem } from '../ArticleItem/ArticleItem.types'

export interface articleState {
  articleId: string
}

const initialState: articleState = {
  articleId: ''
}
export const articleSelector = (state: RootState) => state.article
export const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    setArticle (state, action) {
      state.articleId = action.payload
    },
    clear (state) {
      state.articleId = ''
    }
  }
})

export const { setArticle } = articleSlice.actions

export default articleSlice.reducer
