import { StyledButton } from './Button.styled'
import { IButtonProps } from './Button.types'

export const Button = ({
  buttonTypeEvent,
  buttonType,
  buttonBackgroundColor,
  buttonHeight,
  buttonColor,
  children,
  onClick
}: IButtonProps) => {
  return (
    <StyledButton
      type={buttonTypeEvent}
      buttonType={buttonType}
      buttonBackgroundColor={buttonBackgroundColor}
      buttonHeight={buttonHeight}
      buttonColor={buttonColor}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )
}
