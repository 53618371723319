import React, { FC, memo, useCallback } from 'react'
import s from '../NewUserOrder.module.scss'

export const FindLocationButton: FC<propTypes> = memo(({ setShowLocationDetectModal, setDetectedLocationAsCurrent, isLocationDetectAlowed }) => {

    const onLocationDetectButtonClick = useCallback(() => {        
        isLocationDetectAlowed ? setDetectedLocationAsCurrent() : setShowLocationDetectModal(true)
    }, [isLocationDetectAlowed])

    return (
        <button className={s.findLocationButton} onClick={onLocationDetectButtonClick}>
            Определить местоположение
        </button>
    )
})


type propTypes = {
    setShowLocationDetectModal: (value: boolean) => void
    setDetectedLocationAsCurrent: () => void
    isLocationDetectAlowed: boolean | undefined
}