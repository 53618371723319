import React, { FC, useCallback, memo, useState } from 'react'
import s from './OrderCompletion.module.scss'
import OrderDetailsArrow from '../../../../../../assets/svg/OrderDetailsArrow.svg'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/hooks'
import { newUserOrderSelector, setShowOrderCompletion, setShowPaymentDetails } from '../../NewUserOrderSlicer'
import { InfoMessageType } from '../../../../../../components/InfoMessage/InfoMessage.types'
import { openInfoMessage } from '../../../../../../components/InfoMessage/infoMessageSlice'
import {currentOrderData, getTariffs} from "../../CreateOrderSlicer";
import {PaymentDetails} from "../PaymentDetails/PaymentDetails";

export const PaymentDetailsButton: FC<{}> = memo(({ }) => {

    const dispatch = useAppDispatch()
    const [isShowPaymentDetails, setShowPaymentDetails] = useState<boolean>(false);

    const  currentOrder = useAppSelector(currentOrderData);
    const  tariffs = useAppSelector(getTariffs);

    const [buttonIsActive, setButtonIsActive] = useState<boolean>(true);

    const onDetailsClick = useCallback(() => {
        if (buttonIsActive) {
            setButtonIsActive(false)
            if (tariffs.filter(x => x.tariff_type_id === currentOrder.tariff_type_id)[0].cost.toString() === 'Скоро! 0') {
                dispatch(openInfoMessage({
                    type: InfoMessageType.FAILIED,
                    title: '',
                    text: 'Выбранный тариф не доступен!'
                  }))
                setButtonIsActive(true)
            } else {
                setButtonIsActive(true)
                setShowPaymentDetails(true)
            }
        }        
    }, [currentOrder])

    return (
        <>
        <div className={s.paymentDetails} onClick={onDetailsClick}>
            <span>Детализация заказа</span>
            <img alt="" src={OrderDetailsArrow} />
        </div>
            {isShowPaymentDetails &&  <PaymentDetails onClose={()=> setShowPaymentDetails(false)} />}
        </>
    )
})
