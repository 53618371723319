import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/redux/store'
import { IVehicle } from '../Order/Order.types'

export interface amountWindowState {
  vehicle: IVehicle
  amount: number
  carTypeId: string
  tariffTypeId: string
  status: string
  dateForShow: string
}

const initialState: amountWindowState = {
  vehicle: {
    height: 0,
    length: 0,
    max_weight: 0,
    width: 0
  },
  amount: 0,
  carTypeId: '',
  tariffTypeId: '',
  status: '',
  dateForShow: ''
}
export const amountWindowSelector = (state: RootState) => state.amountWindow
export const amountWindowSlice = createSlice({
  name: 'amountWindow',
  initialState,
  reducers: {
    initializeAmount (state, action) {
      const { vehicle, amount, carTypeId, tariffTypeId, status, dateForShow } = action.payload
      state.vehicle = vehicle
      state.amount = amount
      state.carTypeId = carTypeId
      state.tariffTypeId = tariffTypeId
      state.status = status
      state.dateForShow = dateForShow
    },

    clear (state) {
      state.vehicle = {
        height: 0,
        length: 0,
        max_weight: 0,
        width: 0
      }
      state.tariffTypeId = ''
      state.carTypeId = ''
      state.status = ''
      state.dateForShow = ''
    }
  }
})
export const { clear, initializeAmount } = amountWindowSlice.actions

export default amountWindowSlice.reducer
