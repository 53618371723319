import React, { FC,  memo } from 'react'

import s from './PaymentDetails.module.scss'
import CloseDetailing from '../../../../../../assets/svg/CloseDetailing.svg'
import {currentOrderData, getTariffs} from "../../CreateOrderSlicer";
import {useAppSelector} from "../../../../../../app/redux/hooks";
import {CTariffData, SendOrderInfo} from "../../CreateOrderType";
import {newUserOrderSelector} from "../../NewUserOrderSlicer";

const GroupeHeader: FC<HeaderPropTypes> = memo(({ text }: HeaderPropTypes) => {
    return (
        <div className={s.groupeHeader}>
            {text}
        </div>
    )
})

const AdvancedRow: FC<AdvancedRowTypes> = memo(({ topText, bottomText, cost }: AdvancedRowTypes) => {
    return (
        <div className={s.advancedRow}>
            <div>
                <div>
                    {topText}
                </div>
                <div>
                    {bottomText}
                </div>
            </div>
            <div>
                {cost} &#8381;
            </div>
        </div>
    )
})

const Row: FC<RowTypes> = memo(({ text, cost }: RowTypes) => {
    return (
        <div className={s.row}>
            <div>
                {text}
            </div>
            <div>
                {`${cost}`} &#8381;
            </div>
        </div>
    )
})

interface IProps {
    onClose:()=>void,
    order?:SendOrderInfo,
    order_tariffs?: CTariffData[],
}
export const PaymentDetails: FC<IProps> = ({onClose, order,order_tariffs }) => {

     let currentOrder =  useAppSelector(currentOrderData);
     let tariffs =    useAppSelector(getTariffs);
     let {tariffTypes} =    useAppSelector(newUserOrderSelector);
    console.log('currentOrder', currentOrder, 'order', order)
    currentOrder = order ? order : currentOrder;
    tariffs = order_tariffs ? order_tariffs : tariffs;

    console.log('tariffs', tariffs)
    const  currentTariff = tariffs.filter(x => x.tariff_type_id === currentOrder.tariff_type_id)[0];

    const getTariffName = () => {
        if (currentTariff.tariff_name ) return currentTariff.tariff_name;
        let name = '';
        tariffTypes.forEach( x=> {if (x.id == currentOrder.tariff_type_id) name = x.name});
        return name;
    }

    return (
        <div className={s.paymantDetailsWrapper}>
            <div className={s.paymantDetailsLayout} onClick={()=>onClose()} />
            <div className={s.paymantDetailsBody}>
                <div className={s.header}>
                    <span>
                        Детализация заказа
                    </span>
                    <img alt='Закрыть детализацию' src={CloseDetailing} onClick={()=>onClose()} />
                </div>
                <div>
                    <GroupeHeader text={'Тариф'} />
                    <AdvancedRow topText={`Тариф “${getTariffName()}”`} bottomText={`${currentTariff.rate} , стоим. часа`} cost={currentTariff.cost_by_hour} />
                    <AdvancedRow topText={'Мин., время'} bottomText={`${currentTariff.min_hours} часов | стоимость`} cost={currentTariff.min_cost} />
                    <AdvancedRow topText={'Планируемое время'} bottomText={`${currentTariff.hours} | стоимость`} cost={currentTariff.cost} />
                </div>
                {currentTariff.items.length > 0 &&
                        <div>
                            <GroupeHeader text={'Дополнительные услуги'} />
                            {currentTariff.items.map(x => <Row key={x.cost} text={x.name} cost={x.cost} />)}
                        </div>
                }
                {currentTariff.items_by_route.length > 0 &&
                    <div className={s.lastRow}>
                        <GroupeHeader text={'По маршруту'} />
                        {currentTariff.items_by_route.map(x => <Row key={x.cost} text={x.name} cost={x.cost} />)}
                    </div>
                }
            </div>
        </div>
    )
}



type HeaderPropTypes = {
    text: string
}

type AdvancedRowTypes = {
    topText: string
    bottomText: string
    cost: number | null
}

type RowTypes = {
    text: string
    cost: number
}
