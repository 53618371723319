import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/redux/store'

export interface ratingWindowState {
  isShowRatingWindow: boolean
  mistakes: string[]
  rating: number
}

const initialState: ratingWindowState = {
  isShowRatingWindow: false,
  mistakes: [],
  rating: -1
}
export const ratingWindowSelector = (state: RootState) => state.ratingWindow
export const ratingWindowSlice = createSlice({
  name: 'ratingWindow',
  initialState,
  reducers: {
    setMistakes (state, action) {
      state.mistakes = action.payload
    },
    setRating (state, action) {
      state.rating = action.payload
    },
    setIsShowRatingWindow (state, action) {
      state.isShowRatingWindow = action.payload
    },

    clear (state) {
      state.isShowRatingWindow = false
      state.mistakes = new Array(0)
      state.rating = -1
    }
  }
})
export const {
  setMistakes,
  setRating,
  setIsShowRatingWindow,
  clear
} = ratingWindowSlice.actions

export default ratingWindowSlice.reducer
