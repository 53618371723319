import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import Button from '../../../../components/Button'
import {StyledButtonBottomBlock, StyledLinkBack} from '../../../../components/Button/Button.styled'
import {
  ButtonBackgroundColor,
  ButtonColor,
  ButtonHeight,
  ButtonTypeEvent
} from '../../../../components/Button/Button.types'
import OrderCar from '../OrderCar/OrderCar'
import {getBodyName, getCategorySvg} from '../OrderItem/categoryHelper'
import { orderListSelector } from '../OrderList/orderListSlice'
import { amountWindowSelector } from './amountWindowSlice'
import { closeModal } from '../../../../components/Modal/modalSlice'
import {
  StyledAmountCount, StyledAmountModalWindow, StyledAmountModalWindowTopDecor,
  StyledAmountPaymentTypeItem,
  StyledAmountPaymentTypeList,
  StyledAmountText,
  StyledAmountWindowBlock,
  StyledAmountWindowColumn,
  StyledAmountWindowConditions,
  StyledAmountWindowRow,
  StyledAmountWindowRowBlue
} from './AmountWindow.styled'

import { ArrowRightIcon } from '../../../../assets/icons/ArrowRightIcon'
import { IPaymentTypes } from './AmountWindow.types'

import React, {FC, useEffect, useState} from 'react'
import {orderSelector} from "../Order/orderSlice";
import {PaymentDetails} from "../../../Home/components/NewUserOrder/EditNewOrder/PaymentDetails/PaymentDetails";
import {IOrderFull} from "../Order/Order.types";
import Overlay from "../../../Home/components/Overlay/Overlay";
import {newUserOrderSelector} from "../../../Home/components/NewUserOrder/NewUserOrderSlicer";
import {useGetTariffTypesMutation} from "../../../../api/orderApi";



const AmountWindow :FC<{onClose:()=>void, order:IOrderFull | undefined, onVehicleClick:()=>void}>= ({onClose, order,onVehicleClick}) => {
  const {
    vehicle,
    amount,
    carTypeId,
    tariffTypeId,
    status,
    dateForShow
  } = useAppSelector(amountWindowSelector)
  const { model, number, height, width, max_weight, length } = vehicle
  const { categories } = useAppSelector(orderListSelector)
  const {  tariffs } = useAppSelector(orderSelector);

  const dispatch = useAppDispatch();
  const [getTariffTypes] = useGetTariffTypesMutation();
  const [amountName, setAmountName] = useState('Ставка');
  const [isDetailsShow, setDetailsShow] = useState(false);
  const {carBodyOptions} = useAppSelector(newUserOrderSelector);

  const [selectedPaymentType ] = useState( order && order.payment_type == 'paymentonaccount' ? IPaymentTypes.byCount :  IPaymentTypes.cash );
  useEffect(() => {
    getTariffTypes(null).unwrap().then((res:any)=>{
      if (res && res?.length) {
        const tariffType = res.filter((item:any) => item.id === tariffTypeId)
        if (tariffType) {
          setAmountName(tariffType[0].name)
        }
      }
    })
  }, [])
  return (
      <>
        <StyledAmountModalWindow>
          <StyledAmountModalWindowTopDecor>
          <OrderCar
            isGrey={true}
            model={model || ''}
            number={number || ''}
            height={height}
            width={width}
            length={length}
            bodyName={getBodyName(carBodyOptions, order ? order.body_option_id : '')}
            category={getCategorySvg(categories, carTypeId)}
            isNeedBorderBottom={true}
            onClick={onVehicleClick}
          />
          <div style={{background:'#fff'}}>
            <StyledAmountWindowBlock>
              {/*<StyledAmountWindowRow>*/}
              {/*  <span>Услуги к транспортировке</span>*/}
              {/*  <div>*/}
              {/*    <AddIcon />*/}
              {/*  </div>*/}
              {/*</StyledAmountWindowRow>*/}
              <StyledAmountWindowRowBlue>
                <div>
                  <StyledAmountWindowColumn>
                    <span>{amountName}</span>
                    <StyledAmountWindowConditions>
                      <a href='https:\\google.com' target='_blank'>
                        Подробные условия
                      </a>
                    </StyledAmountWindowConditions>
                  </StyledAmountWindowColumn>
                </div>
                <StyledAmountCount>
                  {/* <span>5 670 ₽</span> */}
                  <span></span>
                  <span>{`${amount} ₽`}</span>
                </StyledAmountCount>
              </StyledAmountWindowRowBlue>
              <StyledAmountWindowRow onClick={()=>{setDetailsShow(true)}}>
                <span>Детализация заказа</span>
                <div>
                  <ArrowRightIcon />
                </div>
              </StyledAmountWindowRow>
            </StyledAmountWindowBlock>
            <StyledAmountPaymentTypeList>
              {Object.values(IPaymentTypes).map((item, index) => (
                <StyledAmountPaymentTypeItem
                  key={index}
                  selected={selectedPaymentType === item}
                >
                  {item}
                </StyledAmountPaymentTypeItem>
              ))}
            </StyledAmountPaymentTypeList>
            <StyledAmountText>
              Отдайте оплату за заказ наличными в руки исполнителю (грузчику)
            </StyledAmountText>
            <StyledAmountText color={'var(--green)'} padding={'10px 0'}>
              {status && dateForShow && `${status} ${dateForShow}`}
            </StyledAmountText>
            <StyledButtonBottomBlock style={{ paddingBottom:'33px'}}>
              <Button
                  buttonTypeEvent={ButtonTypeEvent.BUTTON}
                  buttonBackgroundColor={ButtonBackgroundColor.RED_GRADIENT}
                  buttonColor={ButtonColor.WHITE}
                  buttonHeight={ButtonHeight.MID_HEIGHT}
                  children='Закрыть'
                  onClick={  onClose}
              />

            </StyledButtonBottomBlock>
          </div>

          </StyledAmountModalWindowTopDecor>
        </StyledAmountModalWindow>
        {isDetailsShow &&  <PaymentDetails onClose={()=>setDetailsShow(false)} order={(order as any)} order_tariffs={tariffs} />}
        <Overlay is_visible={true} onClick={onClose}/>
      </>
  )
};

export default AmountWindow;
