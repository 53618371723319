import React, {FC, memo, useCallback, useState, useEffect, useMemo} from 'react'
import s from './AutoVehicleSelectSummary.module.scss'
import ToTarifsArrow from '../../../../../../assets/svg/ToTarifsArrow.svg'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/hooks'
import {
    filterCategory,
    getActiveCharacteristics, getBOCharacteristics, getBodyOptionsList, getCharacteristicValueList,
    newUserOrderSelector,
} from '../../NewUserOrderSlicer'

import { SelectedCategoryInfo } from '../../VehicleBlockAuto/SelectedCategoryInfo'

import {
    BodyOptionCharacteristicsResponse,
    BodyOptionsResponse,
    CarTypeTreeQueryResponse, CharacteristicTypeEnum,
    useCalcOrderRequestAllMutation,
    usePackMutation
} from '../../../../../../api/orderApi'

import {CTariffData, SendOrderInfo} from "../../CreateOrderType";
import {
    currentOrderData,
    getCost,
    getTariffs,
    setPackInfo,
    setTariffsData,
    setOrderData,
    wait_before_update, getPackInfo, getTariffLoading, setTariffLoading
} from "../../CreateOrderSlicer";
import {openInfoMessage} from "../../../../../../components/InfoMessage/infoMessageSlice";
import {InfoMessageType} from "../../../../../../components/InfoMessage/InfoMessage.types";
import {AutoDropDownGreyList} from "../../AutoDropDownGreyList/AutoDropDownGreyList";
import {VehicleGrayButton} from "../../VehicleGrayButton/VehicleGrayButton";
import {ButtonTariff} from "../../ButtonTariff/ButtonTariff";
import {StyledLinkBack} from "../../../../../../components/Button/Button.styled";
import HeaderWithAddresses from "../HeaderWithAddresses/HeaderWithAddresses";
import {VehicleBlockAuto} from "../../VehicleBlockAuto/VehicleBlockAuto";

interface IProps {
    onClose: ()=>void;
    onNext: ()=> void;
}

export const AutoVehicleSelectSummary: FC<IProps> =  ({onClose, onNext}) => {

    const dispatch = useAppDispatch();
    const [  calcOrderRequestAll ] = useCalcOrderRequestAllMutation();


    let currentOrder : SendOrderInfo = useAppSelector(currentOrderData);

    const { carTypesTree, tariffTypes } = useAppSelector(newUserOrderSelector);

    let  categories : CarTypeTreeQueryResponse[]  = carTypesTree ? carTypesTree : [];

    let selectedBodyID  = currentOrder.body_option_id ;

    const packInfo = useAppSelector(getPackInfo);

    const tarrifs : CTariffData[] = useAppSelector(getTariffs);
    const cost =  getCost(tarrifs, currentOrder.tariff_type_id);
    const is_tariff_loading : boolean = useAppSelector(getTariffLoading);

    const getTariffInfo = async () => {
        dispatch(setTariffLoading(true));
        let res = await wait_before_update(3000);

        if (res) {
            console.log('getTariffInfo');
            let data = {...currentOrder};
            if (!data.date)
                data.date='2022-01-24T15:24:00T15:39:00';

            if (!data.car_type_id)
                data.car_type_id = packInfo.car_type_id ? packInfo.car_type_id : categories[0].id ;

            calcOrderRequestAll(data)
                .unwrap()
                .then(x => {
                    dispatch(setTariffLoading(false));
                    dispatch(setTariffsData({data: x, names:tariffTypes}))
                }).catch((e)=>{
                dispatch(setTariffLoading(false));
            })
        }
    };


    
    return (
        <div className={s.vehicleSelectWrapper}>
            <HeaderWithAddresses type={true} />
            <VehicleBlockAuto onChange={()=>getTariffInfo()} />

            { selectedBodyID &&
                <ButtonTariff onNext={onNext} onBack={onClose} disabled={tarrifs.length ==0} cost={cost+''} type={'orange'} isLoading={is_tariff_loading}/>
            }
            {
                !selectedBodyID &&
                <div className={s.needMoreData}>
                    <div className={s.akaButton}>Для продолжения необходимо выбрать тип кузова</div>
                    <StyledLinkBack onClick={onClose}>вернуться назад</StyledLinkBack>
                </div>
            }

        </div>
    )
}
