import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";

export interface LocationDetectState {
  isLocationDetectAlowed: boolean | undefined;
  detectedLocation: {
    lat: number | undefined;
    lon: number | undefined;
  };
  isLocationDetectModalOpened: boolean;
}

const initialState: LocationDetectState = {
  isLocationDetectAlowed: undefined,
  detectedLocation: {
    lat: undefined,
    lon: undefined,
  },
  isLocationDetectModalOpened: false,
};

export const locationDetectSelector = (state: RootState) =>
  state.locationDetect;

export const locationDetectSlice = createSlice({
  name: "locationDetect",
  initialState,
  reducers: {
    chooseLocationDetect(state, action: PayloadAction<boolean>) {
      state.isLocationDetectAlowed = action.payload;
    },
    setDetectedLocation(
      state,
      action: PayloadAction<LocationDetectState["detectedLocation"]>
    ) {
      state.detectedLocation = action.payload;
    },
    openLocationDetectModal(state) {
      state.isLocationDetectModalOpened = true;
    },
    closeLocationDetectModal(state) {
      state.isLocationDetectModalOpened = false;
    },
  },
});

export const {
  chooseLocationDetect,
  setDetectedLocation,
  openLocationDetectModal,
  closeLocationDetectModal,
} = locationDetectSlice.actions;

export default locationDetectSlice.reducer;
