import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
    AditionalRequirementsTypes,
    BodyOptionCharacteristicsResponse,
    BodyOptionCharacteristicsValuesResponse,
    BodyOptionsResponse,
    BodyTypesResponse,
    CargoType,
    CarTypeTreeQueryResponse,
    OrderCalcResponse,
    OrderPostResponseType,
    OrderRoute,
    TariffTypesResponse,
    PalletTypesQuery,
    PackageTypesQuery,
    PackResponseType,
    CargoPlaceType,
    CargoPalletsType,
    CargoPackegesType, CharacteristicTypeEnum
} from "../../../../api/orderApi"
import { RootState } from "../../../../app/redux/store"



export type Coords = {
    lon: number
    lat: number
}

export type TariffTypesCost = {
    id: string
    name: string
    cost: number
}

export type OrderContactsType = {
    full_name: string
    phone: string
    phone_ext: string
    email: string | null
}

export interface NewUserOrderState {
    fromAddress: string
    fromCoords: Coords
    fromAdvancedFilled: boolean
    toAddress: string
    toCoords: Coords
    currentAddress: string
    currentCoords: Coords

    hideElement: boolean
    hideNewOrderWindow: boolean
    newOrder: newOrder
    newOrderCalcResponse: OrderCalcResponse
    newOrderPostResponse: OrderPostResponseType
    packResponse: PackResponseType
    carTypesTree: CarTypeTreeQueryResponse[]
    carBodyTypes: BodyTypesResponse[]
    carBodyOptions: BodyOptionsResponse[]
    carBodyOptionCharacteristics: BodyOptionCharacteristicsResponse[]
    carBodyOptionCharacteristicsValues: BodyOptionCharacteristicsValuesResponse[]
    tariffTypes: TariffTypesResponse[]
    palletTypes: PalletTypesQuery[]
    packageTypes: PackageTypesQuery[]
    aditionalRequirements: AditionalRequirementsTypes[]
    showNewOrder: boolean
    showCalendar: boolean
    showInputModal: boolean
    showVehicleSelect: boolean
    showAutoVehicleSelect: boolean
    showAutoVehicleSelectSummary: boolean
    showOrderCompletion: boolean
    showPaymentDetails: boolean
    showOrderIsReady: boolean
    selectedDateFromCalendar: {
        date: string
        fromDate: string
        toDate: string
    }
    travers: TraversTypes
    currentID: number
    isHouseHas: boolean
    onMapClick: boolean
    clickableMap: boolean
}

export type BodyOptions = {
    id: string
    body_type_id: number
    name: string
}

export type BodyOptionsCharacteristics = {
    id: string
    name: string
    type: string
}

export type BodyOptionsCharacteristicsWithValues = {
    body_option_characteristics_id: string
    id: string
    name: string
    Порядок: number
}

export interface DropDownListItem {
    id: string;
    text: string;
}

export type newOrder = {
    urgency: string
    date: string
    isLoading: boolean
    addresses: OrderRoute[]
    selectedCarCategory: string
    bodyOptions: BodyOptions
    bodyOptionsCharacteristics: BodyOptionsCharacteristics[]
    bodyOptionsCharacteristicsWithValues: BodyOptionsCharacteristicsWithValues[]
    bodyMaxSize: {
        length: string
        height: string
        width: string
    }
    bodyHeight: number
    additionalRequirements: AditionalRequirementsTypes[]
    selectedTariff: TariffTypesResponse
    tariffTypesCoast: TariffTypesCost[]
    contacts: OrderContactsType
    maxDimensions: boolean
    agreementAccepted: boolean
    phoneSended: boolean
    phoneChecked: boolean
    selectedPaymentType: PaymentTypes
    TIN: string
    cargo: CargoType
    autoSelectVehicleCargo: CargoType
}

export enum TraversTypes {
    to = "to",
    from = "from",
    correct = "correct",
    add = "add"
}

export enum PaymentTypes {
    card = 'paymentonline',
    tin = 'paymentonaccount',
    cash = 'paymentcash'
}

const initialState: NewUserOrderState = {
    fromAddress: '',
    fromCoords: { lon: 0, lat: 0 },
    fromAdvancedFilled: false,
    currentAddress: '',
    currentCoords: { lon: 0, lat: 0 },
    // currentAdvanced: {
    //     person: '',
    //     phone: '',
    //     workTime: { from: '', to: '' },
    //     lunchTime: { from: '', to: '' },
    //     addressComment: '',
    //     fromWho: '',
    //     toWhom: '',
    //     whatToDo: ''
    // },
    toAddress: '',
    toCoords: { lon: 0, lat: 0 },
    hideElement: false,
    hideNewOrderWindow: false,
    newOrder: {
        urgency: '',
        date: '',
        isLoading: false,
        addresses: [],
        selectedCarCategory: '',
        bodyOptions: {
            id: '',
            body_type_id: 0,
            name: ''
        },
        bodyOptionsCharacteristics: [],
        bodyOptionsCharacteristicsWithValues: [],
        bodyMaxSize: {
            length: '',
            height: '',
            width: ''
        },
        bodyHeight: 0,
        additionalRequirements: [],
        selectedTariff: {
            id: '',
            name: ''
        },
        tariffTypesCoast: [],
        contacts: {
            full_name: '',
            phone: '',
            phone_ext: '',
            email: null
        },
        maxDimensions: false,
        agreementAccepted: false,
        phoneSended: false,
        phoneChecked: false,
        selectedPaymentType: PaymentTypes.card,
        TIN: '',
        cargo: {
            name: '',
            price: 0,
            places: [],
            pallets: [],
            packages: [],
            max_dimensions: false,
        },
        autoSelectVehicleCargo: {
            name: '',
            price: 0,
            places: [],
            pallets: [],
            packages: [],
            max_dimensions: false,
        }
    },
    newOrderCalcResponse: {
        points: [],
        service_information: '',
        items_by_route: [
            {
                cost: 0,
                name: ''
            }
        ],
        hours: 0,
        min_hours: 0,
        rate: '',
        min_cost: 0,
        items: [
            {
                cost: 0,
                name: ''
            }
        ],
        cost_by_hour: 0,
        cost: 0,
        tariff_type_id: '',
        errormessage: '',
        status: ''
    },
    newOrderPostResponse: {
        id: "",
        order_number: 0
    },
    packResponse: {
        packed_items: [],
        car_type_id: "",
        height: 0,
        width: 0,
        load_by_weight: 0,
        load_by_volume: 0,
        load_by_area: 0,
        total_weight: 0,
        total_volume: 0,
        total_area: 0
      },
    carTypesTree: [],
    carBodyTypes: [],
    carBodyOptions: [],
    carBodyOptionCharacteristics: [],
    carBodyOptionCharacteristicsValues: [],
    tariffTypes: [],
    palletTypes: [],
    packageTypes: [],
    aditionalRequirements: [],
    showNewOrder: false,
    showCalendar: false,
    showInputModal: false,
    showVehicleSelect: false,
    showAutoVehicleSelect: false,
    showAutoVehicleSelectSummary: false,
    showOrderCompletion: false,
    showPaymentDetails: false,
    showOrderIsReady: false,
    selectedDateFromCalendar: { date: '', fromDate: '', toDate: '' },
    travers: TraversTypes.from,
    currentID: 0,
    isHouseHas: false,
    onMapClick: false,
    clickableMap: true,
}

export const newUserOrderSelector = (state: RootState) => state.newUserOrder
export const getCategories = (state: RootState) => state.newUserOrder.carTypesTree
export const getTariffTypes = (state: RootState) => state.newUserOrder.tariffTypes

export const orderDataSelector = (state: RootState) => ({
            id: '',
            date: state.newUserOrder.newOrder.date,
            body_type_id: state.newUserOrder.newOrder.bodyOptions.body_type_id,
            body_option_id: state.newUserOrder.newOrder.bodyOptions.id,
            body_option_characteristics: [
                ...state.newUserOrder.newOrder.bodyOptionsCharacteristics.map(x => ({ id: x.id, value: '' })),
                ...state.newUserOrder.newOrder.bodyOptionsCharacteristicsWithValues.map(y => ({ id: y.body_option_characteristics_id, value: y.name }))
            ],
            additional_requirements: state.newUserOrder.newOrder.additionalRequirements.map(x => ({ id: x.id, value: false })),
            routes: state.newUserOrder.newOrder.addresses,
            cargo: state.newUserOrder.newOrder.cargo,
            tariff_type_id: state.newUserOrder.newOrder.selectedTariff.id,
            contacts: state.newUserOrder.newOrder.contacts,
            payment_type: state.newUserOrder.newOrder.selectedPaymentType,
            TIN: state.newUserOrder.newOrder.TIN,
            comment: '',
            car_type_id: state.newUserOrder.newOrder.selectedCarCategory,
            draft: false,
            max_dimensions: state.newUserOrder.newOrder.maxDimensions
})

export const newUserOrderSlicer = createSlice({
    name: 'newUserOrder',
    initialState,
    reducers: {
        setFromAddress(state, actions) {
            state.fromAddress = actions.payload
        },
        setToAddress(state, actions: PayloadAction<string>) {
            state.toAddress = actions.payload
        },
        setCurrentAddress(state, actions: PayloadAction<string>) {
            state.currentAddress = actions.payload
        },
        setCurrentCoords(state, actions: PayloadAction<Coords>) {
            state.currentCoords = actions.payload
        },
        setFromCoords(state, actions: PayloadAction<Coords>) {
            state.fromCoords = actions.payload
        },
        setToCoords(state, actions: PayloadAction<Coords>) {
            state.toCoords = actions.payload
        },
        setFromAdvancedFilled(state, actions: PayloadAction<boolean>) {
            state.fromAdvancedFilled = actions.payload
        },
        setHideElement(state, actions: PayloadAction<boolean>) {
            state.hideElement = actions.payload
        },
        setUrgency(state, actions: PayloadAction<string>) {
            state.newOrder.urgency = actions.payload
        },
        setDate(state, actions: PayloadAction<string>) {
            state.newOrder.date = actions.payload
        },
        setIsNewOrderCalcLoading(state, actions: PayloadAction<boolean>) {
            state.newOrder.isLoading = actions.payload
        },
        setNewOrderCalcResponse(state, actions: PayloadAction<OrderCalcResponse>) {
            state.newOrderCalcResponse = { ...actions.payload }
        },
        setNewOrderPostResponse(state, actions: PayloadAction<OrderPostResponseType>) {
            state.newOrderPostResponse = actions.payload
        },
        setStartedPoint(state) {
            // state.newOrder.addresses = [...state.newOrder.addresses, {
            //     id: state.newOrder.addresses.length ? Math.max(...state.newOrder.addresses.map(x => x.id)) + 1 : 1,
            //     adress: state.currentAddress,
            //     adress_comment: state.currentAdvanced.addressComment,
            //     adress_longitude: state.currentCoords.lon,
            //     adress_latitude: state.currentCoords.lat,
            //     company: '',
            //     contact_persons: [
            //         {
            //             full_name: state.currentAdvanced.person,
            //             phone: state.currentAdvanced.phone,
            //             phone_ext: '',
            //             email: ''
            //         }
            //     ],
            //     what_to_do: state.currentAdvanced.whatToDo,
            //     working_hours: {
            //         time_from: state.currentAdvanced.workTime.from,
            //         time_to: state.currentAdvanced.workTime.to,
            //         lunch_from: state.currentAdvanced.lunchTime.from,
            //         lunch_to: state.currentAdvanced.lunchTime.to,
            //         no_lunch: !!state.currentAdvanced.lunchTime.from,
            //         max_landing_time: ''
            //     },
            //     action_documents: false,
            //     action_loading: false,
            //     action_unloading: false,
            //     action_forwarder: false,
            //     files_ids: ['']
            // }]
        },
        setChangePoint(state, actions: PayloadAction<number>) {
            // state.newOrder.addresses.splice(state.newOrder.addresses.findIndex(x => x.id === actions.payload), 1, {
            //     id: actions.payload,
            //     adress: state.currentAddress,
            //     adress_comment: state.currentAdvanced.addressComment,
            //     adress_longitude: state.currentCoords.lon,
            //     adress_latitude: state.currentCoords.lat,
            //     company: '',
            //     contact_persons: [
            //         {
            //             full_name: state.currentAdvanced.person,
            //             phone: state.currentAdvanced.phone,
            //             phone_ext: '',
            //             email: ''
            //         }
            //     ],
            //     what_to_do: state.currentAdvanced.whatToDo,
            //     working_hours: {
            //         time_from: state.currentAdvanced.workTime.from,
            //         time_to: state.currentAdvanced.workTime.to,
            //         lunch_from: state.currentAdvanced.lunchTime.from,
            //         lunch_to: state.currentAdvanced.lunchTime.to,
            //         no_lunch: !!state.currentAdvanced.lunchTime.from,
            //         max_landing_time: ''
            //     },
            //     action_documents: false,
            //     action_loading: false,
            //     action_unloading: false,
            //     action_forwarder: false,
            //     files_ids: ['']
            // })
        },
        setDeletePoint(state, actions: PayloadAction<number>) {
            if (state.newOrder.addresses.findIndex(x => x.id === actions.payload) >= 0) state.newOrder.addresses.splice(state.newOrder.addresses.findIndex(x => x.id === actions.payload), 1)
        },
        setCarTypesTree(state, actions: PayloadAction<CarTypeTreeQueryResponse[]>) {
            state.carTypesTree = actions.payload
        },
        setCarBodyTypes(state, actions: PayloadAction<BodyTypesResponse[]>) {
            state.carBodyTypes = actions.payload
        },
        setCarBodyOptions(state, actions: PayloadAction<BodyOptionsResponse[]>) {
            state.carBodyOptions = actions.payload
        },
        setCarBodyOptionCharacteristics(state, actions: PayloadAction<BodyOptionCharacteristicsResponse[]>) {
            state.carBodyOptionCharacteristics = actions.payload
        },
        setCarBodyOptionCharacteristicsValues(state, actions: PayloadAction<BodyOptionCharacteristicsValuesResponse[]>) {
            state.carBodyOptionCharacteristicsValues = actions.payload
        },
        setTariffTypes(state, actions: PayloadAction<TariffTypesResponse[]>) {
            state.tariffTypes = actions.payload
        },
        setPalletTypes(state, actions: PayloadAction<PalletTypesQuery[]>) {
            state.palletTypes = actions.payload
        },
        setPackageTypes(state, actions: PayloadAction<PackageTypesQuery[]>) {
            state.packageTypes = actions.payload
        },
        setSelectedTariff(state, actions: PayloadAction<TariffTypesResponse>) {
            state.newOrder.selectedTariff = actions.payload
        },
        setAditionalRequirements(state, actions: PayloadAction<AditionalRequirementsTypes[]>) {
            state.aditionalRequirements = actions.payload
        },
        setSelectedDateFromCalendar(state, actions: PayloadAction<{ date: string, fromDate: string, toDate: string }>) {
            state.selectedDateFromCalendar = actions.payload
        },
        setShowNewOrder(state, actions: PayloadAction<boolean>) {
            state.showNewOrder = actions.payload
        },
        setShowCalendar(state, actions: PayloadAction<boolean>) {
            state.showCalendar = actions.payload
        },
        setShowInputModal(state, actions: PayloadAction<boolean>) {
            state.showInputModal = actions.payload
        },
        setShowVehicleSelect(state, actions: PayloadAction<boolean>) {
            state.showVehicleSelect = actions.payload
        },
        setShowAutoVehicleSelect(state, actions: PayloadAction<boolean>) {
            state.showAutoVehicleSelect = actions.payload
        },
        setShowAutoVehikleSelectSummary(state, actions: PayloadAction<boolean>) {
            state.showAutoVehicleSelectSummary = actions.payload
        },
        setShowOrderCompletion(state, actions: PayloadAction<boolean>) {
            state.showOrderCompletion = actions.payload
        },
        setShowPaymentDetails(state, actions: PayloadAction<boolean>) {
            state.showPaymentDetails = actions.payload
        },
        setShowOrderIsReady(state, actions: PayloadAction<boolean>) {
            state.showOrderIsReady = actions.payload
        },
        setTravers(state, actions: PayloadAction<TraversTypes>) {
            state.travers = actions.payload
        },
        setCurrentID(state, actions: PayloadAction<number>) {
            state.currentID = actions.payload
        },
        setIsHouseHas(state, actions: PayloadAction<boolean>) {
            state.isHouseHas = actions.payload
        },
        setOnMapClick(state, actions: PayloadAction<boolean>) {
            state.onMapClick = actions.payload
        },

        setCleareCurrent(state) {
            state.currentAddress = ''
            state.currentCoords = { lon: 0, lat: 0 }
            // state.currentAdvanced = {
            //     person: '',
            //     phone: '',
            //     workTime: { from: '', to: '' },
            //     lunchTime: { from: '', to: '' },
            //     addressComment: '',
            //     fromWho: '',
            //     toWhom: '',
            //     whatToDo: ''
            // }
        },
        setClickableMap(state, actions: PayloadAction<boolean>) {
            state.clickableMap = actions.payload
        },
        setHideNewOrderWindow(state, actions: PayloadAction<boolean>) {
            state.hideNewOrderWindow = actions.payload
        },
        setSelectedCarCategory(state, actions: PayloadAction<string>) {
            state.newOrder.selectedCarCategory = actions.payload
        },
        setBodyOptions(state, actions: PayloadAction<BodyOptions>) {
            state.newOrder.bodyOptions = actions.payload
        },
        deleteBodyOptions(state) {
            state.newOrder.bodyOptions = {
                id: '',
                body_type_id: 0,
                name: ''
            }
        },
        setBodyOptionsCharacteristics(state, actions: PayloadAction<BodyOptionsCharacteristics>) {
            state.newOrder.bodyOptionsCharacteristics = !state.newOrder.bodyOptionsCharacteristics.some(x => x.id === actions.payload.id) ? [...state.newOrder.bodyOptionsCharacteristics, actions.payload] : state.newOrder.bodyOptionsCharacteristics
        },
        deleteBodyOptionsCharacteristics(state, actions: PayloadAction<BodyOptionsCharacteristics>) {
            state.newOrder.bodyOptionsCharacteristics.forEach((v, i) => v.id === actions.payload.id && state.newOrder.bodyOptionsCharacteristics.splice(i, 1))
        },
        setCleareBodyOptionsCharacteristics(state) {
            state.newOrder.bodyOptionsCharacteristics = []
        },
        setBodyOptionsCharacteristicsWithValues(state, actions: PayloadAction<BodyOptionsCharacteristicsWithValues>) {
            state.newOrder.bodyOptionsCharacteristicsWithValues = [...state.newOrder.bodyOptionsCharacteristicsWithValues.filter(x => x.body_option_characteristics_id !== actions.payload.body_option_characteristics_id), actions.payload]
        },
        deleteBodyOptionsCharacteristicsWithValues(state, actions: PayloadAction<string>) {
            state.newOrder.bodyOptionsCharacteristicsWithValues = state.newOrder.bodyOptionsCharacteristicsWithValues.filter(x => x.body_option_characteristics_id !== actions.payload)
        },
        setCleareBodyOptionsCharacteristicsWithValues(state) {
            state.newOrder.bodyOptionsCharacteristicsWithValues = []
        },
        setBodyHeight(state, actions: PayloadAction<number>) {
            state.newOrder.bodyHeight = actions.payload
        },
        setCleareNewOrder(state) {
            state.newOrder = {
                urgency: '',
                date: '',
                isLoading: false,
                addresses: [],
                selectedCarCategory: '',
                bodyOptions: {
                    id: '',
                    body_type_id: 0,
                    name: ''
                },
                bodyOptionsCharacteristics: [],
                bodyOptionsCharacteristicsWithValues: [],
                bodyMaxSize: {
                    length: '',
                    height: '',
                    width: ''
                },
                bodyHeight: 0,
                additionalRequirements: [],
                selectedTariff: state.tariffTypes[0],
                tariffTypesCoast: [],
                contacts: {
                    full_name: '',
                    phone: '',
                    phone_ext: '',
                    email: null
                },
                maxDimensions: false,
                agreementAccepted: false,
                phoneSended: false,
                phoneChecked: false,
                selectedPaymentType: PaymentTypes.card,
                TIN: '',
                cargo: {
                    name: '',
                    price: 0,
                    places: [],
                    pallets: [],
                    packages: [],
                    max_dimensions: false,
                },
                autoSelectVehicleCargo: {
                    name: '',
                    price: 0,
                    places: [],
                    pallets: [],
                    packages: [],
                    max_dimensions: false,
                }
            }
            state.newOrderCalcResponse = {
            points: [],
            service_information: '',
            items_by_route: [
                {
                    cost: 0,
                    name: ''
                }
            ],
            hours: 0,
            min_hours: 0,
            rate: '',
            min_cost: 0,
            items: [
                {
                    cost: 0,
                    name: ''
                }
            ],
            cost_by_hour: 0,
            cost: 0,
            tariff_type_id: '',
            errormessage: '',
            status: ''
        }
        },
        addAdditionalRequirement(state, actions: PayloadAction<AditionalRequirementsTypes>) {
            state.newOrder.additionalRequirements = state.newOrder.additionalRequirements.some(x => x.id === actions.payload.id) ? state.newOrder.additionalRequirements : [...state.newOrder.additionalRequirements, actions.payload]
        },
        deleteAdditionalRequirement(state, actions: PayloadAction<AditionalRequirementsTypes>) {
            state.newOrder.additionalRequirements = state.newOrder.additionalRequirements.filter(x => x.id !== actions.payload.id)
        },
        setTariffTypesCoast(state, actions: PayloadAction<TariffTypesCost[]>) {
            !state.newOrder.tariffTypesCoast.length && state.newOrder.tariffTypesCoast.push(...actions.payload)
        },
        setMaxDimensions(state, actions: PayloadAction<boolean>) {
            state.newOrder.maxDimensions = actions.payload
        },
        setMaxCargoDimensions(state, actions: PayloadAction<boolean>) {
            state.newOrder.cargo.max_dimensions = actions.payload
        },
        setContactsFullName(state, actions: PayloadAction<string>) {
            state.newOrder.contacts.full_name = actions.payload
        },
        setContactsPhone(state, actions: PayloadAction<string>) {
            state.newOrder.contacts.phone = actions.payload
        },
        setContactsEmail(state, actions: PayloadAction<string | null>) {
            state.newOrder.contacts.email = actions.payload
        },
        setAgreementAccepted(state) {
            state.newOrder.agreementAccepted = !state.newOrder.agreementAccepted
        },
        setPhoneSended(state, actions: PayloadAction<boolean>) {
            state.newOrder.phoneSended = actions.payload
        },
        setPhoneChecked(state, actions: PayloadAction<boolean>) {
            state.newOrder.phoneChecked = actions.payload
        },
        setOrderTIN(state, actions: PayloadAction<string>) {
            state.newOrder.TIN = actions.payload
        },
        setSelectedPaymentType(state, actions: PayloadAction<PaymentTypes>) {
            state.newOrder.selectedPaymentType = actions.payload
        },
        setCleareNewOrderCalcResponse(state) {
            state.newOrderCalcResponse = {
                points: [],
                service_information: '',
                items_by_route: [
                    {
                        cost: 0,
                        name: ''
                    }
                ],
                hours: 0,
                min_hours: 0,
                rate: '',
                min_cost: 0,
                items: [
                    {
                        cost: 0,
                        name: ''
                    }
                ],
                cost_by_hour: 0,
                cost: 0,
                tariff_type_id: '',
                errormessage: '',
                status: ''
            }
        },
        setCleareNewOrderPostResponse(state) {
            state.newOrderPostResponse = {
                id: "",
                order_number: 0
            }
        },
        setPackResponse(state, actions: PayloadAction<PackResponseType>) {
            state.packResponse = actions.payload
        },
        setClearePackResponse(state) {
            state.packResponse = {
                packed_items: [],
                car_type_id: "",
                height: 0,
                width: 0,
                load_by_weight: 0,
                load_by_volume: 0,
                load_by_area: 0,
                total_weight: 0,
                total_volume: 0,
                total_area: 0
              }
        },
        addPlace(state, actions: PayloadAction<CargoPlaceType>) {
            state.newOrder.cargo.places = [...state.newOrder.cargo.places, actions.payload]
        },
        changePlace(state, actions: PayloadAction<{item:CargoPlaceType, index: number}>) {
            state.newOrder.cargo.places[actions.payload.index] = actions.payload.item
        },
        removePlace(state, actions: PayloadAction<number>) {
            state.newOrder.cargo.places.splice(actions.payload, 1)
        },
        addPallet(state, actions: PayloadAction<CargoPalletsType>) {
            state.newOrder.cargo.pallets = [...state.newOrder.cargo.pallets, actions.payload]
        },
        changePallet(state, actions: PayloadAction<{item:CargoPalletsType, index: number}>) {
            state.newOrder.cargo.pallets[actions.payload.index] = actions.payload.item
        },
        removePallet(state, actions: PayloadAction<number>) {
            state.newOrder.cargo.pallets.splice(actions.payload, 1)
        },
        addPackage(state, actions: PayloadAction<CargoPackegesType>) {
            state.newOrder.cargo.packages = [...state.newOrder.cargo.packages, actions.payload]
        },
        changePackage(state, actions: PayloadAction<{item:CargoPackegesType, index: number}>) {
            state.newOrder.cargo.packages[actions.payload.index] = actions.payload.item
        },
        removePackage(state, actions: PayloadAction<number>) {
            state.newOrder.cargo.packages.splice(actions.payload, 1)
        },
}
})

export const filterCategory = (categories: CarTypeTreeQueryResponse[], category_id:string, body_id:string, activeCharacteristics:BodyOptionCharacteristicsResponse[] ) => {

    let m_categories : CarTypeTreeQueryResponse[] = JSON.parse(JSON.stringify(categories));
    m_categories = m_categories.filter(category => !category_id || category.id === category_id)
        .filter(category => {

            category.body_options = category.body_options.filter(bo=> body_id=='' || bo.id === body_id)
                .filter(car_body => {
                    console.log('category.body_options car_body', car_body)
                    if (activeCharacteristics.length === 0) return true;
                    let tmp = car_body.body_option_characteristics.filter(character => {
                        let index = activeCharacteristics.filter(active_ch => active_ch.id === character.id)
                            .findIndex(active_ch => {
                                    if (active_ch.type !== CharacteristicTypeEnum.Ref) return true;
                                    let selectedValue = character.body_option_characteristics_values.find(val => val.id === active_ch.value);
                                    return !!selectedValue;

                                }
                            );
                        return index >= 0;
                    });
                    return tmp.length >= activeCharacteristics.length;
                });
            return category.body_options.length
        });

    return m_categories;
};

export const  getBodyOptionsList  = (categories_full : CarTypeTreeQueryResponse[], selected_id: string = '') : BodyOptionsResponse [] => {
    let body_options : BodyOptionsResponse[] = [];
    categories_full.filter( category=> category.id == selected_id || selected_id == '')
        .forEach( category=> {

            let op : BodyOptionsResponse[] = category.body_options.filter( x => !body_options.find(z=> z.id === x.id) );
            body_options = body_options.concat([...op])
    });
    console.log('body_options', body_options);
    // body_options.forEach(x=> {
    //    // if (x.name !== "ЦМФ" && x.name && x.name.length>0)
    //    //     x.name = x.name.toUpperCase()[0] + x.name.toLowerCase().substring(1, x.name.length) ;
    //     x.name
    // });
    return  body_options;
};


export const  getBOCharacteristics  = (categories_full : CarTypeTreeQueryResponse[], category_id: string, body_id: string) : BodyOptionCharacteristicsResponse[] => {


    let body_option_characteristics: BodyOptionCharacteristicsResponse[] = [];
    // body_options.forEach( body=> {
    //     body_option_characteristics = body_option_characteristics.concat(
    //         [...body.body_option_characteristics.filter( x => !body_option_characteristics.find(z=> z.id === x.id) )])
    // });
    categories_full.filter( category=> category.id == category_id || category_id == '')
        .forEach( category=> {

            category.body_options.filter( x => x.id==body_id || body_id == '' )
                .forEach( body=> {
                    body_option_characteristics = body_option_characteristics.concat(
                           [...body.body_option_characteristics.filter( x => !body_option_characteristics.find(z=> z.id === x.id) )])
                });

        });
    return body_option_characteristics;
};

export const  getCharacteristicValueList = (categories : CarTypeTreeQueryResponse[], characteristic:BodyOptionCharacteristicsResponse, bodyID:string, categoryID:string) : DropDownListItem[] => {
    let dd : DropDownListItem[] = [{id:'', text :'Любой'}];
    categories.filter(x=> x.id === categoryID || categoryID === '')
        .forEach(category => {
            category.body_options.filter( bo=> bo.id === bodyID || bodyID ==='')
                .forEach( bo=> {
                    bo.body_option_characteristics.filter(ch => ch.id === characteristic.id)
                        .forEach(ch=> {
                            ch.body_option_characteristics_values.forEach(v=> {
                                if (!dd.some(lv=>lv.id === v.id)) dd.push({id:v.id, text:v.name});
                            })
                        })
                })
        });
    return dd;
};

export const getActiveCharacteristics = (chs : {id:string, value:boolean|string}[], categories_full : CarTypeTreeQueryResponse[]) : BodyOptionCharacteristicsResponse[] => {
    let arr : BodyOptionCharacteristicsResponse[] = [];
    let all = getBOCharacteristics(categories_full, '', '' );
    chs.filter(x=> x.value != 'false' && x.value != false && +x.value != 0)
       .forEach(x=> {
            let v = all.find(f=> f.id == x.id  );
            if (v)
                arr.push({...v, value : x.value, selected:true})
        })
    return arr;
}

export const {
    setFromAddress,
    setToAddress,
    setCurrentAddress,
    setFromCoords,
    setToCoords,
    setCurrentCoords,
    setFromAdvancedFilled,
    setHideElement,
    setUrgency,
    setDate,
    setIsNewOrderCalcLoading,
    setNewOrderCalcResponse,
    setNewOrderPostResponse,
    setStartedPoint,
    setChangePoint,
    setDeletePoint,
    setCarTypesTree,
    setCarBodyTypes,
    setCarBodyOptions,
    setCarBodyOptionCharacteristics,
    setCarBodyOptionCharacteristicsValues,
    setTariffTypes,
    setPackageTypes,
    setPalletTypes,
    setSelectedTariff,
    setAditionalRequirements,
    setSelectedDateFromCalendar,
    setShowNewOrder,
    setShowCalendar,
    setShowInputModal,
    setShowVehicleSelect,
    setShowAutoVehicleSelect,
    setShowAutoVehikleSelectSummary,
    setShowOrderCompletion,
    setShowPaymentDetails,
    setShowOrderIsReady,
    setTravers,
    setCurrentID,
    setIsHouseHas,
    setOnMapClick,
    // setCurrentAdvanced,
    setCleareCurrent,
    setClickableMap,
    setHideNewOrderWindow,
    setSelectedCarCategory,
    setBodyOptions,
    deleteBodyOptions,
    setBodyOptionsCharacteristics,
    deleteBodyOptionsCharacteristics,
    setCleareBodyOptionsCharacteristics,
    setBodyOptionsCharacteristicsWithValues,
    deleteBodyOptionsCharacteristicsWithValues,
    setCleareBodyOptionsCharacteristicsWithValues,
    setBodyHeight,
    setCleareNewOrder,
    addAdditionalRequirement,
    deleteAdditionalRequirement,
    setTariffTypesCoast,
    setMaxDimensions,
    setMaxCargoDimensions,
    setContactsFullName,
    setContactsPhone,
    setContactsEmail,
    setAgreementAccepted,
    setPhoneSended,
    setPhoneChecked,
    setOrderTIN,
    setSelectedPaymentType,
    setCleareNewOrderCalcResponse,
    setCleareNewOrderPostResponse,
    setPackResponse,
    setClearePackResponse,
    addPlace,
    changePlace,
    removePlace,
    addPallet,
    changePallet,
    removePallet,
    addPackage,
    changePackage,
    removePackage
} = newUserOrderSlicer.actions

export default newUserOrderSlicer.reducer
