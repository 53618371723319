import './AppLoadingForm.css'
import React from "react";
import Overlay from "../Overlay/Overlay";
import {ThreeDots} from "react-loader-spinner";
interface IProps {
    message?:string
}
const AppLoadingForm:React.FC<IProps> = ({message}) => {
    return (
        <>
            <div className="app_loading_form">
                <div className="app_loading_form__title">
                    {message ? message : 'Пожалуйста подождите, идет загрузка данных !'}

                </div>
                <div className="app_loading_form__spinner">
                    <ThreeDots  color={  "orange"} width={"75"} height={"75"} />
                </div>
            </div>
            <Overlay zIndex={     1350 } />
        </>
    )

}

export default AppLoadingForm;
