export class KalmanFilterGPS {
    variance: number = -1;
    minAccuracy: number = 0.1;
    decay: number = 3;
    timestampInMs: number = 0;
    lat: number = 0;
    lng: number = 0;

    constructor(decay = 3) {
        this.decay = decay
    }

    process (lat:number, lng:number, accuracy:number, timestampInMs:number) {
        if (accuracy < this.minAccuracy) accuracy = this.minAccuracy;

        if (this.variance < 0) {
            this.timestampInMs = timestampInMs;
            this.lat = lat;
            this.lng = lng;
            this.variance = accuracy * accuracy
        } else {
            const timeIncMs = timestampInMs - this.timestampInMs;

            if (timeIncMs > 0) {
                this.variance += (timeIncMs * this.decay * this.decay) / 1000;
                this.timestampInMs = timestampInMs;
            }

            const _k = this.variance / (this.variance + (accuracy * accuracy));
            this.lat += _k * (lat - this.lat);
            this.lng += _k * (lng - this.lng);

            this.variance = (1 - _k) * this.variance;
        }

        return [this.lat, this.lng];
    }
};


