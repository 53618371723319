import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/redux/store'
import { IArticleItem } from '../ArticleItem/ArticleItem.types'

export interface articleListState {
  articles: IArticleItem[]
  title: string
}

const initialState: articleListState = {
  articles: [],
  title: ''
}
export const articleListSelector = (state: RootState) => state.articleList
export const articleListSlice = createSlice({
  name: 'articleList',
  initialState,
  reducers: {
    setArticleList (state, action) {
      const { articles, title } = action.payload
      state.articles = articles
      state.title = title
    },
    clear (state) {
      state.articles = []
      state.title = ''
    }
  }
})

export const { setArticleList } = articleListSlice.actions

export default articleListSlice.reducer
