import styled from 'styled-components'

const StyledSvg = styled.svg`
  cursor: pointer;
`

export const CLockIcon = () => {
  return (
    <StyledSvg
      width='20'
      height='20'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='8' cy='8' r='7' stroke='#888E99' strokeWidth='2' />
      <path d='M11.5 10L7.5 8V4' stroke='#888E99' strokeWidth='2' />
    </StyledSvg>
  )
}
