export const MAX_LENGTH_FULLNAME = 50
export const MAX_LENGTH_INN = 12
export const checkIsValidFullName = (fullName: string) => {
  const FULLNAME_PATTERN = /[a-zA-ZА-Яа-яЁё ]{0,50}$/
  return (fullName === '' || FULLNAME_PATTERN.test(fullName))
}
export const checkIsValidEmail = (email: string) => {
  const EMAIL_PATTERN = /^([\w.-]+)@([\w-]+)((.(\w){2,3})+)$/
  return email === '' || EMAIL_PATTERN.test(email)
}

export const checkIsValidInn = (inn: string) => {
  const INN_PATTERN = /^\d{10}(\d{2})?$/
  return inn === '' || INN_PATTERN.test(inn)
}
