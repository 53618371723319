import React, {FC, useEffect, useState} from "react";
import s from "./AppendCargoForm.module.scss";
import {CargoParamInput} from "./CagroParamInput";
import {CargoSize} from "../../../CreateOrderType";
import {allCargoTypes, TypeOfCargo} from "../VehicleSelectAuto";
import {type} from "os";
import Overlay from "../../../../Overlay/Overlay";
import CloseIcon from "../../../../../../../assets/svg/CloseDetailing.svg";

export enum EnumActionMode{None, Append, Edit}
interface IProps {
    value: allCargoTypes,
    cargoType:TypeOfCargo,
    additional?:{id:string, name:string},
    onChange:( cargo: allCargoTypes, type: TypeOfCargo, id: string)=>void,
    mode:EnumActionMode,
    onCancel:()=>void;
}
export const AppendCargoForm:FC<IProps> = ({value,cargoType,additional,onChange, mode,onCancel}) =>{
    const [localState,setLocalState] = useState<allCargoTypes>(value);

    const canSave = () => {
        if (!localState.size.length  || !localState.size.weight || !localState.size.height  || !localState.size.width ) return false;
        if ((cargoType == TypeOfCargo.pallets || cargoType == TypeOfCargo.packages) && !localState.quantity) return false
        return true;
    };

    const getTitle = () => {
        if (cargoType == TypeOfCargo.size && mode == EnumActionMode.Append) return "Добавление груза по габаритам";
        if (cargoType == TypeOfCargo.size && mode == EnumActionMode.Edit) return "Изменение габаритов груза ";

        if (cargoType == TypeOfCargo.pallets && mode == EnumActionMode.Append) return "Добавление паллета " + (additional? additional.name : '')+ " по габаритам";
        if (cargoType == TypeOfCargo.pallets && mode == EnumActionMode.Edit) return "Изменение габаритов паллета "+ (additional? additional.name : '');


        if (cargoType == TypeOfCargo.packages && mode == EnumActionMode.Append) return "Добавление упаковки типа " + (additional? '"' + additional.name + '"' : '') + " по габаритам";
        if (cargoType == TypeOfCargo.packages && mode == EnumActionMode.Edit) return "Изменение габаритов упаковки типа "+ (additional? '"' + additional.name + '"' : '') ;
    }

    useEffect(()=>{

    }, [localState])

    return  (
        <>
            <div className={s.AppendCargoForm}>
                <div className={s.header}>
                    <div>{ getTitle() }</div>
                    <img alt='Закрыть автоподбор ТС' src={CloseIcon} onClick={onCancel}/>
                </div>
                <div className={s.line}/>
                <div className={s.cargoParams}>
                    <CargoParamInput value={localState.size.length} units={'м'} name={"Длина"}
                                     onChange={(val)=> setLocalState({...localState, size: {...localState.size, length:val}})} />
                    <CargoParamInput value={localState.size.width} units={'м'} name={"Ширина"}
                                     onChange={(val)=> setLocalState({...localState, size: {...localState.size, width:val}})} />
                    <CargoParamInput value={localState.size.height} units={'м'} name={"Высота"}
                                     onChange={(val)=> setLocalState({...localState, size: {...localState.size,  height:val}})} />
                    {(cargoType == TypeOfCargo.pallets || cargoType == TypeOfCargo.packages) &&
                        <CargoParamInput value={localState.quantity ? localState.quantity : 0} units={'шт'} name={"Кол-во"}
                                         onChange={(val)=> setLocalState({...localState, quantity:val})} />
                    }
                    <CargoParamInput value={localState.size.weight} units={'кг'} name={"Вес"}
                                     onChange={(val)=> setLocalState({...localState,  size: {...localState.size, weight:val}})} />
                </div>
                <div className={s.saveButton + ' ' + (canSave() ? s.active : ' ')} onClick={()=> {if (canSave()) onChange(localState, cargoType, additional ? additional.id : '')}}>
                    Сохранить
                </div>
            </div>
            <Overlay is_visible={true} zIndex={999} onClick={onCancel}/>
        </>
    )
};
