import {TypeLonLat} from "../../modules/Home/components/NewUserOrder/CreateOrderType";

export const  MARKER_4 = 'MARKER_4';
export const  ROUTE_DRIVER_LINE = 'route_driver_line';
export const  ROUTE_DRIVER_LINE_CAR = 'route_driver_line_car';
export const  KALMAN_ROUTE_DRIVER_LINE_CAR = 'kalman_route_driver_line_car';

export class mapMarker   {
    lon: number = 0;
    lat: number = 0;
    type: number = 0;
    index: number = 0;
    title: string = '';
    text: string = '';
    is_show_text: boolean = false;
    bg_color: string= '';
}

export class mapRoute   {
    coordinate: TypeLonLat[] = [];
    polylineArray: string[] =[];
    color : string = 'rgba(66, 170, 255, 0.8)';
    width : number = 5;
    type: number = 1;
}

export type routeRequestParamsType = {
    routingMode: string
    transportMode: string
    origin: string | undefined
    destination: string | undefined
    return: string
    via: string | undefined
}
