import styled from 'styled-components'

export const StyledRegistrationFormBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  grid-gap: 1rem;
`
export const StyledInn = styled.div`
  position: relative;
`
export const StyledInnSubscription = styled.span`
  position: absolute;
  bottom: 0;
  left: 16px;
  font-size: 14px;
  color:  var(--grey);
  white-space: nowrap;
`
export const StyledAgreement = styled.div`
  margin-top: 40px;
`
