import Category1 from '../../../../assets/icons/Categories/Category1'
import Category1Plus from '../../../../assets/icons/Categories/Category1Plus'
import Category2 from '../../../../assets/icons/Categories/Category2'
import Category2Plus from '../../../../assets/icons/Categories/Category2Plus'
import Category3 from '../../../../assets/icons/Categories/Category3'
import Category4 from '../../../../assets/icons/Categories/Category4'
import Category5 from '../../../../assets/icons/Categories/Category5'
import Category6 from '../../../../assets/icons/Categories/Category6'
import Category7 from '../../../../assets/icons/Categories/Category7'
import { IVehicle } from '../Order/Order.types'
import { ICategory } from './OrderItem.types'
import {BodyOptions} from "../../../Home/components/NewUserOrder/NewUserOrderSlicer";
import {BodyOptionsResponse} from "../../../../api/orderApi";

export const getBodyName = (bodyOptions: BodyOptionsResponse[], carBodyOptionId:string) => {
  let val = bodyOptions.find(x=> x.id == carBodyOptionId);
  return val ? val.name : '';
}


export const getCategorySvg = (categories: ICategory[], carTypeId: string) => {
  const categoryName = getCategoryName(categories, carTypeId);
  const hasPlus = categoryName.indexOf('+')
  const countLastSymbols = hasPlus !== -1 ? 2 : 1
  let categoryNumber = categoryName.substring(
    categoryName.length - countLastSymbols,
    categoryName.length
  )

  switch (categoryNumber) {
    case '1':
    default:
      return <Category1 />
    case '1+':
      return <Category1Plus />
    case '2':
      return <Category2 />
    case '2+':
      return <Category2Plus />
    case '3':
      return <Category3 />
    case '4':
      return <Category4 />
    case '5':
      return <Category5 />
    case '6':
      return <Category6 />
    case '7':
      return <Category7 />
  }
}

export const getCategoryName = (categories: ICategory[], carTypeId: string)=>{
  const categoryNameIndex = categories.findIndex(item => item.id === carTypeId)
  return categoryNameIndex !== -1 ? categories[categoryNameIndex].name : ''
}

export const getVehicle = (categories: ICategory[], carTypeId: string) => {
  const categoryIndex = categories.findIndex(item => item.id === carTypeId)
  const category = categories[categoryIndex]
  const vehicle: IVehicle = category
    ? {
        length: category.length_to,
        width: category.width_to,
        height: category.height_to,
        max_weight: category.weight_to
      }
    : {
        length: 0,
        width: 0,
        height: 0,
        max_weight: 0
      }
  return vehicle
}
