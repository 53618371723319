const Category4 = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='90'
      height='32'
      fill='none'
      viewBox='0 0 90 32'
    >
      <path
        fill='#000'
        d='M40 31c2.099 0 3.8-2.015 3.8-4.5S42.099 22 40 22c-2.099 0-3.8 2.015-3.8 4.5S37.901 31 40 31zM27.5 31c1.933 0 3.5-2.015 3.5-4.5S29.433 22 27.5 22 24 24.015 24 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path
        fill='#5364FF'
        d='M53 26H17c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3h36c.6 0 1 .4 1 1v21c0 .6-.4 1-1 1z'
      ></path>
      <path
        fill='#000'
        d='M67 31c2.099 0 3.8-2.015 3.8-4.5S69.099 22 67 22c-2.099 0-3.8 2.015-3.8 4.5S64.901 31 67 31zM55.5 31c1.933 0 3.5-2.015 3.5-4.5S57.433 22 55.5 22 52 24.015 52 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path fill='#BCC0CA' d='M50 26H27v2h23v-2z'></path>
      <path
        fill='#3C4AC1'
        d='M67 26H50c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1h15c1.7 0 3 1.3 3 3v19c0 .6-.4 1-1 1z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M74 24c0 1-1 4-1 4H50v-7c0-5.6.5-11.7.8-14.2.1-1 1.2-2.8 3-2.8h10.9c6.3 0 8.5 4.4 8.7 6.3.3 2.5.6 5.9.6 8.7v5z'
      ></path>
      <path
        fill='#000'
        d='M50 26H29v2h44s.1-.4.3-1H51c-.6 0-1-.4-1-1z'
        opacity='0.1'
      ></path>
      <path
        fill='#000'
        d='M58 26.5h-5V28h5v-1.5zM73.4 10.5H60.9v4.7c0 1 .8 1.9 1.8 2 1.6.1 4.1.3 7.8.3 3.1 0 3.2-.4 3.5-.5-.1-2.2-.4-4.6-.6-6.5z'
      ></path>
      <path
        fill='#000'
        d='M54.5 31c1.933 0 3.5-2.015 3.5-4.5S56.433 22 54.5 22 51 24.015 51 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path
        fill='#ECEDEF'
        d='M53.8 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z'
      ></path>
      <path
        fill='#000'
        d='M56.6 15.6c-1-.2-1.8-1.1-1.8-2.2v-3.1h5.5v6.1l-3.7-.8z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M60 10.5V16l-3.4-.7c-.9-.2-1.6-1-1.6-2v-2.9h5v.1zm.5-.5h-6v3.4c0 1.2.8 2.2 2 2.5l3.4.7.6.1V10z'
      ></path>
      <path fill='#fff' d='M74 22H62c0 .6.4 1 1 1h11v-1z'></path>
      <path
        fill='#000'
        d='M76 31H13c0-.6.5-1 1-1h61c.5 0 1 .4 1 1zM30 26.5h-5V28h5v-1.5z'
      ></path>
      <path
        fill='#000'
        d='M26.5 31c1.933 0 3.5-2.015 3.5-4.5S28.433 22 26.5 22 23 24.015 23 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path
        fill='#ECEDEF'
        d='M25.9 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M60 15v1c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1v3z'
      ></path>
    </svg>
  )
}

export default Category4
