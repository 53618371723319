import React, {useRef, useState} from "react";
import {CloseIcon} from "../../../../assets/icons/CloseIcon";
import {BankingInfo, CUserProfile, editingChars, EnumEditOneRecordType} from "./userProfile.type";
import {BankSuggestions} from "react-dadata";
import {daDataToken} from "../../../../api/api.constants";
import {setUserProfile, userProfileSelector} from "./userProfileSlice";
import {useAppDispatch, useAppSelector} from "../../../../app/redux/hooks";
import {useClientsProfileMutation} from "../../../../api/orderApi";
import {showErrorMessage} from "../../../../components/InfoMessage/infoMessageSlice";
import {ThreeDots} from "react-loader-spinner";
interface IProps{
    company_index:number
    onClose : ()=>void
}
const ProfileBankInfo : React.FC<IProps> = ({onClose,company_index}) =>{
    const dispatch = useAppDispatch();
    const userProfile = useAppSelector(userProfileSelector);

    const [isBIKEntered, setBIKEntered] = useState(userProfile.profile.companies[company_index].bank && userProfile.profile.companies[company_index].bank.BIK!='');
    const [isAccountEntered, setAccountEntered] = useState(userProfile.profile.companies[company_index].bank && userProfile.profile.companies[company_index].bank.account_number!='');
    const [wasChanged, setChanged] = useState(false);
    const [wasError, setError] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const bicRef = useRef<BankSuggestions>(null);
    const [localState, setLocalState] = useState<BankingInfo>(  userProfile.profile.companies[company_index].bank ?
                                                                userProfile.profile.companies[company_index].bank : new BankingInfo());
    const [profile] = useClientsProfileMutation();

    const canSave = () => {
        return isBIKEntered && isAccountEntered
    }

    const saveData = () => {
        console.log('company_index', company_index)
        let data : CUserProfile = {...userProfile.profile, companies: [...userProfile.profile.companies]};
        data.companies[company_index] = {...data.companies[company_index], bank: localState};
        setSaving(true);
        profile(data)
            .unwrap()
            .then((res)=> {
                setSaving(false);
                console.log('updateData res', res);
                if (!res || typeof res != 'object' ) return showErrorMessage( 'Ошибка сохранения данных', dispatch);
                if (res.status=='error') return showErrorMessage( 'Ошибка сохранения данных: ' + res.error_message, dispatch);
                dispatch( setUserProfile( data) );
                onClose();
            })
            .catch(e=>{ setSaving(false); return showErrorMessage( 'Ошибка сохранения данных', dispatch);});
    };

    return (
        <div className="profile_edit_one_record">
            <div className="profile_edit_one_record_window">
                <div className="title">
                    <div className="inner-text">
                        <div>Банковские реквизиты компании</div>
                        <CloseIcon onClick={onClose}/>
                    </div>
                </div>
                <div className="content">
                    <div id="bank_bic" className={'bank_input' + (isBIKEntered ? ' bank_input_complete' : (wasError ? ' error': ''))}>
                        <BankSuggestions
                            token={daDataToken}
                            ref={bicRef}
                            defaultQuery={userProfile.profile.companies[company_index].bank ? userProfile.profile.companies[company_index].bank.BIK : ''}
                            count={5}
                            onChange={(suggestion) => {
                                setChanged(true);
                                if (suggestion && suggestion.data && suggestion.data.state &&
                                    suggestion.data.state.status === 'ACTIVE') {
                                    setLocalState((s)=>  ({...s,  BIK:suggestion.data.bic,
                                        correction_number: suggestion.data.correspondent_account,
                                        bank: {...s.bank, correction_number: suggestion.data.correspondent_account,
                                            BIK: suggestion.data.bic, TIN:  suggestion.data.inn,
                                            KPP:  suggestion.data.kpp, adress: suggestion.value,
                                            name: suggestion.value}
                                    } ));
                                    // console.log(suggestion.value, suggestion.data);
                                    if (bicRef.current) bicRef.current.setInputValue(suggestion.data.bic);
                                    setBIKEntered(true);
                                }
                            }}
                            inputProps={{className: 'bank_input_text', placeholder: 'БИК банка', type: 'number',
                                onKeyDown: (e: React.KeyboardEvent) => {
                                    if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
                                        (/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 9)) {
                                        e.preventDefault();
                                    }
                                },
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    setChanged(true);
                                    setLocalState((s)=>({...s, BIK: e.target.value, bank:{...localState.bank, BIK: e.target.value} }));
                                    setBIKEntered(e.target.value.length === 9)

                                },
                            }}
                        />
                    </div>
                    <div id="bank_name" className={'bank_input bank_autofill' + (isBIKEntered  ? ' bank_input_complete' : '')}>
                        <input className="bank_input_text" placeholder="Наименование банка" type="text" value={localState.bank.name} readOnly/>
                    </div>
                    <div id="bank_corr" className={'bank_input bank_autofill' + (isBIKEntered  ?
                        ' bank_input_complete' : '')}>
                        <input className="bank_input_text" placeholder="Корр. счет банка" type="number"
                               value={localState.correction_number} readOnly
                        />
                    </div>
                    <div id="bank_inn" className={'bank_input bank_autofill' + (isBIKEntered ?' bank_input_complete' : '')}>
                        <input className="bank_input_text" placeholder="ИНН банка" type="number"
                               value={localState.bank.TIN} readOnly
                        />
                    </div>
                    <div id="bank_kpp" className={'bank_input bank_autofill' + (isBIKEntered ? ' bank_input_complete' : '')}>
                        <input className="bank_input_text" placeholder="КПП банка" type="number"
                               value={localState.bank.KPP} readOnly
                        />
                    </div>
                    <div id="bank_address" className={'bank_input bank_autofill' + (isBIKEntered ? ' bank_input_complete' : '')}>
                        <input className="bank_input_text" placeholder="Юридический адрес банка" type="text"
                               value={localState.bank.adress || ''} readOnly
                        />
                    </div>
                    <div id="bank_account" className={'bank_input' + (isAccountEntered ? ' bank_input_complete' : (wasError ? ' error' : ''))}>
                        <input className="bank_input_text" placeholder="Рассчетный счет в банке" type="number"
                               value={localState.account_number} onKeyDown={(e: React.KeyboardEvent) => {
                            if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
                                (/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 20)) {
                                e.preventDefault();
                            }
                        }}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                   setChanged(true);
                                   setLocalState((s)=>  ({...s,  account_number:e.target.value}));
                                   setAccountEntered( e.target.value.length === 20)
                               }}
                        />
                    </div>

                    <div className="button-area">
                        {!isSaving && <button onClick={saveData} disabled={!canSave()}> Сохранить </button>}
                        {isSaving && <div className='saving-button'><div style={{marginRight:10}}>Сохранение данных</div><ThreeDots  color={  "#999" } width={40} height={40}  /></div>}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ProfileBankInfo;
