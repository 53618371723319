import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/redux/store";
import { mapMarker, mapRoute } from "./hereMapTypes";
import {TypeLonLat} from "../../modules/Home/components/NewUserOrder/CreateOrderType";

export const hereMapSelector = (state: RootState) => state.hereMap;

export interface HomeState {
  markers: mapMarker[]
  routes: mapRoute[]
  car_position: TypeLonLat [][]
  driver_route: number[][],
  point_index: number,
  left_time: number
  full_time: number
  full_distance: number
  left_distance: number
}

const initialState: HomeState = {
  markers: [new mapMarker(), new mapMarker()],
  routes: [],
  car_position: [],
  driver_route: [],
  point_index: -1,
  left_time: 0,
  full_time: 0,
  full_distance: 0,
  left_distance: 0,
};

export const hereMapSlice = createSlice({
  name: "hereMap",
  initialState,
  reducers: {
    setMarkers(state, action) {
      state.markers =  [...action.payload]
    },
    setClearMarkers(state) {
      state.markers = []
    },
    setRoutes(state, actions) {
      state.routes = [...actions.payload]
    },
    setClearRoutes(state) {
      state.routes = []
    },
    setCarPosition(state, actions) {
      if (Array.isArray(actions.payload)) {
        state.car_position.push(actions.payload)
        //@ts-ignore
        if (!window.car_position) window.car_position = [];

        //@ts-ignore
        window.car_position.push(actions.payload)

      }
      // console.log('setCarPosition ', actions.payload);
    },
    setCarPositionPop(state) {
       if (state.car_position.length>0)
        state.car_position.shift();
      //@ts-ignore
      if (window.car_position && window.car_position.length) window.car_position.shift();

      console.log('setCarPositionPop ');
    },
    setCarPositionEmpty(state) {
      state.car_position =[];
      //@ts-ignore
      if (window.car_position) window.car_position=[];

      console.log('setCarPositionPop ');
    },
    setDriverCurrentRoute(state, actions) {
      state.driver_route =  [...actions.payload]
    },
    setNextPointIndex(state, actions) {
      state.point_index =  actions.payload
    },
    setLeftTime(state, actions) {
      state.left_time =  actions.payload
    },
    setFullTime(state, actions) {
      state.full_time =  actions.payload
    },
    setFullDistance(state, actions) {
      state.full_distance =  actions.payload
    },
    setLeftDistance(state, actions) {
      state.left_distance =  actions.payload
    },
  },
});

export const {
  setMarkers,
  setClearMarkers,
  setRoutes,
  setClearRoutes,
  setCarPosition,
  setCarPositionPop,
  setCarPositionEmpty,
  setDriverCurrentRoute,
  setNextPointIndex,
  setLeftTime,
  setFullTime,
  setFullDistance,
  setLeftDistance,
} = hereMapSlice.actions;

export default hereMapSlice.reducer;
