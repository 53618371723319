import React, {useEffect, useState} from "react";
import './TimeBtn.css'
import {useDispatch} from "react-redux";

import {useAppSelector} from "../../../../../app/redux/hooks";
import {hereMapSelector, setCarPosition,  setNextPointIndex, setLeftTime, setFullTime, setFullDistance} from "../../../../../components/HereMap/hereMapSlice";

import {TypeLonLat, TypeLonLatTime} from "../../../../Home/components/NewUserOrder/CreateOrderType";
import {OrderCarPositionsResponse,
    useGetCarPointPositionCurrentMutation,
    useGetCarPointPositionMutation, useGetNextRoutePointMutation} from "../../../../../api/orderApi";
import {orderSelector, setOrderId} from "../orderSlice";
import {CONST_TIME_1C, IS_DEBUG_MODE, TIME_FOR_ROUTE_CACHE_SECONDS} from "../../../../../api/api.constants";
import {useGetRouteMutation} from "../../../../../api/here-api";
import {showInfoMessage} from "../../../../../components/InfoMessage/infoMessageSlice";


export const toTwoDigits = (val:string | number) : string => {
    if (( Math.abs(+val) + '').length == 1) {
        if (val < 0) return  '-0' + Math.abs(+val);
        return '0' + Math.abs(+val);
    }
    return val + '';
}

interface IProps{

}
var tikInterval : any = undefined;
var timerInterval : any = undefined;
var timerValue = 0;
var tikValue = 0;
var index = 0;
const TimeBtn: React.FunctionComponent<IProps> = ({}) => {
    const isImitationOn = IS_DEBUG_MODE ? false : false;

    const dispatch =  useDispatch();
    const map = useAppSelector(hereMapSelector);
    const [getRoute] = useGetRouteMutation();
    const { order, orderRouteWithTime } = useAppSelector(orderSelector);
    const { car_position, point_index, left_time } = useAppSelector(hereMapSelector);
    const order_id = order ? order.id : '';

    const [getCarPointPosition ] = useGetCarPointPositionMutation();
    const [getCarPointPositionCurrent ] = useGetCarPointPositionCurrentMutation();
    const [getNextRoutePoint ] = useGetNextRoutePointMutation();

    // const [getOrderCarPositions ] = useGetOrderCarPositionsMutation();

    const [isTimerStart, setTimerStart] = useState(0);
    const [position, setPosition] = useState<number>(0);

    const [tikTak, setTickTack] = useState<number>(0);
    const [tikTakTimer, setTickTackTimer] = useState<number>(0);
    const [lastTimestamp, setLastTimestamp] = useState<number>(0);
    const [localLeftTime, setLocalLeftTime] = useState<number>(0);

    const [testRouteData, setTestRouteData] = useState<OrderCarPositionsResponse[]>([]);
    const [testPointIndex, setTestPointIndex] = useState<number>(20);


    //---------------------------------------------------------------------------
    // Возвращает следующую точку на тестовом на маршруте
    //---------------------------------------------------------------------------
    const getImitationCarPoints = () : any => {
        if ( (testPointIndex+1)*(TIME_FOR_ROUTE_CACHE_SECONDS*3) >= testRouteData.length || testRouteData.length <= 0 )
            return [];
        if (isTimerStart)
            setTestPointIndex(testPointIndex + 1);
        return testRouteData.slice(testPointIndex * (TIME_FOR_ROUTE_CACHE_SECONDS*3), (testPointIndex + 1) * (TIME_FOR_ROUTE_CACHE_SECONDS*3))
    };

    const loadCarPosition = async () => {
        await getNextPointIndex();
        let points : TypeLonLatTime[]   = [];
        if (isImitationOn && isTimerStart)
            points =  getImitationCarPoints() ;
        else  {
            let lastStamp = lastTimestamp;
            // Если это первое обращение за координатами, то нам нужно получить текущее время сервера,
            // для этого берем последнюю координату,
            // TODO: когда появится рест для получения времени сервера нужно будет переделать
            if (!lastTimestamp){

                // lastStamp = 63820443630843;
                // setLastTimestamp(lastStamp);


                let tmp: any = await getCarPointPositionCurrent({order_id}).unwrap();
                if (tmp && Array.isArray(tmp) && tmp.length) {
                    lastStamp = tmp[tmp.length-1].date_utc -(TIME_FOR_ROUTE_CACHE_SECONDS * 1000)
                    setLastTimestamp(lastStamp)
                }
            }

            if (!lastStamp) {
                console.log('нет данных по маршруту lastStamp is null')
                return;
            }

            console.log("loadCarPosition получаем архив координат за прошедшее время: ",  (new Date(lastStamp - CONST_TIME_1C)).toLocaleTimeString());

            let data: any = await getCarPointPositionCurrent({order_id, date_utc: lastStamp}).unwrap();
            if (data && Array.isArray(data) && data.length > 0) {
                points = data;
                setLastTimestamp(points[points.length-1].date_utc);
            }
        }
        //console.log("points",points);

        if (!points.length ) return;

        if (!IS_DEBUG_MODE || (isImitationOn && isTimerStart && IS_DEBUG_MODE)) {
            //console.log('getLeftTime setCarPosition');
            await dispatch(setCarPosition(points));
        }

        // let [point, d, index ] = getNearestPointOnLine( [pos.lat, pos.lon], testRouteData.map(x=>[+x.lat,+x.lon]) as [number[]]);
        // let distanceLeft = getDistanceLine(point as  number[] , testRouteData.map(x=>[+x.lat,+x.lon])  as [number[]]);
        // let distanceFull = orderRouteWithTime.distance == 0 ? 1 : orderRouteWithTime.distance;
        // let timeFull = orderRouteWithTime.traffic_time;
        // let timeLeft = Math.round(timeFull / distanceFull * distanceLeft );
        // setLeftTime(timeLeft);
        // console.log('timeLeft', timeLeft, Math.ceil(timeLeft/60), timeLeft - Math.ceil(timeLeft/60)*60, distanceFull, distanceLeft)

    }

    // обнуляем счетчик времени при изменении маршрута
    useEffect(()=>{
        if (orderRouteWithTime) {
            dispatch(setLeftTime(orderRouteWithTime.base_time));
            dispatch(setFullTime(orderRouteWithTime.base_time));
            dispatch(setFullDistance(orderRouteWithTime.distance > 0 ? orderRouteWithTime.distance : 1));
        }
        else {
                dispatch(setLeftTime(0));
                dispatch(setFullTime( 0));
                dispatch(setFullDistance(0));
        }
    },[orderRouteWithTime]);

    useEffect(()=>{
        loadCarPosition().then();
    }, [tikTak]);
    // отрисовка по времени часов
    useEffect(()=>{
        let isMounted = true;

        if (tikInterval) clearInterval(tikInterval);
        tikInterval = setInterval(() => {
                console.log("tick", (new Date()).toLocaleTimeString())
                if (!isMounted)  return clearInterval(tikInterval);
                setTickTack(tikValue);
                tikValue = (tikValue+1) > 60 ? 0: tikValue+1;
            }, TIME_FOR_ROUTE_CACHE_SECONDS * 1000/ (isTimerStart <= 0 ? 1 : isTimerStart ));
        return () => { isMounted = false };
    }, [isTimerStart]);

    useEffect(()=>{
        let isMounted = true;

        if (timerInterval) clearInterval(timerInterval);
        timerInterval = setInterval(() => {
            if (!isMounted)  return clearInterval(timerInterval);
            setTickTackTimer(timerValue);
            timerValue = (timerValue+1) > 60 ? 0: timerValue + 1;
        }, 1000);
        return () => { isMounted = false };
    }, []);


    useEffect(()=>{
        // console.log("tikTakTimer=%s, localLeftTime=%s", tikTakTimer, localLeftTime);
        setLocalLeftTime((localLeftTime -1) > 0? localLeftTime - 1 : 0);
     }, [tikTakTimer]);

    // инициируем изменениие времени обратного отсчета
    useEffect(()=>{ setLocalLeftTime(left_time); }, [left_time]);

    // быть функция получения следующей точки на маршруте из реста
    const getNextPointIndex = async () => {
        if (IS_DEBUG_MODE) {
            dispatch(setNextPointIndex(1));
            return ;
        }

        let data : any = await getNextRoutePoint(order_id).unwrap();

        // if (IS_DEBUG_MODE) {
        //     data = [{
        //         "driver_id": "",
        //         "order_id": "e6ca65eb-65cd-11ed-8ebe-00155d010f1b",
        //         "status": "at_point",
        //         "point": 1,
        //         "time": "2022-11-16T20:12:30"
        //     },];
        // }
        if (!data || !Array.isArray(data) || !data.length) {
            console.error('ошибка получения следующей точки getNextPointIndex()');
            return;
        }
        data = data.filter(x=> x.status != "at_point");
        let current_point = data[data.length-1];
        let next_point_index =  current_point.point;
        console.log('получили следующую точку %s статус=%s ', next_point_index, current_point.status)
        // if ( current_point.status== 'left_point')
        //     next_point_index+=1;
        if ( current_point.status== 'finish') {
            await showInfoMessage("Заказ успешно выполнен !", dispatch);
            dispatch(setOrderId(''));
        }

        dispatch(setNextPointIndex(next_point_index));
        console.log('getNextPoint Is %s', next_point_index);
    };

    // -----------------------------------------------------------------
    // Если это имитатор то заполняем маршрут для теста
    // -----------------------------------------------------------------
    useEffect(()=>{
        if (!IS_DEBUG_MODE) return;
        (async function  () {
            let data : any = await getCarPointPosition(order_id).unwrap();
            if (data?.status !='error' && Array.isArray(data))
                setTestRouteData(data)
        })()
    },[]);


    return (
        <>
            <div className='time_btn'>
                <div className="two_text"> в пути </div>
                <div className="two_digits">{ toTwoDigits( Math.ceil(localLeftTime/60)) }</div>
                <div className="two_points">{tikTakTimer % 2 == 0 ? ":" : <>&nbsp;</>}</div>
                <div className="two_digits">{ toTwoDigits(Math.abs(localLeftTime % 60))}</div>
            </div>
            <div className='time_last_stamp'>{lastTimestamp}</div>
            {isImitationOn && IS_DEBUG_MODE &&
                    <div className="imitation_btn" onClick={( event)=>{
                        console.log('event', event);
                        if (!event.shiftKey)
                            setTimerStart( isTimerStart + 1 > 10 ?   10 : isTimerStart + 1)
                        else
                            setTimerStart( isTimerStart - 1 < 0 ?   0 : isTimerStart - 1)
                    }}>
                        {isTimerStart ? '':'запустить'} имитатор {isTimerStart ? 'работает x' + isTimerStart : ''}
                    </div>
            }
         </>
    )

}

export default TimeBtn;
