import styled from 'styled-components'
import {
  DisplayType,
  FlexDirectionType,
  IStyledForm,
  JustifyContentType
} from './Form.types'

export const StyledForm = styled.form<IStyledForm>`
  display: ${props => (props.display ? props.display : DisplayType.FLEX)};
  grid-gap: ${props => (props.gridGap ? props.gridGap : '1em')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : JustifyContentType.CENTER};
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : FlexDirectionType.ROW};
  height: ${props => (props.height ? props.height : 'calc(100vh - 370px)')};
  text-align: ${props => props.textAlign && props.textAlign};

  margin:${props => (props.margin ? props.margin : ' 46px 18px 0 18px')};
`
