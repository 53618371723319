import React, {FC, memo, useEffect, useState} from "react";
import {RouteAddress} from "../CreateOrderType";
import {NewOrderAddresses} from "./NewOrderAddresses";
import {currentOrderData, getRoutePointAsMarkers, setOrderRoutes} from "../CreateOrderSlicer";
import {useAppDispatch, useAppSelector} from "../../../../../app/redux/hooks";
import {InputAddressModal} from "../InputAddressModal/InputAddressModal";
import s from './AddressBlock.module.scss';
import {setMarkers, setRoutes} from "../../../../../components/HereMap/hereMapSlice";
import {mapRoute} from "../../../../../components/HereMap/hereMapTypes";
interface IProps {
    isTab?:boolean,
    isShown?:boolean
}

const AddressBlock: FC<IProps> = ({isTab,isShown}) => {
    const dispatch = useAppDispatch();
    const [isShowAddressSelect, setShowAddressSelect ] = useState<RouteAddress|null>(null);
    const currentOrder = useAppSelector(currentOrderData)

    const onSetAddress = (address: RouteAddress) => {
        console.log('onSetAddress')
        if (address) {
            let routes = [...currentOrder.routes];
            let routeIndex = routes.findIndex((x)=> x.id == address.id);
            if (routeIndex !== -1)
                routes[routeIndex] = address;
            else
                routes.push(address);
            dispatch(setOrderRoutes([...routes]));
            setShowAddressSelect(null);

        }
    };

    const onRemoveAddress = (address: RouteAddress) => {
        let routes = currentOrder.routes.filter((x)=> x.id !== address.id);
        dispatch(setOrderRoutes(routes))
        setShowAddressSelect(null);
    };

    useEffect(()=> {
        dispatch(setMarkers(getRoutePointAsMarkers(currentOrder)));
        if (currentOrder.routes.length < 2){
            dispatch(setRoutes([]));
        } else {
            let route = new mapRoute();
            route.coordinate = currentOrder.routes.map(x=> ({ lon: x.adress_longitude, lat: x.adress_latitude  }) );
            route.type = 0;
            dispatch( setRoutes([route]))
        }
    },[currentOrder.routes])

    return (

        <div className={s.AddressBlock + " address_block " + (isTab ? s.AddressBlockTab : '') + ' ' + (isShown ? s.active : '')}>
            <NewOrderAddresses onAdd={()=> setShowAddressSelect(new RouteAddress(currentOrder.routes.length)) }
                               onEdit={(address)=> setShowAddressSelect(address)}
            />
            {isShowAddressSelect && <InputAddressModal address={isShowAddressSelect}
                                                       onAddressChanged={onSetAddress}
                                                       onAddressRemove={isShowAddressSelect?.id > 1 ? onRemoveAddress : undefined}
                                                       onCancel={()=> setShowAddressSelect(null)}
                                                       useAdvanceMode={(isShowAddressSelect && isShowAddressSelect.house && isShowAddressSelect.house != '0') as boolean}/>}


        </div>
    )
};

export default AddressBlock;
