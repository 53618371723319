import styled from "styled-components";

const StyledSvg = styled.svg`
  cursor: pointer;
`;
interface IArrowRightIconProps {
  onClick?: () => void;
}

export const ArrowRightIcon = ({ onClick }: IArrowRightIconProps) => {
  return (
    <StyledSvg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
    >
      <path
        d="M1 9L5 5L1 1"
        stroke="#888E99"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </StyledSvg>
  );
};
