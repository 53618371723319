import React, { FC, useState, useCallback, memo, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/hooks'
import { newUserOrderSelector, setSelectedTariff } from '../../NewUserOrderSlicer'
import s from './OrderCompletion.module.scss'
import { TariffRow } from './TariffRow'
import {currentOrderData, getTariffs} from "../../CreateOrderSlicer";

export const Tariffs: FC<{isLoading:boolean}> = memo(({isLoading}) => {

    const dispatch = useAppDispatch()

    const { tariffTypes } = useAppSelector(newUserOrderSelector)
    const currentOrder = useAppSelector(currentOrderData)
    const tariffs = useAppSelector(getTariffs)

    const [usePM, setUsePM] = useState<boolean>(false)

    const onAMClick = useCallback(() => {
        setUsePM(false)
        dispatch(setSelectedTariff(tariffTypes.filter(y => y.name.slice(-2) !== 'РМ')[0]))
    }, [tariffTypes])

    const onPMClick = useCallback(() => {
        setUsePM(true)
        dispatch(setSelectedTariff(tariffTypes.filter(y => y.name.slice(-2) === 'РМ')[0]))
    }, [tariffTypes])


    return (
        <div className={s.tariffs}>
            <div className={s.tariffTypes}>
                <div className={!usePM ? s.active : ''} onClick={onAMClick}>
                    Обычная цена (AM)
                </div>
                <div className={usePM ? s.activePM : ''} onClick={onPMClick}>
                    с 13:00 до 17:00 (PM)
                </div>
            </div>
            {tariffs.filter(y => usePM ? y.tariff_name.slice(-2) === 'РМ' : y.tariff_name.slice(-2) !== 'РМ')
                    .map(x => <TariffRow key={x.tariff_type_id} PM={usePM} data={x} selected={currentOrder.tariff_type_id === x.tariff_type_id} isLoading={isLoading}/>)}
        </div>
    )
})
