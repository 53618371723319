
const Category1Plus=()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="32"
      fill="none"
      viewBox="0 0 90 32"
    >
      <path
        fill="#000"
        d="M65 31c2.761 0 5-2.686 5-6s-2.239-6-5-6-5 2.686-5 6 2.239 6 5 6zM50.5 31c2.485 0 4.5-2.462 4.5-5.5S52.985 20 50.5 20 46 22.462 46 25.5s2.015 5.5 4.5 5.5zM38 31c2.761 0 5-2.686 5-6s-2.239-6-5-6-5 2.686-5 6 2.239 6 5 6zM24.5 31c2.485 0 4.5-2.462 4.5-5.5S26.985 20 24.5 20 20 22.462 20 25.5s2.015 5.5 4.5 5.5z"
      ></path>
      <path
        fill="url(#paint0_linear)"
        d="M68 16l-2-1s-2-4.5-5-7.5C59.8 3.6 53.2 1 49.2 1H23.7c-2.1 0-4 1.5-4.4 3.6l-.7 3.9C17.5 14.2 17 19.9 17 25.6c0 1.3 1.1 2.4 2.4 2.4H70c.6 0 1-.4 1-1v-1.7c0-7.3-1-8.4-3-9.3z"
      ></path>
      <path
        fill="#000"
        d="M70 28H20c-.6 0-1-.4-1-1h52c0 .6-.4 1-1 1z"
        opacity="0.15"
      ></path>
      <path fill="#000" d="M53 25.5h-7V28h7v-2.5z"></path>
      <path
        fill="#000"
        d="M48.5 31c2.485 0 4.5-2.462 4.5-5.5S50.985 20 48.5 20 44 22.462 44 25.5s2.015 5.5 4.5 5.5zM55 7c-3 0-10 .5-10 .5l2.5 7.5H66s-2-4.5-5-7.5c0 0-3-.5-6-.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M47.5 29.5c1.38 0 2.5-1.567 2.5-3.5s-1.12-3.5-2.5-3.5S45 24.067 45 26s1.12 3.5 2.5 3.5z"
      ></path>
      <path
        fill="#5364FF"
        d="M29 9h-4c-1.7 0-3 1.6-3 3.5s1.3 3.5 3 3.5h4c1.7 0 3-1.6 3-3.5S30.7 9 29 9z"
      ></path>
      <path
        fill="#000"
        d="M38.5 15.5v-7c0-.8.7-1.5 1.5-1.5h5.3l.1.3c.1.1 2 3.6 3.1 7.6l.2.6H38.5z"
      ></path>
      <path
        fill="#C6CAD2"
        d="M45 7.5s2 3.5 3 7.5h-9V8.5c0-.6.4-1 1-1h5zm.6-1H40c-1.1 0-2 .9-2 2V16h11.3l-.3-1.2c-1-4.1-3-7.6-3.1-7.8l-.3-.5z"
      ></path>
      <path
        fill="#000"
        d="M76 31H13c0-.6.5-1 1-1h61c.5 0 1 .4 1 1zM27 25.5h-7V28h7v-2.5z"
      ></path>
      <path
        fill="#000"
        d="M22.5 31c2.485 0 4.5-2.462 4.5-5.5S24.985 20 22.5 20 18 22.462 18 25.5s2.015 5.5 4.5 5.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M21.5 29.5c1.38 0 2.5-1.567 2.5-3.5s-1.12-3.5-2.5-3.5S19 24.067 19 26s1.12 3.5 2.5 3.5z"
      ></path>
      <path
        fill="#fff"
        d="M69.5 17H55c0 1.1.9 2 2 2h13.5c-.3-.9-.6-1.6-1-2z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="17"
          x2="71"
          y1="14.5"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A2AAB4"></stop>
          <stop offset="0.348" stopColor="#C6CAD2"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Category1Plus;