import styled from 'styled-components'

export const StyledArticleFolderList = styled.ul`
  list-style-type: none;
  margin-top: 25px;

`
export const StyledArticleFolderListItem = styled.li`
  font-size: 17px;
  padding: 22px 24px;
  border-top: 1px solid var(--medium-grey);
  &:first-child {
    border-top: none;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:var(--black);
  cursor:pointer;
`
