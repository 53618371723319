
const Category5 = ()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="32"
      fill="none"
      viewBox="0 0 90 32"
    >
      <path
        fill="#000"
        d="M23.5 31c1.933 0 3.5-2.015 3.5-4.5S25.433 22 23.5 22 20 24.015 20 26.5s1.567 4.5 3.5 4.5zM36 31c2.099 0 3.8-2.015 3.8-4.5S38.099 22 36 22c-2.099 0-3.8 2.015-3.8 4.5S33.901 31 36 31z"
      ></path>
      <path
        fill="#000"
        d="M42 31c2.099 0 3.8-2.015 3.8-4.5S44.099 22 42 22c-2.099 0-3.8 2.015-3.8 4.5S39.901 31 42 31zM29.5 31c1.933 0 3.5-2.015 3.5-4.5S31.433 22 29.5 22 26 24.015 26 26.5s1.567 4.5 3.5 4.5z"
      ></path>
      <path
        fill="#5364FF"
        d="M55 26H16c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3h39c.6 0 1 .4 1 1v21c0 .6-.4 1-1 1z"
      ></path>
      <path
        fill="#000"
        d="M69 31c2.099 0 3.8-2.015 3.8-4.5S71.099 22 69 22c-2.099 0-3.8 2.015-3.8 4.5S66.901 31 69 31zM57.5 31c1.933 0 3.5-2.015 3.5-4.5S59.433 22 57.5 22 54 24.015 54 26.5s1.567 4.5 3.5 4.5z"
      ></path>
      <path fill="#BCC0CA" d="M52 26H29v2h23v-2z"></path>
      <path
        fill="#3C4AC1"
        d="M69 26H52c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1h15c1.7 0 3 1.3 3 3v19c0 .6-.4 1-1 1z"
      ></path>
      <path
        fill="#BCC0CA"
        d="M76 24c0 1-1 4-1 4H52v-7c0-5.6.5-11.7.8-14.2.1-1 1.2-2.8 3-2.8h10.9c6.3 0 8.5 4.4 8.7 6.3.3 2.5.6 5.9.6 8.7v5z"
      ></path>
      <path
        fill="#000"
        d="M52 26H31v2h44s.1-.4.3-1H53c-.6 0-1-.4-1-1z"
        opacity="0.1"
      ></path>
      <path
        fill="#000"
        d="M60 26.5h-5V28h5v-1.5zM75.4 10.5H62.9v4.7c0 1 .8 1.9 1.8 2 1.6.1 4.1.3 7.8.3 3.1 0 3.2-.4 3.5-.5-.1-2.2-.4-4.6-.6-6.5z"
      ></path>
      <path
        fill="#000"
        d="M56.5 31c1.933 0 3.5-2.015 3.5-4.5S58.433 22 56.5 22 53 24.015 53 26.5s1.567 4.5 3.5 4.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M55.8 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z"
      ></path>
      <path
        fill="#000"
        d="M58.6 15.6c-1-.2-1.8-1.1-1.8-2.2v-3.1h5.5v6.1l-3.7-.8z"
      ></path>
      <path
        fill="#BCC0CA"
        d="M62 10.5V16l-3.4-.7c-.9-.2-1.6-1-1.6-2v-2.9h5v.1zm.5-.5h-6v3.4c0 1.2.8 2.2 2 2.5l3.4.7.6.1V10z"
      ></path>
      <path fill="#fff" d="M76 22H64c0 .6.4 1 1 1h11v-1z"></path>
      <path
        fill="#000"
        d="M78 31H12c0-.6.5-1 1.1-1h63.8c.6 0 1.1.4 1.1 1zM32 26.5h-5V28h5v-1.5z"
      ></path>
      <path
        fill="#000"
        d="M28.5 31c1.933 0 3.5-2.015 3.5-4.5S30.433 22 28.5 22 25 24.015 25 26.5s1.567 4.5 3.5 4.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M27.9 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z"
      ></path>
      <path
        fill="#000"
        d="M22.5 31c1.933 0 3.5-2.015 3.5-4.5S24.433 22 22.5 22 19 24.015 19 26.5s1.567 4.5 3.5 4.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M21.9 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z"
      ></path>
      <path
        fill="#BCC0CA"
        d="M62 15v1c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1v3z"
      ></path>
    </svg>
  );
}

export default Category5;
