
export interface ILinkProps{
    children: string | JSX.Element;
    href: string
    textDecoration?: TextDecorationType,
    textDecorationColor?: string;
    color?: string
}

export interface IStyledLinkProps{
    textDecoration?: TextDecorationType,
    textDecorationColor?: string;
    color?: string
}

export enum TextDecorationType {
    none = 'none',
    underline = 'underline',
    auto = 'auto'
}