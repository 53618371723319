export enum IPaymentTypes {
  online = 'Оплата онлайн',
  cash = 'Наличными',
  byCount = 'По счёту'
}

export interface IStyledAmountText {
  color?: string
  padding?: string
}

export interface IPaymentTypesItemProp{
    selected: boolean
}
