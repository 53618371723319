import { StyledInput } from './Input.styled'
import { IInputProps } from './Input.types'
export const Input = ({
  id,
  name,
  type,
  color,
  background,
  fontWeight,
  fontSize,
  textAlign,
  placeholder,
  pattern,
  value,
  onChange,
  required,
  border,
  maxLength,
  min,
  max,
  title,
  checked,
  width,
  height,
  padding,
  margin
}: IInputProps) => {
  return (
    <StyledInput
      id={id}
      name={name}
      type={type}
      color={color}
      background={background}
      fontWeight={fontWeight}
      fontSize={fontSize}
      textAlign={textAlign}
      placeholder={placeholder}
      pattern={pattern}
      value={value}
      onChange={onChange}
      required={required}
      border={border}
      maxLength={maxLength}
      min={min}
      max={max}
      title={title}
      checked={checked}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
    />
  )
}
