import { FC } from "react";
import { useDispatch } from "react-redux";
import { CloseIcon } from "../../../../assets/icons/CloseIcon"
import Button from "../../../../components/Button"
import { ButtonBackgroundColor, ButtonColor, ButtonHeight } from "../../../../components/Button/Button.types";
import { ContentCardWrapper, ContentCardWrapperFooter, ContentCardWrapperHeader, ContentCardWrapperHeaderCol, ContentCardWrapperHeaderColHeading, ContentCardWrapperHeaderDate, ContentCardWrapperHeaderProductName } from "../../../../components/ContentCard/ContentCard.styled"
import { chooseLocationDetect } from "./locationDetectSlice";

export const LocationDetectModal: FC<propTypes> = ({ setShowLocationDetectModal, setDetectedLocationAsCurrent }) => {

    const dispatch = useDispatch()

    const closeModal = () => {
        setShowLocationDetectModal(false)
    }

    const acceptLocationDetect = () => {
        dispatch(chooseLocationDetect(true))
        setShowLocationDetectModal(false)
        setDetectedLocationAsCurrent()
    }

    return (
        <div style={{ background: 'rgba(0, 0, 0, 0.5)', width: '100vw', height: '200px', position: 'fixed', zIndex: 3000 }} onClick={closeModal}>
            <ContentCardWrapper>
                <ContentCardWrapperHeader>
                    <ContentCardWrapperHeaderCol>
                        <ContentCardWrapperHeaderColHeading>
                            Доступ к местоположению
                        </ContentCardWrapperHeaderColHeading>
                        <CloseIcon onClick={closeModal} />
                    </ContentCardWrapperHeaderCol>
                    <ContentCardWrapperHeaderDate></ContentCardWrapperHeaderDate>
                    <ContentCardWrapperHeaderProductName>
                        Для нормальной работы системы нам требуется доступ к
                        вашему местоположению
                    </ContentCardWrapperHeaderProductName>
                </ContentCardWrapperHeader>
                <ContentCardWrapperFooter>
                    <Button
                        buttonBackgroundColor={ButtonBackgroundColor.BLUE}
                        buttonHeight={ButtonHeight.MIN_HEIGHT}
                        buttonColor={ButtonColor.WHITE}
                        onClick={acceptLocationDetect}
                    >
                        Разрешить
                    </Button>
                </ContentCardWrapperFooter>
            </ContentCardWrapper>
        </div>
    )
}


type propTypes = {
    setShowLocationDetectModal: (value: boolean) => void
    setDetectedLocationAsCurrent: () => void
}
