import { ArrowLeftIcon } from '../../assets/icons/ArrowLeftIcon'
import { useAppSelector } from '../../app/redux/hooks'
import { useDispatch } from 'react-redux'
import {
  StyledModalFull,
  StyledModalFullArrowLeft,
  StyledModalFullCloseIcon,
  StyledModalFullTitle
} from './ModalFull.styled'
import { modalFullSelector } from './modalFullSlice'
import { IModalFullProps } from './ModalFull.types'
import { createRef, useEffect } from 'react'
import { close, getChildren, getTitle } from './modalFullHelper'
import { CloseIcon } from '../../assets/icons/CloseIcon'

export const ModalFull = ({ child }: IModalFullProps) => {
  const {
    isModalOpened,
    children,
    title,
    textAlignTitle,
    fontSizeTitle,
    paddingLeft,
    background,
    isArrowClose,
    isButtonClose
  } = useAppSelector(modalFullSelector)
  const dispatch = useDispatch()
  const refTitle = createRef<HTMLDivElement>()

  useEffect(() => {
    getTitle(refTitle, title)
  })
  const closeModal = () => {
    close(dispatch, children)
  }
  return (
    <>
      {isModalOpened && (
        <StyledModalFull background={background}>
          <StyledModalFullArrowLeft
            isAbsolute={children === 'Order'}
            borderRadius={children === 'Order' ? '0 0 16px 16px' : undefined}
            background={background}
            paddingLeft={paddingLeft}
          >
            {isArrowClose && <ArrowLeftIcon onClick={closeModal} />}
            <StyledModalFullTitle
              ref={refTitle}
              textAlignTitle={textAlignTitle}
              fontSizeTitle={fontSizeTitle}
            ></StyledModalFullTitle>
            {isButtonClose && (
              <StyledModalFullCloseIcon>
                <CloseIcon onClick={closeModal} />
              </StyledModalFullCloseIcon>
            )}
          </StyledModalFullArrowLeft>

          {child || getChildren(children)}
        </StyledModalFull>
      )}
    </>
  )
}
