import styled from "styled-components";

const StyledSvg = styled.svg`
  cursor: pointer;
`;
interface IWasRudeIconProps {
  color?: string;
}

export const WasRudeIcon = ({ color }: IWasRudeIconProps) => {
  return (
    <StyledSvg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5 20H22.5V12H26.5V20ZM26.5 24H22.5V28H26.5V24ZM44.5 8V32C44.5 33.0609 44.0786 34.0783 43.3284 34.8284C42.5783 35.5786 41.5609 36 40.5 36H12.5L4.5 44V8C4.5 6.93913 4.92143 5.92172 5.67157 5.17157C6.42172 4.42143 7.43913 4 8.5 4H40.5C41.5609 4 42.5783 4.42143 43.3284 5.17157C44.0786 5.92172 44.5 6.93913 44.5 8ZM40.5 8H8.5V34.4L10.9 32H40.5V8Z"
        fill={color ?? "#888E99"}
      />
    </StyledSvg>
  );
};
