const Category7 =()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="32"
      fill="none"
      viewBox="0 0 90 32"
    >
      <path
        fill="#000"
        d="M7.5 31c1.933 0 3.5-2.015 3.5-4.5S9.433 22 7.5 22 4 24.015 4 26.5 5.567 31 7.5 31zM20 31c2.099 0 3.8-2.015 3.8-4.5S22.099 22 20 22c-2.099 0-3.8 2.015-3.8 4.5S17.901 31 20 31z"
      ></path>
      <path
        fill="#000"
        d="M14 31c2.099 0 3.8-2.015 3.8-4.5S16.099 22 14 22c-2.099 0-3.8 2.015-3.8 4.5S11.901 31 14 31z"
      ></path>
      <path
        fill="#000"
        d="M13.5 31c1.933 0 3.5-2.015 3.5-4.5S15.433 22 13.5 22 10 24.015 10 26.5s1.567 4.5 3.5 4.5zM26 31c2.099 0 3.8-2.015 3.8-4.5S28.099 22 26 22c-2.099 0-3.8 2.015-3.8 4.5S23.901 31 26 31zM60 31c2.099 0 3.8-2.015 3.8-4.5S62.099 22 60 22c-2.099 0-3.8 2.015-3.8 4.5S57.901 31 60 31zM47.5 31c1.933 0 3.5-2.015 3.5-4.5S49.433 22 47.5 22 44 24.015 44 26.5s1.567 4.5 3.5 4.5z"
      ></path>
      <path fill="#5364FF" d="M65 1H0v25h65V1z"></path>
      <path
        fill="#000"
        d="M78 31c2.099 0 3.8-2.015 3.8-4.5S80.099 22 78 22c-2.099 0-3.8 2.015-3.8 4.5S75.901 31 78 31zM66.5 31c1.933 0 3.5-2.015 3.5-4.5S68.433 22 66.5 22 63 24.015 63 26.5s1.567 4.5 3.5 4.5z"
      ></path>
      <path fill="#BCC0CA" d="M62 26H0v2h62v-2z"></path>
      <path
        fill="#3C4AC1"
        d="M78 26H61c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h15c1.7 0 3 1.3 3 3v21c0 .6-.4 1-1 1z"
      ></path>
      <path
        fill="#BCC0CA"
        d="M85 24c0 1-1 4-1 4H61v-7c0-5.6.5-13.7.8-16.2.1-1 1.2-2.8 3-2.8h10.9c6.3 0 8.5 6.4 8.7 8.3.3 2.5.6 5.9.6 8.7v5z"
      ></path>
      <path
        fill="#000"
        d="M61 26H6v2h78s.1-.4.3-1H62c-.6 0-1-.4-1-1z"
        opacity="0.1"
      ></path>
      <path
        fill="#000"
        d="M69 26.5h-5V28h5v-1.5zM84.4 10.5H71.9v4.7c0 1 .8 1.9 1.8 2 1.6.1 4.1.3 7.8.3 3.1 0 3.2-.4 3.5-.5-.1-2.2-.4-4.6-.6-6.5z"
      ></path>
      <path
        fill="#000"
        d="M65.5 31c1.933 0 3.5-2.015 3.5-4.5S67.433 22 65.5 22 62 24.015 62 26.5s1.567 4.5 3.5 4.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M64.8 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z"
      ></path>
      <path
        fill="#000"
        d="M67.6 15.6c-1-.2-1.8-1.1-1.8-2.2v-3.1h5.5v6.1l-3.7-.8z"
      ></path>
      <path
        fill="#BCC0CA"
        d="M71 10.5V16l-3.4-.7c-.9-.2-1.6-1-1.6-2v-2.9h5v.1zm.5-.5h-6v3.4c0 1.2.8 2.2 2 2.5l3.4.7.6.1V10z"
      ></path>
      <path fill="#fff" d="M85 22H73c0 .6.4 1 1 1h11v-1z"></path>
      <path
        fill="#000"
        d="M87 31H0v-1h85.6c.8 0 1.4.4 1.4 1zM16 26.5h-5V28h5v-1.5zM50 26.5h-5V28h5v-1.5z"
      ></path>
      <path
        fill="#000"
        d="M46.5 31c1.933 0 3.5-2.015 3.5-4.5S48.433 22 46.5 22 43 24.015 43 26.5s1.567 4.5 3.5 4.5zM4 26.5C4 24 2.4 22 .5 22c-.2 0-.3 0-.5.1V31h.5c1.9-.1 3.5-2 3.5-4.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M45.8 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z"
      ></path>
      <path
        fill="#000"
        d="M12.5 31c1.933 0 3.5-2.015 3.5-4.5S14.433 22 12.5 22 9 24.015 9 26.5s1.567 4.5 3.5 4.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M11.9 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z"
      ></path>
      <path
        fill="#BCC0CA"
        d="M71 15v1c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1v3z"
      ></path>
      <path
        fill="#000"
        d="M6.5 31c1.933 0 3.5-2.015 3.5-4.5S8.433 22 6.5 22 3 24.015 3 26.5 4.567 31 6.5 31z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M5.8 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5zM0 24.5v5c1-.1 1.7-1.2 1.7-2.5S1 24.6 0 24.5z"
      ></path>
    </svg>
  );
}

export default Category7;
