import React, { FC, useState, memo, useCallback, ChangeEvent } from 'react'
import './AutoDropDownGreyList.css'
import CloseValueInputModal from '../../../../../../src/assets/svg/CloseValueInputModal.svg'

import {DropDownListItem} from "../NewUserOrderSlicer";

interface ModalValueProps {
    value: string
    is_selected: boolean;
    onSelect:()=>void
}

const ModalValue: FC<ModalValueProps> = memo(({ value, is_selected, onSelect }) => {
    return (
        <div onClick={onSelect} className={`value ${is_selected ? 'selected-value' : ''}`}>
            {value}
        </div>
    )
});


type propTypes = {
    onSelect: (value: DropDownListItem) => void
    onCancel: () => void
    name: string
    list: DropDownListItem[]
    need_input:boolean;
    placeholder:string
    value: DropDownListItem|undefined

}


export const SelectValueModal: FC<propTypes> = ({ onSelect, onCancel, name, list, need_input, placeholder ="", value = undefined }) => {

    const [numberValue, setNumberValue] = useState<string>(value ? value.text : '')

    const onNumberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value
        setNumberValue(newValue)
    }, [])

    const onSetNumberValue = () => {
        if (numberValue.trim().length > 0)
            onSelect({id:numberValue, text:numberValue})
    }

    return (
        <div className='drop-down-select-modal-window'>
            <div className='header'>
                <span>{name}</span>
                <img alt="Закрыть окно" src={CloseValueInputModal} onClick={onCancel} />
            </div>
            <div className='body'>
                {need_input &&
                    <div className='TSHeight'>
                        <input placeholder={placeholder} type="number" value={numberValue} onChange={onNumberChange} />
                        <div className={'select-button ' + (numberValue.trim().length==0 ? '' : 'active')} onClick={onSetNumberValue}>
                            Установить высоту
                        </div>
                    </div>
                }
                {list.map(x => <ModalValue
                                    key={x.id}
                                    is_selected={need_input ? (!!value && value.text == x.text) : (!!value && value.id == x.id) }
                                    value={x.text}
                                    onSelect={()=> onSelect( x ) }
                               />)}
            </div>
        </div>
    )
}



