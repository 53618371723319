import React, { FC, memo, useCallback } from 'react'
import s from './HeaderWithAddresses.module.scss'
import VehicleSelectAddressesListIcon from '../../../../../../assets/svg/VehicleSelectAddressesListIcon.svg'
import { AddressItem } from './AddressItem'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/hooks'
import { 
    newUserOrderSelector, 
    setShowAutoVehikleSelectSummary, 
    setShowNewOrder, 
    setShowVehicleSelect 
} from '../../NewUserOrderSlicer'
import {currentOrderData} from "../../CreateOrderSlicer";
import {SendOrderInfo} from "../../CreateOrderType";

const HeaderWithAddresses: FC<{ type: boolean, not_is_rounded?:boolean, onClick?:()=>void }> = ({ type, not_is_rounded, onClick }) => {

    const dispatch = useAppDispatch()

    const currentOrder : SendOrderInfo = useAppSelector(currentOrderData)

    const onHeaderClick =  () => {
        if (type) {
            dispatch(setShowVehicleSelect(false))
            dispatch(setShowAutoVehikleSelectSummary(false))
            dispatch(setShowNewOrder(true))   
        } else
            if (onClick) onClick();
    }

    return (
        <div className={`${s.header}  ${( not_is_rounded ? s.notRounded : '')}`} onClick={onHeaderClick}>
                <img alt="Список адресов доставки" src={VehicleSelectAddressesListIcon} />
                <div className={s.addressesContainer}>
                    {currentOrder.routes.map((el, index) => <AddressItem key={el.id + ' ' + index} item={el} index={index} type={type} />)}
                </div>
            </div>
    )
}

export default HeaderWithAddresses;
