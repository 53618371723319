import {KalmanFilterGPS} from "./KalmanFilterGPS";
import {hereMap} from "./HereMap";
import turf from "turf";
import moment, {Moment} from "moment";
import {
    CONST_TIME_1C,
    MAX_DISTANCE_FOR_NEW_ROUTE_IN_METERS,
    NAVIGATION_GET_COORDINATE_TIME_IN_MS,
    NEED_NEW_ROUTE, TIME_FOR_ROUTE_CACHE_SECONDS
} from "../../api/api.constants";
import {TypeLonLat, TypeLonLatTime} from "../../modules/Home/components/NewUserOrder/CreateOrderType";
import {
    createAsyncDomMarker,
    createDomMarker,
    decodeHerePolyline,
    ease,
    getBearing, move_car,
    removeObjectFromMap,
    rotateDomMarker
} from "./hereFunctions";
import {HereMarkerIconCar} from "./hereMarkerIcon";
import {MARKER_4} from "./hereMapTypes";
import { setDriverCurrentRoute } from "./hereMapSlice";
import HistoricalDataQueue from "./HistoricalDataQueue";
export type NearestPointData = {pointOnRoute:number[] | undefined; distance:number; index:number; delta:number;};

//@ts-ignore
const getCountRouteArrayInCash = () => Array.isArray(window.car_position) ? window.car_position.length : 0;
const MAX_DISTANCE = 100;
export class Navigator {

    static kalmanFilter : KalmanFilterGPS | null= null;
    static kalmanFilterDraw : KalmanFilterGPS = new KalmanFilterGPS();
    static count_max_distance : number = 0;
    static planed_route : number[][] = [];
    static detail_route : number[][] = [];
    static line_route : any = null;
    static dispatch : any = [];
    static markerCar : any = null;
    static routeArchiveInfo : {point: number[], vi:number, sit: number}[] = [];
    static lastPointIndexOnPlanedRoute : number = 0;
    //static prevPoint : NearestPointData | undefined = undefined;
    static lastTimeMapHandMove: Moment = moment(new Date());
    static statistic: HistoricalDataQueue = new HistoricalDataQueue(24, NEED_NEW_ROUTE);
    static navigation_time: number = NAVIGATION_GET_COORDINATE_TIME_IN_MS + NAVIGATION_GET_COORDINATE_TIME_IN_MS*0.1;
    static _lastDateTime : any = undefined;
    static getRoute : Function | undefined = undefined;
    static is_stop : boolean = false;
    static index : number = 0;
    static stop(){
        Navigator.is_stop = true;
    }
    static clear(){
        Navigator.planed_route = [];
        Navigator.detail_route = [];
        Navigator.line_route = null;
        //Navigator.prevPoint = undefined;
        Navigator._lastDateTime = undefined;
        Navigator.count_max_distance = 0;
        Navigator.is_stop = true;
        Navigator.markerCar = null;
        Navigator.index = 0;
    }

    static getTimeLeft(coords : TypeLonLatTime[]){
        if (coords.length == 0) return 0;
        let v1 = coords[0].date_utc - CONST_TIME_1C;
        let v2 = coords[coords.length-1].date_utc - CONST_TIME_1C;
        return Math.abs(v2-v1)/1000;
    }
    // static checkIsNeedNewRouteByDistance(distance:number, array_length:number) : boolean{
    //     // расчитываем количество точек которые допустимы для отклонения от целевого маршрута в
    //     // маршруте движения машины
    //     let max_dopusk = [Math.round(array_length * 0.5), Math.round(array_length * 0.3)]
    //     // if (NEED_NEW_ROUTE.length > 1)
    //     //     NEED_NEW_ROUTE[1].count =  Math.round(array_length * 0.5);
    //     //
    //     // if (NEED_NEW_ROUTE.length > 0)
    //     //     NEED_NEW_ROUTE[0].count =  Math.round(array_length * 0.3);
    //
    //     for (let i=0; i< NEED_NEW_ROUTE.length; i++) {
    //         if (distance >= NEED_NEW_ROUTE[i].max_distance_in_meters)  max_dopusk[i] -= 1;
    //         if (max_dopusk[i]<0) {
    //             Navigator.statistic.fill(0);
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    static isNeedNewRoute(car_moved_route:TypeLonLatTime[]): [boolean, number] {
        if (!Navigator.kalmanFilter)  Navigator.kalmanFilter = new KalmanFilterGPS();

        let maxIndexOnRoute = 0;
        for (let i=0; i< car_moved_route.length; i++)
        {
            let x :TypeLonLatTime = car_moved_route[i];

            const kalmanPoint =  Navigator.kalmanFilter.process(x.lat, x.lon, 1, +x.date_utc - CONST_TIME_1C);

            let {pointOnRoute, distance, index, delta}  =
                Navigator.nearestPointOnLine(kalmanPoint, Navigator.lastPointIndexOnPlanedRoute, maxIndexOnRoute + MAX_DISTANCE) ;
            // определяем конечную точку на маршруте движения
            maxIndexOnRoute = index > maxIndexOnRoute ? index : maxIndexOnRoute;
            Navigator.statistic.append(distance);
            if ( Navigator.statistic.checkIsNeedNewRoute()) {
               return [true, maxIndexOnRoute]
            }
        }

        return [false, maxIndexOnRoute];
    }

    /*
        car_moved_route - маршрут движения машины
        planed_route - маршрут который спланирован
        destinationPoint - точка назначения
     */
    static async process(car_moved_route:TypeLonLatTime[], planed_route : [number[]], destinationPoint: {lat:number, lng:number},
                   drawDriverRouteToMap:Function, setDebugData:Function, dispatch: any)  :Promise<number> {
        let dt = (new Date()).getHours() + ":" + (new Date()).getMinutes() + ":" + (new Date()).getSeconds();
        console.log('Navigator.process', car_moved_route)
        if (!car_moved_route.length) return new Promise(resolve => resolve(0));

        drawDriverRouteToMap(car_moved_route, hereMap, true, Navigator.kalmanFilterDraw);
        let route_time_in_s = car_moved_route.length  ? Navigator.getTimeLeft(car_moved_route) : 0;

        Navigator.is_stop = false;
        // если планируемый маршрут был изменен
        if (planed_route != Navigator.planed_route) {
            Navigator.planed_route = planed_route;
            Navigator.line_route = turf.lineString(planed_route);
            if (!Navigator.detail_route.length)
                Navigator.detail_route = planed_route;
            drawDriverRouteToMap(Navigator.detail_route, hereMap);
        }
        // 0. отрисовка остатка планируемого маршрута водителя на карте
        if (0 < Navigator.lastPointIndexOnPlanedRoute && Navigator.lastPointIndexOnPlanedRoute < Navigator.detail_route.length)
            drawDriverRouteToMap(Navigator.detail_route.slice(Navigator.lastPointIndexOnPlanedRoute), hereMap);

        // 1. определяем нужен ли новый маршрут и точку на маршруте ближайшую крайней точке маршрута движения машины
        let [need_new_route, finalIndexPointOnRoute] = this.isNeedNewRoute(car_moved_route);
        // 1.1 отображаем отладочную информацию
        setDebugData({index: Navigator.index + ") " +  dt, bad_points: need_new_route ? 'нужен новый маршрут' : 'маршрут найден',
                      timestamp: car_moved_route.length ? car_moved_route[0].date_utc : '0'});
        // если маршрут нужен то ищем его
        if (need_new_route) {

            Navigator.detail_route = await Navigator.getDriverRouteToNextPoint(dispatch,
                                                [ car_moved_route[0], car_moved_route[car_moved_route.length-1]],
                                             {lat: destinationPoint.lat, lon: destinationPoint.lng});
            Navigator.line_route = turf.lineString(Navigator.detail_route); // TODO не очень уверен что она нужна
            drawDriverRouteToMap(Navigator.detail_route, hereMap); // рисуем новый плановый маршрут на карте
            Navigator.statistic.clear(); // очищаем статистику
            Navigator.count_max_distance = 0;  // очищаем статистику
            //Navigator.kalmanFilter = null;      // очищаем статистику
            Navigator.lastPointIndexOnPlanedRoute = 0; // сбрасываем индекс последней точки
            [need_new_route, finalIndexPointOnRoute] = this.isNeedNewRoute(car_moved_route);
            if (need_new_route) {
                setDebugData({index: Navigator.index + ") " +  dt, bad_points: "ОШИБКА ПОИСКА МАРШРУТА",
                              timestamp: car_moved_route.length ? car_moved_route[0].date_utc : '0'});
            }
        }
        // Перемещаем машину с точки последней итерации на последнюю найденую точку на маршруте
        let distance_in_meters : number =
            await Navigator.moveCarPosition(this.lastPointIndexOnPlanedRoute,  finalIndexPointOnRoute,
                                            getCountRouteArrayInCash(), route_time_in_s);

        if (finalIndexPointOnRoute > Navigator.lastPointIndexOnPlanedRoute)
            Navigator.lastPointIndexOnPlanedRoute = finalIndexPointOnRoute;

        return new Promise(resolve => resolve(distance_in_meters));
    }


    static async moveCarPosition(startPointIndex:number, finishPointIndex:number, has_next_array : number, routeTimeInS: number) : Promise<number>{
        //console.log('moveCarPosition start =%s end = %s ', startPointIndex, finishPointIndex);
        let getPointCoordinateByIndex = (index:number) => Navigator.detail_route.length>index  ? Navigator.detail_route[index] : undefined;

        if (!Navigator.detail_route.length) return 0;

        if (!Navigator.markerCar && getPointCoordinateByIndex(startPointIndex)!=undefined) {
            // @ts-ignore
            Navigator.markerCar = await createAsyncDomMarker(getPointCoordinateByIndex(startPointIndex), 0, HereMarkerIconCar(0), MARKER_4);

        }

        startPointIndex = startPointIndex == -1 ? 0 : startPointIndex;
        finishPointIndex = finishPointIndex>= Navigator.detail_route.length ? Navigator.detail_route.length - 1 : finishPointIndex;
        console.log(`startPointIndex=${startPointIndex} finishPointIndex = ${finishPointIndex}`)


        let distance_in_meters = 0;
        for (let i=startPointIndex; i <= (finishPointIndex -1); i++)
            distance_in_meters += turf.distance(turf.point(Navigator.detail_route[i]), turf.point(Navigator.detail_route[i + 1]), 'meters');
         //let total_sec = has_next_array  ? TIME_FOR_ROUTE_CACHE_SECONDS - has_next_array : TIME_FOR_ROUTE_CACHE_SECONDS;
         let total_sec = routeTimeInS < TIME_FOR_ROUTE_CACHE_SECONDS ? TIME_FOR_ROUTE_CACHE_SECONDS : routeTimeInS;

        if (has_next_array == 0)  total_sec += 2;
        total_sec = has_next_array>1 ? total_sec - has_next_array : total_sec; // если у нас есть еще массивы для движения машины мы сокращаем время на текущем

        //если расстояние равно нулю (мы стояли на месте то ждем то время пока стояли на месте
        if (distance_in_meters <=0 ) {
            await new Promise(resolve => setTimeout(()=>resolve(true), routeTimeInS * 1000));
            return 0;
        }
        console.log('total_sec', total_sec);
        let meter_per_millisecond  =  (total_sec * 1000) / (!distance_in_meters? 1 : distance_in_meters);
        if (finishPointIndex > 0) {
            finishPointIndex = finishPointIndex;
        }
        let dst = 0;
        for (let i=startPointIndex; i <= finishPointIndex-1 && i < Navigator.detail_route.length; i++) {
            if (Navigator.is_stop) return 0;
            //console.log(Navigator.detail_route, Navigator.detail_route.length, i)
            let v1 = {lat: Navigator.detail_route[i][0], lon: Navigator.detail_route[i][1]};
            let v2 = {lat: Navigator.detail_route[i + 1][0], lon: Navigator.detail_route[i + 1][1]};
            let distance  =    turf.distance(turf.point(Navigator.detail_route[i]), turf.point( Navigator.detail_route[i + 1]), 'meters');
            distance = !distance ? 1 : distance;
            //console.log('[pointIndex][0] ', [pointIndex][0], '[pointIndex][0] ', Navigator.detail_route.length, pointIndex);
            // removeObjectFromMap(hereMap, MARKER_4);
            // Navigator.markerCar = createDomMarker([coord.lat, coord.lng], 0, HereMarkerIconCar(getBearing(v1, v2)), MARKER_4);
            // hereMap.addObject(Navigator.markerCar);
            dst += distance;
            rotateDomMarker(Navigator.markerCar, getBearing(v1, v2));


            // if (!Navigator.routeArchiveInfo.length) return Navigator.routeArchiveInfo.push({
            //     point: pointCurrent,
            //     vi: 0,
            //     sit: 0
            // });
            // let last = Navigator.routeArchiveInfo[Navigator.routeArchiveInfo.length - 1];

            // let distance_to_current_point = Navigator.getDistanceABLine(pointCurrent, Navigator.current_route[0], Navigator.current_route as [number[]]);
            // Navigator.routeArchiveInfo.push({point: pointCurrent, vi: 0, sit: distance_to_current_point});

            // if (distance_to_current_point < last.sit) return;
            // update marker's position within ease function callback

            // Navigator.markerCar.setGeometry({lat: pointCurrent[0], lng: pointCurrent[1]});
            // console.log('moveCarPosition pointCurrent', pointCurrent);
            let moving_time = Math.round(distance * meter_per_millisecond);
            await new  Promise(resolve => {
                move_car(
                    Navigator.markerCar.getGeometry(),
                    {lat: v2.lat, lng: v2.lon},
                    moving_time, (coord:any) => {
                        if (Navigator.is_stop) return;
                        Navigator.markerCar.setGeometry(coord);
                        let curDate = moment(new Date());
                        if (curDate.diff(Navigator.lastTimeMapHandMove, "seconds") > 5)
                            hereMap.setCenter({lat: coord.lat, lng: coord.lng}, true)
                    },
                    () => resolve(true)
                );
            })

        }
        return distance_in_meters;
        // console.log('src %s , dst %s', distance_in_meters, dst)
    };

    static getElapsedTime() {
        let time1 = Navigator._lastDateTime;
        Navigator._lastDateTime = (new Date()).getTime();
        let r = Navigator._lastDateTime - time1;
        //console.log('r=', r);

        return r < Navigator.navigation_time ? r : Navigator.navigation_time;
    }
    static nearestPointOnLine(point: number[], lastIndex:number, distance: number = 100) : {pointOnRoute:number[] | undefined, distance:number, index:number, delta:number}   {
        lastIndex = lastIndex == -1 ? 0 : lastIndex;

        if (!Navigator.detail_route.length|| !Array.isArray(point) || point.length !=2) return {pointOnRoute: undefined, distance: 0, index: 0, delta: 0};
        let detail_route = [...Navigator.detail_route]
        let nearestPoint, minDist = Infinity, minIndex = Infinity;
        let tPoint = turf.point(point);
        // console.log('lastIndex', lastIndex, point, Navigator.detail_route[lastIndex])
        let prevDistance = 100000;
        let startFrom = lastIndex - distance > 0 ? lastIndex - distance : 0 ;
        let searchTo = lastIndex + distance;

        for (var i = startFrom; i < searchTo && i < Navigator.detail_route.length ; i++) {

            var distanceToPoint = turf.distance(tPoint, turf.point(Navigator.detail_route[i]), 'meters');

            if (distanceToPoint < minDist) {
                nearestPoint = Navigator.detail_route[i];
                minDist = distanceToPoint;
                minIndex = i;
            }
        }

        if (minDist > MAX_DISTANCE_FOR_NEW_ROUTE_IN_METERS) {
            let startIndex = lastIndex - minDist*1.5;
            for (var i = startIndex > 0 ? lastIndex : 0; i < minIndex  && i < Navigator.detail_route.length ; i++) {
                var distanceToPoint = turf.distance(tPoint, turf.point(detail_route[i]), 'meters');
                if (distanceToPoint < minDist) {
                    minDist = distanceToPoint;
                }
            }
        }
        return {pointOnRoute: nearestPoint, distance: minDist, index: minIndex, delta: minIndex - lastIndex};
    }





    static getDriverRouteToNextPoint = (dispatch:any, points:TypeLonLat[], next_point: TypeLonLat, extendedRoute: Function | null = null) => {
        return Navigator.getRoute ?  Navigator.getRoute([...points, next_point])
            .then((res: any) => {
                let coordinates: number[][] = [];

                if (res && res.data && res.data.routes)
                    res.data.routes.forEach((route: any) => {

                        route.sections.forEach((section: any) => {
                            // console.log('section.polyline = ', section.polyline);

                            let arr: any = decodeHerePolyline(section.polyline);
                            // console.log('section.polyline as arr ', arr);
                            coordinates = coordinates.concat(arr)
                        })
                    })
                dispatch(setDriverCurrentRoute( coordinates  ));
                if (extendedRoute != null) extendedRoute(Navigator.extendsCoordinates(coordinates));
                if (res.data.routes.length && res.data.routes[0].sections && res.data.routes[0].sections.length) {
                    let timeLeft = res.data.routes[0].sections[0].departure.time;
                    let timeArrival = res.data.routes[0].sections[0].arrival.time;
                    //setCurrentDriverRouteTimeThunk(dispatch, timeLeft, timeArrival);
                }
                return coordinates;
            }) : new Promise( (resolve, reject) => reject());
    };

    /**
     * Функция расширения точек на маршруте до дистанции 1 метр
     * @param route - набо координат маршрута
     * @return route - расширенный набор координат маршрута
     */
    static extendsCoordinates = (route: number[][]) =>{

        let detailRoute : number[][] = [];
        var line = turf.lineString(route);
        var length = 0, inc = 0;
        try {
             length = turf.lineDistance(line, 'meters');
             inc = Math.ceil(length / 15000);
        } catch (exeption){
            console.log('exeption', exeption.message)
        }
        if (length == 0) return route;

        for (let i=0; i < length;i+=inc){
            let along = turf.along(line, i, 'meters');

            detailRoute.push( along.geometry.coordinates );
        }
        //console.log('extendsCoordinates', detailRoute );

        // return detailRoute.map(x=> ( {lat: x[0], lon: x[1]}));
        return detailRoute;
    };

    static getDistanceABLine = (pointA: number[], pointB: number[], line: [number[]]  ) => {
        const from = turf.point( pointA  );
        const to = turf.point( pointB);
        const way = turf.lineString( line );

        var sliced =  turf.lineSlice(from, to, way) ;
        let distance = turf.lineDistance(sliced,   "meters" );
        return distance;
    };


}
