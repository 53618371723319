import React, {FC, useCallback, useState} from 'react'
import s from './AutoVehicleSelect.module.scss'
import CloseAutoselect from '../../../../../../assets/svg/CloseDetailing.svg'
import wrappingSelect from '../../../../../../assets/svg/wrappingSelect.svg'
import {useAppDispatch, useAppSelector} from '../../../../../../app/redux/hooks'
import {getActiveCharacteristics, newUserOrderSelector} from '../../NewUserOrderSlicer'

import {PalletsSelectModal} from './PalletsSelectModal/PalletsSelectModal'
import {WrappingSelectModal} from './WrappingSelectModal/WrappingSelectModal'
import {
    CargoPackegesType,
    CargoPalletsType,
    CargoPlaceType,
    createCargoPlace,
    usePackMutation,
} from '../../../../../../api/orderApi'
import {CargoItem} from './CargoItem/CargoItem'

import Overlay from "../../../Overlay/Overlay";
import {currentOrderData, setOrderData, setPackInfo} from "../../CreateOrderSlicer";
import {CargoSize, IPackage, IPallet, SendOrderInfo} from "../../CreateOrderType";
import {openInfoMessage} from "../../../../../../components/InfoMessage/infoMessageSlice";
import {InfoMessageType} from "../../../../../../components/InfoMessage/InfoMessage.types";
import {ThreeDots} from "react-loader-spinner";
import {AppendCargoForm, EnumActionMode} from "./AppendCargoForm/AppendCargoForm";

export enum TypeOfCargo {
    size = 'size',
    pallets = 'pallets',
    packages = 'wrapping',
    none = 'none'

}
export type allCargoTypes  = CargoPalletsType | CargoPackegesType | CargoPlaceType;
export class SelectedItemType  {
    type: TypeOfCargo = TypeOfCargo.size;
    index: number = -1;
    cargo: allCargoTypes;
    pallet_package_data:{id:string,name:string} | undefined = undefined;
    constructor(cargo : any, tp = TypeOfCargo.size, index = -1) {
        this.cargo = cargo;
        this.type = tp;
        this.index = index;
        this.pallet_package_data = undefined;
    }
}

interface IProps {
    onClose: ()=>void;
    onNext: ()=> void;
    onBack:()=>void
}
export const VehicleSelectAuto: FC<IProps> =  ({onClose, onNext, onBack}) => {

    const dispatch = useAppDispatch();

    const { palletTypes, packageTypes } = useAppSelector(newUserOrderSelector)
    const currentOrder : SendOrderInfo = useAppSelector(currentOrderData)

    const
          [disableSaveButton, setDisableSaveButton] = useState<boolean>(false),
          [showPallets, setShowPallets] = useState<boolean>(false),

          [showWrappers, setShowWrappers] = useState<boolean>(false),

        [isAppendMode, setAppendMode] = useState<boolean>(false),

        [selectedItem, setSelectedItem] = useState<SelectedItemType>( new SelectedItemType(createCargoPlace())),
        [isLoading, setLoading] = useState<boolean>(false),
        [isEditMode, setEditMode] = useState<boolean>( false),
        [isCargoActionMode, setCargoActionMode] = useState<EnumActionMode>( EnumActionMode.None);

    const [ pack ] = usePackMutation();


    const { carTypesTree } = useAppSelector(newUserOrderSelector);

    const packeges: CargoPackegesType[] = currentOrder.cargo.packages,
            pallets: CargoPalletsType[] = currentOrder.cargo.pallets,
               places: CargoPlaceType[] = currentOrder.cargo.places





    const onSelectButtonClick = useCallback((value: TypeOfCargo) => {


        setSelectedItem({type:value, index:-1, cargo: {size:{width:0,height:0,weight:0,length:0}, quantity:0}, pallet_package_data:undefined});
        if (value === TypeOfCargo.pallets) setShowPallets(true);
        if (value === TypeOfCargo.packages) setShowWrappers(true);
        if (value === TypeOfCargo.size) setCargoActionMode(EnumActionMode.Append);

    }, [])

    const onClickClose = () => {
        if (isEditMode || isAppendMode) hideAllActions();
        else onClose();
    }
    const hideAllActions = () => {

        setSelectedItem(new SelectedItemType(createCargoPlace()));

        setAppendMode(false);
        setEditMode(false);
        // setShowChangeItemButton(false);
    }

    // const onSelectedItemChanged = ( cargo: allCargoTypes, isChange:boolean) => {
    //
    //
    //     let isEmpty = true;
    //     if (
    //         (cargo.hasOwnProperty('quantity') && cargo.quantity) ||
    //         cargo.size.height || cargo.size.length || cargo.size.weight || cargo.size.width
    //     ) isEmpty  =false;
    //     console.log('isEmpty', isEmpty)
    //     if (isEmpty) {
    //         setAppendMode(false);
    //         setEditMode(false);
    //         setSelectedItem( new SelectedItemType(createCargoPlace()));
    //         return;
    //     }
    //
    //
    //     setSelectedItem({...selectedItem, cargo: {...cargo, size: {...cargo.size}}});
    //     if (!isEditMode && !isAppendMode)
    //         setAppendMode(true)
    // }

    const onAddAndChangeItem =  ( cargo: allCargoTypes,  isChange:boolean, type: TypeOfCargo, pallet_package_id:string) => {
        setCargoActionMode(EnumActionMode.None);
        setEditMode(false);

        console.log('onAddAndChangeItem selectedItem.cargo', selectedItem )
        console.log('onAddAndChangeItem selectedItem.cargo', cargo, isChange, type, pallet_package_id)

        let order : SendOrderInfo = {...currentOrder};
        order.cargo = {...order.cargo};
        order.cargo.places = [...order.cargo.places];
        order.cargo.packages = [...order.cargo.packages];
        order.cargo.pallets = [...order.cargo.pallets];

        const getSizeInMeters = (size: CargoSize) : CargoSize => {
            return {
                length: +(( +size.length ).toFixed(2))  ,
                width: +((+size.width ).toFixed(2)),
                height: +((+size.height ).toFixed(2)),
                weight: size.weight ? +size.weight : 0
            }
        }
        // console.log('==========', palletTypes, isChange, selectedItem, palletTypes.filter(x =>  isChange ? x.id === selectedItem.cargoID : x.name === palletType ));
        switch (type) {
            case TypeOfCargo.size:
                let place = {size: getSizeInMeters(cargo.size)};
                 if (isChange) {
                     order.cargo.places[selectedItem.index] = place;
                     order.cargo.places = [...order.cargo.places]
                 } else
                     order.cargo.places = [...order.cargo.places, place];

                break;

            case TypeOfCargo.pallets:
                let pallet : IPallet = {
                    pallet_type_id: pallet_package_id,
                    quantity: cargo.quantity ? + cargo.quantity : 0,
                    size: getSizeInMeters(cargo.size)
                };
                if (isChange) {
                    order.cargo.pallets[selectedItem.index] = pallet;
                    order.cargo.pallets = [...order.cargo.pallets]
                } else
                    order.cargo.pallets  = [...order.cargo.pallets, pallet];
                break;

            case TypeOfCargo.packages:
                let pack : IPackage ={
                    package_type_id: pallet_package_id,
                    quantity: cargo.quantity ? +cargo.quantity : 0,
                    size: getSizeInMeters(cargo.size)
                };
                if (isChange) {
                    order.cargo.packages[selectedItem.index] = pack;
                    order.cargo.packages = [...order.cargo.packages]
                } else
                    order.cargo.packages  = [...order.cargo.packages, pack];
                break;

            default:
                break;
        }
        dispatch(setOrderData(order));
    }

    const onSaveChangesClick = () => {

    }

    const onDeleteItemClick = useCallback(() => {
        let order : SendOrderInfo = {...currentOrder};
        order.cargo = {...order.cargo};
        order.cargo.places = [...order.cargo.places];
        order.cargo.packages = [...order.cargo.packages];
        order.cargo.pallets = [...order.cargo.pallets];

        if (selectedItem.type === TypeOfCargo.size) order.cargo.places = order.cargo.places.filter((x, index)=> index !=selectedItem.index );
        if (selectedItem.type === TypeOfCargo.packages) order.cargo.packages = order.cargo.packages.filter((x, index)=> index !=selectedItem.index );
        if (selectedItem.type === TypeOfCargo.pallets) order.cargo.pallets = order.cargo.pallets.filter((x, index)=> index !=selectedItem.index );

        dispatch(setOrderData(order));
        hideAllActions()
    }, [selectedItem])

    const getCargoCount = useCallback(() => {
        return packeges.length + pallets.length + places.length
    }, [packeges, pallets, places]);


    const onSelectCargoItem = (x: CargoPalletsType | CargoPackegesType | CargoPlaceType , tp : TypeOfCargo, index: number) =>{

        if (isEditMode  ) return setEditMode(false);
        console.log('x',x)
        x = {... x, size : {width : x.size.width , height : x.size.height , length : x.size.length , weight: x.size.weight}};
        let val = new SelectedItemType( x ,tp,index);
        if (x.hasOwnProperty('pallet_type_id')) {
            let fnd = palletTypes.find(z=> z.id == (x as any).pallet_type_id);
            val.pallet_package_data = fnd ? {id:fnd.id, name: fnd.name} : undefined;
        }
        if (x.hasOwnProperty('package_type_id')) {
            let fnd = packageTypes.find(z=> z.id == (x as any).package_type_id);
            val.pallet_package_data = fnd ? {id:fnd.id, name: fnd.name} : undefined;
        }
        setSelectedItem(val);

        setEditMode(true)
    }

    const onSelectPalletOrPackage = (val:{name:string, id:string,  length:number, width:number}) => {
        let v = new SelectedItemType(createCargoPlace());
        v.pallet_package_data = val;
        v.type = showPallets ? TypeOfCargo.pallets :  TypeOfCargo.packages;
        v.cargo.size.length = val.length;
        v.cargo.size.width = val.width;
        setSelectedItem(v);
        setCargoActionMode(EnumActionMode.Append);
        setShowPallets(false);
        setShowWrappers(false);
    }
    const canSave = () => {
        return !disableSaveButton && !isLoading
    }
    const loadPackData = () => {
        setLoading(true);
        pack({ cargo: currentOrder.cargo,
            body_option_id: currentOrder.body_option_id,
            body_option_characteristics: currentOrder.body_option_characteristics.length ?
                getActiveCharacteristics(currentOrder.body_option_characteristics, carTypesTree) : []
        })
            .unwrap()
            .then(x => {
                setLoading(false);
                if (x.error) {
                    dispatch(openInfoMessage({ type: InfoMessageType.FAILIED,  title: '',  text: x.error_description ? x.error_description : '' }))
                } else {
                    dispatch(setPackInfo(x));
                    dispatch(setOrderData({...currentOrder, car_type_id : x.car_type_id}))
                    onNext();
                }
            })
            .catch(y => {
                setLoading(false);
                dispatch(openInfoMessage({ type: InfoMessageType.FAILIED,  title: '',  text: 'Ошибка загрузки данных о расположении груза в машине' }))
            })


    }

    console.log('selectedItem',selectedItem)
    return (
        <>
            <div className="vehicle_select_auto">
                <div className={s.autoSelectWrapper}>
                    <div className={s.autoSelectBodyWrapper}>
                        <div className={s.autoSelectHeader}>
                            <span>
                                Груз
                            </span>
                            <img alt='Закрыть автоподбор ТС' src={CloseAutoselect} onClick={onClickClose}/>
                        </div>
                        <div className={s.appendCargo}>
                            Добавить груз:
                        </div>
                        <div className={s.autoSelectBody}>
                            <div className={s.select}>
                                <div className={ s.selected } onClick={() => onSelectButtonClick(TypeOfCargo.size)}>
                                    По габаритам
                                </div>
                                <div className={s.selected } onClick={() => onSelectButtonClick(TypeOfCargo.pallets)}>
                                    { 'На паллетах'}
                                    <img alt="Выбрать паллет" src={wrappingSelect} />
                                </div>
                                <div className={ s.selected } onClick={() => onSelectButtonClick(TypeOfCargo.packages)}>
                                    { 'Упаковки'}
                                    <img alt="Выбрать упаковку" src={wrappingSelect} />
                                </div>
                            </div>
                            {/*{typeCargo === TypeOfCargo.size && !isEditMode &&*/}
                            {/*    <SizeParamsPanel  cargo={selectedItem.cargo} onChange={onSelectedItemChanged}/>*/}
                            {/*}*/}
                            {/*/!*не происходит перересовка если входим в режим редактирования, поэтому сделал второй нет время заморачиваться*!/*/}
                            {/*{typeCargo === TypeOfCargo.size && isEditMode &&*/}
                            {/*    <SizeParamsPanel  cargo={selectedItem.cargo} onChange={onSelectedItemChanged}/>*/}
                            {/*}*/}
                            {/*{typeCargo === TypeOfCargo.pallets && !isEditMode &&*/}
                            {/*    <PalletsParamsPanel cargo={selectedItem.cargo as CargoPalletsType} onChange={onSelectedItemChanged}*/}
                            {/*                        pallet_type_id={selectedCargoTypeID} />*/}
                            {/*}*/}
                            {/*{typeCargo === TypeOfCargo.pallets && isEditMode &&*/}
                            {/*<PalletsParamsPanel cargo={selectedItem.cargo as CargoPalletsType} onChange={onSelectedItemChanged}*/}
                            {/*                    pallet_type_id={selectedCargoTypeID} />*/}
                            {/*}*/}
                            {/*{typeCargo === TypeOfCargo.packages  && !isEditMode &&*/}
                            {/*    <WrappingParamsPanel cargo={selectedItem.cargo as CargoPackegesType} onChange={onSelectedItemChanged}*/}
                            {/*                         package_type_id={packageType}  />*/}
                            {/*}*/}

                            {/*{typeCargo === TypeOfCargo.packages  && isEditMode &&*/}
                            {/*<WrappingParamsPanel cargo={selectedItem.cargo as CargoPackegesType} onChange={onSelectedItemChanged}*/}
                            {/*                     package_type_id={packageType}  />*/}
                            {/*}*/}
                            <div className={s.bottomField}>
                                {pallets.length == 0 && packeges.length == 0 && places.length == 0 &&
                                    <div className={s.infoMessage}>Для продолжения необходимо добавить хотябы один груз</div>
                                }
                                {pallets.map((x, i) => <CargoItem item={x}
                                                                                           cargo_type={TypeOfCargo.pallets}
                                                                                           cargo_name={x.pallet_type_id}
                                                                                           onClick={()=>onSelectCargoItem(x, TypeOfCargo.pallets, i)}
                                                                                           key={'pallets'+i}
                                                                                           is_selected={ i== selectedItem.index && selectedItem.type == TypeOfCargo.pallets}
                                                                                    />)}
                                {packeges.map((x, i) => <CargoItem
                                                                                            item={x}
                                                                                            cargo_type={TypeOfCargo.packages}
                                                                                            cargo_name={x.package_type_id}
                                                                                            onClick={()=>onSelectCargoItem(x, TypeOfCargo.packages, i)}
                                                                                            key={'wrapping'+i}
                                                                                            is_selected={ i== selectedItem.index && selectedItem.type == TypeOfCargo.packages}
                                                                                       />)}
                                {places.map((x, i) => <CargoItem
                                                                                            item={x}
                                                                                            cargo_type={TypeOfCargo.size}
                                                                                            cargo_name={'Груз'}
                                                                                            onClick={()=>onSelectCargoItem(x, TypeOfCargo.size, i)}
                                                                                            key={'gruz'+i}
                                                                                            is_selected={ i== selectedItem.index && selectedItem.type == TypeOfCargo.size}
                                                                                        />)}

                            </div>





                            {!isEditMode && !isAppendMode && getCargoCount() >0 && <div className={s.saveButton}>
                                <div className={`${s.button} ${!canSave() ? s.disableSaveButton : ''}`}  onClick={()=>{if (canSave()) loadPackData()}}>
                                    {isLoading ?
                                        <div className={s.title}>
                                            <div style={{marginRight: 10}}>Подбор ТС</div>
                                            <ThreeDots color={"#fff"} width={40} height={40}/>
                                        </div>
                                        :  <div className={s.title} style={{height:'40px'}}>
                                             Продолжить
                                            </div>
                                    }
                                </div>
                            </div>
                            }
                            {isEditMode && <div className={s.changeButtons}>
                                <div className={s.removeItemButton} onClick={onDeleteItemClick}>
                                    Удалить
                                </div>
                                <div className={s.saveChangesButton} onClick={()=>setCargoActionMode(EnumActionMode.Edit)}>
                                    Изменить
                                </div>
                                <div className={s.cancelButton} onClick={()=>setEditMode(false)}>
                                    Отменить
                                </div>
                            </div>
                            }
                        </div>
                        {/*<div className={`${s.addItemButton} ${ s.activeAddItemButton}`} onClick={()=>onAddAndChangeItem(false)}>*/}
                        {/*    {`*/}
                        {/*                                        ${typeCargo === TypeOfCargo.size ? 'Добавить груз' : ''}*/}
                        {/*                                        ${typeCargo === TypeOfCargo.pallets ? 'Добавить паллет' : ''}*/}
                        {/*                                        ${typeCargo === TypeOfCargo.packages ? 'Добавить упаковки' : ''}*/}
                        {/*                                    `}*/}
                        {/*</div>*/}
                        <div className={s.linkWrapper} onClick={()=>onBack()}>вернуться назад</div>
                    </div>
                    {showPallets && <PalletsSelectModal onSelect={onSelectPalletOrPackage}
                                                        onCancel={()=>{setShowPallets(false); }}
                    />}
                    {showWrappers && <WrappingSelectModal onSelect={onSelectPalletOrPackage}
                                                          onCancel={()=>{setShowWrappers(false);}}
                    />}


                </div>

              </div>
            {isCargoActionMode!=EnumActionMode.None &&
                <AppendCargoForm value={selectedItem.cargo} cargoType={selectedItem.type}
                                               mode={isCargoActionMode}
                                               additional={selectedItem.pallet_package_data}
                                               onChange={(val, type, id)=> onAddAndChangeItem(val, isCargoActionMode==EnumActionMode.Edit, type, id)}
                                               onCancel={()=>{
                                                   setEditMode(false)
                                                   setCargoActionMode(EnumActionMode.None)
                                               }}
                />}
            <Overlay zIndex={600} onClick={()=>onClickClose()}/>
        </>
    )
}




