import styled from 'styled-components'

export const StyledDriverBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  grid-gap: 28px;
  border-bottom: 1px solid var(--medium-grey);
`

export const StyledDriverRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledDriverItemContainer = styled.div`
    display: flex;
`
export const StyledDriverItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledDriverItemTitle = styled.span`
  color: var(--grey);
  font-size: 14px;
  font-weight: 400;
`

export const StyledDriverPhotoImage = styled.img`
    width: 24px;
    height: 24px;
    margin-right:15px;
    margin-top:6px;
`

export const StyledImgPhoto = styled.img`
    max-width: 100%;
    margin: 3px 0;
    border-radius: 6px;
    border: 3px solid #c6c6c6;
`;

export const StyledLoadingPhoto = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #888e99;
`;

export const StyledPhotoContainer = styled.div`
   display: flex;
   flex-direction: column;
   width:50%;
`;

export const StyledDriverButton = styled.button`
  background-color: var(--light-grey);
  color: var(--blue);
  padding: 17px 33.25px;
  border: none;
  border-radius: 8px;
  width: 166.5px;
`

export const StyledDriverItemStar = styled.span`
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-gap: 4px;
  justify-content: center;
  font-weight: 700;
  font-size: 17px;
`
