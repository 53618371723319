import { useEffect, useState } from 'react'
import { useGetArticlesQuery } from '../../../../api/orderApi'
import { useAppDispatch } from '../../../../app/redux/hooks'
import { closeLoader } from '../../../../components/Loader/loaderSlice'
import ArticleFolderList from '../ArticleFolderList'
import { IArticleItem } from '../ArticleItem/ArticleItem.types'

export const Help = () => {
  const { data, isFetching } = useGetArticlesQuery('')
  const dispatch = useAppDispatch()
  const [articles, setArticles] = useState<IArticleItem[]>([])

  useEffect(() => {
    dispatch(closeLoader())
    if (!isFetching && data?.length) {
      setArticles(data)
    }
  }, [isFetching])

 
  return (
    <>{articles && !isFetching && <ArticleFolderList articles={articles} />}</>
  )
}
