export interface IButtonProps {
  buttonType?: ButtonType;
  buttonBackgroundColor: ButtonBackgroundColor;
  buttonHeight?: ButtonHeight;
  buttonColor: ButtonColor;
  children: string,
  onClick? :()=> void;
  buttonTypeEvent?: ButtonTypeEvent
}

export enum ButtonType {
  FULL_WIDTH = 'FULL_WIDTH',
  HALF_WIDTH = 'HALF_WIDTH'
}

export enum ButtonBackgroundColor {
  BLUE = 'var(--blue)',
  DARK_GREY = 'var(--dark-grey)',
  VIOLET = 'var(--violet)',
  RED_GRADIENT = 'var(--red-gradient)',
  GREEN = 'var(--green)'
}

export enum ButtonHeight {
  MIN_HEIGHT = '56px',
  MID_HEIGHT = '58px',
  MAX_HEIGHT = '60px'
}

export enum ButtonColor {
  WHITE = 'var(--white)',
  RED = 'var(--red)'
}

export enum ButtonTypeEvent {
  SUBMIT = 'submit',
  BUTTON = 'button'
}

export interface IStyledButtonProps {
  buttonType?: ButtonType;
  buttonBackgroundColor: ButtonBackgroundColor;
  buttonHeight?: ButtonHeight;
  buttonColor: ButtonColor;
}
