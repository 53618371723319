import React, {FC, useEffect, useState} from "react";
import s from "./AppendCargoForm.module.scss";
import {editingChars} from "../../../../UserProfile/userProfile.type";
interface IProps {
    value:number,
    units:string,
    name:string,
    onChange:(val:number)=>void,
}

export const CargoParamInput: FC<IProps> = ({value,units, onChange,name}) => {
    const [localState, setLocalState] = useState<string>(''+value);
    const [isFocus, setFocused] = useState<boolean>(false);

   const onKeyDown = (e: React.KeyboardEvent) => {
        if (editingChars.includes(e.key)) return;
        if (!/[0-9,.]/.test(e.key)  || (e.target as HTMLInputElement).value.length > 6)
            e.preventDefault();
    };


    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalState(e.target.value.replace(',','.'));

    };
    useEffect(()=>{
        onChange( localState ?  +localState : 0)
    }, [localState])

    return (
        <div className={s.CargoParamInput}>
            <div className={s.header}>{name}</div>
            <div className={ s.CargoParamInputValue + ' ' + (isFocus ? s.focused : '')}>
                <input
                    type='number'
                    placeholder={'0'}
                    value={localState == '0' ? '' : localState}
                    onKeyDown={onKeyDown}
                    onChange={onValueChange}
                    onBlur={()=>setFocused(false)}
                    onFocus={()=>setFocused(true)}
                />
                <span >{units}</span>
            </div>
        </div>
    )
}
