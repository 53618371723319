import React, {ChangeEvent, FC, useCallback, useEffect} from 'react'
import s from './OrderCompletion.module.scss'
import AddCardPayment from '../../../../../../assets/svg/AddCardPayment.svg'
import {useAppDispatch, useAppSelector} from '../../../../../../app/redux/hooks'
import {currentOrderData, setOrderINN, setOrderPaymentType} from "../../CreateOrderSlicer";
import {EnumPaymentType} from "../../CreateOrderType";
import {CCompany} from "../../../UserProfile/userProfile.type";
import {userProfileSelector} from "../../../UserProfile/userProfileSlice";
import {authorizationSelector} from "../../../../../Authorization/authorizationSlice";
import SelectCompany from "./SelectCompany";

export const PaymentMethod: FC<{onPaymentEntered:(value:boolean)=>void}> = ({onPaymentEntered}) => {

    const dispatch = useAppDispatch()
    const userProfile  = useAppSelector(userProfileSelector);
    const { isAuth } = useAppSelector(authorizationSelector);

    const company : CCompany | undefined = userProfile.profile.companies.find(x=> x.id == userProfile.selected_company_id);

    const currentOrder = useAppSelector(currentOrderData)



    const onAddCardClick = useCallback(() => {
        alert('данная опция пока не доступна')
    }, [])

    useEffect(()=>{
        if (isAuth && company)
            dispatch(setOrderINN(company.tin))
    }, [])

    useEffect(()=>{
        onPaymentEntered((currentOrder.payment_type  !== EnumPaymentType.PaymentOnAccount) || currentOrder.TIN.length > 9)
    }, [currentOrder.payment_type, currentOrder.TIN])
    return (
        <div className={s.paymentMethod}>
            <div className={s.paymentButtons}>
                <div className={currentOrder.payment_type === EnumPaymentType.PaymentOnline ? s.selected : s.unselected} onClick={() => dispatch(setOrderPaymentType(EnumPaymentType.PaymentOnline))}>
                    Оплата онлайн
                </div>
                <div className={currentOrder.payment_type  === EnumPaymentType.PaymentCash ? s.selected : s.unselected} onClick={() => dispatch(setOrderPaymentType(EnumPaymentType.PaymentCash))}>
                    Наличными
                </div>
                <div className={currentOrder.payment_type  === EnumPaymentType.PaymentOnAccount ? s.selected : s.unselected} onClick={() => dispatch(setOrderPaymentType(EnumPaymentType.PaymentOnAccount))}>
                    По счету
                </div>
            </div>
            <div className={s.paymentData}>
                <div className={s.card} hidden={currentOrder.payment_type  !== EnumPaymentType.PaymentOnline } onClick={onAddCardClick}>
                    <span>Добавить карту</span>
                    <img alt="Добавить карту" src={AddCardPayment} />
                </div>
                <div className={s.cash} hidden={currentOrder.payment_type  !== EnumPaymentType.PaymentCash }>
                    Отдайте оплату за заказ наличными в руки исполнителю (грузчику)
                </div>
                <div className={s.INN} hidden={currentOrder.payment_type  !== EnumPaymentType.PaymentOnAccount }>

                    <SelectCompany/>

                </div>
            </div>
        </div>
    )
}
