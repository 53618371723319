import React, {FC, memo, useCallback, useEffect, useState} from 'react'
import s from './NewOrder.module.scss'
import {NewOrderHeader} from './NewOrderHeader'
import CarsIcon from '../../../../../assets/icons/CarsIcon.svg'
import {   setClickableMap, } from '../NewUserOrderSlicer'
import {NewOrderAddresses} from '../AddressBlock/NewOrderAddresses'
import {useAppDispatch, useAppSelector} from '../../../../../app/redux/hooks'
import {CloseOrder} from './CloseOrder/CloseOrder'
import {LocationDetectModal} from "../../LocationDetect/LocationDetectModal";
import {FindLocationButton} from "../AddAddressesForm/FindLocationButton";
import {
  currentOrderData,
  getOrderMode,
  getShowMapMode,
  getStreetValue,
  getTariffs,
  setAddressMode,
  setOrderRoutes,
  setOrderMode, getCost, getRoutePointAsMarkers
} from "../CreateOrderSlicer";
import {
  EnumAddressMode,
  EnumOrderMode,
  EnumShowMapMode,
  IMapShowMode,
  CTariffData,
  RouteAddress,
  SendOrderInfo,

} from "../CreateOrderType";
import {daDataApi} from "../../../../../api/daDataApi";
import {InputAddressModal} from "../InputAddressModal/InputAddressModal";
import {VehicleSelectManual} from "./VehicleSelect/VehicleSelectManual";
import {VehicleSelectAuto} from "./AutoVehicleSelect/VehicleSelectAuto";
import {AutoVehicleSelectSummary} from "./AutoVehicleSelectSummary/AutoVehicleSelectSummary";
import {mapRoute} from "../../../../../components/HereMap/hereMapTypes";
import {setMarkers, setRoutes} from "../../../../../components/HereMap/hereMapSlice";
import AddressBlock from "../AddressBlock/AddressBlock";


interface IProps {
  onComplete:()=>void;
  onCancelOrder:()=>void;
}
export const NewOrder: FC<IProps> =  ({ onComplete, onCancelOrder}) => {

  const dispatch = useAppDispatch()

  const currentOrder : SendOrderInfo = useAppSelector(currentOrderData);
  const map_mode : IMapShowMode = useAppSelector(getShowMapMode);
  const tariffs : CTariffData[] = useAppSelector(getTariffs);
  const order_mode : EnumOrderMode = useAppSelector(getOrderMode);
  const cost  =  getCost(tariffs) ;

  //@ts-ignore
  window.order = currentOrder;

  const [showLocationDetectModal, setShowLocationDetectModal] = useState<boolean>(false);
  const [isLocationDetectAllowed, setDetectedLocation ] = useState<boolean>(false);

  const [isShowAutoVehicleSummary, setShowAutoVehicleSummary] = useState<boolean>(false);
  const [wasError, setWasError] = useState<boolean>(false);





    useEffect(()=> {
       setWasError(currentOrder.date=='' && currentOrder.routes.length > 1)
    },[currentOrder.date]);

  const setDetectedLocationAsCurrent = async () => {

    await navigator.permissions
        .query({ name: "geolocation" })
        .then(data => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(  async (pos: any) => {
              let coord = {lat: pos.coords.latitude, lon: pos.coords.longitude};
              const result : any = await dispatch( daDataApi.endpoints.getAddressByCoords.initiate(coord));
              if (result.isSuccess && result.data  && result.data.suggestions && result.data.suggestions.length) {
                let route = new RouteAddress(0);
                let data  = result.data.suggestions[0];
                route.adress = getStreetValue(data);
                route.adress_latitude = data.data.geo_lat;
                route.adress_longitude = data.data.geo_lon;
                route.house = data.data.house;
                // dispatch(setTravers(TraversTypes.add))
                dispatch(setClickableMap(true));
                dispatch(setAddressMode(EnumAddressMode.Append));
                dispatch(setOrderRoutes([route]));
                //setShowAddressSelect(route );

              }
              console.log(result);

            });
          } else {
            alert("Geolocation is not supported by this browser.")
          }
        })
  };

  useEffect(()=>{

  }, [map_mode])

  const showNextOrderForm = (value: EnumOrderMode) => {
      if (currentOrder.date)
        dispatch(setOrderMode( value))
      else
          setWasError(true);
          // showErrorMessage('Для продолжения необходимо выбрать дату и время подачи машины!',dispatch)
  };
//    console.log('map_mode', map_mode)
  return (
      <div className={map_mode.mode != EnumShowMapMode.None ? s.hideNewOrderWindow : ''}>
          <div className={`${s.newOrderWrapper} `}>
          <CloseOrder onClick={()=>{setWasError(false);onCancelOrder();}}  />
          <div className={s.newOrder}>
            {
              currentOrder.routes.length ==0 &&
              <FindLocationButton isLocationDetectAlowed={isLocationDetectAllowed} setShowLocationDetectModal={setShowLocationDetectModal} setDetectedLocationAsCurrent={setDetectedLocationAsCurrent} />
            }

            {currentOrder.routes.length > 1 && <NewOrderHeader date={currentOrder.date} wasError={wasError} /> }
              {/*<NewOrderAddresses onAdd={()=> setShowAddressSelect(new RouteAddress(currentOrder.routes.length)) }*/}
              {/*                   onEdit={(address)=> setShowAddressSelect(address)}*/}
              {/*/>*/}
              <AddressBlock/>
            {
              currentOrder.routes.length > 1 &&
              <>
                {cost > 0 &&
                  <div>
                    <div className={s.newOrderCoast}>
                      Стоимость: <big>{cost}  </big>
                    </div>
                    <div>
                      <img alt='Выбор транспортного средства для доставки' src={CarsIcon}/>
                    </div>
                  </div>
                }
                  {wasError &&   <div className={s.errorText}>Необходимо выбрать дату и время подачи ТС</div>}
                <div className={`${s.controllButtons} `}>
                  <button onClick={()=> showNextOrderForm( EnumOrderMode.Manual)}>
                    Выбрать транспорт
                  </button>
                  <button onClick={()=>showNextOrderForm( EnumOrderMode.Auto)}>
                    Автоподбор
                  </button>
                </div>
              </>
            }
          </div>
        </div>
        {showLocationDetectModal && <LocationDetectModal setShowLocationDetectModal={setShowLocationDetectModal} setDetectedLocationAsCurrent={setDetectedLocationAsCurrent} />}

        {order_mode === EnumOrderMode.Manual && <VehicleSelectManual  onClose={() => { dispatch(setOrderMode(EnumOrderMode.None)); }} onNext={onComplete} />}
        {order_mode === EnumOrderMode.Auto && !isShowAutoVehicleSummary &&
                    <VehicleSelectAuto  onClose={() => { dispatch(setOrderMode(EnumOrderMode.None)); }}
                                        onNext={()=>setShowAutoVehicleSummary(true)}
                                        onBack={()=>dispatch(setOrderMode(EnumOrderMode.None))}
                    />}
        {order_mode === EnumOrderMode.Auto && isShowAutoVehicleSummary &&
                    <AutoVehicleSelectSummary  onClose={() => setShowAutoVehicleSummary(false)} onNext={onComplete} />}

      </div>
  )
}
