import styled from "styled-components";

const StyledSvg = styled.svg`
  cursor: pointer;
`;
interface IStarIconProps {
  color?: string;
  onClick: () => void;
}
export const StarIcon = ({ color, onClick }: IStarIconProps) => {
  return (
    <StyledSvg
      width="41"
      height="38"
      viewBox="0 0 41 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M20.5 0L25.2148 14.5106H40.4722L28.1287 23.4787L32.8435 37.9894L20.5 29.0213L8.15651 37.9894L12.8713 23.4787L0.527813 14.5106H15.7852L20.5 0Z"
        fill={color ?? "#C6CAD2"}
      />
    </StyledSvg>
  );
};
