export interface IForm {
  children: JSX.Element[] | JSX.Element | any
  display?: DisplayType
  gridGap?: string
  justifyContent?: JustifyContentType
  flexDirection?: FlexDirectionType
  height?: string
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void
  textAlign?: TextAlignType
  margin?: string
}

export interface IStyledForm {
  display?: DisplayType
  gridGap?: string
  justifyContent?: JustifyContentType
  flexDirection?: FlexDirectionType
  height?: string
  textAlign?: TextAlignType
  margin?: string
}

export enum DisplayType {
  FLEX = 'flex',
  TABLE = 'table',
  NONE = 'none'
}

export enum JustifyContentType {
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end',
  CENTER = 'center',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
  SPACE_EVENLY = 'space-evenly'
}

export enum FlexDirectionType {
  ROW = 'row',
  ROW_REVERSE = 'row-reverse',
  COLUMN = 'column',
  COLUMN_REVERSE = 'column-reverse'
}

export enum TextAlignType {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  JUSTIFY = 'justify',
  START = 'start',
  END = 'end'
}
