import {
  StyledCode,
  Title,
  RepeatCode,
  DisabledDivForButton,
  DisabledDivCenter
} from './Code.styled'
import Input from '../../../../components/Input'
import {
  InputBackgroundColor,
  InputType
} from '../../../../components/Input/Input.types'
import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import { useEffect } from 'react'
import {
  codeSelector,
  setCode,
  setCounter,
  setDisabledRegistration,
  setDisabledSendRepeateCode,
  setIsValidCode,
  setMaxCounter
} from './codeSlice'
import { ICodeProps } from './Code.types'

import {
  FlexDirectionType,
  JustifyContentType,
  TextAlignType
} from '../../../../components/Form/Form.types'
import {
  useSendSmsCodeMutation,
  useCheckSmsMutation,
  useAuthMutation,
  useClientsMutation
} from '../../../../api/orderApi'
import { clear as clearCode } from '../Code/codeSlice'
import {
  authorizationFormSelector,
  clear as clearAuthorization
} from '../AuthorizationForm/authorizationFormSlice'
import { clear as clearRegistration } from '../RegistrationForm/registrationFormSlice'
import { InfoMessageType } from '../../../../components/InfoMessage/InfoMessage.types'
import { openInfoMessage } from '../../../../components/InfoMessage/infoMessageSlice'
import { closeModal } from '../../../../components/ModalFull/modalFullSlice'

import {
  openLoader,
  closeLoader
} from '../../../../components/Loader/loaderSlice'
import { registrationFormSelector } from '../RegistrationForm/registrationFormSlice'
import { setClientId, setFullName } from '../../../../app/redux/reducers/user'
import Button from '../../../../components/Button'
import {
  ButtonBackgroundColor,
  ButtonColor,
  ButtonHeight,
  ButtonTypeEvent
} from '../../../../components/Button/Button.types'
import { useCookies } from 'react-cookie'
import {cookiesClientId, cookiesDefaultUserId} from '../../../../api/api.constants'
import Form from '../../../../components/Form'
import { setIsAuth } from '../../../Authorization/authorizationSlice'

export const Code = ({ isAuth }: ICodeProps) => {
  const MAX_LENGTH_CODE = 4
  const [cookies, setCookie] = useCookies([cookiesClientId, cookiesDefaultUserId])
  const {
    code,
    isHideCode,
    isValidCode,
    disabledSendRepeateCode,
    disabledRegistration,
    counter
  } = useAppSelector(codeSelector)
  const { fullName, email, inn } = useAppSelector(registrationFormSelector)
  const { phone } = useAppSelector(
    isAuth ? authorizationFormSelector : registrationFormSelector
  )
  const dispatch = useAppDispatch()

  const [sendSmsCode] = useSendSmsCodeMutation();
  const [auth] = useAuthMutation();
  const [check_sms] = useCheckSmsMutation();
  const [clients] = useClientsMutation();

  const sendSmsCodeHandler = () => sendSmsCode(phone)

  const onChangeSmsCodeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const codeValue = e.target.value
    dispatch(setCode(codeValue))
    dispatch(setDisabledRegistration(codeValue.length !== MAX_LENGTH_CODE))
  }

  const checkCodeAuth = () => {
    if (code.length !== MAX_LENGTH_CODE)  return;

     auth({ code, phone })
      .unwrap()
      .then(res => {
        const { status, client_id, error_message, full_name } = res;
        const isValidCode = !(
          status === 'error' && error_message === 'Код не подходит'
        )
        dispatch(setIsValidCode(isValidCode))
        if (status === 'error') {
          openErrorMessage(error_message);
        } else {
          success(client_id, full_name)
        }
      })
  }
  const checkCodeRegistration = () => {
    if (code.length !== MAX_LENGTH_CODE)  return;
    check_sms({ code, phone })
        .unwrap()
        .then(res => {
          const { valid } = res;
          if (!valid) {
            openErrorMessage('Ошибка, код указан некорректно');
          } else {
            registerClient();
          }
        });
  }
  const registerClient = () => {
    dispatch(openLoader());
    clients({ phone, email, fullName, inn })
      .unwrap()
      .then(res => {
        dispatch(closeLoader());
        const { status, client_id, error_message, full_name, users } = res;

        if (status === 'error') {
          openErrorMessage(error_message)
        } else {
          const user = users.length ? users[0] : null;
          const fullName = full_name || (user && user.full_name) || '';
          const clientId = client_id || (user && user.client_id) || '';


          success(clientId, fullName)
        }
      }).catch((e)=>{
        dispatch(closeLoader());
        openErrorMessage("Ошибка получения данных clients");
    })
  }

  const success = (clientId: string, fullName: string) => {
    dispatch(setClientId(clientId));
    dispatch(setFullName(fullName));
    setCookie(cookiesClientId, clientId, { path: '/' });

    openSuccessMessage(`Добро пожаловать ${fullName}`);
    dispatch(setIsAuth())
  }

  const sendRepeateCodeHandler = () => {
    sendSmsCodeHandler()
    dispatch(setMaxCounter())
    dispatch(setDisabledSendRepeateCode(true))
    dispatch(setDisabledRegistration(true))
  }

  const openSuccessMessage = ( text?:any,  title?:any) => {
    if (!text) text = `Добро пожаловать`;
    if (!title) title = isAuth ? 'Авторизация завершена' : 'Регистрация завершена';

    dispatch(
      openInfoMessage({
        title: title || 'Авторизация завершена',
        text: text,
        type: InfoMessageType.SUCCESSESED
      })
    )
    dispatch(clearAuthorization())
    dispatch(clearRegistration())
    dispatch(clearCode())
    dispatch(closeModal())
  }

  const openErrorMessage = (text = 'Перепроверьте введенные данные') => {
    dispatch(
      openInfoMessage({
        title: 'Ошибка',
        text: text,
        type: InfoMessageType.FAILIED
      })
    )
    dispatch(clearCode())
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setCounter(counter - 1))
    }, 1000)

    if (counter === 0) {
      dispatch(setDisabledSendRepeateCode(false))
    }

    return () => {
      clearTimeout(timer)
    }
  }, [counter])
  return (
    <>
      <Form
        flexDirection={FlexDirectionType.COLUMN}
        justifyContent={JustifyContentType.SPACE_BETWEEN}
        margin='90px 0 0 0'
      >
        <StyledCode>
          <Title>
            На Ваш номер придёт SMS-сообщение с кодом, который нужно ввести ниже
          </Title>
          <Input
            name='code'
            type={InputType.text}
            placeholder='Код из SMS'
            background={InputBackgroundColor.LIGHT_GREY}
            value={code}
            onChange={onChangeSmsCodeHandler}
            border={
              isValidCode || isValidCode === null ? 'none' : '1px solid red'
            }
            pattern='[0-9]{4}'
            required={!isHideCode}
            maxLength={MAX_LENGTH_CODE}
            textAlign={TextAlignType.LEFT}
            margin='42px 0 0 -14px '
          />
          <RepeatCode>
            Отправить новый код можно через {counter} сек...
          </RepeatCode>
          <DisabledDivCenter disabled={disabledSendRepeateCode} width='auto'>
            <a onClick={sendRepeateCodeHandler}>Получить повторно</a>
          </DisabledDivCenter>
        </StyledCode>
      </Form>
      <DisabledDivForButton
        disabled={disabledRegistration}
        backgroundColor={ButtonBackgroundColor.RED_GRADIENT}
      >
        <Button
          buttonTypeEvent={ButtonTypeEvent.BUTTON}
          buttonBackgroundColor={ButtonBackgroundColor.BLUE}
          buttonColor={ButtonColor.WHITE}
          buttonHeight={ButtonHeight.MID_HEIGHT}
          onClick={() => isAuth ? checkCodeAuth() : checkCodeRegistration()}
          children={isAuth ? 'Авторизоваться' : 'Зарегистрироваться'}
        />
      </DisabledDivForButton>
    </>
  )
}
