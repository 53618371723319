import styled from 'styled-components'
import { DEVICE, IStyledModalProps, WINDOW_SIZE } from './Modal.types'

export const StyledModal = styled.div<IStyledModalProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.isOpen ? 1 : 0)};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  transition: all 0.25s ease-in-out;
  z-index: 100000;
  overflow-y: scroll;
`

export const StyledModalContent = styled.div`
  width: 100%;
  min-height: 80%;
 
  display: block;
  position: absolute;
  top:20%;
  left: 0;
  background: #fff;
 
  border-radius: 10px 10px 0 0;
  @media ${DEVICE.MOBILE_S} {
    width: ${WINDOW_SIZE.MOBILE_S};
  }

  @media ${DEVICE.MOBILE_M} {
    width: ${WINDOW_SIZE.MOBILE_M};
  }

  @media ${DEVICE.MOBILE_L} {
    width: ${WINDOW_SIZE.MOBILE_L};
  }
`
export const StyledModalHeader = styled.div`
  position: relative;
  font-size: 24px;
  font-weight: 500;

  background-color: var(--light-grey);
  border-radius: 10px 10px 0 0;
`

export const StyledStyledModalHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 30px;
  font-size: 20px;
  font-weight: 500;
`
