import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { orderApi } from '../../api/orderApi'
import { driverApi } from '../../api/driverApi'
import { daDataApi } from '../../api/daDataApi'
import { setupListeners } from '@reduxjs/toolkit/query'
import userReducer from './reducers/user'
import authReducer from '../../modules/Authorization/authorizationSlice'
import hereMapReducer from '../../components/HereMap/hereMapSlice'
import locationDetectReducer from '../../modules/Home/components/LocationDetect/locationDetectSlice'
import sideBarReducer from '../../modules/Home/components/SideBar/sideBarSlice'
import modalReducer from '../../components/Modal/modalSlice'
import modalFullReducer from '../../components/ModalFull/modalFullSlice'
import registrationFormReducer from '../../modules/Home/components/RegistrationForm/registrationFormSlice'
import authorizationFormReducer from '../../modules/Home/components/AuthorizationForm/authorizationFormSlice'
import codeReducer from '../../modules/Home/components/Code/codeSlice'
import infoMessageReducer from '../../components/InfoMessage/infoMessageSlice'
import loaderReducer from '../../components/Loader/loaderSlice'
import profileReducer from '../../modules/Home/components/Profile/profileSlice'
import editClientsFormReducer from '../../modules/Home/components/EditClientsForm/editClientsFormSlice'
import phoneInputReducer from '../../components/PhoneInput/phoneInputSlice'
import innReducer from '../../components/Inn/innSlice'
import orderListReducer from '../../modules/Orders/components/OrderList/orderListSlice'
import orderReducer from '../../modules/Orders/components/Order/orderSlice'
import ratingWindowReducer from '../../components/RatingWindow/ratingWindowSlice'
import orderAddressListReducer from '../../modules/Orders/components/OrderAddressList/orderAddressListSlice'
import articleListReducer from '../../modules/Help/components/ArticleList/articleListSlice'
import articleReducer from '../../modules/Help/components/Article/articleSlice'
import vehicleWindowReducer from '../../modules/Orders/components/VehicleWindow/vehicleWindowSlice'
import amountWindowReducer from '../../modules/Orders/components/AmountWindow/amountWindowSlice'
import createOrderReducer from '../../modules/Home/components/NewUserOrder/CreateOrderSlicer'
import newUserOrderReducer from '../../modules/Home/components/NewUserOrder/NewUserOrderSlicer'
import userProfileReducer from '../../modules/Home/components/UserProfile/userProfileSlice'
import { homeApi } from '../../api/homeApi'
import {hereComeApi} from "../../api/here-api";


export const store = configureStore({
  reducer: {
    [orderApi.reducerPath]: orderApi.reducer,
    [driverApi.reducerPath]: driverApi.reducer,
    [daDataApi.reducerPath]: daDataApi.reducer,
    [hereComeApi.reducerPath]: hereComeApi.reducer,
    user: userReducer,
    [homeApi.reducerPath]: homeApi.reducer,
    newUserOrder: newUserOrderReducer,
    auth: authReducer,
    hereMap: hereMapReducer,
    locationDetect: locationDetectReducer,
    sideBar: sideBarReducer,
    modal: modalReducer,
    modalFull: modalFullReducer,
    registrationForm: registrationFormReducer,
    authorizationForm: authorizationFormReducer,
    code: codeReducer,
    infoMessage: infoMessageReducer,
    loader: loaderReducer,
    profile: profileReducer,
    editClientsForm: editClientsFormReducer,
    phoneInput: phoneInputReducer,
    inn: innReducer,
    orderList: orderListReducer,
    selectedOrder: orderReducer,
    ratingWindow: ratingWindowReducer,
    orderAddressList: orderAddressListReducer,
    articleList: articleListReducer,
    article: articleReducer,
    vehicleWindow: vehicleWindowReducer,
    amountWindow: amountWindowReducer,
    createOrder: createOrderReducer,
    userProfile: userProfileReducer,

  },
  middleware: getDefaultMiddleware =>
       getDefaultMiddleware({serializableCheck: false})
      .concat(orderApi.middleware)
      .concat(driverApi.middleware)
      .concat(homeApi.middleware)
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
//@ts-ignore
window.state = store.getState();

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

setupListeners(store.dispatch);
