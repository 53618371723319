import React, {createRef, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {
    SendCalcOrder,
    useCalcOrderRequestAllMutation,
    useDeleteOrderMutation,
    useGetOrderMutation,
    useGetRoutesByOrderIdMutation,
} from "../../../../api/orderApi";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import {clear, orderSelector, setSelectedOrder, setSelectedTariffs, setOrderRouteWithTime } from "./orderSlice";
import {openModalWithTitle as openModalFullWithTitle, setTitle} from "../../../../components/ModalFull/modalFullSlice";
import {IOrderFull, IPointRoute, IRouteByOrderId} from "./Order.types";

import { mapMarker, mapRoute } from "../../../../components/HereMap/hereMapTypes";

import moment from "moment";
import "moment/locale/ru";
import { orderListSelector } from "../OrderList/orderListSlice";
import {getBodyName, getCategorySvg, getVehicle} from "../OrderItem/categoryHelper";
import Button from "../../../../components/Button";
import {
  ButtonBackgroundColor,
  ButtonColor,
  ButtonHeight,
  ButtonTypeEvent,
} from "../../../../components/Button/Button.types";
import {

  closeLoader,
} from "../../../../components/Loader/loaderSlice";
import {EnumOrderStatusID, IOrderItemStatusType} from "../OrderItem/OrderItem.types";
import OrderAddressList from "../OrderAddressList/OrderAddressList";
import {
  orderAddressListSelector,
  setIsFull,
} from "../OrderAddressList/orderAddressListSlice";
import { ArrowBottomIcon } from "../../../../assets/icons/ArrowBottomIcon";
import OrderCar from "../OrderCar/OrderCar";
import OrderAmount from "../OrderAmount/OrderAmount";
import { openModal } from "../../../../components/Modal/modalSlice";
import {
  vehicleWindowSelector,
  initializeVehicle,
} from "../VehicleWindow/vehicleWindowSlice";
import { Provider } from "react-redux";
import { store } from "../../../../app/redux/store";
import {
    StyledButtonBottomBlock,
    StyledButtonBottomBlock2Button,
    StyledLinkBack
} from "../../../../components/Button/Button.styled";
import { initializeAmount } from "../AmountWindow/amountWindowSlice";
import RaitingWindow from "../../../../components/RatingWindow/RatingWindow";
import { ratingWindowSelector, setIsShowRatingWindow } from "../../../../components/RatingWindow/ratingWindowSlice";
import {CTariffData, EnumOrderMode, SendOrderInfo} from "../../../Home/components/NewUserOrder/CreateOrderType";
import AppLoadingForm from "../../../Home/components/AppLoadingForm/AppLoadingForm";
import {
    setCarPositionEmpty,
    setCarPositionPop,
    setClearMarkers,
    setClearRoutes,
    setMarkers,
    setNextPointIndex,
    setRoutes
} from "../../../../components/HereMap/hereMapSlice";
import {ArrowLeftIcon} from "../../../../assets/icons/ArrowLeftIcon";
import {
    StyledModalFullArrowLeft,
    StyledModalFullTitle
} from "../../../../components/ModalFull/ModalFull.styled";
import QuestionWindow from "../../../Home/components/NewUserOrder/QuestionWindow/QuestionWindow";
import {
    getRoutePointAsMarkers,
    setOrderData,
    setOrderDataAuto,
    setOrderDataManual
} from "../../../Home/components/NewUserOrder/CreateOrderSlicer";
import "./Order.css"
import TimeBtn from "./TimeBtn/TimeBtn";
import {VehicleWindow} from "../VehicleWindow/VehicleWindow";
import AmountWindow from "../AmountWindow/AmountWindow";
import {newUserOrderSelector} from "../../../Home/components/NewUserOrder/NewUserOrderSlicer";
import {showErrorMessage} from "../../../../components/InfoMessage/infoMessageSlice";
import {DEBUG_ORDER_ID, IS_DEBUG_MODE} from "../../../../api/api.constants";

enum EnumActionType{Repeat, Remove, Change};
var deltaTouch = 0;
export const Order = () => {
    const [getOrder] = useGetOrderMutation();

    const [  calcOrderRequestAll  ] = useCalcOrderRequestAllMutation();
    const [  getRoutesByOrderId  ] = useGetRoutesByOrderIdMutation();
    const [  deleteOrder  ] = useDeleteOrderMutation();

    const { id, isShowBack, order } = useAppSelector(orderSelector);
    const { categories } = useAppSelector(orderListSelector);
    const [carTypeId, setCarTypeId] = useState<string>("");
    const [isLoading, setLoading] = useState<boolean>(false);

    const [isShowQuestion, setShowQuestion ] = useState<{title:string, action_type: EnumActionType} | undefined>(undefined);
    const [isShowVehicleWindow, setShowVehicleWindow ] = useState<boolean>(false);
    const [isShowAmountWindow, setShowAmountWindow ] = useState<boolean>(false);
    const [isMinSize, setMinSize ] = useState<boolean>(false);

    const {carBodyOptions} = useAppSelector(newUserOrderSelector);

    const dispatch = useAppDispatch();
    const { isFullAddress } = useAppSelector(orderAddressListSelector);
    const { vehicle } = useAppSelector(vehicleWindowSelector);
    const {isShowRatingWindow} = useAppSelector(ratingWindowSelector);
    const refTitle = createRef<HTMLDivElement>();

    useEffect(() => {loadOrder(id)}, [id]);

    const loadOrder = async (id: string|undefined) => {
        if (!id) return;

        setLoading(true);
        let res :any = await getOrder(id).unwrap() ;
        let loadedOrder = {...res} as IOrderFull;
        if (IS_DEBUG_MODE && loadedOrder && loadedOrder.id === DEBUG_ORDER_ID){
            loadedOrder.status = "Выполняется";
            loadedOrder.status_id = EnumOrderStatusID.Executing;
        }
        let vehicle = loadedOrder.vehicle || getVehicle(categories, loadedOrder.car_type_id);
        dispatch(setSelectedOrder(loadedOrder));
        setCarTypeId(loadedOrder.car_type_id);
        if (loadedOrder.status === IOrderItemStatusType.isCompleted && loadedOrder && loadedOrder.driver && !loadedOrder.driver.rating)
          dispatch(setIsShowRatingWindow(true));



        let tmp : any = loadedOrder;
        let data = await calcOrderRequestAll(tmp as SendOrderInfo).unwrap();
        let tariffs = data as CTariffData[];
        if (Array.isArray(tariffs)) {
            dispatch(setSelectedTariffs(tariffs));

        }

        let rts : IRouteByOrderId = await getRoutesByOrderId(id).unwrap();
        dispatch(setOrderRouteWithTime(rts));
        console.log('rts',rts);
        dispatch(setMarkers(constructMarkers(rts.points)));
        let tmp_route = [];
        for (let i = 1; i < rts.points.length; i++) {
              let route: mapRoute = new mapRoute();
              route.type = 1;
              route.coordinate = rts.points[i].shape.map((x) => ({lon: +x.split(',')[1], lat: +x.split(',')[0]}));
              tmp_route.push(route);
          }
        dispatch(setRoutes(tmp_route));



        dispatch(closeLoader());
        dispatch(
          initializeAmount({
            amount: loadedOrder.amount,
            vehicle: vehicle,
            carTypeId: loadedOrder.car_type_id,
            tariffTypeId: loadedOrder.tariff_type_id,
            status: loadedOrder.status,
            dateForShow: getDateForShow(loadedOrder.date),
          })
        );
        dispatch(
          initializeVehicle({
            driver: loadedOrder.driver,
            amount: loadedOrder.amount,
            vehicle: vehicle,
            carTypeId: loadedOrder.car_type_id,
            tariffTypeId: loadedOrder.tariff_type_id,
          })
        );

        setLoading(false);
    };


    const onOrderAction = async () =>{
        setLoading(true);
        setShowQuestion(undefined);


       if (order && (isShowQuestion?.action_type == EnumActionType.Change || isShowQuestion?.action_type == EnumActionType.Remove)) {
          let data = await  deleteOrder(order.id).unwrap();
          if (!data) return showErrorMessage("Не удалось удалить заказ, АПИ еще не реализовано", dispatch);
          if (data.status == 'error') showErrorMessage(data.error_message, dispatch);
       }

        dispatch(clear());
        dispatch(setClearRoutes());
        dispatch(setClearMarkers());

        if (order && (isShowQuestion?.action_type == EnumActionType.Repeat || isShowQuestion?.action_type == EnumActionType.Change)) {

            let tmp: any = {...order, draft: true, id:''};
            if (order.cargo.places.length || order.cargo.pallets.length || order.cargo.packages.length)
                await dispatch(setOrderDataAuto(tmp));
            else
                await dispatch(setOrderDataManual(tmp));

            dispatch(setOrderData(tmp));
            dispatch(setMarkers(getRoutePointAsMarkers(tmp)));
            if (tmp.routes.length < 2){
                dispatch(setRoutes([]));
            } else {
                let route = new mapRoute();
                route.coordinate = tmp.routes.map( (x:any) => ({ lon: x.adress_longitude, lat: x.adress_latitude  }) );
                route.type = 0;
                dispatch( setRoutes([route]))
            }
        }
        setLoading(false);
    };


    const constructMarkers = (points: IPointRoute[]) => {
        const markers: mapMarker[] = [];
        points.map((item, index) => {
          markers.push({
            lon: item.lng,
            lat: item.lat,
            type: index === 0 ? 4 : 3,
            index: index,
            title: moment(item.arrival_traffic_time).format("HH:mm"), //getTitleForMarker(index, order.date),
            text: "",
            is_show_text: false,
            bg_color: index === 0 ? "var(--orange)" : "blue",
          });
        });
        return markers;
    };

    const getDateForShow = (date: string) => {
    const dateSplit = date.split("T");

    return moment(dateSplit[0] + "T" + dateSplit[1])
      .locale("ru")
      .format("DD MMMM HH:mm");
    };

    const getStatusColor = ( status:string) => status === IOrderItemStatusType.isCanceled || status === IOrderItemStatusType.isNotDone ? "red" : "green";
    const minimizeAddressList = () =>   dispatch(setIsFull(false));

    const onBack = () => {
      dispatch(clear())
      dispatch(setClearRoutes());
      dispatch(setClearRoutes());
      dispatch(setClearMarkers());
      dispatch(
          openModalFullWithTitle({
              children: 'OrderList',
              title: 'Журнал заказов'
          })
      )
      dispatch(setIsFull(false))
      dispatch(setCarPositionEmpty());
    }
    const  isOrderExecuting = () => order && order.status_id == EnumOrderStatusID.Executing;
    const  isOrderCompleted = () => order && order.status_id == EnumOrderStatusID.Completed;
    const  isOrderNotStarted = () => order && (order.status_id == EnumOrderStatusID.DriverSearching || order.status_id == EnumOrderStatusID.DriverFound);

    useEffect(() => { }, [order])
    const getTitleRemove = ()  => {
        if (!order || order.status_id == EnumOrderStatusID.DriverSearching) return 'Вы уверены что хотите удалить выбранный заказ ?';
        return  'Вы уверены что хотите удалить выбранный заказ ? Стоимость отмены заказа составляет 500 р.';
    }
    const getTitleChange = ()  => {
        if (!order || order.status_id == EnumOrderStatusID.DriverSearching)
            return `Вы уверены что хотите изменить выбранный заказ ?<br/><br/> Заказ №${(order ? order.number : '')} будет отменен и на его основе будет создан новый заказ`;
        return  `Вы уверены что хотите изменить выбранный заказ ?<br/><br/>
                 Заказ №${(order ? order.number : '')} будет отменен и на его основе будет создан новый заказ<br/><br/>
                 Стоимость отмены заказа составляет 500 р.`;
    }

    if (isShowVehicleWindow) return (<VehicleWindow order={order} onClose={()=>setShowVehicleWindow(false)}
                                                    onAddressClick={()=>{setShowVehicleWindow(false); dispatch(setIsFull(true));}}
                                                    onAmountClick={()=>{setShowVehicleWindow(false); setShowAmountWindow(true);}}
                                      />);
    if (isShowAmountWindow) return (<AmountWindow order={order} onClose={()=>setShowAmountWindow(false)}
                                                  onVehicleClick={()=>{setShowAmountWindow(false);setShowVehicleWindow(true)}}/>);


    const onMouseMove = (e: any) => {
         if (deltaTouch == 0 && e.changedTouches.length >0) deltaTouch =  e.changedTouches[0].clientY};
    const onMouseEnd = (e: any) => {console.log('mooving', e.changedTouches[0].clientY)
        if (e.changedTouches.length <= 0) return;
        if (e.changedTouches[0].clientY - deltaTouch > 30)
            setMinSize(  true);
        if (e.changedTouches[0].clientY - deltaTouch < -19)
            setMinSize(  false);
        console.log(e.changedTouches[0].clientY - deltaTouch)
        deltaTouch = 0;
    };

    return (
      <>
          {order && order.status_id != EnumOrderStatusID.Executing && !isShowRatingWindow &&
              <StyledModalFullArrowLeft
                  isAbsolute={true}
                  borderRadius={ '0 0 16px 16px'}
                  background={"#fff"}
                  paddingLeft={"20px"}
                  style={{top:'0px', paddingTop:'3px', paddingBottom:'7px'}}

              >
                  <ArrowLeftIcon onClick={onBack} />
                  <StyledModalFullTitle
                      ref={refTitle}
                      paddingLeft={"0"}
                      style={{paddingRight:'40px'}}
                      fontSizeTitle={'20px'}
                  >
                      <div className="title-order-number">Заказ № { order.number } </div>
                      <div className={`title-order-status title-order-status-${getStatusColor(order.status)}`}><span>{ order.status}</span>
                      <span>&nbsp;{getDateForShow(order.date)}</span></div>
                  </StyledModalFullTitle>

              </StyledModalFullArrowLeft>
          }


      {!isShowRatingWindow &&
          <div className={"created_order_window " + (order && order.status_id == EnumOrderStatusID.Executing ? ' order_is_executing' : '') + ( isMinSize ? ' min_size' : '')}
               onTouchMove={onMouseMove} onTouchEnd={onMouseEnd}>
              {order && !isLoading && <OrderAddressList order={order} isNotClickable={false}/>}

              {vehicle && (
                  <div onClick={(e) => { minimizeAddressList(); setShowVehicleWindow(true)}}>
                      <OrderCar
                          isGrey={isFullAddress}
                          number={vehicle.number || ""}
                          model={vehicle.model || ""}
                          length={vehicle.length}
                          width={vehicle.width}
                          height={vehicle.height}
                          bodyName={getBodyName(carBodyOptions, order ? order.body_option_id : '')}
                          category={getCategorySvg(categories, carTypeId)}
                          isNeedBorderBottom={true}
                      />
                  </div>
              )}
              {order && (
                  <div onClick={(e) => { minimizeAddressList();  setShowAmountWindow(true)}}>
                      <OrderAmount
                          isGrey={isFullAddress}
                          amount={order.amount}
                          tariffTypeId={order.tariff_type_id}
                          isFullHeightAmount={isFullAddress}
                          isPayed={order.status === IOrderItemStatusType.isPayd}
                      />
                  </div>
              )}
              {!isFullAddress &&
                  <>
                      {isOrderExecuting() &&
                        <div className="order_is_executing_link" onClick={onBack} > вернуться назад </div>
                      }
                      {isOrderCompleted() &&
                        <StyledButtonBottomBlock style={{top:'0px', paddingTop:'3px', paddingBottom:'7px'}}>
                                  <Button
                                      buttonTypeEvent={ButtonTypeEvent.BUTTON}
                                      buttonBackgroundColor={ButtonBackgroundColor.BLUE}
                                      buttonColor={ButtonColor.WHITE}
                                      buttonHeight={ButtonHeight.MID_HEIGHT}
                                      children="Повторить заказ"
                                      onClick={() => setShowQuestion({title:'Вы дейстивтельно хотите повторить данный заказ ?', action_type:EnumActionType.Repeat})}
                                  />
                                  <StyledLinkBack onClick={onBack}>вернуться назад</StyledLinkBack>
                        </StyledButtonBottomBlock>
                      }
                      {isOrderNotStarted() &&
                          <StyledButtonBottomBlock style={{top:'0px', paddingTop:'3px', paddingBottom:'7px'}}>
                              <StyledButtonBottomBlock2Button>
                                  <Button
                                      buttonTypeEvent={ButtonTypeEvent.BUTTON}
                                      buttonBackgroundColor={ButtonBackgroundColor.RED_GRADIENT}
                                      buttonColor={ButtonColor.WHITE}
                                      buttonHeight={ButtonHeight.MID_HEIGHT}
                                      children="Отменить заказ"
                                      onClick={() => setShowQuestion({title:getTitleRemove(), action_type:EnumActionType.Remove})}
                                  />
                                  <div>&nbsp;&nbsp;</div>
                                  <Button
                                      buttonTypeEvent={ButtonTypeEvent.BUTTON}
                                      buttonBackgroundColor={ButtonBackgroundColor.VIOLET}
                                      buttonColor={ButtonColor.WHITE}
                                      buttonHeight={ButtonHeight.MID_HEIGHT}
                                      children="Изменить заказ"
                                      onClick={() => setShowQuestion({title:getTitleChange(), action_type:EnumActionType.Change})}
                                  />
                              </StyledButtonBottomBlock2Button>
                              <StyledLinkBack onClick={onBack}>вернуться назад</StyledLinkBack>
                          </StyledButtonBottomBlock>
                      }
                 </>
              }
              {isFullAddress &&
                <div className="collapse_address_button" onClick={() => minimizeAddressList()}><ArrowBottomIcon/></div>
              }

          </div>
      }
      {order && isShowRatingWindow && (
        <RaitingWindow   orderId={order.id}  orderStatus={order.status} orderAmount={order.amount}  driverFullName={order.driver.full_name}  order={order}
        />
      )}


       {isLoading  && <AppLoadingForm />}
       {isShowQuestion &&  <QuestionWindow onYes={onOrderAction} onNo={()=>setShowQuestion(undefined)} title={isShowQuestion.title} />}
       {order && order.status_id == EnumOrderStatusID.Executing  && <TimeBtn/>}
     </>
  );
};
