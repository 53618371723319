import './OrderCargoBlock.css'
import {FC, useEffect, useRef, useState} from "react";
import {IOrderFull} from "../Order/Order.types";
import {usePackMutation} from "../../../../api/orderApi";

import {ThreeDots} from "react-loader-spinner";
import BoxIcon from "../../../../assets/svg/box.svg";

const OrderCargoBlock:FC<{order:IOrderFull}> = ({order}) =>{
    const packWnd = useRef<HTMLDivElement>(null);

    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState('');
    const [packInfo, setPackInfo] = useState<CPackInfo|undefined>(undefined);
    const [ratio, setRation] = useState<[number, number]>([0,0]);
    const [ pack ] = usePackMutation();

    const loadData = async () => {
        setLoading(true)

        let info: CPackInfo = await pack({ cargo: order.cargo as any,
            body_option_id: order.body_option_id,
            body_option_characteristics: order.body_option_characteristics.length ?  order.body_option_characteristics  : []
        })
            .unwrap();
        setLoading(false);
        if (!info) return setError('Ошибка загрузки данных');
        setPackInfo(info);
    }



    useEffect(()=> { loadData(); },[]);
    useEffect(()=> {   },[ratio]);
    useEffect(()=> {
        if (packInfo) {
            let rt = 0;
            if (packWnd && packWnd.current) {
                let bWidth = +packWnd.current.getBoundingClientRect().width - 10;
                let bHeight = +packWnd.current.getBoundingClientRect().height - 6;
                if (packInfo.width > packInfo.height)
                    setRation([ bWidth / packInfo.width , bHeight / packInfo.height ])
                else
                    setRation([ bWidth / packInfo.height , bHeight / packInfo.width ])
            }

        }
        console.log('packInfo', packInfo, packWnd)
    },[packInfo]);

    if ((order.cargo.places.length + order.cargo.pallets.length + order.cargo.packages.length) == 0)
        return (<div className="order_cargo_block_empty"/>);
    console.log('ratio',ratio, packWnd && packWnd.current ? packWnd.current.getBoundingClientRect() :'')
    return (
        <>
            {packInfo &&
                <div className="cargo_info">
                    <div><img src={BoxIcon} alt=""/></div>

                    <div className="cargo_info_item">
                        <div className="cargo_info_item_text">Общий объем</div>
                        <div className="cargo_info_item_value">{packInfo ? packInfo.total_volume : ''} м³</div>
                    </div>

                    <div className="cargo_info_item">
                        <div className="cargo_info_item_text">Общая площадь</div>
                        <div className="cargo_info_item_value">{packInfo ? packInfo.total_area : ''} м²</div>
                    </div>
                    <div className="cargo_info_item">
                        <div className="cargo_info_item_text">Общий вес</div>
                        <div className="cargo_info_item_value">{packInfo ? packInfo.total_weight : ''} кг</div>
                    </div>
                </div>
            }
            <div className="order_cargo_block">

            <div className="schema_cargo"  ref={packWnd}   >
                {isError && <div className="schema_cargo__error">{isError}</div>}
                {isLoading && <div className="schema_cargo__loading">Загрузка данных&nbsp;<ThreeDots  color="#888E99"  width={20} height={5} /></div>}
                {packInfo && ratio && packInfo.packed_items.length ? packInfo.packed_items.map((block: PackedItemType, index: number) => {
                    return (
                        // packInfo && packInfo?.width > packInfo?.height ?
                        <div key={'blk'+index} className='cargoSizeBlock' style={{
                            width: parseFloat(block.height.toString()) * ratio[0] + 'px',
                            height: parseFloat(block.width.toString()) * ratio[1] + 'px',
                            left: parseFloat(block.y.toString()) * ratio[0] + 'px',
                            top: parseFloat(block.x.toString()) * ratio[1] + 'px'
                        }}>
                            <div className='sizeBlock'/>
                        </div>
                        // :
                        // <div key={'blk'+index} className='cargoSizeBlock' style={{
                        //     height : parseFloat(block.height.toString()) * ratio + 'px',
                        //     width: parseFloat(block.width.toString()) * ratio + 'px',
                        //     top: parseFloat(block.y.toString()) * ratio + 'px',
                        //     left: parseFloat(block.x.toString()) * ratio + 'px'
                        // }}>
                        //     <div className='sizeBlock'/>
                        // </div>
                    );
                }) : ''}
            </div>
        </div>
        </>
    )
};

export default OrderCargoBlock;

export class CPackInfo{
    car_type_id: string='';
    car_types_id: string[] = [];
    error?: boolean;
    error_description?:  string='';
    height: number = 0;
    load_by_area: number= 0;
    load_by_volume: number= 0;
    load_by_weight: number= 0;
    packed_items: IPackedItems[] = [];
    total_area: number= 0;
    total_volume: number= 0;
    total_weight: number= 0;
    width: number= 0;
}

export interface  IPackedItems {
    width: number,
    height: number,
    x: number,
    y: number,
    item: { name: string },
    bin: number,
}

export type PackedItemType = {
    width: number
    height: number
    x: number
    y: number
    item: {
        name: string
    }
    bin: number
}
