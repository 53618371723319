import { createRef, useEffect, useState } from 'react'
import { useGetArticleByIdQuery } from '../../../../api/orderApi'
import { useAppSelector } from '../../../../app/redux/hooks'
import { IArticleItem } from '../ArticleItem/ArticleItem.types'
import { StyledArticle, StyledArticleContent } from './Article.styled'
import { articleSelector } from './articleSlice'

export const Article = () => {
  const { articleId } = useAppSelector(articleSelector)
  const refArticle = createRef<HTMLDivElement>()
  const refStyle = createRef<HTMLStyleElement>()
  const { data, isFetching } = useGetArticleByIdQuery(articleId)
  const [article, setArticle] = useState<IArticleItem | null>(null)
  useEffect(() => {
    if (!isFetching && data) {
      setArticle(data[0])
    }
  }, [isFetching])

  useEffect(() => {
    setStyle()
    setContent()
  }, [article])
  const getArticlewithoutSymbols = () => {
    let htmlWhithoutSymbols = ''
    if (article && article?.article) {
      htmlWhithoutSymbols = article.article
        .replaceAll('\\"', '"')
        .replaceAll('//"', '"')
        .replaceAll('\\n', '')
    }
    return htmlWhithoutSymbols
  }
  const getHtmlElement = (start: string, end: string) => {
    const htmlWhithoutSymbols = getArticlewithoutSymbols()
    if (!htmlWhithoutSymbols) return
    const htmlSplitByStyle = htmlWhithoutSymbols.split(start)
    return htmlSplitByStyle && htmlSplitByStyle[1]
      ? htmlSplitByStyle[1].split(end)[0]
      : ''
  }

  const setStyle = () => {
    const css = getHtmlElement('<style type="text/css">', '</style>')
    if (!css) return
    if (refStyle && refStyle.current) {
      refStyle.current.innerHTML = css
    }
  }

  const setContent = () => {
    let body = getHtmlElement('<body>', '</body>')
    if (!body && article && article.article) {
      body = article.article
    }
    if (!body) return
    body = updateImageSrcFromFiles(body)

    if (refArticle && refArticle.current) {
      refArticle.current.innerHTML = body
    }
  }

  const updateImageSrcFromFiles = (body: string) => {
    const files = article?.files
    if (!files || !body) return body
    files.map(file => {
      const fileName = file.name
      body = body.replace(
        fileName,
        file.data.indexOf('base64') === -1
          ? `data:image/png;base64,${file.data}`
          : file.data
      )
      return body
    })
    return body
  }

  return (
    <StyledArticle>
      <style ref={refStyle}></style>
      <StyledArticleContent ref={refArticle}></StyledArticleContent>
    </StyledArticle>
  )
}
