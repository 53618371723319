import React, { useState, memo, useCallback } from "react";
import ChevronLeftSVG from "../../../../../assets/svg/chevron_left.svg";
import ChevronRightSVG from "../../../../../assets/svg/chevron_right.svg";
import moment from "moment";
import "react-day-picker/lib/style.css";
import "./DatePickerWindow.css";
import { ReactSVG } from "react-svg";
import DayPicker, {DayModifiers} from "react-day-picker";
import { setSelectedDateFromCalendar, setShowCalendar } from "../NewUserOrderSlicer";
import { useAppDispatch } from "../../../../../app/redux/hooks";

interface IState {
  days: {date:string}[];
}

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];
const WEEKDAYS_LONG = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
];
const WEEKDAYS_SHORT = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

const NavBar = memo(({ onPreviousClick, onNextClick }: any) => {
  return (
    <div className="datepicker_navbar">
      <div className="datepicker_navbar_item" onClick={() => onPreviousClick()}><ReactSVG src={ChevronLeftSVG} /></div>
      <div className="datepicker_navbar_item" onClick={() => onNextClick()}><ReactSVG src={ChevronRightSVG} /></div>
    </div>
  )
})

interface IProps {
  onSave: (value: string) => void
  onCancel: () => void
  currentValue: string;
}

export const DatePickerWindow: React.FunctionComponent<IProps> = ({onSave , onCancel, currentValue  }) => {
  let [cDate, cFrom, cTo] :string[] = currentValue.split('T');
  console.log('currentValue ', currentValue);
  console.log('cDate ', cDate);
  console.log('cFrom ', cFrom);
  console.log('cTo ', cTo);

  const [localDate, setLocalDate] = useState<IState>({ days: [{date: moment(cDate ? new Date(  cDate ) : new Date()).format("YYYY-MM-DD")}] }),
        [fromTime, setFromTime] = useState<string>(moment(cDate ?new Date( cDate + (cFrom? 'T' +cFrom: ''))  : new Date()).format("HH:mm")),
        [toTime, setToTime] = useState<string>(moment( cDate ? new Date( cDate +  (cTo? 'T' +cTo: cFrom ?  'T' +cFrom :''))  : new Date()).format("HH:mm"));

  const dispatch = useAppDispatch()

  const onButtonClick = useCallback(() => {
    let dt = localDate.days[0].date+'T'+fromTime+':00'+'T'+toTime+':00';
    onSave(dt);
    console.log(dt);
    // dispatch(setSelectedDateFromCalendar({date: localDate.days[0].date, fromDate: fromTime, toDate: toTime}))
    // dispatch(setShowCalendar(false))
  }, [localDate, fromTime, toTime])

  const renderDay = useCallback((date: Date, modifiers: DayModifiers) => {
    const days =  localDate.days.findIndex(x => x.date.indexOf(moment(date).format("YYYY-MM-DD")) === 0) !== -1
    return (
      <div className={days ? "datepicker_day datepicker_day_off" : "datepicker_day"}><div>{moment(date).format("D")}</div></div>
    )
  }, [localDate])

  const dayChanging = useCallback((date: Date, modifiers: DayModifiers) =>{
    setLocalDate(({
      days: [{date: moment(date).format("YYYY-MM-DD")}]
    }))
  }, [])

  const onFromTimeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFromTime(e.currentTarget.value)
  }, [])

  const onToTimeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setToTime(e.currentTarget.value)
  }, [])

  return (
    <>
      <div className="datepicker_window_container"
           onClick={(e)=>{onCancel();e.stopPropagation();}}>
          <div className="datepicker_window" onClick={(e)=> e.stopPropagation()}>

            <DayPicker
              // selectedDays={ new Date(localDate.days[0].date)}
              className="datepicker"
              locale="it"
              navbarElement={<NavBar />}
              months={MONTHS}
              weekdaysLong={WEEKDAYS_LONG}
              weekdaysShort={WEEKDAYS_SHORT}
              firstDayOfWeek={1}
              showOutsideDays={false}
              enableOutsideDaysClick={false}
              modifiers={{
              }}
              onDayClick={dayChanging}
              renderDay={renderDay}
            />

            <div className="datepicker_time">
              <div className="datapicker_time_fromto">
                <div>
                  Приехать с
                </div>
                <input type="time" value={fromTime} onChange={onFromTimeChange} />
              </div>
              <div className="datapicker_time_fromto">
                <div>
                  До
                </div>
                <input type="time" value={toTime} onChange={onToTimeChange} />
              </div>
            </div>
            <div className="datepicker_save">
              <button className="saveButton" onClick={onButtonClick} >
                {(localDate.days.length || fromTime || toTime) ? "Сохранить" : "Отменить"}
              </button>
            </div>
          </div>
        </div>
    </>
  )
}
