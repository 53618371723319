import styled from "styled-components";
import { ISideBarStyledProps } from "./SideBar.types";

export const SideBarWrapper = styled.div<ISideBarStyledProps>`
  display: flex;
  flex-direction: column;
  width: 90vw;
  padding-top: 94px;
  height: 100vh;
  background: var(--white);
  transform: translateX(
    ${({ isSideBarOpened }) => (isSideBarOpened ? 0 : "-90vw")}
  );
  transition: all 0.3s;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
`;


export const SideBarField = styled.div`
  padding: 24px;
  border-bottom: 1px solid var(--medium-grey);
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Fira' Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  }
  :last-child {
    border-bottom: none;
  }
`
export const SideBarFieldHeader = styled.div`
  display: flex;
  flex-direction: column;
`

export const SideBarFieldHeaderName = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
`

export const SideBarFieldHeaderTitle = styled.p`
  font-size: 15px;
  line-height: 22px;
  color: var(--grey);
`

export const SideBarFieldText = styled.p`
  font-size: 17px;
  color: var(--black)
`
export const SideBarFieldIcon = styled.div`
  position: absolute;
  right: 24px;
  top: 2;
  height: 20px;
`