import styled from 'styled-components'
import { IStyledOrderListFilerItemProp } from './OrderList.types'

export const StyledOrderListFiler = styled.ul`
  margin: 28px 12px 12px 12px;
  list-style-type: none;
  display: flex;
  text-align: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 500;
  color: var(--grey);
`

export const StyledOrderListFilerItem = styled.li<
  IStyledOrderListFilerItemProp
>`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 90px;
  height: 36px;
  background-color: ${prop => (prop.selected ? 'var(--dark-grey)' : '')};
  color: ${prop => (prop.selected ? 'var(--white)' : 'var(--grey)')};
  border-radius: 8px;
  width:100%;
`
export const StyledOrderListFilteredContent = styled.div`
  width: 100vw;
  height: calc(100vh - 130px);
  background-color: var(--light-grey);
  overflow-y: scroll;
`

export const StyledOrderListDate = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding: 16px 0 6px 0;
  text-align: center;
  color: var(--grey);
`
export const StyledOrderListGrouppedItem = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
`
