import moment from 'moment'
import { useAppSelector } from '../../../../app/redux/hooks'
import { CLockIcon } from '../../../../assets/icons/CLockIcon'
import OrderAddress from '../OrderAddress'
import {
  StyledOrderAddressList,
  StyledOrderAddressListTime
} from './OrderAddressList.styled'
import { IOrderAddressListProps } from './OrderAddressList.types'
import { orderAddressListSelector } from './orderAddressListSlice'
import {orderSelector} from "../Order/orderSlice";
import {IRouteByOrderId} from "../Order/Order.types";

export const OrderAddressList = ({
  order,
  isNotClickable,
  colorPoint,
  onClick
}: IOrderAddressListProps) => {

  const { isFullAddress } = useAppSelector(orderAddressListSelector)
  const { orderRouteWithTime } = useAppSelector(orderSelector)
  if (!order || !order.date) return (<></>);
  //  console.log('order.date',order.date)
  const date =   order.date.split('T')
  const orderDate =
      date && date[0]
          ? moment(date[0])
              .locale('ru')
              .format('DD MMMM')
          : ''
  const timeFrom = date && date[1] ? moment(`${date[0]}.${date[1]}`).format('HH:mm') : '';
  const timeTo =  date && date[1] ? moment(`${date[0]}.${date[2]}`).format('HH:mm') : '';

  const getTime = (index: number) => {

    let route : IRouteByOrderId | undefined =  orderRouteWithTime;
    if (!route || !route.points || route.points.length < index) return '';
    let from = moment(route.points[index].arrival_traffic_time).format('HH:mm');
    let to = moment(route.points[index].departure_traffic_time).format('HH:mm');
    return (index==0 && !isFullAddress ? orderDate : '' ) + ` с ${from} по ${to}`;
  };

  return (
    <>
      {isFullAddress && (
        <StyledOrderAddressListTime >
          <CLockIcon />
          <span>{`${orderDate} c ${timeFrom} до ${timeTo}`}</span>
        </StyledOrderAddressListTime>
      )}
      <StyledOrderAddressList isFullAddress={isFullAddress} style={{background: (isNotClickable ? 'var(--light-grey)' :'transparent')}} onClick={()=>{if (onClick) onClick()}}>
        {order?.routes.map(({ id, adress }, index) => (
          <OrderAddress
            key={id}
            address={adress}
            time={getTime(index)}
            isNeedArrow={index < order?.routes.length - 1}
            isNeedPoint={index === 0}
            color={index !== 0 ? 'var(--blue)' : colorPoint || ''}
            isNotClickable={isNotClickable}
          />
        ))}
      </StyledOrderAddressList>
    </>
  )
}

export default OrderAddressList;
