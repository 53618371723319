import styled from 'styled-components'

const StyledSvg = styled.svg`
  cursor: pointer;
`
interface IClearIconProps {
  onClick: () => void;
}

export const ClearIcon = ({ onClick }: IClearIconProps) => {
  return (
    <StyledSvg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <line
        x1='5.05029'
        y1='14.9497'
        x2='14.9498'
        y2='5.05024'
        stroke='#C6CAD2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.9497 14.9497L5.05021 5.05021'
        stroke='#C6CAD2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </StyledSvg>
  )
}
