import React, {FC, useEffect, useState} from "react";
import s from './VehicleBlockAuto.module.scss'
import {useAppDispatch, useAppSelector} from "../../../../../app/redux/hooks";
import {SendOrderInfo} from "../CreateOrderType";
import {currentOrderData, getPackInfo, setOrderData} from "../CreateOrderSlicer";
import {VehicleGrayButton} from "../VehicleGrayButton/VehicleGrayButton";
import {
    BodyOptionCharacteristicsResponse,
    BodyOptionsResponse, CarTypeTreeQueryResponse,
    CharacteristicTypeEnum, usePackMutation
} from "../../../../../api/orderApi";
import {AutoDropDownGreyList} from "../AutoDropDownGreyList/AutoDropDownGreyList";
import {
    filterCategory, getActiveCharacteristics,
    getBOCharacteristics,
    getBodyOptionsList,
    getCharacteristicValueList, newUserOrderSelector
} from "../NewUserOrderSlicer";
import {SelectedCategoryInfo} from "./SelectedCategoryInfo";
interface IProps {
    onChange:()=>void,
    isTab?:boolean,
    isShown?:boolean,
    onBack?:()=>void,
}
export const VehicleBlockAuto :FC<IProps> =({onChange,isTab,isShown,onBack}) =>{
    const dispatch = useAppDispatch();
    const currentOrder : SendOrderInfo = useAppSelector(currentOrderData);
    const [ pack ] = usePackMutation();
    const packInfo = useAppSelector(getPackInfo);

    const { carTypesTree  } = useAppSelector(newUserOrderSelector);

    let  categories : CarTypeTreeQueryResponse[]  = carTypesTree ? carTypesTree : [];

    let selectedCategoryID  =  currentOrder.car_type_id;


    let selectedBodyID  = currentOrder.body_option_id ;
    const setSelectedBodyID = (value:string) =>  dispatch( setOrderData({...currentOrder, body_option_id: value}) );

    let [categoryList, setCategoryList] = useState<CarTypeTreeQueryResponse[]>([]);

    let  isMaxDimensions = currentOrder.max_dimensions;
    const setMaxDimensions = (value:boolean) =>  dispatch( setOrderData({...currentOrder, max_dimensions: value}) );


    let  activeCharacteristics  = getActiveCharacteristics(currentOrder.body_option_characteristics, categories);

    const setActiveCharacteristics = (value: BodyOptionCharacteristicsResponse[]) => {
        if (value.length)
            dispatch( setOrderData({...currentOrder, body_option_characteristics: value.map(x=> ({id:x.id, value:x.value}))}));
        else
            dispatch( setOrderData({...currentOrder, body_option_characteristics: []}));
    }

    let [bodyOptions, setBodyOptions] = useState<BodyOptionsResponse[]>([]);
    let [characteristics, setCharacteristics] = useState<BodyOptionCharacteristicsResponse[]>([]);

    const onBodySelect = (bo: BodyOptionsResponse) => {
        setSelectedBodyID(bo.id === selectedBodyID ? '' : bo.id);
    };


    const onCharacteristicSelect = (ch: BodyOptionCharacteristicsResponse, value : boolean|string) => {
        characteristics.filter(x=> x.id === ch.id).forEach(x=> {
            if (ch.type === CharacteristicTypeEnum.Boolean && typeof value === "boolean") {
                x.selected = value;
            }
            if (ch.type === CharacteristicTypeEnum.Number && typeof value === "string") {
                x.selected = +value > 0;
            }

            if (ch.type === CharacteristicTypeEnum.Ref && typeof value === "string") {
                x.selected = value !== '';
            }
            ch.value = value;
        });
        console.log(characteristics)
        setActiveCharacteristics(characteristics.filter(x=>x.selected ));
        setCharacteristics([...characteristics]);
    };

    useEffect(()=> {

        let cats = categories.filter( x=> packInfo.car_types_id.some(z=>z == x.id));

        categoryList = filterCategory( cats , "", selectedBodyID, activeCharacteristics);
        console.log(
            'categories ', categories,
            'cats ', cats,
            'categoryList ', categoryList,
            'activeCharacteristics', activeCharacteristics
        );
        setCategoryList(categoryList);
        if (categoryList.length) {

            setBodyOptions(getBodyOptionsList(categoryList, selectedCategoryID));
            let chs = getBOCharacteristics(categoryList, selectedCategoryID, selectedBodyID);
            chs.forEach(x=> {
                let ch = activeCharacteristics.find(a=> a.id === x.id);
                if (!ch)  return;
                x.value = ch.value;
                x.selected = ch.selected;
            });
            setCharacteristics(chs.sort( (a,b)=> {
                if (a.name > b.name ) return  -1;
                return 1;
            }).sort( (a,b)=> {
                if (a.type > b.type ) return  1;
                return -1;
            }));
        }
        onChange()

    },[ currentOrder.car_type_id, selectedBodyID, currentOrder.body_option_characteristics, isMaxDimensions]);

    return (
        <div className={ (isTab ? s.VehicleBlockAutoTab : '') + ' ' + (isShown ? s.active : '') + ' ' + s.VehicleBlockAuto + " vehicle_manual_block "} >
            <SelectedCategoryInfo onCargoClick={isTab ? onBack : undefined} />
            <div className={s.body+' ff'}>
                { bodyOptions.map( opt=>  <VehicleGrayButton  bgColor={isTab ? 'white' : ''} key={opt.id} text={opt.name} isSelected={opt.id===selectedBodyID}  onClick={() => onBodySelect(opt)} />)}
            </div>
            <div className={s.bodyType}>
                {characteristics.filter(x=>    x.type === CharacteristicTypeEnum.Boolean )
                    .map(char => <VehicleGrayButton
                        bgColor={isTab ? 'white' : ''}
                        key={char.id}
                        text={char.name}
                        isSelected={char.selected}
                        onClick={() => {onCharacteristicSelect(char, !char.selected)}}
                    />)}
            </div>
            <div className={s.bodyConditions + (characteristics.filter(x=>    x.type !== CharacteristicTypeEnum.Boolean ).length == 0 ? s.bodyHidden :'')}>
                {characteristics.filter(x=>    x.type !== CharacteristicTypeEnum.Boolean )
                    .map(x =>  {
                            if (x.type === CharacteristicTypeEnum.Ref)
                                return  <AutoDropDownGreyList  type={isTab ?"white" : "gray"} list={getCharacteristicValueList(categoryList, x, selectedBodyID, selectedCategoryID)}
                                                               key={"ch_"+x.id} title={x.name} isSelected={x.selected}
                                                               value={x.value}
                                                               onSelect={(item) => onCharacteristicSelect(x, item.id)} />;
                            if (x.type === "number")
                                return <AutoDropDownGreyList  type={isTab ?"white" : "gray"} list={ [{id:'', text :'Любая'},{id:'1', text :'до 1 м.'},{id:'2', text :'до 2 м.'},{id:'3', text :'до 3 м.'},{id:'4', text :'до 4 м.'}]}
                                                              value={x.value}
                                                              key={"ch_"+x.id} title={x.name} isSelected={x.selected}
                                                              onSelect={(item) => onCharacteristicSelect(x, item.id)} />;
                        }

                    )}
            </div>
            <div className={s.bodySize}>
                <div className={isMaxDimensions ? s.selectedMaxSize : (isTab ? s.bodySizeWhite : '')} onClick={()=> setMaxDimensions(!isMaxDimensions)}>
                    Максимальный размер кузова
                </div>
            </div>
        </div>
    )
}
