
import React, { FC, useState } from 'react'
import {useClientsProfileMutation, usePostOrderMutation, useAuthMutation} from '../../../../../../api/orderApi'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/hooks'

import {setUserProfile } from "../../../UserProfile/userProfileSlice";

import s from './OrderCompletion.module.scss'
import {currentOrderData, getOrderReducer, getTariffLoading, setClearData} from "../../CreateOrderSlicer";
import {showErrorMessage} from "../../../../../../components/InfoMessage/infoMessageSlice";
import {ThreeDots} from "react-loader-spinner";
import {EnumPaymentType, SendOrderInfo} from "../../CreateOrderType";
import {authorizationSelector, setIsAuth, setNonAuth} from "../../../../../Authorization/authorizationSlice";
import {setClientId, setFullName, userSelector} from "../../../../../../app/redux/reducers/user";
import {useCookies} from "react-cookie";
import {cookiesClientId, cookiesOrderId} from "../../../../../../api/api.constants";
import {CCompany, CUser, CUserProfile} from "../../../UserProfile/userProfile.type";

interface IProps {
    onClose:()=>void
    onCreateOrder:()=>void
}
export const CreateOrder: FC<IProps> =  ({onClose,onCreateOrder}) => {

    const [ postOrder  ] = usePostOrderMutation();
    const [ postProfile ] = useClientsProfileMutation();
    const [ postAuth ] = useAuthMutation();

    const dispatch = useAppDispatch();
    const currentOrder : SendOrderInfo= useAppSelector(currentOrderData);
    const isTariffLoading= useAppSelector(getTariffLoading);
    const   [errorVisible, setErrorVisible] = useState<boolean>(false);
    const   [isLoading, setLoading] = useState<boolean>(false);
    const { isAuth } = useAppSelector(authorizationSelector)
    const { clientId } = useAppSelector(userSelector);
    const [cookies, setCookie] = useCookies([cookiesOrderId]);
    const [_, setCookieClientId] = useCookies([cookiesClientId])
    const orderReducer  = useAppSelector(getOrderReducer);

    const OnOrderClick = async () => {

        if (isTariffLoading || isLoading  ) {
            return;
        }
        if (!getOrderStatus().value) {
            setErrorVisible(true);
            return;
        }
        setLoading(true);
        let data : SendOrderInfo = {...currentOrder};
        data.draft = false;
        if (isAuth && clientId)
            data.client_id = clientId;
        else {
            let profile = new CUserProfile();
            profile.users.push({...new CUser(currentOrder.contacts.full_name, currentOrder.contacts.phone, currentOrder.contacts.email )});
            if (currentOrder.TIN)
                profile.companies.push({...new CCompany(currentOrder.TIN, currentOrder.company_name)});

            let res =  await postProfile({...profile}).unwrap();
            console.log('response postProfile',res)
            if (!res)  { setLoading(false); return showErrorMessage('Ошибка регистрации аккаунта ', dispatch);}
            if (res.status == 'error')  { setLoading(false); return showErrorMessage(res.error_message, dispatch);}
            dispatch(setUserProfile(res));

            // let res =  await postAuth(profile).unwrap();
            // dispatch(setUserProfile(res));
            const { client_id  } = res;
            dispatch(setClientId(client_id ? client_id : ''));
            dispatch(setFullName(currentOrder.contacts.full_name));

            setCookieClientId(cookiesClientId, client_id, { path: '/' });

            dispatch( client_id ? setIsAuth() : setNonAuth());
            data.client_id = client_id ? client_id : data.client_id;
        }

        let x:any = await postOrder(data);
        setLoading(false);
        if (!x || x.status == 'error')  return showErrorMessage(x.error_message? x.error_message : 'Ошибка создания заказа ', dispatch);
        setCookie(cookiesOrderId, '', { path: '/' });
        onCreateOrder();
    };

    const getOrderStatus = () : {value:boolean, error_message: string} => {
        if (currentOrder.car_type_id == '') return {value:false, error_message:"Для продолжения необходимо выбрать категорию ТС"};
        if (currentOrder.body_option_id == '') return {value:false, error_message:"Для продолжения необходимо выбрать тип кузова"};
        if (currentOrder.payment_type == EnumPaymentType.PaymentOnAccount && currentOrder.TIN == "") return {value:false, error_message:"Для продолжения необходимо заполнить ИНН компании"};
        if (currentOrder.payment_type == EnumPaymentType.PaymentOnline) return {value:false, error_message:"Данный вид оплаты временно недоступен"};
        if (!orderReducer.is_agreement) return {value:false, error_message:"Для продолжения необходимо принять пользовательское соглашение и политику конфиденциальности"};
        if (currentOrder.contacts.full_name == "" ) return {value:false, error_message:"Для продолжения необходимо указать ФИО"};
        if (currentOrder.contacts.phone == "" || !orderReducer.is_phone_checked) return {value:false, error_message:"Для продолжения необходимо подтвердить номер телефона"};

        return {value:true, error_message:''};
    }

    return (
        <div className={s.createOrder}>
            {!getOrderStatus().value && errorVisible && <div className={s.errorMessage}>{getOrderStatus().error_message}</div>}
            <button onClick={()=>OnOrderClick()} className={!getOrderStatus().value || isTariffLoading || isLoading? s.disabledOrderButton : ''}>
                {isLoading ? <div className={s.creatingOrder}>
                                 <div style={{marginRight:10}}>Создание заказа</div><ThreeDots  color={  "#999" } width={40} height={40}  />
                            </div>
                    : 'Заказать'}
            </button>
            <div className={s.linkWrapper} onClick={()=>onClose()}>вернуться назад</div>
        </div>
    )
}
