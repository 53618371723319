import styled from 'styled-components'
import { IStyledOrderAddressProps } from './OrderAddress.types'

export const StyledOrderAddress = styled.div<IStyledOrderAddressProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 14px;
  width: ${props => (props.isFullAddress ? '100%' : 'auto')};
  background-color: ${props =>
    props.isFullAddress ? 'var(--light-grey)' : ''};
  border-radius: ${props => (props.isFullAddress ? '8px' : '')};
  padding: ${props => (props.isFullAddress ? '12px 14px' : '0')};
  font-size: 15px;
  font-weight: 400;
  
`

export const StyledOrderAddressBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`
export const StyledOrderAddressName = styled.span<IStyledOrderAddressProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${props => (props.isFullAddress ? 'calc(100vw - 20%)' : '110px')};
  color: ${props => props.color || ''};
`

export const StyledOrderAddressTime = styled.span`
  color: var(--grey);
  font-size: 15px;
  font-weight: 400;
`
