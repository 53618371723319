import moment from "moment";
import { createRef, useEffect, useState } from "react";
import {
  useGetOrderListMutation,
  IErorrResult
} from "../../../../api/orderApi";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { userSelector } from "../../../../app/redux/reducers/user";
import { groupBy } from "../../../../helper";
import OrderItem from "../OrderItem/OrderItem";
import { IOrder } from "../OrderItem/OrderItem.types";
import {
  StyledOrderListDate,
  StyledOrderListFiler,
  StyledOrderListFilerItem,
  StyledOrderListFilteredContent,
  StyledOrderListGrouppedItem,
} from "./OrderList.styled";
import { IOrderListFilterType } from "./OrderList.types";
import { orderListSelector, setCategories, setFilter } from "./orderListSlice";
import { openInfoMessage } from "../../../../components/InfoMessage/infoMessageSlice";
import { InfoMessageType } from "../../../../components/InfoMessage/InfoMessage.types";
import { useGetCarTypesTreeMutation } from "../../../../api/orderApi";
import {
  openLoader,
  closeLoader,
} from "../../../../components/Loader/loaderSlice";
import {DEBUG_ORDER_ID, IS_DEBUG_MODE} from "../../../../api/api.constants";

export const OrderList = () => {
  const { clientId } = useAppSelector(userSelector);
  const [getOrderList] = useGetOrderListMutation();

  const countOffset = 10;
  const [count, setCount] = useState(countOffset);
  const [orderList, setOrderList] = useState<Record<string, IOrder[]>>();
  const { filter } = useAppSelector(orderListSelector);
  const [getCarTypesTree] = useGetCarTypesTreeMutation();

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCarTypesTree(null).unwrap().then((res:any)=> {
      if (res && res?.length)
        dispatch(setCategories(res));
    });
  }, []);

  useEffect(() => {
    loadOrderList(filter);
  }, []);

  const getParams = (type: IOrderListFilterType | undefined) => {
    switch (type) {
      case IOrderListFilterType.now:
      default:
        return {
          clientId: clientId || "",
          startFrom: today.toDateString(),
          startTo: tomorrow.toDateString(),
          count: count,
        };
      case IOrderListFilterType.past:
        return {
          clientId: clientId || "",
          startTo: today.toDateString(),
          count: count,
        };
      case IOrderListFilterType.future:
        return {
          clientId: clientId || "",
          startFrom: tomorrow.toDateString(),
          count: count,
        };
    }
  };
  const loadOrderList = (   type: IOrderListFilterType | undefined, isScroll?: boolean ) => {
    if (clientId) {
      if (isScroll) {
        setCount(count + countOffset);
      } else {
        setCount(countOffset);
      }
      dispatch(openLoader());
      const params = getParams(type);

      getOrderList(params)
        .unwrap()
        .then((res) => {
          dispatch(closeLoader());
          const errorResult = res as IErorrResult;
          let orderList = res as IOrder[];

          if (errorResult && errorResult.status) {
            openErrorMessage(errorResult.errormessage);
          } else {

            // Если отладочный режим то добавляем выполняемы заказ
            if (IS_DEBUG_MODE) {
              orderList = [...orderList, {
                "id": DEBUG_ORDER_ID,
                "date_from": moment().format("yyyy-MM-DD") + "T20:00:00",
                "date_to": moment().format("yyyy-MM-DD") + "T23:00:00",
                "car_type_id": "52b30be4-49d5-11e7-9696-e41f13c2b942",
                "car_body_option_id": "c50b92ab-52d3-11ea-a9c9-00155d8e4e03",
                "status": "Выполняется",
                "amount": 16821,
                "adress": [
                  "г Москва, Спартаковский пер, д 2 стр 1",
                  "г Москва, Филёвский б-р, д 10"
                ]
              }];
            }
            const prepareOrderList = getSortedGrouppedOrderList(orderList);
            filterOrderList(type, prepareOrderList, isScroll);
          }

        });
    }
  };

  const getSortedGrouppedOrderList = (list: IOrder[]) => {
    const array: IOrder[] = JSON.parse(JSON.stringify(list));
    return groupBy(array, (i) => i.date_from.split("T")[0]);
  };

  const filterOrderList = (
    type: IOrderListFilterType | undefined,
    prepareOrderList: Record<string, IOrder[]>,
    isScroll?: boolean
  ) => {
    const dateNow = new Date().setHours(0, 0, 0, 0);
    let filteredOrderList: Record<string, IOrder[]> = {};
    let orderListKeys: string[] = [];

    orderListKeys = Object.keys(prepareOrderList).filter((value, index) => {
      const formatValue = new Date(value).setHours(0, 0, 0, 0);

      switch (type || filter) {
        case IOrderListFilterType.now:
          return formatValue === dateNow;
        case IOrderListFilterType.future:
          return formatValue > dateNow;
        case IOrderListFilterType.past:
          return formatValue < dateNow;
      }
    });
    orderListKeys.map((key) => {
      filteredOrderList[key] = prepareOrderList[key];
    });

    const newOrderList = isScroll
      ? { ...orderList, ...filteredOrderList }
      : filteredOrderList;
    setOrderList(newOrderList);
  };

  const onClickFilter = (type: IOrderListFilterType) => {

    setOrderList({});
    dispatch(setFilter(type));
    loadOrderList(type);
  };

  const scrollingDivRef = createRef<HTMLDivElement>();

  const onScrollContent = () => {
    const elem = scrollingDivRef.current;
    const scrollHeight = (elem && elem.scrollHeight) || 0;
    const scrollTop = (elem && elem.scrollTop) || 0;
    const clientHeight = (elem && elem.clientHeight) || 0;
    if (
      Math.round(scrollHeight - scrollTop) === clientHeight ||
      Math.round(scrollHeight - scrollTop) === clientHeight + 1
    ) {
      dispatch(openLoader());
      loadOrderList(filter, true);
    }
  };

  const openErrorMessage = (text = "Перепроверьте введенные данные") => {
    dispatch(
      openInfoMessage({
        title: "Ошибка",
        text: text,
        type: InfoMessageType.FAILIED,
      })
    );
  };

  return (
    <>
      <StyledOrderListFiler>
        {Object.values(IOrderListFilterType).map((item, index) => (
          <StyledOrderListFilerItem
            key={index}
            onClick={(e) => onClickFilter(item)}
            selected={filter === item}
          >
            {item}
          </StyledOrderListFilerItem>
        ))}
      </StyledOrderListFiler>
      <StyledOrderListFilteredContent
        onScroll={(e) => onScrollContent()}
        ref={scrollingDivRef}
      >
        {orderList &&
          Object.entries(orderList).map(([key, value]) => (
            <StyledOrderListGrouppedItem key={key}>
              {filter !== IOrderListFilterType.now && (
                <StyledOrderListDate>
                  {moment(key).format("DD.MM.yyyy")}
                </StyledOrderListDate>
              )}
              {filter === IOrderListFilterType.now && <div></div>}
              {value.map(
                ({
                  id,
                  adress,
                  amount,
                  car_body_option_id,
                  car_type_id,
                  date_from,
                  date_to,
                  status,
                }) => (
                  <OrderItem
                    key={id}
                    id={id}
                    timeFrom={date_from}
                    timeTo={date_to}
                    from={adress && adress.length ? adress[0] : ""}
                    to={adress && adress.length ? adress[1] : ""}
                    amount={amount}
                    status={status}
                    carTypeId={car_type_id}
                    filterStatus={filter}
                  />
                )
              )}
            </StyledOrderListGrouppedItem>
          ))}
        {!orderList && (
          <StyledOrderListDate>Список заказов пуст</StyledOrderListDate>
        )}
      </StyledOrderListFilteredContent>
    </>
  );
};
