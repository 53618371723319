import React, {FC, memo, useEffect, useState} from 'react'
import s from './OrderCompletion.module.scss'

import { CategoryDetails } from './CategoryDetails'
import { AdditionalRequirements } from './AdditionalRequirements'

import { UserRegistration } from './UserRegistration'
import { PaymentDetailsButton } from './PaymentDetailsButton'
import { CreateOrder } from './CreateOrder'
import { CloseOrder } from '../CloseOrder/CloseOrder'
import { PaymentMethod } from './PaymentMethod'
import {NewOrderHeader} from "../NewOrderHeader";
import {useAppDispatch, useAppSelector} from "../../../../../../app/redux/hooks";
import {
    currentOrderData,
    getOrderMode,
    getShowMapMode,
    setTariffsData,
    wait_before_update
} from "../../CreateOrderSlicer";

import AddressBlock from "../../AddressBlock/AddressBlock";
import HeaderWithAddresses from "../HeaderWithAddresses/HeaderWithAddresses";
import {EnumOrderMode, EnumShowMapMode, IMapShowMode} from "../../CreateOrderType";
import VehicleBlockManual from "../../VehicleBlockManual/VehicleBlockManual";
import {Tariffs} from "./Tariffs";
import {useCalcOrderRequestAllMutation} from "../../../../../../api/orderApi";
import {newUserOrderSelector} from "../../NewUserOrderSlicer";
import {VehicleBlockAuto} from "../../VehicleBlockAuto/VehicleBlockAuto";


interface IProps {
    onBack:()=>void

    onOrderComplete:()=>void
    onCancelOrder: ()=> void
}

const OrderCompletion: FC<IProps> = ({onBack,  onOrderComplete, onCancelOrder  }) => {

    const dispatch = useAppDispatch();
    const { tariffTypes } = useAppSelector(newUserOrderSelector);


    const [isPaymentDataSet, setPaymentData ] = useState<boolean>(false);
    const [isAddressExpanded, setAddressExpanded] = useState<boolean>(false);
    const [isVehicleExpanded, setVehicleExpanded] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const [  calcOrderRequestAll  ] = useCalcOrderRequestAllMutation();

    const currentOrder = useAppSelector(currentOrderData);
    const order_mode = useAppSelector(getOrderMode);
    const map_mode : IMapShowMode = useAppSelector(getShowMapMode);

    const onOrderChanged = async () => {
            setLoading(true);
            let res = await wait_before_update(3000);

            if (res) {
                calcOrderRequestAll({...currentOrder})
                    .unwrap()
                    .then(x => {
                        setLoading(false);
                        dispatch(setTariffsData({data: x, names: tariffTypes}))
                    })
            }
    };

    return (
        <div className={s.orderCompletionWrapper + (map_mode.mode != EnumShowMapMode.None ? s.orderCompletionHidden : '')}>
            <NewOrderHeader date={currentOrder.date}  />
            <HeaderWithAddresses type={false} not_is_rounded={true} onClick={()=>{setAddressExpanded(!isAddressExpanded); setVehicleExpanded(false)}}  />
            <AddressBlock isTab={true} isShown={isAddressExpanded}/>

            <CloseOrder onClick={()=>onCancelOrder()}  />
            <CategoryDetails  onClick={()=>{console.log('CategoryDetails onClick', isVehicleExpanded); setVehicleExpanded(!isVehicleExpanded); setAddressExpanded(false)}}  />
            {order_mode == EnumOrderMode.Manual && <VehicleBlockManual isTab={true} isShown={isVehicleExpanded} onChange={onOrderChanged} />}
            {order_mode == EnumOrderMode.Auto && <VehicleBlockAuto isTab={true} isShown={isVehicleExpanded} onChange={onOrderChanged} onBack={onBack} />}
            <AdditionalRequirements  onChange={onOrderChanged}/>
            <Tariffs isLoading={isLoading}/>
            <PaymentDetailsButton  />
            <UserRegistration />
            <PaymentMethod onPaymentEntered={(value :boolean )=>setPaymentData(value)} />
            <CreateOrder  onClose={()=>onBack()} onCreateOrder={()=>onOrderComplete()} />

        </div>
    )
}
export default OrderCompletion;
