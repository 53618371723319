import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import { ArrowRightIcon } from '../../../../assets/icons/ArrowRightIcon'
import {
  orderAddressListSelector,
  setIsFull
} from '../OrderAddressList/orderAddressListSlice'
import {
  StyledOrderAddress,
  StyledOrderAddressBlock,
  StyledOrderAddressName,
  StyledOrderAddressTime
} from './OrderAddress.styled'
import { IOrderAddressProps } from './OrderAddress.types'
import { Point } from '../../../../assets/icons/Point'

export const OrderAddress = ({
  address,
  time,
  isNeedArrow,
  isNeedPoint,
  color,
  isNotClickable
}: IOrderAddressProps) => {
  const dispatch = useAppDispatch()
  const { isFullAddress } = useAppSelector(orderAddressListSelector)
  const setIsFullAddress = () => {
    if (!isFullAddress) {
      dispatch(setIsFull(true))
    }
  }
  return (
    <>
      <StyledOrderAddress
        onClick={() => {
          !isNotClickable && setIsFullAddress()
        }}
        isFullAddress={isFullAddress}
      >
        {(isFullAddress || isNeedPoint) && (
          <div><Point width='20' height='20' color={color} /></div>
        )}
        <StyledOrderAddressBlock>
          <StyledOrderAddressName isFullAddress={isFullAddress} color={isNotClickable?'var(--grey)':''}>{address}</StyledOrderAddressName>
          <StyledOrderAddressTime>{time}</StyledOrderAddressTime>
        </StyledOrderAddressBlock>
        {!isFullAddress && isNeedArrow && <ArrowRightIcon />}
      </StyledOrderAddress>
    </>
  )
}
