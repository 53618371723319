import styled from 'styled-components'
import { IStyledOrderAddressListProps } from './OrderAddressList.types'

export const StyledOrderAddressList = styled.div<IStyledOrderAddressListProps>`
  display: flex;
  flex-direction: ${props => (props.isFullAddress ? 'column' : 'row')};
  grid-gap: ${props => (props.isFullAddress ? '6px' : ' 14px')};
  padding: ${props => (props.isFullAddress ? '6px 6px' : '18px 0 18px 20px')};
  width: 100vw;
  white-space: nowrap;
  overflow-y: scroll; 
  border-bottom: 1px solid var(--medium-grey);
  align-items: center;
  position: relative;
  ${props => (props.isFullAddress ? 'min-height: 26vh;' : 'max-height: 26vh;')}; 
   border-radius: 10px 10px 0 0;
  
`

export const StyledOrderAddressListTime = styled.div`
  display: flex;
  flex-direction: row;
  padding: 21px 20px 15px 20px;
  grid-gap: 23px;
  align-items: center;
  color: var(--grey);
`
