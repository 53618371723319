import {createSlice} from "@reduxjs/toolkit";
import {
    CPackInfo,
    CreateOrderState,
    EnumAddressMode,
    EnumOrderMode, EnumShowMapMode, IMapShowMode, TypeLonLat, CTariffData,

    RouteAddress,
    SendOrderInfo, CContactInformation, EnumPaymentType, CargoInfo
} from "./CreateOrderType";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";
import {RootState} from "../../../../app/redux/store";
import {mapMarker} from "../../../../components/HereMap/hereMapTypes";
import {IOrderFull, IRoute} from "../../../Orders/components/Order/Order.types";


export const getStreetValue = (dadata: any) =>{
    if (!dadata || !dadata.data) return '';
    let val = dadata.data.country;
    if (dadata.data.city) val +=', ' + dadata.data.city_with_type;
    else if (dadata.data.settlement) val +=', ' + dadata.data.settlement;
    if (dadata.data.street) val +=', ' +dadata.data.street_with_type;
    return val;
}
export const currentOrderData = (state: RootState) => state.createOrder.order_mode == EnumOrderMode.Manual ?  state.createOrder.order_manual :  state.createOrder.order_auto;
export const getShowMapMode = (state: RootState) => state.createOrder.map_mode;
export const getTariffs = (state: RootState) => state.createOrder.tariffs;
export const getOrderMode = (state: RootState) => state.createOrder.order_mode;
export const getPackInfo = (state: RootState) => state.createOrder.pack_info;
export const getTariffLoading = (state: RootState) => state.createOrder.is_tariff_loading;
export const getOrderContacts = (state: RootState) => state.createOrder.order_manual.contacts;
export const getOrderIsChanged = (state: RootState) => state.createOrder.is_changed;
export const getOrderReducer = (state: RootState) => state.createOrder;



export const getRoutePointAsMarkers = ( order: any) => {
    return order.routes.length ? order.routes.map( (x:RouteAddress | IRoute) => {
        let m = new mapMarker();
        m.index = x.id;
        m.lat = x.adress_latitude;
        m.lon = x.adress_longitude;
        m.type = 1;
        m.is_show_text = true;
        m.text = x.adress ;
        m.title = x.id == 0 ?  'Откуда' : 'Куда';
        m.bg_color = x.id == 0 ? 'orange' : '#5364ff';
        return m;
    }) : []
};

export const getCost  = (tariffs: CTariffData[], tariff_id='') => {
    if (!tariffs.length) return 0;
    let t = tariff_id == '' ? tariffs.find( x=> x.cost > 0) : tariffs.find( x=> x.tariff_type_id == tariff_id);
    return t ? t.cost : 0;
};

var dl = 0;
var stack : number[] = [];
var counter = 0;
export function wait_before_update(n:number){
    // if (dl > 0) {
    //     dl = n;
    //     return new Promise(resolve => resolve(false));
    // }
    let my_counter = counter++;
    dl = n;
    stack.push(my_counter);
    return new Promise( resolve => {
        let m = setInterval(()=>{
            // console.log('getTariffInfo  stack is', stack.length, 'my_counter', my_counter, stack);
            if (stack.length > 1 && stack[0] == my_counter) {
                stack.shift();
                clearInterval(m);
                resolve(false);
                return;
            }

            dl-=100;
            if (dl <=0 ) {
                dl = 0;
                clearInterval(m);
                stack = [];
                resolve (true)
            }
        }, 100);
    })
}

export const getFullTariffArray = (data:CTariffData[], names:{id:string, name:string}[]) =>{
    let tariffs :CTariffData[] = [];

    for (let i=0; i< data.length; i++){
        let newTariff = new CTariffData();
        let tar =  data[i];
        for (var key in tar) {
            //@ts-ignore
            newTariff[key] = tar[key];
        }

        let t = names.find(z => z.id == tar.tariff_type_id);
        if (t) newTariff.tariff_name = t.name;
        tariffs.push(newTariff)
    }
    return tariffs
}

let getInitState = () : CreateOrderState=> ({
    order_manual: {...new SendOrderInfo()},
    order_auto: {...new SendOrderInfo()},
    order_mode: EnumOrderMode.None,
    tariffs: [],
    selected_address_index: 0,
    address_mode: EnumAddressMode.None,
    map_mode: {mode: EnumShowMapMode.None, coordinate:{lon:0, lat:0}},
    pack_info: {...new CPackInfo()},
    is_tariff_loading: false,
    is_changed: false,
    is_agreement: false,
    is_phone_checked: false,
});
const initialState : CreateOrderState = getInitState();

export const createOrderSlicer = createSlice({
    name: 'createOrderSlicer',
    initialState,
    reducers: {
        setOrderMode(state, actions:PayloadAction<EnumOrderMode>) {
            state.order_mode = actions.payload
            return state
        },
        setOrderData(state, actions:PayloadAction<SendOrderInfo>) {
            if (state.order_mode === EnumOrderMode.Auto)
                state.order_auto = {... actions.payload};
            if (state.order_mode === EnumOrderMode.Manual)
                state.order_manual = {... actions.payload};
            state.is_changed = true;
            return state;
        },
        setOrderDataAuto(state, actions:PayloadAction<SendOrderInfo>) {
            state.order_auto = {... actions.payload};
            state.order_manual = {... actions.payload};
            state.order_manual.cargo = {...new CargoInfo()};
            return state;
        },
        setOrderDataManual(state, actions:PayloadAction<SendOrderInfo>) {
            state.order_auto = {... actions.payload};
            state.order_manual = {... actions.payload};
            return state;
        },
        setOrderRoutes(state, actions:PayloadAction<RouteAddress[]>) {
            state.order_auto.routes = [...actions.payload];
            state.order_manual.routes = [...actions.payload];
            state.is_changed = true;
            return state
        },
        setSelectedAddress(state, actions:PayloadAction<number>) {
            state.selected_address_index = actions.payload;
            state.selected_address_index = actions.payload;
            return state
        },
        setTariffsData(state, actions:PayloadAction<{data:CTariffData[], names:{id:string, name:string}[]} | null>) {
            if (actions.payload ) {

                state.tariffs = getFullTariffArray(actions.payload.data, actions.payload.names );
            } else
                state.tariffs = [];
            return state
        },
        setAddressMode(state, actions:PayloadAction<EnumAddressMode>) {
            state.address_mode = actions.payload;
        },
        setShowMapMode(state, actions:PayloadAction<IMapShowMode>) {
            console.log('setShowMapMode', actions.payload);
            state.map_mode = {mode: actions.payload.mode, coordinate : actions.payload.coordinate ? actions.payload.coordinate : {lon:0,lat:0}};

            return  state
        },
        setOrderDate(state, actions:PayloadAction<string>) {
            state.order_auto.date = actions.payload ;
            state.order_manual.date = actions.payload ;
            state.is_changed = true;
            return state
        },
        setOrderAdditional(state, actions:PayloadAction<{id:string, value:boolean|string|number}[]>) {
            state.order_auto.additional_requirements = actions.payload ;
            state.order_manual.additional_requirements = actions.payload ;
            state.is_changed = true;
            return state
        },
        setOrderTariff(state, actions:PayloadAction<CTariffData>) {
            state.order_auto.tariff_type_id = actions.payload.tariff_type_id ;
            state.order_manual.tariff_type_id = actions.payload.tariff_type_id ;
            state.is_changed = true;
            return state
        },
        setPackInfo(state, actions:PayloadAction<CPackInfo>) {
            state.pack_info  = {...actions.payload} ;
            state.is_changed = true;
            return state
        },
        setTariffLoading(state, actions:PayloadAction<boolean>) {
            state.is_tariff_loading  = actions.payload ;
            return state
        },
        setContactInformation(state, actions:PayloadAction<CContactInformation>) {
            state.order_auto.contacts = {...actions.payload};
            state.order_manual.contacts = {...actions.payload};
            state.is_changed = true;
            return state
        },
        setOrderINN(state, actions:PayloadAction<string>) {
            state.order_auto.TIN = actions.payload ;
            state.order_manual.TIN = actions.payload ;
            state.is_changed = true;
            return state
        },
        setOrderPaymentType(state, actions:PayloadAction<EnumPaymentType>) {
            state.order_auto.payment_type = actions.payload ;
            state.order_manual.payment_type = actions.payload ;
            state.is_changed = true;
            return state
        },
        setClearData(state, actions:PayloadAction<boolean>) {
            // console.log('initialState', initialState);
            state = getInitState();
            return state
        },
        setOrderChanged(state, actions:PayloadAction<boolean>) {
            // console.log('initialState', initialState);
            state.is_changed = actions.payload ;
            return state
        },
        setOrderID(state, actions:PayloadAction<string>) {
            state.order_auto.id = actions.payload ;
            state.order_manual.id = actions.payload ;
            return state
        },
        setAgreement(state, actions:PayloadAction<boolean>) {

            state.is_agreement = actions.payload ;
            return state
        },
        setPhoneChecked(state, actions:PayloadAction<boolean>) {

            state.is_phone_checked = actions.payload ;
            return state
        }
    }
});

export const {
    setOrderMode,
    setOrderData,
    setOrderDataAuto,
    setOrderDataManual,
    setOrderRoutes,
    setSelectedAddress,
    setTariffsData,
    setAddressMode,
    setShowMapMode,
    setOrderDate,
    setOrderAdditional,
    setOrderTariff,
    setPackInfo,
    setTariffLoading,
    setContactInformation,
    setOrderINN,
    setOrderPaymentType,
    setClearData,
    setOrderChanged,
    setOrderID,
    setAgreement,
    setPhoneChecked,
} = createOrderSlicer.actions;

export default createOrderSlicer.reducer;
