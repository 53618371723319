import React, {FC, memo, useCallback, useState} from 'react'
import s from './CloseOrder.module.scss'
import CloseOrderIcon from '../../../../../../assets/icons/CloseOrderIcon.svg'
import { useAppDispatch } from '../../../../../../app/redux/hooks'
import {
    setOrderData,
    setOrderRoutes,
    setTariffsData,
    setPackInfo,
    setOrderMode,
    setOrderAdditional, setTariffLoading, setContactInformation, setOrderDataManual, setOrderDataAuto
} from "../../CreateOrderSlicer";
import {CContactInformation, CPackInfo, EnumOrderMode, SendOrderInfo} from "../../CreateOrderType";
import QuestionWindow from "../../QuestionWindow/QuestionWindow";
import {cookiesClientId, cookiesOrderId} from "../../../../../../api/api.constants";
import {useCookies} from "react-cookie";
import {setRoutes} from "../../../../../../components/HereMap/hereMapSlice";

interface IProps {
    onClick:()=>void
}
export const CloseOrder: FC<IProps> = memo(({onClick}) => {

    const dispatch = useAppDispatch()
    const [isShowQuestion, setShowQuestion ] = useState<boolean>(false);
    const [cookies, setCookie] = useCookies([cookiesOrderId])

    const onCloseClick = useCallback(() => {
        setCookie(cookiesOrderId, '', { path: '/' });
        dispatch(setOrderDataManual(new SendOrderInfo()));
        dispatch(setOrderDataAuto(new SendOrderInfo()));
        dispatch(setRoutes([]));
        // dispatch(setOrderRoutes([]));
        dispatch(setTariffsData({data:[], names:[]}));
        // dispatch(setPackInfo(new CPackInfo()));
        dispatch(setOrderMode(EnumOrderMode.None));
        // dispatch(setOrderAdditional([]));
        dispatch(setTariffLoading(false));
        // dispatch(setContactInformation(new CContactInformation()));

        onClick();
    }, [])

    return (
        <>
            <div className={s.abbortOrder}>
                <img alt='Отменить оформление заказа' src={CloseOrderIcon} onClick={()=>setShowQuestion(true)} />
            </div>
            {isShowQuestion &&  <QuestionWindow onYes={()=> {
                onCloseClick();
                setShowQuestion(false);
            }} onNo={()=>setShowQuestion(false)} title={'Вы дейстивтельно хотите удалить данный заказ ?'} />}
        </>
    )
})
