import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { daDataUrl, daDataToken } from "./api.constants";

interface IGetCoordsQueryArgs {
  "lon": number;
  "lat": number;
}

interface IdaDataResponse {
  suggestions: {
    value: string;
    unrestricted_value: string;
    data: {
      postal_code: string | null;
      country: string | null;
      country_iso_code: string | null;
      federal_district: string | null;
      region_fias_id: string | null;
      region_kladr_id: string | null;
      region_iso_code: string | null;
      region_with_type: string | null;
      region_type: string | null;
      region_type_full: string | null;
      region: string | null;
      area_fias_id: string | null;
      area_kladr_id: string | null;
      area_with_type: string | null;
      area_type: string | null;
      area_type_full: string | null;
      area: string | null;
      city_fias_id: string | null;
      city_kladr_id: string | null;
      city_with_type: string | null;
      city_type: string | null;
      city_type_full: string | null;
      city: string | null;
      city_area: string | null;
      city_district_fias_id: string | null;
      city_district_kladr_id: string | null;
      city_district_with_type: string | null;
      city_district_type: string | null;
      city_district_type_full: string | null;
      city_district: string | null;
      settlement_fias_id: string | null;
      settlement_kladr_id: string | null;
      settlement_with_type: string | null;
      settlement_type: string | null;
      settlement_type_full: string | null;
      settlement: string | null;
      street_fias_id: string | null;
      street_kladr_id: string | null;
      street_with_type: string | null;
      street_type: string | null;
      street_type_full: string | null;
      street: string | null;
      house_fias_id: string | null;
      house_kladr_id: string | null;
      house_cadnum: string | null;
      house_type: string | null;
      house_type_full: string | null;
      house: string | null;
      block_type: string | null;
      block_type_full: string | null;
      block: string | null;
      flat_fias_id: string | null;
      flat_cadnum: string | null;
      flat_type: string | null;
      flat_type_full: string | null;
      flat: string | null;
      flat_area: string | null;
      square_meter_price: string | null;
      flat_price: string | null;
      postal_box: string | null;
      fias_id: string | null;
      fias_code: string | null;
      fias_level: string | null;
      fias_actuality_state: string | null;
      kladr_id: string | null;
      geoname_id: string | null;
      capital_marker: string | null;
      okato: string | null;
      oktmo: string | null;
      tax_office: string | null;
      tax_office_legal: string | null;
      timezone: string | null;
      geo_lat: string | null;
      geo_lon: string | null;
      beltway_hit: string | null;
      beltway_distance: string | null;
      metro: string | null;
      qc_geo: string | null;
      qc_complete: string | null;
      qc_house: string | null;
      history_values: string[] | null[];
      unparsed_parts: string | null;
      source: string | null;
      qc: string | null;
    };
  }[];
}

export const daDataApi = createApi({
  reducerPath: "daDataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: daDataUrl,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Token ${daDataToken}`);
      headers.set("Content-Type", "application/json")
      headers.set("Accept", "application/json")
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAddressByCoords: builder.query<IdaDataResponse, IGetCoordsQueryArgs>({
      query: (coords) => ({
        url: "/geolocate/address",
        method: "post",
        body: {"lat": coords.lat, "lon": coords.lon, "count": 1},
      }),
    }),
    getAddressByName: builder.query<IdaDataResponse, string>({
      query: (query) => ({
        url: "/suggest/address",
        method: "post",
        body: {
          count: 10,
          locations: [{ region: "Москва" }, { region: "Московская" }],
          query: query,
        },
      }),
    }),
    getCompanyByTIN: builder.mutation<IdaDataResponse, string>({
      query: (query) => ({
        url: `/suggest/party?query=${query}&count=1`,
        method: "get",

      }),
    }),
  }),
});

export const { useGetAddressByCoordsQuery, useGetAddressByNameQuery,  useGetCompanyByTINMutation } =  daDataApi;
