import React, { FC, memo, useCallback } from 'react'
import s from './VehicleBlockManual.module.scss'
import Category1 from '../../../../../assets/svg/Category1.svg'
import Category1Plus from '../../../../../assets/svg/Category1Plus.svg'
import Category2 from '../../../../../assets/svg/Category2.svg'
import Category2Plus from '../../../../../assets/svg/Category2Plus.svg'
import Category3 from '../../../../../assets/svg/Category3.svg'
import Category4 from '../../../../../assets/svg/Category4.svg'
import Category5 from '../../../../../assets/svg/Category5.svg'
import Category6 from '../../../../../assets/svg/Category6.svg'
import Category7 from '../../../../../assets/svg/Category7.svg'
import Category1u from '../../../../../assets/svg/Category1u.svg'
import Category1Plusu from '../../../../../assets/svg/Category1Plusu.svg'
import Category2u from '../../../../../assets/svg/Category2u.svg'
import Category2Plusu from '../../../../../assets/svg/Category2Plusu.svg'
import Category3u from '../../../../../assets/svg/Category3u.svg'
import Category4u from '../../../../../assets/svg/Category4u.svg'
import Category5u from '../../../../../assets/svg/Category5u.svg'
import Category6u from '../../../../../assets/svg/Category6u.svg'
import Category7u from '../../../../../assets/svg/Category7u.svg'
import { CarTypeTreeQueryResponse } from '../../../../../api/orderApi'
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/hooks'
import { newUserOrderSelector, setSelectedCarCategory } from '../NewUserOrderSlicer'

export const CarTypeItem: FC<propTypes> = memo(({ data, onClick, selectedID }) => {

    const dispatch = useAppDispatch()

    const { newOrder
    } = useAppSelector(newUserOrderSelector)

    let activeCategory = selectedID === data.id

    const categoryIcon = useCallback((category: string) => {
        switch (category) {
            case "Категория №1":
                return activeCategory ? Category1 : Category1u

            case "Категория №1+":
                return activeCategory ? Category1Plus : Category1Plusu

            case "Категория №2":
                return activeCategory ? Category2 : Category2u

            case "Категория №2+":
                return activeCategory ? Category2Plus : Category2Plusu

            case "Категория №3":
                return activeCategory ? Category3 : Category3u

            case "Категория №4":
                return activeCategory ? Category4 : Category4u

            case "Категория №5":
                return activeCategory ? Category5 : Category5u

            case "Категория №6":
                return activeCategory ? Category6 : Category6u

            case "Категория №7":
                return activeCategory ? Category7 : Category7u

            default:
                break;
        }
    }, [activeCategory])

    const onCategoryClick = useCallback(() => {
        newOrder.selectedCarCategory === data.id ? dispatch(setSelectedCarCategory('')) : dispatch(setSelectedCarCategory(data.id))
    }, [newOrder.selectedCarCategory, data])

    return (
        <div className={`${s.carTypeWrapper} ${activeCategory && s.activeCarType}`} onClick={ ()=> { if (onClick) onClick(); else onCategoryClick();}}>
            <div>
                <img alt={data.name} src={categoryIcon(data.name)} />
            </div>
            <div className={s.textContainer}>
                <div className={s.text}>
                    {data.name.replace(/№/g, '')}
                </div>
                <div className={s.weight}>
                    {data.weight_to} кг
                </div>
            </div>
        </div>
    )
})


type propTypes = {
    data: CarTypeTreeQueryResponse,
    onClick?: ()=> void,
    selectedID: string
}
