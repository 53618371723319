import styled, { keyframes } from 'styled-components'

export const StyledLoader = styled.div`
  background: none repeat scroll 0 0 #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9999999999;
  top: 0;
  font-weight: bold;
  font-size: 22px;
  padding: 0px 30px;
  text-align: center;
`
const animate = keyframes`
0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
`

export const StyledLoaderContent = styled.div`
border-radius: 50%;
width: 10em;
height: 10em;
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #00A99F;
  border-right: 1.1em solid #00A99F;
  border-bottom: 1.1em solid #00A99F;
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${animate} 1.1s infinite linear;
  animation: ${animate} 1.1s infinite linear;
`
