import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/redux/store'
import { checkIsValidInn } from '../../modules/Home/components/RegistrationForm/registrationFormHelper'

export interface innState {
    inn: string,
    companyName: string,
    isValid: boolean
}

const initialState: innState = {
  inn: '',
  companyName: '',
  isValid: true
}
export const innSelector = (state: RootState) => state.inn
export const innSlice = createSlice({
  name: 'inn',
  initialState,
  reducers: {
    setValue (state, action) {
      const {inn, companyName} = action.payload
      state.inn = inn;
      state.companyName = companyName;
      state.isValid = checkIsValidInn(inn) || false
    },
    clear (state) {
      state.inn = ''
      state.companyName = '';
      state.isValid = false
    }
  }
})

export const { setValue, clear } = innSlice.actions

export default innSlice.reducer
