import React, { FC, memo, useCallback, Dispatch } from 'react'
import { PackageTypesQuery } from '../../../../../../../api/orderApi'
import { useAppSelector } from '../../../../../../../app/redux/hooks'
import { newUserOrderSelector } from '../../../NewUserOrderSlicer'
import s from './WrappingSelectModal.module.scss'

type PropTypes = {
    onSelect:(val:{id:string, name:string, length: number, width: number})=>void;
    onCancel:()=>void
}

export const WrappingSelectModal: FC<PropTypes> = memo(({onSelect , onCancel}) => {

    const { packageTypes } = useAppSelector(newUserOrderSelector)


    return (
        <div className={s.wrappingSelectWrapper}>

            <div className={s.body}>
                <div className={s.header}>
                    Тип паллета
                </div>
                <div className={s.text}>
                    Груз предварительно уложен на паллетах
                </div>
                <div className={s.list}>
                    {packageTypes.map(x => <div key={x.id} className={s.item} onClick={() => onSelect({id:x.id, name: x.name, length: 0, width: 0})}>{x.name}</div>)}
                </div>
            </div>
            <div className={s.layout} onClick={()=>onCancel()}/>
        </div>
    )
})

