import { StyledInfoMassage } from "./InfoMessage.styled";
import { InfoMessageType } from "./InfoMessage.types";
import { useAppSelector } from "../../app/redux/hooks";
import { infoMessageSelector, closeInfoMessage } from "./infoMessageSlice";
import { useAppDispatch } from "../../app/redux/hooks";
import { useEffect, useRef, MutableRefObject } from "react";
import { FailIcon } from "../../assets/icons/FailIcon";
import { OkIcon } from "../../assets/icons/OkIcon";
import {  StyledButtonBottomBlock} from "../Button/Button.styled";
import {ButtonBackgroundColor, ButtonColor, ButtonHeight, ButtonTypeEvent} from "../Button/Button.types";
import Button from "../../components/Button"


export const InfoMessage = () => {
  const { isOpened, text, title, type } = useAppSelector(infoMessageSelector);
  const dispatch = useAppDispatch();
  const timerRef = useRef<ReturnType<typeof setTimeout>>(null)! as any;

  useEffect(() => {
    if (isOpened) {
      timerRef.current = setTimeout(() => {
        dispatch(closeInfoMessage());
      }, 5000);
    }
    return () => clearTimeout(timerRef.current);
  }, [isOpened]);

  return (
    <>
      {isOpened && (
        <StyledInfoMassage messageType={type}>
          <div>
            {type === InfoMessageType.FAILIED ? <FailIcon /> : <OkIcon />}
          </div>
          <p>{title}</p>
          <p>{text}</p>
          <StyledButtonBottomBlock>
            <Button
                buttonTypeEvent={ButtonTypeEvent.BUTTON}
                buttonBackgroundColor={type === InfoMessageType.FAILIED ? ButtonBackgroundColor.RED_GRADIENT : ButtonBackgroundColor.GREEN}
                buttonColor={ButtonColor.WHITE}
                buttonHeight={ButtonHeight.MID_HEIGHT}
                children='Закрыть'
                onClick={() => {
                  dispatch(closeInfoMessage());
                  //@ts-ignore
                  window.message_is_open = false;
                }}
            />
          </StyledButtonBottomBlock>
        </StyledInfoMassage>
      )}
    </>
  );
};
