const Category3 = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='90'
      height='32'
      fill='none'
      viewBox='0 0 90 32'
    >
      <path
        fill='#000'
        d='M39 31c2.099 0 3.8-2.015 3.8-4.5S41.099 22 39 22c-2.099 0-3.8 2.015-3.8 4.5S36.901 31 39 31zM26.5 31c1.933 0 3.5-2.015 3.5-4.5S28.433 22 26.5 22 23 24.015 23 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path
        fill='#5364FF'
        d='M50 26H21c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h29c.6 0 1 .4 1 1v19c0 .6-.4 1-1 1z'
      ></path>
      <path
        fill='#000'
        d='M64 31c2.099 0 3.8-2.015 3.8-4.5S66.099 22 64 22c-2.099 0-3.8 2.015-3.8 4.5S61.901 31 64 31zM52.5 31c1.933 0 3.5-2.015 3.5-4.5S54.433 22 52.5 22 49 24.015 49 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path fill='#BCC0CA' d='M47 26H24v2h23v-2z'></path>
      <path
        fill='#3C4AC1'
        d='M64 26H47c-.6 0-1-.4-1-1V6c0-.6.4-1 1-1h15c1.7 0 3 1.3 3 3v17c0 .6-.4 1-1 1z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M71 24c0 1-1 4-1 4H47v-7c0-5.6.2-8.7.5-11.2.1-1 1.3-1.8 2.3-1.8h11.9c6.3 0 8.5.4 8.7 2.3.3 2.5.6 5.9.6 8.7v5z'
      ></path>
      <path
        fill='#000'
        d='M47 26H28v2h42s.1-.4.3-1H48c-.6 0-1-.4-1-1z'
        opacity='0.1'
      ></path>
      <path
        fill='#000'
        d='M55 26.5h-5V28h5v-1.5zM70.4 10.5H57.9v4.7c0 1 .8 1.9 1.8 2 1.6.1 4.1.3 7.8.3 3.1 0 3.2-.4 3.5-.5-.1-2.2-.4-4.6-.6-6.5z'
      ></path>
      <path
        fill='#000'
        d='M51.5 31c1.933 0 3.5-2.015 3.5-4.5S53.433 22 51.5 22 48 24.015 48 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path
        fill='#ECEDEF'
        d='M50.8 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z'
      ></path>
      <path
        fill='#000'
        d='M53.6 15.6c-1-.2-1.8-1.1-1.8-2.2v-3.1h5.5v6.1l-3.7-.8z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M57 10.5V16l-3.4-.7c-.9-.2-1.6-1-1.6-2v-2.9h5v.1zm.5-.5h-6v3.4c0 1.2.8 2.2 2 2.5l3.4.7.6.1V10z'
      ></path>
      <path fill='#fff' d='M71 22H59c0 .6.4 1 1 1h11v-1z'></path>
      <path
        fill='#000'
        d='M73 31H16c0-.6.4-1 .9-1H72c.6 0 1 .4 1 1zM29 26.5h-5V28h5v-1.5z'
      ></path>
      <path
        fill='#000'
        d='M25.5 31c1.933 0 3.5-2.015 3.5-4.5S27.433 22 25.5 22 22 24.015 22 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path
        fill='#ECEDEF'
        d='M24.9 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M57 15v1c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1v3z'
      ></path>
    </svg>
  )
}

export default Category3
