import React, { FC, memo, useState, useCallback, ChangeEvent, useEffect } from 'react'
import s from './OrderCompletion.module.scss'
import uncheckedUserTerm from '../../../../../../assets/svg/uncheckedUserTerm.svg'
import checkedUserTerm from '../../../../../../assets/svg/checkedUserTerm.svg'
import AcceptedPhoneNumber from '../../../../../../assets/svg/AcceptedPhoneNumber.svg'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/hooks'

import InputMask from "react-input-mask"
import { useCheckSmsMutation, useSendSmsCodeMutation } from '../../../../../../api/orderApi'
import {currentOrderData, setOrderData, setContactInformation, getOrderReducer, setAgreement, setPhoneChecked} from "../../CreateOrderSlicer";
import {SendOrderInfo} from "../../CreateOrderType";
import {authorizationSelector} from "../../../../../Authorization/authorizationSlice";
import {userProfileSelector} from "../../../UserProfile/userProfileSlice";
import {CCompany, CUser, CUserProfile} from "../../../UserProfile/userProfile.type";

interface IProps {

}
export const UserRegistration: FC<IProps> =  ({ }) => {
    console.log('UserRegistration updated');
    const dispatch = useAppDispatch()
    const userProfile  = useAppSelector(userProfileSelector);
    const currentOrder : SendOrderInfo = useAppSelector(currentOrderData);
    const orderReducer  = useAppSelector(getOrderReducer);
    const contacts = currentOrder.contacts;
    const { isAuth } = useAppSelector(authorizationSelector);

    const user : CUser = userProfile.profile.users.length ? userProfile.profile.users[0] : new CUser();

    const [isPhoneSended, setPhoneSended] = useState<boolean>(false);


    useEffect(()=>{
        if (!isAuth) return;
        let cont = {...contacts};
        cont.full_name = user.full_name;
        cont.phone = user.phone;
        cont.email = user.email;
        if (cont.phone) dispatch(setPhoneChecked(true));
        dispatch(setContactInformation(cont))
    }, [])


    useEffect(()=>{

    }, [isAuth])

    const [
        sendSmsCode,
        sendSMSData
     ] = useSendSmsCodeMutation(),
     [
        checkSms,
        checkSMSData
     ] = useCheckSmsMutation()

    const [showCheckCode, setShowCheckCode] = useState<boolean>(false),
          [checkCode, setCheckCode] = useState<string>('')
    
    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setContactInformation(  {...contacts, email: e.currentTarget.value}))
    }

    const onFIOChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setContactInformation(   {...contacts,full_name : e.currentTarget.value}))
    }

    const onChangePhoneInput =  (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setPhoneChecked(false));
        dispatch(setContactInformation(  {...contacts,phone : e.currentTarget.value}))
    }

    const onCheckboxClick = () => {
        dispatch(setAgreement(!orderReducer.is_agreement));
    }



    const onPhoneClick = useCallback(() => {
        setShowCheckCode(true)
    }, [])

    const onBlurPhoneInput = useCallback(() => {
        if(!contacts.phone.length) {
            setShowCheckCode(false)
        } else {
            !isPhoneSended && sendSmsCode(contacts.phone)
            setShowCheckCode(true)
        }
    }, [contacts.phone, isPhoneSended])

    const onCheckCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        setCheckCode(value)
    }, [])

    useEffect(() => {
        if(!sendSMSData.isLoading) {
            sendSMSData.data?.status === 'success' && (() => {setPhoneSended(true); dispatch(setPhoneChecked(false))})();
            sendSMSData.error && alert(sendSMSData.error)
        }
    }, [sendSMSData.isLoading])

    useEffect(() => {
        checkCode.length === 4 && checkSms({ phone:  contacts.phone, code: checkCode})
    }, [checkCode])

    useEffect(() => {
        if(!checkSMSData.isLoading) {
            checkSMSData.data?.valid && dispatch(setPhoneChecked(true))
            checkSMSData.error && alert(checkSMSData.error)
        }
    }, [checkSMSData.isLoading])

    useEffect(() => {
        contacts.phone.length !== 15 && setPhoneSended(false)
    }, [ contacts.phone])



    return (
        <div className={s.userRegistration}>
            <div className={s.FIOWrapper}>
                <input placeholder="Ваши ФИО" value={ contacts.full_name} onChange={onFIOChange} onBlur={onFIOChange} />
            </div>
            <div className={s.phoneWrapper}>
                <div className={s.phone}>
                    <InputMask mask="+7 999 999 9999" value={ contacts.phone}
                        onChange={onChangePhoneInput} onClick={onPhoneClick} onBlur={onBlurPhoneInput}>
                        {(inputProps: any) => <input {...inputProps} placeholder="Моб. телефон" type="tel" />}
                    </InputMask>
                    {orderReducer.is_phone_checked && <img alt='Номер подтвержден' src={AcceptedPhoneNumber} />}
                </div>
                <div className={showCheckCode ? s.checkCode : s.hideCode}>
                    <input placeholder="Код из SMS" value={checkCode} onChange={onCheckCodeChange} maxLength={4} />
                </div>
            </div>
            <div className={s.emailWrapper}>
                <input placeholder="Ваш Email"  value={ contacts.email} onChange={onEmailChange}  onBlur={onEmailChange} />
            </div>
            <div className={s.acceptWrapper}>
                <img alt="" src={orderReducer.is_agreement ? checkedUserTerm : uncheckedUserTerm} onClick={onCheckboxClick} />
                <div>
                    Я принимаю <a href="#">пользовательское соглашение</a> и <a href="#">политику конфиденциальности</a>
                </div>
            </div>
        </div>
    )
}
