import React, { FC, useState  } from 'react'
import { useAppSelector } from '../../../../../app/redux/hooks'
import style from './AddressBlock.module.scss'
import { NewOrderAddressItem } from './NewOrderAddressItem'
import OpenListAddressesIcon from '../../../../../assets/icons/OpenListAddressesIcon.svg'
import CloseListAddressesIcon from '../../../../../assets/icons/CloseListAddressesIcon.svg'

import {RouteAddress, SendOrderInfo} from "../CreateOrderType";
import FromInputIcon from "../../../../../assets/icons/FromInputIcon.svg";
import ToInputIcon from "../../../../../assets/icons/ToInputIcon.svg";
import {currentOrderData} from "../CreateOrderSlicer";
interface IProps {
    onAdd:()=>void
    onEdit:(address: RouteAddress)=>void
}

export const NewOrderAddresses: FC<IProps> = ({onAdd, onEdit }) => {

    const currentOrder : SendOrderInfo = useAppSelector(currentOrderData);

    const [showAddresses, setShowAddresses] = useState<boolean>(false);
    if(currentOrder.routes.length <= 1) {
        return (<>
                    <div className={`${style.addressInputWrapper} ${!currentOrder.routes.length && style.addressInputWrapperOne}`}>
                        <img src={FromInputIcon } />
                        <input value={currentOrder.routes.length ? currentOrder.routes[0].adress : ''}
                               onClick={()=> currentOrder.routes.length ? onEdit(currentOrder.routes[0]) : onAdd() }
                               placeholder={`Откуда начать перевозку`}
                               onChange={()=>{}}
                        />
                    </div>
                    {currentOrder.routes.length == 1 &&
                        <div className={style.toWhereShowButtonWrapper}>
                              <button className={style.toWhereShowButton} onClick={onAdd}>Куда</button>
                        </div>
                    }
               </>)
    }  else {
        return (
            <div className={style.dropDownAddressesWrapper}>
            {currentOrder.routes.slice(0, 1).map(address =>
                <NewOrderAddressItem key={address.adress} address={address} lastAddress={false} from={true} onClick={()=>onEdit(address)} onAddClick={onAdd} />)}
            {!showAddresses && currentOrder.routes.length > 2 &&
                <div className={style.nestedAddress} onClick={() => setShowAddresses(true)}><img alt="Показать адреса" src={OpenListAddressesIcon} /> показать еще {currentOrder.routes.length - 2} адресов</div>
            }
            {showAddresses && currentOrder.routes.length > 2 &&
                <div className={style.nestedAddress} onClick={() => setShowAddresses(false)}><img alt="Показать адреса" src={CloseListAddressesIcon} /> Свернуть список адресов</div>
            }
            {showAddresses && currentOrder.routes.slice(1, currentOrder.routes.length - 1).map((address, index) =>
                <NewOrderAddressItem key={address.adress} address={address} lastAddress={false} from={false}  onClick={()=>onEdit(address)} onAddClick={onAdd}  />)
            }

            {currentOrder.routes.slice(-1).map(address =>
                <NewOrderAddressItem key={address.adress} address={address} lastAddress={true} from={false}  onClick={()=>onEdit(address)} onAddClick={onAdd} />)
            }
            </div>
        )

    }
}
