import React, { FC, memo, useCallback } from 'react'
import s from './OrderCompletion.module.scss'
import Category1u from '../../../../../../assets/svg/Category1u.svg'
import Category1Plusu from '../../../../../../assets/svg/Category1Plusu.svg'
import Category2u from '../../../../../../assets/svg/Category2u.svg'
import Category2Plusu from '../../../../../../assets/svg/Category2Plusu.svg'
import Category3u from '../../../../../../assets/svg/Category3u.svg'
import Category4u from '../../../../../../assets/svg/Category4u.svg'
import Category5u from '../../../../../../assets/svg/Category5u.svg'
import Category6u from '../../../../../../assets/svg/Category6u.svg'
import Category7u from '../../../../../../assets/svg/Category7u.svg'
import { useAppSelector } from '../../../../../../app/redux/hooks'
import { newUserOrderSelector } from '../../NewUserOrderSlicer'
import {currentOrderData} from "../../CreateOrderSlicer";
import {SendOrderInfo} from "../../CreateOrderType";

export const CategoryDetails: FC<{onClick?:()=>void}> =  ({onClick}) => {

    const {  carTypesTree } = useAppSelector(newUserOrderSelector);
    const currentOrder :SendOrderInfo = useAppSelector(currentOrderData);

    const selectedCategoryDetails = currentOrder.car_type_id ? carTypesTree.filter(x => x.id === currentOrder.car_type_id)[0] : carTypesTree[0],
            valueFrom = selectedCategoryDetails.length_from * selectedCategoryDetails.height_from * selectedCategoryDetails.width_from,
            valueTo = selectedCategoryDetails.length_to * selectedCategoryDetails. height_to * selectedCategoryDetails.width_to

    const categoryIcon = useCallback((category: string) => {
        switch (category) {
            case "Категория №1":
                return Category1u

            case "Категория №1+":
                return Category1Plusu

            case "Категория №2 ":
                return Category2u

            case "Категория №2+":
                return Category2Plusu

            case "Категория №3":
                return Category3u

            case "Категория №4":
                return Category4u

            case "Категория №5":
                return Category5u

            case "Категория №6":
                return Category6u

            case "Категория №7":
                return Category7u

            default:
                break;
        }
    }, [])

    return (
        <div className={s.categoryDetails} onClick={()=> {if (onClick) onClick()}}>
            <img alt={selectedCategoryDetails.name} src={categoryIcon(selectedCategoryDetails.name)} />
            <span>{selectedCategoryDetails.name}</span>
            <span>{`${valueFrom.toFixed(2).replace(/[.]?0+$/, '')} - ${valueTo.toFixed(2).replace(/[.]?0+$/, '')} м`}<sup>3</sup></span>
        </div>
    )
}
