import { StyledLink } from './Link.styled'
import { ILinkProps } from './Link.types'

export const Link = ({
  children,
  href,
  textDecorationColor,
  textDecoration,
  color
}: ILinkProps) => {
  return (
    <StyledLink
      href={href}
      color={color}
      textDecorationColor={textDecorationColor}
      textDecoration={textDecoration}
    >
      {children}
    </StyledLink>
  )
}
