import moment from 'moment'
import React, { FC, useState, memo, ChangeEvent, useCallback } from 'react'

import s from './SetTimeModal.module.scss'

type propTypes = {
    setShowDateModal: (value: boolean) => void
    setValues: (value: any) => void
    name: string
    setHideInput: (value: boolean) => void
    from?:string
    to?:string
}

export const SetTimeModal: FC<propTypes> = memo(({ setShowDateModal, setValues, name, setHideInput, from, to }) => {

    const [fromTime, setFromTime] = useState<string>(from && from!=':00' ?   from : name=='workTime' ? '08:00' : '12:00'),
          [toTime, setToTime] = useState<string>( to  && to!=':00' ? to : name=='workTime' ? '20:00' : '13:00');

    const onFromTimeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setFromTime(e.currentTarget.value)
    }, [])

    const onToTimeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setToTime(e.currentTarget.value)
    }, [])

    const onCancelClick = useCallback(() => {
        setShowDateModal(false)
        if (fromTime === moment(new Date()).format("HH:mm") && toTime === moment(new Date()).format("HH:mm")) {

            setHideInput(true)
        }
    }, [fromTime, toTime])

    const onSaveClick = useCallback(() => {
        setValues(  {from: fromTime, to: toTime}  )
        setShowDateModal(false)
        setHideInput(false)
    }, [fromTime, toTime])

    return (
        <div className={s.setTimeWrapper}>
            <div className={s.setTimeOverlay}></div>
            <div className={s.setTime}>
                <div className={s.datapickerTimeFromto}>
                    <div>
                        {name=='workTime' ? 'Время работы с' : 'Перерыв с'}
                    </div>
                    <input type="time" value={fromTime} onChange={onFromTimeChange} />
                </div>
                <div>
                    <div>
                    До
                    </div>
                    <input type="time" value={toTime} onChange={onToTimeChange} />
                </div>
                <div>
                    <button className={s.cancel} onClick={onCancelClick}>Отменить</button>
                    <button className={s.save} onClick={onSaveClick}>Сохранить</button>
                </div>
            </div>
        </div>
    )
})



