const Category6 = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='90'
      height='32'
      fill='none'
      viewBox='0 0 90 32'
    >
      <path
        fill='#000'
        d='M21.5 31c1.933 0 3.5-2.015 3.5-4.5S23.433 22 21.5 22 18 24.015 18 26.5s1.567 4.5 3.5 4.5zM34 31c2.099 0 3.8-2.015 3.8-4.5S36.099 22 34 22c-2.099 0-3.8 2.015-3.8 4.5S31.901 31 34 31z'
      ></path>
      <path
        fill='#000'
        d='M40 31c2.099 0 3.8-2.015 3.8-4.5S42.099 22 40 22c-2.099 0-3.8 2.015-3.8 4.5S37.901 31 40 31zM27.5 31c1.933 0 3.5-2.015 3.5-4.5S29.433 22 27.5 22 24 24.015 24 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path
        fill='#5364FF'
        d='M57 26H13c-1.7 0-3-1.3-3-3V4c0-1.7 1.3-3 3-3h44c.6 0 1 .4 1 1v23c0 .6-.4 1-1 1z'
      ></path>
      <path
        fill='#000'
        d='M71 31c2.099 0 3.8-2.015 3.8-4.5S73.099 22 71 22c-2.099 0-3.8 2.015-3.8 4.5S68.901 31 71 31zM59.5 31c1.933 0 3.5-2.015 3.5-4.5S61.433 22 59.5 22 56 24.015 56 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path fill='#BCC0CA' d='M54 26H28v2h26v-2z'></path>
      <path
        fill='#3C4AC1'
        d='M71 26H54c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h15c1.7 0 3 1.3 3 3v21c0 .6-.4 1-1 1z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M78 24c0 1-1 4-1 4H54v-7c0-5.6.5-13.7.8-16.2.1-1 1.2-2.8 3-2.8h10.9c6.3 0 8.5 6.4 8.7 8.3.3 2.5.6 5.9.6 8.7v5z'
      ></path>
      <path
        fill='#000'
        d='M54 26H29v2h48s.1-.4.3-1H55c-.6 0-1-.4-1-1z'
        opacity='0.1'
      ></path>
      <path
        fill='#000'
        d='M62 26.5h-5V28h5v-1.5zM77.4 10.5H64.9v4.7c0 1 .8 1.9 1.8 2 1.6.1 4.1.3 7.8.3 3.1 0 3.2-.4 3.5-.5-.1-2.2-.4-4.6-.6-6.5z'
      ></path>
      <path
        fill='#000'
        d='M58.5 31c1.933 0 3.5-2.015 3.5-4.5S60.433 22 58.5 22 55 24.015 55 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path
        fill='#ECEDEF'
        d='M57.8 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z'
      ></path>
      <path
        fill='#000'
        d='M60.6 15.6c-1-.2-1.8-1.1-1.8-2.2v-3.1h5.5v6.1l-3.7-.8z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M64 10.5V16l-3.4-.7c-.9-.2-1.6-1-1.6-2v-2.9h5v.1zm.5-.5h-6v3.4c0 1.2.8 2.2 2 2.5l3.4.7.6.1V10z'
      ></path>
      <path fill='#fff' d='M78 22H66c0 .6.4 1 1 1h11v-1z'></path>
      <path
        fill='#000'
        d='M80 31H9c0-.6.5-1 1.2-1h68.7c.6 0 1.1.4 1.1 1zM30 26.5h-5V28h5v-1.5z'
      ></path>
      <path
        fill='#000'
        d='M26.5 31c1.933 0 3.5-2.015 3.5-4.5S28.433 22 26.5 22 23 24.015 23 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path
        fill='#ECEDEF'
        d='M25.9 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z'
      ></path>
      <path
        fill='#000'
        d='M20.5 31c1.933 0 3.5-2.015 3.5-4.5S22.433 22 20.5 22 17 24.015 17 26.5s1.567 4.5 3.5 4.5z'
      ></path>
      <path
        fill='#ECEDEF'
        d='M19.9 29.5c1.05 0 1.9-1.12 1.9-2.5s-.85-2.5-1.9-2.5c-1.05 0-1.9 1.12-1.9 2.5s.85 2.5 1.9 2.5z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M64 15v1c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1v3z'
      ></path>
    </svg>
  )
}

export default Category6
