const Category2 = ()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="32"
      fill="none"
      viewBox="0 0 90 32"
    >
      <path
        fill="#BCC0CA"
        d="M67 15l-26 1V7.9c0-.5.4-1 .9-1 2.8-.2 11.3-.6 17.6-.2 1.6.1 3.2.9 4.2 2.2C66.3 12.2 67 15 67 15z"
      ></path>
      <path
        fill="#000"
        d="M40 31c2.761 0 5-2.686 5-6s-2.239-6-5-6-5 2.686-5 6 2.239 6 5 6zM26 31c2.21 0 4-2.239 4-5s-1.79-5-4-5-4 2.239-4 5 1.79 5 4 5z"
      ></path>
      <path
        fill="#5364FF"
        d="M49 25H17c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h32c.6 0 1 .4 1 1v22c0 .6-.4 1-1 1z"
      ></path>
      <path
        fill="#000"
        d="M66 31c2.761 0 5-2.686 5-6s-2.239-6-5-6-5 2.686-5 6 2.239 6 5 6zM52 31c2.21 0 4-2.239 4-5s-1.79-5-4-5-4 2.239-4 5 1.79 5 4 5z"
      ></path>
      <path fill="#BCC0CA" d="M42 25H25v3h17v-3z"></path>
      <path
        fill="#3C4AC1"
        d="M58 25H40c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h18c.6 0 1 .4 1 1v22c0 .6-.4 1-1 1z"
      ></path>
      <path
        fill="#BCC0CA"
        d="M68 15l-27 1V7.9c0-.5.4-1 .9-1 2.8-.2 11.2-.6 17.5-.2 1.6.1 3.2.9 4.3 2.1C66.6 12.2 68 15 68 15z"
      ></path>
      <path
        fill="#BCC0CA"
        d="M72 26v1c0 .6-.4 1-1 1H41V15h27l2 1c1.5.7 2 3 2 9.3v.7z"
      ></path>
      <path
        fill="#000"
        d="M71.1 28H23.9c-.5 0-.9-.4-.9-1v-2h18v2h31c0 .6-.4 1-.9 1z"
        opacity="0.15"
      ></path>
      <path fill="#000" d="M54 26h-6v2h6v-2z"></path>
      <path
        fill="#000"
        d="M50 31c2.21 0 4-2.239 4-5s-1.79-5-4-5-4 2.239-4 5 1.79 5 4 5zM63.3 8.4C62 8.3 59.2 8 57 8c-3 0-11 .5-11 .5l2.5 6.5H68s-1.4-2.8-4.3-6.1c-.1-.2-.2-.3-.4-.5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M49.2 29.5c1.215 0 2.2-1.343 2.2-3s-.985-3-2.2-3c-1.215 0-2.2 1.343-2.2 3s.985 3 2.2 3z"
      ></path>
      <path
        fill="#000"
        d="M42.5 15.5v-6c0-.8.7-1.5 1.5-1.5h3.2l.2.2c.1.1 2.1 2.6 3.1 6.7l.2.6h-8.2z"
      ></path>
      <path
        fill="#BCC0CA"
        d="M47 8.5s2 2.5 3 6.5h-7V9.5c0-.6.4-1 1-1h3zm.5-1H44c-1.1 0-2 .9-2 2V16h9.3l-.3-1.2c-1-4.2-3.1-6.8-3.2-6.9l-.3-.4z"
      ></path>
      <path fill="#000" d="M76 31H13c0-.6.5-1 1-1h61c.5 0 1 .4 1 1z"></path>
      <path
        fill="#fff"
        d="M71 17H56c0 .8.7 1.5 1.5 1.5h14c-.1-.6-.3-1.1-.5-1.5z"
      ></path>
      <path fill="#000" d="M28 26h-6v2h6v-2z"></path>
      <path
        fill="#000"
        d="M24 31c2.21 0 4-2.239 4-5s-1.79-5-4-5-4 2.239-4 5 1.79 5 4 5z"
      ></path>
      <path
        fill="#ECEDEF"
        d="M23.2 29.5c1.215 0 2.2-1.343 2.2-3s-.985-3-2.2-3c-1.215 0-2.2 1.343-2.2 3s.985 3 2.2 3z"
      ></path>
    </svg>
  );
}

export default Category2;
