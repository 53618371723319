const Category2Plus = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='90'
      height='32'
      fill='none'
      viewBox='0 0 90 32'
    >
      <path
        fill='#BCC0CA'
        d='M70 15l-26 1V7.9c0-.5.4-1 .9-1 2.8-.2 11.3-.6 17.6-.2 1.6.1 3.2.9 4.2 2.2C69.3 12.2 70 15 70 15z'
      ></path>
      <path
        fill='#000'
        d='M41 31c2.761 0 5-2.686 5-6s-2.239-6-5-6-5 2.686-5 6 2.239 6 5 6zM27 31c2.21 0 4-2.239 4-5s-1.79-5-4-5-4 2.239-4 5 1.79 5 4 5z'
      ></path>
      <path
        fill='#5364FF'
        d='M52 25H14c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h38c.6 0 1 .4 1 1v22c0 .6-.4 1-1 1z'
      ></path>
      <path
        fill='#000'
        d='M69 31c2.761 0 5-2.686 5-6s-2.239-6-5-6-5 2.686-5 6 2.239 6 5 6zM55 31c2.21 0 4-2.239 4-5s-1.79-5-4-5-4 2.239-4 5 1.79 5 4 5z'
      ></path>
      <path fill='#BCC0CA' d='M44 25H26v3h18v-3z'></path>
      <path
        fill='#3C4AC1'
        d='M62 25H41c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h21c.6 0 1 .4 1 1v22c0 .6-.4 1-1 1z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M71 15l-27 1 .5-11.1c0-1.4.9-2 1.4-2 2.8-.2 10.2-.6 16.5-.2 1.6.1 3.2 4.9 4.3 6.1C69.6 12.2 71 15 71 15z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M75 26v1c0 .6-.4 1-1 1H44V15h27l2 1c1.5.7 2 3 2 9.3v.7z'
      ></path>
      <path
        fill='#000'
        d='M74.1 28H26.9c-.5 0-.9-.4-.9-1v-2h18c0 1.1.9 2 2 2h29c0 .6-.4 1-.9 1z'
        opacity='0.15'
      ></path>
      <path fill='#000' d='M57 26h-6v2h6v-2z'></path>
      <path
        fill='#000'
        d='M53 31c2.21 0 4-2.239 4-5s-1.79-5-4-5-4 2.239-4 5 1.79 5 4 5zM66.3 8.4C65 8.3 62.2 8 60 8c-3 0-11 .5-11 .5l2.5 6.5H71s-1.4-2.8-4.3-6.1c-.1-.2-.2-.3-.4-.5z'
      ></path>
      <path
        fill='#000'
        d='M45.5 15.5v-6c0-.8.7-1.5 1.5-1.5h3.2l.2.2c.1.1 2.1 2.6 3.1 6.7l.2.6h-8.2z'
      ></path>
      <path
        fill='#BCC0CA'
        d='M50 8.5s2 2.5 3 6.5h-7V9.5c0-.6.4-1 1-1h3zm.5-1H47c-1.1 0-2 .9-2 2V16h9.3l-.3-1.2c-1-4.2-3.1-6.8-3.2-6.9l-.3-.4z'
      ></path>
      <path
        fill='#fff'
        d='M74 17H59c0 .8.7 1.5 1.5 1.5h14c-.1-.6-.3-1.1-.5-1.5z'
      ></path>
      <path fill='#000' d='M29 26h-6v2h6v-2z'></path>
      <path
        fill='#000'
        d='M25 31c2.21 0 4-2.239 4-5s-1.79-5-4-5-4 2.239-4 5 1.79 5 4 5z'
      ></path>
      <path
        fill='#000'
        d='M79 31H11c0-.6.5-1 1.1-1h65.8c.6 0 1.1.4 1.1 1z'
      ></path>
      <path
        fill='#ECEDEF'
        d='M52.2 29.5c1.215 0 2.2-1.343 2.2-3s-.985-3-2.2-3c-1.215 0-2.2 1.343-2.2 3s.985 3 2.2 3zM24.2 29.5c1.215 0 2.2-1.343 2.2-3s-.985-3-2.2-3c-1.215 0-2.2 1.343-2.2 3s.985 3 2.2 3z'
      ></path>
    </svg>
  )
}

export default Category2Plus
