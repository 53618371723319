import styled from "styled-components";

const StyledSvg = styled.svg`
  cursor: pointer;
`;

export const FailIcon = () => (
  <StyledSvg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 4.16675C13.4792 4.16675 4.16667 13.4792 4.16667 25.0001C4.16667 36.5209 13.4792 45.8334 25 45.8334C36.5208 45.8334 45.8333 36.5209 45.8333 25.0001C45.8333 13.4792 36.5208 4.16675 25 4.16675ZM34.8063 31.8605C35.6208 32.6751 35.6208 33.9918 34.8063 34.8063C34.4 35.2126 33.8667 35.4168 33.3333 35.4168C32.8 35.4168 32.2667 35.2126 31.8604 34.8063L25 27.9459L18.1396 34.8063C17.7333 35.2126 17.2 35.4168 16.6667 35.4168C16.1333 35.4168 15.6 35.2126 15.1938 34.8063C14.3792 33.9918 14.3792 32.6751 15.1938 31.8605L22.0542 25.0001L15.1938 18.1397C14.3792 17.3251 14.3792 16.0084 15.1938 15.1938C16.0083 14.3792 17.325 14.3792 18.1396 15.1938L25 22.0543L31.8604 15.1938C32.675 14.3792 33.9917 14.3792 34.8063 15.1938C35.6208 16.0084 35.6208 17.3251 34.8063 18.1397L27.9458 25.0001L34.8063 31.8605Z" fill="#FF3131"
    />
  </StyledSvg>
);


