
import {daDataToken, daDataUrl, hereMapApiKey} from "./api.constants";
import {TypeLonLat} from "../modules/Home/components/NewUserOrder/CreateOrderType";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {BodyOptionCharacteristicsValuesResponse, orderApi} from "./orderApi";



export type TypeGetAddress = {lat: number, lon: number, limit?: number}
export type TypeGetAddressByName = {q:string, limit: number}

export const hereComeApi = createApi({
    reducerPath: 'hereComeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '',
        prepareHeaders: (headers) => {
            headers.set("Content-Type", "application/json")
            headers.set("Accept", "application/json")
            return headers;
        },
    }),

    endpoints: builder => ({
        getAddressByCoords: builder.query<any,TypeGetAddress>({
            query: ({lat, lon, limit}) =>
                `https://revgeocode.search.hereapi.com/v1/revgeocode?xnlp=CL_JSMv3.1.21.3&apikey=${hereMapApiKey}&limit=${limit ? limit : 5}&at=${lat},${lon}&lang=ru`
        }),
        getAddressByName: builder.query<any, TypeGetAddressByName>({
            query: ({q, limit}) =>
                `https://geocode.search.hereapi.com/v1/geocode?xnlp=CL_JSMv3.1.23.0&apikey=${hereMapApiKey}&lang=ru&q=${q}&limit=${limit}&at=55.75602%2C37.61882&in=countryCode%3ARUS`
        }),
        getRoute: builder.mutation<any, TypeLonLat[]>({
            query:  points => {
                    let url : string = `https://router.hereapi.com/v8/routes?xnlp=CL_JSMv3.1.30.8&apikey=${hereMapApiKey}&lang=ru&routingMode=fast&transportMode=car&return=polyline,travelSummary`;
                    if (points.length < 2) return '';
                    url += `&origin=${points[0].lat},${points[0].lon}`;
                    url += `&destination=${points[points.length - 1].lat},${points[points.length - 1].lon}`;
                    for (let i = 1; i < points.length - 1; i++)
                        url += `&via=${points[i].lat},${points[i].lon}`;
                    return  url;
            }
        }),

    })
});

export const {
    useGetAddressByCoordsQuery,
    useGetAddressByNameQuery,
    useGetRouteMutation,
} = hereComeApi;




