import {useEffect, useState} from "react";
import cn from "classnames";
import optionToggler from "../../../../../assets/svg/DropDown.svg";
import "./AutoDropDownGreyList.css";
import { ReactSVG } from "react-svg";

import {DropDownListItem} from "../NewUserOrderSlicer";
import {SelectValueModal} from "./SelectValueModal";




interface AutoDropDownGreyListProps {
  type: "gray" | "blue" | "transparent" | "transparent-green"| "white";
  size?: "normal" | "lg" | "xl";
  align?: "center" | "left" | "right";
  onSelect: ({ id, text }: DropDownListItem) => void;
  onClick?:Function;
  list: DropDownListItem[];
  selectedId?: number;
  title: string;
  isSelected: boolean;
  showTitleWhenSelect?: boolean;
  value?:string;
  canEnterValue?:boolean;
  add_class?: string;
}
export const AutoDropDownGreyList: React.FC<AutoDropDownGreyListProps> = ({
  type,
  size = "normal",
  align = "left",
  onSelect,
  onClick,
  list,
  selectedId = 0,
  title= '',
  isSelected = false,
  showTitleWhenSelect = true,
  value = '',
  canEnterValue = false,
  add_class = ""


}) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DropDownListItem|undefined>(value && value != '-1' ? list.find(x=> x.id === value) : undefined);
  const clickDropDown = () => {
    //
    if (list.length)
      setOpenDropDown(true);
      ;
    // if (onClick && !openDropDown) onClick();
  };
  const clickDropDownItem = (item: DropDownListItem) => {
    setSelectedItem(item);
    onSelect(item);
  };

  useEffect(()=>{ if (value=='-1') setSelectedItem(undefined)},[value])
  return (
      <>
          <div
            className={cn( "drop-down", `${add_class}`, `drop-down--${type}`,  `drop-down--${size}`, `drop-down--${align}`, {  "drop-down--show": openDropDown, "drop-down-selected": isSelected},)}
            onClick={() => clickDropDown()}
          >
              <div className="drop-down__header" >
                { title  && selectedItem && selectedItem.id ? (showTitleWhenSelect ? title: '') +  (selectedItem.id ?  (showTitleWhenSelect ? ': ':'') + selectedItem.text : '') : title}
                { !title && selectedItem ? selectedItem.text : '' }
                { list.length > 0 && <ReactSVG src={optionToggler} className="drop-down__toggler" />}
              </div>

          </div>
          {openDropDown && <SelectValueModal value={selectedItem} onCancel={()=>setOpenDropDown(false)}
                                             placeholder={title} name={title} list={list}
                                             onSelect={(item) => {
                                               clickDropDownItem(item);
                                               setOpenDropDown(false)
                                             }}  need_input={canEnterValue}/>

          }
  </>
  );
};
