import React, { useEffect, useState } from 'react'
import {
  AddressSuggestions,
  DaDataAddress,
  DaDataSuggestion
} from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'
import { daDataToken } from '../../api/api.constants'
import { ClearIcon } from '../../assets/icons/ClearIcon'
import { StyledInnInput } from '../Input/Input.styled'
import { StyledAddress } from './Address.styled'
import { IAddressProps } from './Address.types'
export const Address = ({
  placeholder,
  onChange,
  defaultValue
}: IAddressProps) => {
  const [value, setValue] = useState<
    DaDataSuggestion<DaDataAddress> | undefined
  >(undefined)
  let ref = React.createRef<AddressSuggestions>()
  
  useEffect(() => {
    ref.current?.setInputValue(value?.value || defaultValue)
  }, [defaultValue, value, ref])

  const onChangeHandler = (
    selected: DaDataSuggestion<DaDataAddress> | undefined
  ) => {
    setValue(selected)
    onChange(selected?.value)
    ref.current?.setInputValue(selected?.value)
  }
  const clear = () => {
    onChangeHandler(undefined)
  }
  return (
    <StyledAddress>
      <AddressSuggestions
      ref = {ref}
        token={daDataToken}
        value={value}
        onChange={onChangeHandler}
        inputProps={{ placeholder: placeholder || 'Адрес' }}
        customInput={StyledInnInput}
        defaultQuery={defaultValue}
      />
      <ClearIcon onClick={() => clear()} />
    </StyledAddress>
  )
}
