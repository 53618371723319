import React, { FC, useState, memo } from 'react'

import { AdvancedInputElement } from './AdvancedInputElement'
import { ControllButtons } from './Buttons/ControllButtons'
import { StartedButton } from './Buttons/StartedButton'
import s from './InputAddressModal.module.scss'
import {ContactPersons, RouteAddress} from "../CreateOrderType";

interface IProps {
    address: RouteAddress,
    onSave: (address:RouteAddress)=> void;
    onDelete?: ()=> void
}

export const AdvancedSettings: FC<IProps> = memo(({ address, onSave, onDelete }) => {

    const [currentAddress, setCurrentAddress] = useState<RouteAddress>(address),
    [submit, setSubmit] = useState<boolean>(false);

    const getPersonName = () => currentAddress.contact_persons.length? currentAddress.contact_persons[0].full_name : '';
    const getPhone = () => currentAddress.contact_persons.length? currentAddress.contact_persons[0].phone : '';
    const getWorkingHours = () : {from: string, to: string} | string =>   {
        if (!currentAddress.working_hours.time_from && !currentAddress.working_hours.time_to) return '';
        return {from: currentAddress.working_hours.time_from, to: currentAddress.working_hours.time_to};
    };

    const getLunchHours = () : {from: string, to: string} | string =>   {
        if (!currentAddress.working_hours.lunch_from && !currentAddress.working_hours.lunch_to) return '';
        return {from: currentAddress.working_hours.lunch_from, to: currentAddress.working_hours.lunch_to}
    };


    const setPersonName = (value: string) => {
        let address = {...currentAddress};
        if (!address.contact_persons.length)
            address.contact_persons = [ new ContactPersons()];
        address.contact_persons[0].full_name = value;
        setCurrentAddress(address)
    };
    const setPhone = (value: string) => {
        let address = {...currentAddress};
        if (!address.contact_persons.length)
            address.contact_persons = [ new ContactPersons()];

        address.contact_persons[0].phone = value;
        setCurrentAddress(address)
    };
    const setWorkingHours = (value: {from: string, to: string}) => {
        let address = {...currentAddress, working_hours: {...currentAddress.working_hours}};
        address.working_hours.time_from = value.from;
        address.working_hours.time_to = value.to;
        setCurrentAddress(address)
    };
    const setLunchHours = (value: {from: string, to: string}) => {
        let address = {...currentAddress, working_hours: {...currentAddress.working_hours}};
        address.working_hours.lunch_from = value.from;
        address.working_hours.lunch_to = value.to;
        setCurrentAddress({...address})
    };

    const setValue= (field: string, value : any) =>{
        let tmp = {...currentAddress};
        if (tmp.hasOwnProperty(field)) {
            //@ts-ignore
            tmp[field] = value;
            setCurrentAddress( tmp );

        }
    }

    return (
        <div className={s.advancedSettingsWrapper}>
            <div className={s.header}>
                Расширенные настройки
            </div>
            <AdvancedInputElement text='Добавить контактное лицо'
                                  altText='Контактное лицо'
                                  values={getPersonName()}
                                  setValues={setPersonName}
                                  name={elementsEnum.person}
                                  placeholder="ФИО" type="text" />

            <AdvancedInputElement text='Указать номер телефона' altText='Контактный номер'
                                  values={getPhone()}
                                  setValues={setPhone}
                                  name={elementsEnum.phone} placeholder="Укажите номер телефона" type="phone" />

            <div className={s.multipleInputWrapper}>
                <AdvancedInputElement text='Время работы' altText='Время работы'
                                      values={getWorkingHours()}
                                      setValues={setWorkingHours}
                                      name={elementsEnum.workTime}
                                      placeholder="Время работы" type="date" />
                <AdvancedInputElement text='Указать перерыв' altText='Указать перерыв'
                                      values={getLunchHours()}
                                      setValues={setLunchHours}
                                      name={elementsEnum.lunchTime}
                                      placeholder="Указать перерыв" type="date" />
            </div>
            <AdvancedInputElement text='Добавить комментарий к адресу' altText='Комментарий к адресу'
                                  values={currentAddress.adress_comment}
                                  setValues={(value)=>setValue('adress_comment', value)}
                                  name={elementsEnum.addressComment} placeholder="7 подъезд" type="text" />

            <div className={s.multipleInputWrapper}>
                <AdvancedInputElement text='От кого' altText='От кого' values={''} setValues={(value) => {}} name={elementsEnum.fromWho} placeholder="От кого" type="text" />
                <AdvancedInputElement text='Кому' altText='Кому' values={''} setValues={(value) => {}} name={elementsEnum.toWhom} placeholder="Кому" type="text" />
            </div>
            <AdvancedInputElement text='Что нужно сделать на точке' altText='Задание на точке'
                                  values={currentAddress.what_to_do}
                                  setValues={(value)=>setValue('what_to_do', value)}
                                  name={elementsEnum.whatToDo} placeholder="Что нужно сделать на точке" type="text" />
            <div className={s.saveDeleteButtonContainer}>
            {
                onDelete ? <ControllButtons onSaveControllButtonClick={()=>onSave(currentAddress)} onDeleteControllButtonClick={onDelete} /> :
                    <StartedButton onStartedButtonClick={()=>onSave(currentAddress)} submit={submit} /> }

            </div>
        </div>
    )
})


export enum elementsEnum {
    person = 'person',
    phone = 'phone',
    workTime = 'workTime',
    lunchTime = 'lunchTime',
    addressComment = 'addressComment',
    fromWho = 'fromWho',
    toWhom = 'toWhom',
    whatToDo = 'whatToDo'
}

