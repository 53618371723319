import styled from 'styled-components'
import { IStyledOrderRowCarProps } from '../Order/Order.types'


export const StyledOrderRowCar = styled.div<IStyledOrderRowCarProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 12px 0;
  border-bottom: ${props => (props.isNeedBorderBottom ? '1px solid var(--medium-grey)' : '')};
  background-color: ${props => (props.isGrey ? 'var(--light-grey)' : '#fff')};
   
`

export const StyledOrderCar = styled.div``
export const StyledOrderCarModel = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  color: var(--grey);
  text-align: center;
`

export const StyledOrderCarModelRight = styled(StyledOrderCarModel)`
  text-align: right;
`

export const StyledOrderCarNumber = styled.span`
  font-weight: 400;
  font-size: 14px;
`
