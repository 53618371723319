import {SendOrderInfo} from "../../modules/Home/components/NewUserOrder/CreateOrderType";
import {IOrderFull} from "../../modules/Orders/components/Order/Order.types";

export interface IRatingWindowProps{
    orderId: string,
    orderStatus: string,
    orderAmount: number,
    driverFullName: string,
    order: IOrderFull,
}

export interface IRatingWindowMistakeListProps {
    mistakes: string[];
    onClickMistake: (mistake: string) => void;
  }

  export interface IRatingWindowMistakeListItemProps {
    message: EnumRatingMessage;
    onClickMistake: () => void;
    color? : string
  }

  export interface IStyledRatingWindowMistakeListItemProps {
    color? : string
  }

export enum EnumRatingMessage {
    late = "Опоздал",
    didNotHelp = "Не помог",
    wasRude = "Был груб",
  }
