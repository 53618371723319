import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/redux/store'

export interface ModalState {
  isModalOpened: boolean
  title: string
  children: string | null,
  isNotNeedTitle?: boolean
}

const initialState: ModalState = {
  isModalOpened: false,
  children: null,
  title: '',
  isNotNeedTitle: false
}

export const modalSelector = (state: RootState) => state.modal

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal (state, action) {
      state.isModalOpened = true
      state.title = action.payload.title
      state.children = action.payload.children
      state.isNotNeedTitle = action.payload.isNotNeedTitle
    },
    closeModal (state) {
      state.isModalOpened = false
      state.title = ''
      state.children = null
      state.isNotNeedTitle = false
    }
  }
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer
