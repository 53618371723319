import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import s from './HeaderOrdersButton.module.scss'
import {openModalWithTitle as openModalFullWithTitle} from "../../../../components/ModalFull/modalFullSlice";
import {useDispatch} from "react-redux";

export const HeaderOrdersButton: FC<propTypes> = () => {
    const  dispatch = useDispatch();

    const onOrdersClick = () => {
        dispatch(
            openModalFullWithTitle({ children: 'OrderList', title: 'Журнал заказов' })
        )
    }

    return (
        <>
    <button className={s.ordersButton} onClick={onOrdersClick}>
        Заказы
    </button>
        </>
    )
}


type propTypes = {}
