
class HistoricalDataQueue{
    protected sizeQueue:number = 0;
    protected historicalData:number[] = [] ;
    protected maxValues:{max_distance_in_meters:number, count: number}[] = [] ;
    constructor(sizeQueue:number, maxValues:{max_distance_in_meters:number, count: number}[]) {
        this.sizeQueue = sizeQueue;
        this.maxValues = maxValues;
    }

    append = (val:number) => {
        if ((this.historicalData.length +1) >= this.sizeQueue)
            this.historicalData.shift()
        this.historicalData.push(val);
    }
    clear = () => this.historicalData = [];

    checkIsNeedNewRoute = () => {
        let arr = this.maxValues.map(x=> ({...x}));
        for (let i= 0; i< arr.length; i++) {
            let len = this.historicalData.filter(x=> x > arr[i].max_distance_in_meters).length;
            if (len >= arr[i].count) return true;
        }
        return false;
    }

}

export default HistoricalDataQueue