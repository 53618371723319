import styled from "styled-components";
import {IStyledButtonProps, ButtonType, ButtonBackgroundColor, ButtonHeight, ButtonColor} from './Button.types' 

export const StyledButton = styled.button<IStyledButtonProps>`
  width: ${({ buttonType }) => !buttonType ? '100%' :
    buttonType === ButtonType.FULL_WIDTH
      ? "363px"
      : buttonType === ButtonType.HALF_WIDTH
      ? "343px"
      : "172,5px"};
  background-color: ${({ buttonBackgroundColor }) =>
    buttonBackgroundColor === ButtonBackgroundColor.BLUE
      ? ButtonBackgroundColor.BLUE
      : buttonBackgroundColor === ButtonBackgroundColor.DARK_GREY
      ? ButtonBackgroundColor.DARK_GREY
      : buttonBackgroundColor === ButtonBackgroundColor.VIOLET
      ? ButtonBackgroundColor.VIOLET
      : buttonBackgroundColor === ButtonBackgroundColor.GREEN
      ? ButtonBackgroundColor.GREEN
      : ButtonBackgroundColor.RED_GRADIENT};
  background-image: ${props=>props.buttonBackgroundColor === ButtonBackgroundColor.RED_GRADIENT ?ButtonBackgroundColor.RED_GRADIENT: ButtonBackgroundColor.BLUE} ;
  border: none;
  border-radius: 8px;
  height: ${({buttonHeight}) => buttonHeight === ButtonHeight.MAX_HEIGHT ? ButtonHeight.MAX_HEIGHT 
  : buttonHeight === ButtonHeight.MID_HEIGHT ? ButtonHeight.MID_HEIGHT : ButtonHeight.MIN_HEIGHT};
  color: ${({buttonColor}) => buttonColor === ButtonColor.WHITE ? ButtonColor.WHITE : ButtonColor.RED};
  font-size: 17px;
`;



export const StyledLinkBack = styled.div`
    color: #ff743c;
    text-decoration: underline;
    padding: 20px 0 ;
    text-align: center;
`;

export const StyledButtonBottomBlock2Button = styled.div`
    display: flex;
`;

export const StyledButtonBottomBlock = styled.div`
padding: 33px 24px 19px 24px;

bottom: 0;
width: 100%;
display: flex;
grid-gap: 10px;
flex-direction: column;
`
