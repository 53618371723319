import {
  EnumRatingMessage,
  IRatingWindowMistakeListProps,
} from "../RatingWindow.types";
import RatingWindowMistakeListItem from "../RatingWindowMistakeListItem";

const RatingWindowMistakeList = ({
  mistakes,
  onClickMistake,
}: IRatingWindowMistakeListProps) => {
  return (
    <>
      {Object.entries(EnumRatingMessage).map((item, index) => {
        const [i, mistake] = item;
        const colorMistakeSvg =
          mistakes && mistakes.indexOf(mistake, 0) === -1
            ? undefined
            : "var(--red)";

        return (
          <RatingWindowMistakeListItem
            key={`${index}_mistake`}
            message={mistake}
            onClickMistake={() => onClickMistake(mistake)}
            color={colorMistakeSvg}
          />
        );
      })}
    </>
  );
};
export default RatingWindowMistakeList;
