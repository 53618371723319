import {KALMAN_ROUTE_DRIVER_LINE_CAR, mapMarker, ROUTE_DRIVER_LINE, ROUTE_DRIVER_LINE_CAR} from "./hereMapTypes"
import hereMarkerIcon from "./hereMarkerIcon"
import  turf  from "turf"
import {TypeLonLat} from "../../modules/Home/components/NewUserOrder/CreateOrderType";
import {hereMap} from "./HereMap";
import {KalmanFilterGPS} from "./KalmanFilterGPS";
import {CONST_TIME_1C} from "../../api/api.constants";


const H = window.H;
let oldWaypointsString = '';


// функция создает маркер

export const hereMarker = (coordination: number[], marker: mapMarker = new mapMarker()) => {
    let text = `<span>${marker.title}</span><br/><span>${marker.text}</span>`
    let markerItem = new H.map.DomMarker({ lat: coordination[0], lng: coordination[1] }
      , {
        //@ts-ignore
        volatility: true,
        icon: new H.map.DomIcon(hereMarkerIcon(marker.type, text, marker.bg_color, marker.is_show_text))
      })

    markerItem.draggable = true

    markerItem.setData(marker.index)

    return markerItem
}

// функция добавляет поведение перетаскивания маркеров на карте

export const setDraggable = (map: H.Map, behavior: H.mapevents.Behavior, onMarkerClick: (index: number) => void, onClick: (lon: number, lat: number) => void ) => {

    map.addEventListener('dragstart', (ev: any) => {
      const target = ev.target
      if (target instanceof H.map.Marker || target instanceof H.map.DomMarker) {
        behavior.disable()
      }
    }, false)

    map.addEventListener('dragend', (ev: any) => {
      const target = ev.target
      if (target instanceof H.map.Marker || target instanceof H.map.DomMarker) {
        behavior.enable()
        onMarkerClick(target.getData())
        //@ts-ignore
        const cords: { lat: number, lng: number, alt?: any, ctx?: any } = {...target.getGeometry()}
        onClick(cords.lat, cords.lng)
      }
    }, false)

    map.addEventListener('drag', (ev: any) => {
      const target = ev.target,
        pointer = ev.currentPointer
      if (target instanceof H.map.Marker || target instanceof H.map.DomMarker) {
        target.setGeometry(map.screenToGeo(pointer.viewportX, pointer.viewportY + 20))
      }
    }, false)
}

// функция отрисовывает примерную линию маршрута

export const clearRouteInfo = (hereMap:any) => {
    oldWaypointsString = "";
    hereMap.removeObjects(hereMap.getObjects().filter((obj: H.map.Object) => !(obj instanceof window.H.map.DomMarker || obj instanceof window.H.map.Marker)));
    removeObjectFromMap(hereMap, ROUTE_DRIVER_LINE);
}
export const drawWayOnMap = (points: Array<{lon: number, lat: number}>, color: string  = 'orange', width: number = 4, hMap: any) => {
    let newWaypointsString = JSON.stringify(points.map(x=> x.lat + x.lon))
    if ( oldWaypointsString === newWaypointsString) return

    oldWaypointsString = newWaypointsString

    newWaypointsString = ''

    // hMap.removeObjects(hMap.getObjects().filter( (obj: any) => !(obj instanceof window.H.map.DomMarker || obj instanceof window.H.map.Marker)))

    var lineString = new H.geo.LineString()

    points.forEach( point => lineString.pushPoint({lat:point.lat, lng:point.lon}))
    let p =new H.map.Polyline( lineString, { style: { lineWidth: width, strokeColor: color }} )
    hMap.addObject(p);
    setCenterRoute(p, hMap);
}

const setCenterRoute = (p:H.map.Polyline, hMap: any) => {
    hMap.getViewModel().setLookAtData({
        bounds:  p.getBoundingBox()
    });
    setTimeout(()=>{
        let p = hMap.getViewModel().getLookAtData().position;
        let zoom = hMap.getZoom();

        if (p) p.lat -= 0.005;
        hMap.getViewModel().setLookAtData({
            position:  p,
            zoom : zoom -  zoom*0.06
        });
        // map.setZoom(zoom -  zoom*0.05 );
    }, 0)
}

//  функция контейнер при создании вычисляемого средствами HERE maps маршрута
export const is_way_point_changed = (waypoints: Array<string>) =>  (oldWaypointsString !== JSON.stringify(waypoints));

export const calculateRoute = (platform: any, waypoints: Array<string>, hMap: H.Map, color: string | undefined = "orange", width: number | undefined = 1) => {

    if (waypoints.length < 2  || !is_way_point_changed(waypoints)) return;
    oldWaypointsString = JSON.stringify(waypoints);

    var router = platform.getRoutingService(null, 8),

        routeRequestParams = {
            routingMode: 'fast',
            transportMode: 'car',
            origin: waypoints.shift() as string,
            destination: waypoints.pop() as string,
            return: 'polyline,travelSummary'
        };

    if (waypoints.length > 1) {
        //@ts-ignore
        routeRequestParams['via'] = new H.service.Url.MultiValueQueryParameter(
            waypoints
        );
    } else if (waypoints.length === 1) {
        //@ts-ignore
        routeRequestParams['via'] = waypoints.pop();
    }


    console.log('calculateRoute routeRequestParams', routeRequestParams)
    router.calculateRoute( routeRequestParams, (result: H.service.ServiceResult) => {
          addRouteShapeToMap(result.routes[0], hMap, color, width)
      },
      (d: Error) => {
        console.error(d)
      }
    )
}

// функция добавляет вычисляемого средствами HERE maps маршрута на карту

export const addRouteShapeToMap = (route: H.service.ServiceResult, hMap: H.Map, color: string = 'orange', width: number = 1) => {
    var group = new H.map.Group();

    route.sections.forEach((section: H.service.ServiceResult) => {
        group.addObject(
            new H.map.Polyline(
                // @ts-ignore
                new H.geo.LineString.fromFlexiblePolyline(section.polyline), {
                    style: {
                        lineWidth: width,
                        strokeColor: color
                    }
                }
            )
        )
    })

    hMap.addObject(group);
    setCenterRoute(group, hMap)
};


export const getDistanceLine = (pointA: number[], line: [number[]] ) => {
    if (!pointA || pointA.length < 2 || !line || line.length < 2)
        return 0;

    const from = turf.point( pointA  );
    const way = turf.lineString( line );
    const to = turf.point( line[line.length-1] );
    var sliced = turf.lineSlice(from, to, way);
    let distance = turf.lineDistance(sliced,   "metres" );
    return distance;
};

export const getDistanceP2P = (pointA: number[], pointB: number[]  ) => {
    if (!pointA || pointA.length < 2 || !pointB || pointB.length < 2)
        return 0;
    const from = turf.point( pointA  );
    const to = turf.point( pointB );
    let distance = turf.distance(from, to,   "metres" );
    return distance;
};

export const getNearestPointOnLine = (pointA: number[], line: [number[]] ) => {
    let r = 1000000000, i = 0;
    line.forEach((x,index)=> {
        let d = getDistanceP2P(pointA, x);
        if (r > d) {
            r = d;
            i=index;
        }
    })
    return [ line[i], r, i ];
}

export function removeObjectFromMap( map: H.Map, nameObject:string) {

    let listObj : H.map.Object[] = map.getObjects() as H.map.Object[];

    let objects: H.map.Object[] = listObj.filter( (obj : H.map.Object) => {

        // @ts-ignore
        if (obj.data && obj.data.name === nameObject) return true;
        return  false;
    });
    if (objects && objects.length)
        map.removeObjects(objects);
}

// Converts from degrees to radians.
function toRadians(degrees:number) {
    return degrees * Math.PI / 180;
};

// Converts from radians to degrees.
function toDegrees(radians:number) {
    return radians * 180 / Math.PI;
}

export function getBearing(start: TypeLonLat, end: TypeLonLat){
    let startLat = toRadians(start.lat);
    let startLng = toRadians(start.lon);
    let destLat = toRadians(end.lat);
    let destLng = toRadians(end.lon);

    let y = Math.sin(destLng - startLng) * Math.cos(destLat);
    let x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = toDegrees(brng);
    return (brng + 360) % 360;
}


export const createDomMarker = (coordination:number[], index :number|string = 1, icon:string, marker_type: string, marker_text: string = '', draggable:boolean = false) => {
    let marker = new H.map.DomMarker({lat: coordination[0], lng: coordination[1]}
        , {
            // @ts-ignore
            volatility: true,
            icon: new H.map.DomIcon(  icon ),
            data: {name: marker_type, text :  marker_text}
        });
    // @ts-ignore
    marker.draggable = draggable;
    // @ts-ignore
    marker.itemIndex = +index;
    return marker;
}

export const createAsyncDomMarker = async (coordination:number[], index :number|string = 1, icon:string, marker_type: string, marker_text: string = '', draggable:boolean = false) => {

    hereMap.setCenter({lat: coordination[0], lng: coordination[1]});
    var htmlElement:HTMLImageElement | undefined = undefined;
    let marker = new H.map.DomMarker({lat: coordination[0], lng: coordination[1]}
        , {
            // @ts-ignore
            volatility: true,
            icon: new H.map.DomIcon(  icon , {
                onAttach: function(clonedElement: Element, domIcon: any, domMarker:any) {
                    htmlElement = clonedElement.getElementsByTagName('img')[0];
                }
            }),
            data: {name: marker_type, text :  marker_text}
        });
    // @ts-ignore
    marker.draggable = draggable;
    // @ts-ignore
    marker.itemIndex = +index;
    hereMap.addObject(marker);
    return new Promise( (resolve, reject) => {
        let cnt = 0;
        let interval = setInterval(()=>{
            if (cnt>100) {clearInterval(interval); reject();}
            if (!htmlElement) return;
            marker.htmlElement = htmlElement;
            resolve(marker);
        }, 10)
    });
}




export const decodeHerePolyline = (str:string) => {
    //@ts-ignore
    return H.util.flexiblePolyline.decode(str).polyline
}

export const rotateDomMarker = (marker: any, bearing: number) => {
    if (marker && marker.htmlElement)
        marker.htmlElement.style.transform = 'rotate(' + bearing + 'deg)';
}

/**
 * Ease function
 * @param   {H.geo.IPoint} startCoord   start geo coordinate
 * @param   {H.geo.IPoint} endCoord     end geo coordinate
 * @param   number durationMs           duration of animation between start & end coordinates
 * @param   function onStep             callback executed each step
 * @param   function onStep             callback executed at the end
 */
export function ease(
    startCoord = {lat: 0, lng: 0},
    endCoord = {lat: 1, lng: 1},
    durationMs = 200,
    onStep = console.log,
    onComplete = function() {},

) {
    var raf = window.requestAnimationFrame || function(f) {window.setTimeout(f, 16)},
        stepCount = durationMs / 16,
        valueIncrementLat = (endCoord.lat - startCoord.lat) / stepCount,
        valueIncrementLng = (endCoord.lng - startCoord.lng) / stepCount,
        sinValueIncrement = Math.PI / stepCount,
        currentValueLat = startCoord.lat,
        currentValueLng = startCoord.lng,
        currentSinValue = 0;

    function step() {
        currentSinValue += sinValueIncrement;
        currentValueLat += valueIncrementLat * (Math.sin(currentSinValue) ** 2) * 2;
        currentValueLng += valueIncrementLng * (Math.sin(currentSinValue) ** 2) * 2;

        if (currentSinValue < Math.PI) {
            onStep({lat: currentValueLat, lng: currentValueLng});
            raf(step);
        } else {
            onStep(endCoord);
            onComplete();
        }
    }

    raf(step);
}


/**
 * Ease function
 * @param   {H.geo.IPoint} startCoord   start geo coordinate
 * @param   {H.geo.IPoint} endCoord     end geo coordinate
 * @param   number durationMs           duration of animation between start & end coordinates
 * @param   function onStep             callback executed each step
 * @param   function onStep             callback executed at the end
 */
export function move_car(
    startCoord = {lat: 0, lng: 0},
    endCoord = {lat: 1, lng: 1},
    durationMs = 200,
    onStep = console.log,
    onComplete = function() {},

) {
    var raf = window.requestAnimationFrame || function(f) {window.setTimeout(f, 16)},
        stepCount = durationMs / 16,
        valueIncrementLat = (endCoord.lat - startCoord.lat) / stepCount,
        valueIncrementLng = (endCoord.lng - startCoord.lng) / stepCount,
        currentValueLat = startCoord.lat,
        currentValueLng = startCoord.lng,
        counterIncrement = 0;

    function step() {
        counterIncrement++;
        currentValueLat += valueIncrementLat;
        currentValueLng += valueIncrementLng;

        if (counterIncrement < stepCount) {
            onStep({lat: currentValueLat, lng: currentValueLng});
            raf(step);
        } else {
            onStep(endCoord);
            onComplete();
        }
    }

    raf(step);
}

export function drawDriverRouteToMap(shape: any[], map:H.Map, isDriverPosition:boolean, filter: KalmanFilterGPS){
    if (!isDriverPosition) removeObjectFromMap(map, ROUTE_DRIVER_LINE);
    // if (isDriverPosition) removeObjectFromMap(map, ROUTE_DRIVER_LINE_CAR);
    // console.log('drawDriverRouteToMap shape=', shape)
    if (!shape || shape.length < 2 ) return;

    var lineString = new H.geo.LineString();

    shape.forEach( (x:any) => {

        if (isDriverPosition)
            lineString.pushPoint({lat: x.lat, lng: x.lon})
        else
            lineString.pushPoint({lat: x[0], lng: x[1]});
    });

    let p = isDriverPosition ?
        new H.map.Polyline( lineString, { data:{name: ROUTE_DRIVER_LINE_CAR}, style: { lineWidth: 2, strokeColor: 'rgba(255, 0, 255, 0.8)'}}) :
        new H.map.Polyline( lineString, { data:{name: ROUTE_DRIVER_LINE}, style: { lineWidth: 6, strokeColor: 'rgba(0, 255, 255, 0.8)'}});
    map.addObject(p);

    // if (isDriverPosition) {
    //     let kalmanPoints = shape.map( x=> filter.process(x.lat, x.lon, 0.3, +x.date_utc - CONST_TIME_1C));
    //     var kalmanLineString = new H.geo.LineString();
    //     kalmanPoints.forEach( (x:any) => lineString.pushPoint({lat: x[0], lng: x[1]}));
    //     p = new H.map.Polyline( lineString, { data:{name: KALMAN_ROUTE_DRIVER_LINE_CAR}, style: { lineWidth: 2, strokeColor: 'rgba(5,180,23,0.8)'}});
    //     map.addObject(p);
    // }
}
