import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiDriverUrl } from "./api.constants";
import {OrderCarPositionsResponse} from "./orderApi";


export const driverApi = createApi({
  reducerPath: "driverApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDriverUrl,
  }),
  endpoints: (builder) => ({
    getOrderCarPositions: builder.mutation<OrderCarPositionsResponse[], string>({
      query: id => 'car-position?order_id='+id
    }),

  }),
});

export const {
  useGetOrderCarPositionsMutation
} = driverApi;
