import { createSlice } from '@reduxjs/toolkit'
import { apiOrderUrl } from '../../../../api/api.constants'
import { AppDispatch, RootState } from '../../../../app/redux/store'
import { IProfile, IProfileItem } from './Profile.types'
import { getProfileItems, getProfileItemsWithCompany } from './profileHelper'

export interface profileState {
  profile: IProfile
  profileItems: IProfileItem[]
  selectedCompanyInn: string | undefined
}

const initialState: profileState = {
  profile: { users: [], companies: [] },
  profileItems: [],
  selectedCompanyInn: undefined
}
export const profileSelector = (state: RootState) => state.profile
export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile (state, action) {
      const profile = action.payload
      state.profile = profile
      state.profileItems = getProfileItems(profile)
    },
    setProfileWithSelectedCompanyInn (state, action) {
      const { profile, selectedCompanyInn } = action.payload
      state.profile = profile
      state.selectedCompanyInn = selectedCompanyInn
      state.profileItems = getProfileItemsWithCompany(
        profile,
        selectedCompanyInn
      )
    },
    clear (state) {
      state.profile = { users: [], companies: [] }
      state.profileItems = []
      state.selectedCompanyInn = undefined
    }
  }
})

export const {
  setProfile,
  clear,
  setProfileWithSelectedCompanyInn
} = profileSlice.actions

export const setProfileById = async ( clientId: string | undefined, dispatch: AppDispatch, selectedCompanyInn?: string | undefined ) => {
  if (!clientId) return
  const response = await fetch( `${apiOrderUrl}/clients?client_id=${clientId}`).then(res => res.json())
  dispatch(
    selectedCompanyInn
      ? setProfileWithSelectedCompanyInn({
          profile: response,
          selectedCompanyInn: selectedCompanyInn
        })
      : setProfile(response)
  )
}

export default profileSlice.reducer
