import {useAppDispatch, useAppSelector} from "../../../../app/redux/hooks";
import {
    setSelectedCompanyId,
    setUserProfile,
    setVisibleUserProfileForm,
    userProfileSelector
} from "./userProfileSlice";
import {CCompany, CEditOneRecordData, CUser, CUserProfile, EnumEditOneRecordType} from "./userProfile.type";
import {useEffect, useState} from "react";
import {useClientsProfileMutation} from "../../../../api/orderApi";
import './UserProfile.css'
import ProfileMenuItem from "./ProfileMenuItem";
import ProfileEditOneRecord from "./ProfileEditOneRecord";
import ProfileCompanyInfo from "./ProfileContractInfo";
import ProfileBankInfo from "./ProfileBankInfo";
import ProfileAddUser from "./ProfileAddUser";
import {useCookies} from "react-cookie";
import {cookiesClientId, cookiesDefaultUserId, cookiesOrderId} from "../../../../api/api.constants";
import {openInfoMessage} from "../../../../components/InfoMessage/infoMessageSlice";
import {InfoMessageType} from "../../../../components/InfoMessage/InfoMessage.types";
import {ArrowLeftIcon} from "../../../../assets/icons/ArrowLeftIcon";

interface IProps {
    companyId:string;
    userIndex:number;
}
const ProfileUserInfo : React.FC<IProps> = ({companyId, userIndex}) =>{

    const dispatch = useAppDispatch();
    const [cookies, setCookie] = useCookies([cookiesDefaultUserId]);
    const current_id = cookies.selectedUserId;
    const userProfile = useAppSelector(userProfileSelector);
    let companyIndex = userProfile.profile.companies.findIndex(x=> x.id == companyId);
    if (companyIndex<0) companyIndex = 0;
    // const companyIndex = current_id && userProfile.profile.companies.some(x=>x.id == current_id) ?
    //                                     userProfile.profile.companies.findIndex(x=>x.id == current_id) :
    //                      userProfile.selectedCompanyInn  && userProfile.profile.companies.some(x=>x.tin == userProfile.selectedCompanyInn) ?
    //                          userProfile.profile.companies.findIndex(x=>x.tin == userProfile.selectedCompanyInn) : 0 ;
    // const userIndex = 0;
    const company : CCompany = userProfile.profile.companies.length ? userProfile.profile.companies[companyIndex] :  new CCompany();
    const user : CUser = userProfile.profile.users.length ? userProfile.profile.users[userIndex] :  new CUser();


    const [isEditItem, setEditItem] = useState<CEditOneRecordData|undefined>(undefined);
    const [isLoadingItem, setLoadingItem] = useState<EnumEditOneRecordType>(EnumEditOneRecordType.none);
    const [isErrorText, setErrorText] = useState<{text:string, type: EnumEditOneRecordType} | undefined>(undefined);
    const [isShowFormContract, setShowFormContract] = useState<boolean>(false);
    const [isShowFormPerson, setShowFormPerson] = useState<boolean>(false);
    const [isShowFormEditPerson, setShowFormEditPerson] = useState<CUser|undefined>(undefined);
    const [isShowFormBanking, setShowFormBanking] = useState<number>(-1);
    const [profile] = useClientsProfileMutation();

    const updateData = (data : CUserProfile, loadingType: EnumEditOneRecordType, inn="") => {
        setEditItem(undefined);
        setLoadingItem(loadingType);
        profile(data)
            .unwrap()
            .then((res)=> {
                setLoadingItem(EnumEditOneRecordType.none);
                console.log('updateData res', res);
                if (!res || typeof res != 'object' ) return setErrorText({text: 'Ошибка сохранения данных', type:loadingType});
                if (res.status=='error') return setErrorText({text: res.error_message, type:loadingType});
                dispatch( setUserProfile( data) );
            })
            .catch(e=>{ setLoadingItem(EnumEditOneRecordType.none); return setErrorText({text: 'Ошибка сохранения данных', type:loadingType});});
    };

    const getProfileNewUser = (new_user_data: CUser) => {
        let profile : CUserProfile = {...userProfile.profile, users:[...userProfile.profile.users], companies:[...userProfile.profile.companies]};
        profile.users = [...profile.users.filter((x,index)=>index != userIndex), new_user_data];
        return profile;
    };

    const getProfileNewCompany = (new_company_data: CCompany) => {
        let profile : CUserProfile = {...userProfile.profile, users:[...userProfile.profile.users], companies:[...userProfile.profile.companies]};
        profile.companies = [...profile.companies.filter((x,index)=>index != companyIndex), new_company_data];
        return profile;
    };

    const onSetData = (value:any,type:EnumEditOneRecordType) =>{
        if (value !== undefined) {
            switch (type) {
                case EnumEditOneRecordType.email: updateData( getProfileNewUser({...user, email: value}), type ); break;
                case EnumEditOneRecordType.phone:  updateData( getProfileNewUser({...user, phone:value}) , type); break;
                case EnumEditOneRecordType.name:  updateData( getProfileNewUser({...user, full_name:value}) , type); break;
                case EnumEditOneRecordType.position:  updateData( getProfileNewUser({...user, position:value}) , type); break;
                case EnumEditOneRecordType.work_phone:  updateData( getProfileNewUser({...user, workPhone:value}) , type); break;
                case EnumEditOneRecordType.company_phone:  updateData( getProfileNewCompany({...company, phone:value}) , type); break;
                case EnumEditOneRecordType.company_email:  updateData( getProfileNewCompany({...company, email:value}) , type); break;
                case EnumEditOneRecordType.company_site:  updateData( getProfileNewCompany({...company, site:value}) , type); break;
                case EnumEditOneRecordType.company_address:  updateData( getProfileNewCompany({...company, postal_address: value}) , type); break;
                default: alert('Отсутсвует API для данного метода');
            }
        }
        setEditItem(undefined);
    };

    const onSetDefaultAccount = () =>{
        setCookie(cookiesDefaultUserId, company.id, { path: '/' });
        dispatch(
            openInfoMessage({
                title: "Выбор профиля компании по умолчанию",
                text: "Профиль компании " + company.name + " установлен в качестве текущего",
                type: InfoMessageType.SUCCESSESED,
            })
        );
        dispatch(setVisibleUserProfileForm(false))
    }
    const onUnSetDefaultAccount = () =>{
        setCookie(cookiesDefaultUserId, '', { path: '/' });
        dispatch(dispatch(setSelectedCompanyId('')))
    };

    useEffect(()=> {}, [userProfile.profile])
    let user_list : CUser [] = userProfile.profile.users.filter(x=> x.company_id == company.id);
    user_list.sort ( (a,b)=> { if (a.full_name >= b.full_name) return 1; return -1});
    return (
        <>
            <div className='back_arrow back_arrow_top' onClick={()=> dispatch(dispatch(setSelectedCompanyId('')))}>
                <ArrowLeftIcon  />
            </div>
            <div className="user_and_company_info company_info">
                <div className='name'>{company.name} <span>({company.tin})</span>
                    <div className='fio_name'>{user.full_name}</div>
                </div>
                <div className='menu-items'>
                    <ProfileMenuItem name={'Моб. телефон'} value={user.phone} is_arrow={true}
                                     append_class={'no-top-border'}
                                     is_loading={isLoadingItem == EnumEditOneRecordType.phone}
                                     onClick={()=>{
                                         setEditItem(new CEditOneRecordData('Мобильный телефон пользователя', '+79006498996', user.phone, onSetData, EnumEditOneRecordType.phone))}

                                     } />

                        <ProfileMenuItem name={user.workPhone ? 'Рабочий телефон': ''} value={user.workPhone ? 'Рабочий телефон': 'Добавить рабочий телефон'} is_arrow={user.workPhone != undefined && user.workPhone!=''}
                                         is_loading={isLoadingItem == EnumEditOneRecordType.work_phone}
                                         onClick={()=>{
                                             setEditItem(new CEditOneRecordData('Рабочий телефон пользователя', '+79006498996', user.workPhone ?  user.workPhone : '', onSetData, EnumEditOneRecordType.work_phone))}

                                         } />

                        <ProfileMenuItem name={user.email ? 'Email':''} value={user.email ? user.email:'Добавить Email'} is_arrow={user.email!=''}
                                     is_loading={isLoadingItem == EnumEditOneRecordType.email}
                                     onClick={()=>{
                                         setEditItem(new CEditOneRecordData('Email пользователя', 'Введите свой Email', user.email, onSetData, EnumEditOneRecordType.email))}
                                     } />

                        <ProfileMenuItem name={user.position ? 'Должность':''} value={user.position ? user.position :'Указать должность'} is_arrow={user.position!=''}
                                         is_loading={isLoadingItem == EnumEditOneRecordType.position}
                                         onClick={()=>{
                                             setEditItem(new CEditOneRecordData('Должность пользователя', 'Введите свою должность в компании', user.position, onSetData, EnumEditOneRecordType.position))}
                                         } />
                        <div className="separator"> Данные компании</div>
                        <ProfileMenuItem name={'Договор с компанией'} value={company.contract.number} is_arrow={true}
                                         onClick={()=> {
                                             setShowFormContract(true);
                                             console.log('Договор с компанией')
                                         } } />

                        <ProfileMenuItem name={company.bank && company.bank.BIK ? 'Реквизиты компании' : ''}
                                         value={company.bank && company.bank.BIK ? company.bank.bank.name + ' р/с ' + company.bank.account_number:'Реквизиты компании'} is_arrow={company.bank && company.bank.BIK != ''}
                                         is_loading={isLoadingItem == EnumEditOneRecordType.add_information}
                                         onClick={()=> setShowFormBanking(companyIndex)} />

                        <ProfileMenuItem name={company.postal_address !='' ? 'Почтовый адрес компании':''} value={company.postal_address ? company.postal_address : 'Добавить почтовый адрес'} is_arrow={company.postal_address != ''}
                                     is_loading={isLoadingItem == EnumEditOneRecordType.company_address}
                                     onClick={()=> setEditItem(new CEditOneRecordData('Почтовый адрес компании', 'Введите почтовый адрес компании', company.postal_address, onSetData,  EnumEditOneRecordType.company_address))} />

                        <ProfileMenuItem name={company.phone ? 'Телефон компании':''} value={company.phone ? company.phone :'Добавить телефон компании'} is_arrow={company.phone!=''}
                                         is_loading={isLoadingItem == EnumEditOneRecordType.company_phone}
                                         onClick={()=>{
                                             setEditItem(new CEditOneRecordData('Телефон компании', '+79006498996', company.phone, onSetData,  EnumEditOneRecordType.company_phone))}
                                         } />

                        <ProfileMenuItem name={company.email ? 'Email компании':''} value={company.email ? company.email :'Добавить Email компании'} is_arrow={company.email!=''}
                                         is_loading={isLoadingItem == EnumEditOneRecordType.company_email}
                                         onClick={()=>{
                                             setEditItem(new CEditOneRecordData('Email компании', 'Введите Email компании', company.email, onSetData,  EnumEditOneRecordType.company_email))}
                                         } />

                        <ProfileMenuItem name={company.site ? 'Сайт компании':''} value={company.site ? company.site :'Добавить сайт компании'} is_arrow={company.site!=''}
                                         is_loading={isLoadingItem == EnumEditOneRecordType.company_site}
                                         onClick={()=>{
                                             setEditItem(new CEditOneRecordData('Сайт компании', 'Введите адрес сайта компании', company.site, onSetData,  EnumEditOneRecordType.company_site))}
                                         } />
                        {user_list.length >0 &&
                            <div className="title-menu-item">Сотрудники компании</div>
                        }
                        {user_list.map((user, index) => <ProfileMenuItem key={'user_'+index} name={''} value={user.full_name} is_arrow={true}
                                                           is_loading={false}
                                                           onClick={()=> setShowFormEditPerson(user)} />
                                       )
                        }

                        <ProfileMenuItem name={''} value={'Добавить сотрудника'} is_arrow={false}
                                         is_loading={isLoadingItem == EnumEditOneRecordType.add_person}
                                         onClick={()=> setShowFormPerson(true)} />

                    { current_id != company.id && <button className='button_exit_profile first'  onClick={onSetDefaultAccount}>Назначить профиль по умолчанию</button> }
                    {
                        current_id != company.id ?
                        <div className="linkWrapper" onClick={() => { dispatch(dispatch(setSelectedCompanyId(''))) }}>вернуться назад</div> :
                            <button className='button_exit_profile '  onClick={onUnSetDefaultAccount}>Выйти из профиля компании</button>

                    }

                </div>
            </div>
            { isEditItem && <ProfileEditOneRecord value={isEditItem} />}
            {isShowFormContract && <ProfileCompanyInfo contract={company.contract} onClose={()=> setShowFormContract(false)} /> }
            {isShowFormBanking !=-1 && <ProfileBankInfo  company_index={isShowFormBanking} onClose={()=> setShowFormBanking(-1)} /> }
            {isShowFormPerson && <ProfileAddUser company_id={company.id}  user={new CUser()} onClose={()=> setShowFormPerson(false)} /> }
            {isShowFormEditPerson && <ProfileAddUser company_id={company.id} user={isShowFormEditPerson}  onClose={()=> setShowFormEditPerson(undefined)} /> }
        </>
    )
}

export default ProfileUserInfo;
