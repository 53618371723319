import styled from 'styled-components'

export const StyledInn = styled.div`
  position: relative;
    background: #fff
  & > div {
    border: none;
  }
  & > svg {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
  & > span.name {
    position: absolute;
    right: 4rem;
    top: 0.5rem;
    color: var(--grey);
    width: 40%;
    text-align: right;
  }
  input:focus,
  input: active {
    outline: none;
    border: none;
    box-shadow: none;
  }
`

export const StyledInnList = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 400;
  background:#fff;
  border: none;
  & > div {
    border: none;
  }
  & > span:last-child {
    color: var(--grey);
  }
`
