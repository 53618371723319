import { useAppDispatch } from '../../../../app/redux/hooks'
import { ArrowRightIcon } from '../../../../assets/icons/ArrowRightIcon'
import {
  StyledArticleFolderList,
  StyledArticleFolderListItem
} from './ArticleFolderList.styled'
import { IArticleFolderListProps } from './ArticleFolderList.types'
import { openModalWithTitle as openModalFullWithTitle, setTitle } from '../../../../components/ModalFull/modalFullSlice'
import { IArticleItem } from '../ArticleItem/ArticleItem.types'
import { setArticleList } from '../ArticleList/articleListSlice'

export const ArticleFolderList = ({ articles }: IArticleFolderListProps) => {
  const dispatch = useAppDispatch()

  const getChildrenArticles = (parentId: string) => {
    return articles.filter(item => item.parent === parentId)
  }

  const selectArticleFolder = (article: IArticleItem) => {
    const filteredArticles = getChildrenArticles(article.id)
    dispatch(
      setArticleList({ articles: filteredArticles, title: article.title })
    )
    dispatch(
      openModalFullWithTitle({ children: 'ArticleList', background: '#E5E5E5' })
    )
  }

  return (
    <StyledArticleFolderList>
      {articles
        .filter(article => article.is_folder)
        .map(article => (
          <StyledArticleFolderListItem
            key={article.id}
            onClick={e => selectArticleFolder(article)}
          >
            <span>{article.title}</span>
            <ArrowRightIcon />
          </StyledArticleFolderListItem>
        ))}
    </StyledArticleFolderList>
  )
}
