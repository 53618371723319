import styled from "styled-components";
import { InfoMessageType, IStyledInfoMessage } from "./InfoMessage.types";

export const StyledInfoMassage = styled.div<IStyledInfoMessage>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  z-index: 9999999999;
  top: 0;
  font-weight: bold;
  font-size: 22px;
  padding: 0px 30px;
  text-align: center;
  p:first-of-type {
    color: ${({ messageType }) =>
      messageType === InfoMessageType.FAILIED ? "var(--red)" : "var(--green)"};
    margin-bottom: 20px;
    font-size: 18px;
  }

  p:last-of-type {
    color: var(--black);
  }
`;
