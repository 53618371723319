import {
  StyledRaitinDetails,
  StyledRatingExecutor,
  StyledRatingMain,
  StyledRatingWindow,
  StyledRatingWindowContent,
  StyledRaitinHeader,
  StyledRaitinHeaderAmount,
  StyledRaitinMistakes,
  StyledRaitinStars,
} from "./RatingWindow.styled";
import { IRatingWindowProps } from "./RatingWindow.types";
import Button from "../Button";
import {
  ButtonBackgroundColor,
  ButtonColor,
  ButtonHeight,
  ButtonTypeEvent,
} from "../Button/Button.types";
import { ArrowRightIcon } from "../../assets/icons/ArrowRightIcon";
import { StarIcon } from "../../assets/icons/StarIcon";
import RatingWindowMistakeList from "./RatingWindowMistakeList/RatingWindowMistakeList";
import {useCalcOrderRequestAllMutation, useSaveOrdersRatingMutation} from "../../api/orderApi";
import { useCookies } from "react-cookie";
import { cookiesClientId } from "../../api/api.constants";
import { setIsShowBack } from "../../modules/Orders/components/Order/orderSlice";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  clear,
  ratingWindowSelector,
  setIsShowRatingWindow,
  setMistakes,
  setRating,
} from "./ratingWindowSlice";
import { DisabledDivForButton } from "../../modules/Home/components/Code/Code.styled";
import Overlay from "../../modules/Home/components/Overlay/Overlay";
import {PaymentDetails} from "../../modules/Home/components/NewUserOrder/EditNewOrder/PaymentDetails/PaymentDetails";
import React, {useEffect, useState} from "react";
import {CTariffData, SendOrderInfo} from "../../modules/Home/components/NewUserOrder/CreateOrderType";
import {
  getFullTariffArray,
  setTariffLoading,
  setTariffsData
} from "../../modules/Home/components/NewUserOrder/CreateOrderSlicer";
import {showErrorMessage} from "../InfoMessage/infoMessageSlice";
import {newUserOrderSelector} from "../../modules/Home/components/NewUserOrder/NewUserOrderSlicer";

const RatingWindow = ({
  driverFullName,
  orderId,
  orderAmount,
  orderStatus,
  order
}: IRatingWindowProps) => {
  const [cookies] = useCookies([cookiesClientId]);
  const { isShowRatingWindow, mistakes, rating } =  useAppSelector(ratingWindowSelector);
  const dispatch = useAppDispatch();
  const ready = () => {
    saveOrderRating({
      clientId: cookies.clientId,
      orderId: orderId,
      mistakes: mistakes,
    })
      .unwrap()
      .then((res) => {
        dispatch(setIsShowRatingWindow(false));
        dispatch(setIsShowBack(false));
        dispatch(clear());
      });
  };
  const currentOrder:any = order;
  const [saveOrderRating] = useSaveOrdersRatingMutation();
  const [isShowPaymentDetails, setShowPaymentDetails] = useState<boolean>(false);
  const [orderTariffs, setOrderTariffs] = useState<CTariffData[]>([]);
  const { tariffTypes } = useAppSelector(newUserOrderSelector);
  const [  calcOrderRequestAll  ] = useCalcOrderRequestAllMutation();

  const getStarsElement = () => {
    let stars: JSX.Element[] = [];
    const MAX_RATING = 5;

    for (let i = 0; i < MAX_RATING; i++) {
      const isOrange = rating >= i;
      stars.push(
        <StarIcon
          key={`${i}_starIcon`}
          color={isOrange ? "var(--orange)" : undefined}
          onClick={() => {
            dispatch(setRating(i));
          }}
        />
      );
    }

    return stars;
  };

  const onClickMistake = (mistake: string) => {
    if (!mistakes) return;

    const indexOfMistake = mistakes.indexOf(mistake, 0);
    let newMistakes = [...mistakes];

    if (indexOfMistake === -1)
      dispatch(setMistakes([...newMistakes, mistake]));
    else
      dispatch(setMistakes(newMistakes.filter(x=> x!=mistake)));

  };

  const goToShowOrder = () => {
    // dispatch(setIsShowBack(true));
    // dispatch(setIsShowRatingWindow(false));
    setShowPaymentDetails(true)
  };
  const loadData = async () => {
    console.log('calcOrderRequestAll')
    let x :any = await calcOrderRequestAll(currentOrder).unwrap();
    console.log('calcOrderRequestAll x',x, getFullTariffArray(x, tariffTypes))
    if (!x || x?.status == 'error')  return showErrorMessage('Ошибка расчета стоимости заказа', dispatch)
          // console.log('calcOrderRequestAll x',x, getFullTariffArray(x, tariffTypes))
    setOrderTariffs(getFullTariffArray(x, tariffTypes))
  }

  useEffect(()=>{
    if (!order) setOrderTariffs([]);
    else loadData()
  }, [order])

  return (
      <>
        <StyledRatingWindow isOpen={isShowRatingWindow} className="rating_window">
          <StyledRatingWindowContent>
            <StyledRaitinHeader>
              <div>{orderStatus}</div>
              <StyledRaitinHeaderAmount>{orderAmount} ₽ </StyledRaitinHeaderAmount>
            </StyledRaitinHeader>

            <StyledRaitinDetails onClick={goToShowOrder}>
              <div>Детализация заказа</div>
              <ArrowRightIcon />
            </StyledRaitinDetails>

            <StyledRatingMain>
              <div>
                <StyledRatingExecutor>Исполнитель</StyledRatingExecutor>
                <div>{driverFullName}</div>
              </div>

              <StyledRaitinStars>{getStarsElement()}</StyledRaitinStars>

              <div>Что вы можете отметить?</div>

              <StyledRaitinMistakes>
                <RatingWindowMistakeList mistakes={mistakes}  onClickMistake={onClickMistake} />
              </StyledRaitinMistakes>
            </StyledRatingMain>

            <DisabledDivForButton disabled={rating === -1}>
              <Button
                buttonTypeEvent={ButtonTypeEvent.BUTTON}
                buttonBackgroundColor={ButtonBackgroundColor.BLUE}
                buttonColor={ButtonColor.WHITE}
                buttonHeight={ButtonHeight.MID_HEIGHT}
                children="Готово"
                onClick={() => ready()}
              />
            </DisabledDivForButton>
          </StyledRatingWindowContent>
        </StyledRatingWindow>
        <Overlay is_visible={true} />
        {isShowPaymentDetails &&  <PaymentDetails order={currentOrder}
                                                  onClose={()=> setShowPaymentDetails(false)}
                                                  order_tariffs={orderTariffs}
                    />}
      </>
  );
};
export default RatingWindow;
