import { useEffect, useState } from 'react'

import { GreenArrow } from '../../../../assets/icons/GreenArrow'
import { StyledOrderAmount, StyledOrderRowAmount } from './OrderAmount.styled'

import { useGetTariffTypesMutation } from '../../../../api/orderApi'

export interface IOrderAmountProps{
  amount: number;
  isGrey?:boolean;
  tariffTypeId: string,
  isFullHeightAmount: boolean,
  isPayed?: boolean,
  onClick?:()=>void,
}

export const OrderAmount = ({
  isGrey,
  amount,
  isFullHeightAmount,
  tariffTypeId,
  isPayed,
  onClick
}: IOrderAmountProps) => {
  const [getTariffTypes] = useGetTariffTypesMutation();
  const [amountName, setAmountName] = useState('Ставка');
  useEffect(() => {
    getTariffTypes(null).unwrap()
    .then( (res:any) => {
      if (!res || !res?.length) return;
      const tariffType = res.filter((item:any) => item.id === tariffTypeId)
      if (tariffType) {
        setAmountName(tariffType[0].name)
      }
    })
  }, [])
  return (
    <StyledOrderRowAmount
      isGrey={isGrey}
      isFullHeightAmount={isFullHeightAmount}
      onClick={()=>{if (onClick) onClick()}}
    >
      <div>{amountName}</div>
      <StyledOrderAmount>
        {isPayed && <GreenArrow />}
        {`${amount.toLocaleString('ru').replace(',', '.')} ₽`}
      </StyledOrderAmount>
    </StyledOrderRowAmount>
  )
};
export default OrderAmount;
