import React, {FC, useEffect, useState} from "react";
import "./SelectPointOnMap.css"
import ToInputIcon from '../../../../../assets/icons/ToInputIcon.svg'
import {EnumShowMapMode, RouteAddress} from "../CreateOrderType";
import {daDataApi} from "../../../../../api/daDataApi";
import {getShowMapMode, setShowMapMode} from "../CreateOrderSlicer";
import {useAppDispatch, useAppSelector} from "../../../../../app/redux/hooks";
import {StyledLinkBack} from "../../../../../components/Button/Button.styled";
import {setMarkers} from "../../../../../components/HereMap/hereMapSlice";
interface IProps {
    address:RouteAddress
    onChange:(value:RouteAddress)=>void
}
const SelectPointOnMap: FC<IProps> = ({address, onChange}) => {
    const dispatch = useAppDispatch();

    let  map_mode = useAppSelector(getShowMapMode);
    const [currentAddress, setCurrentAddress] = useState<RouteAddress>(address)

    useEffect( () => {
        if (map_mode.mode === EnumShowMapMode.wasClickOnMap) {
            let f = async () => {
                const result : any = await dispatch( daDataApi.endpoints.getAddressByCoords.initiate(map_mode.coordinate ? map_mode.coordinate : {lon:0, lat:0}));
                if (result.isSuccess && result.data  && result.data.suggestions && result.data.suggestions.length) {
                    let data  = result.data.suggestions[0];
                    let addr:any = {...currentAddress} ;
                    addr.adress = data.value ;
                    addr.adress_latitude = data.data.geo_lat;
                    addr.adress_longitude = data.data.geo_lon;
                    addr.house = data.data.house;
                    setCurrentAddress({...addr})
                    dispatch(setShowMapMode( {mode:EnumShowMapMode.doAppendPoint}));
                    let marker = { lon: addr.adress_longitude, lat: addr.adress_latitude, type: 1, index: 1,
                                    title: currentAddress.id == 0 ? 'Откуда': 'Куда', text: data.value,
                                    is_show_text: true, bg_color: currentAddress.id == 0 ?  '#FF9900' :'#5364FF'
                                 };
                    dispatch(setMarkers([marker]))
                }
            };
            f();

        }

    }, [map_mode]);

    const onCancel = () => {
        dispatch(setShowMapMode( {mode:EnumShowMapMode.None}));
    };

    const onSave = () => {
        dispatch(setShowMapMode( {mode:EnumShowMapMode.None}));
        onChange(currentAddress);
    };


    return(
        <div className="select-point-on-map">
            <div className='select-point-on-map-address'>
                <img src={ToInputIcon} />
                {currentAddress.adress &&  <div>{currentAddress.adress}</div> }
                {!currentAddress.adress &&  <div>{+currentAddress.id == 0 ? 'Выберите точку на карте откуда начать перевозку' : 'Выберите точку на карте куда доставить груз'}</div> }
            </div>
            <button disabled={!currentAddress.adress} onClick={onSave}>Выбрать позицию</button>
            <StyledLinkBack onClick={onCancel}>вернуться назад</StyledLinkBack>
        </div>
    )
};

export default SelectPointOnMap;
