import { createSlice } from '@reduxjs/toolkit'
import {apiOrderUrl, cookiesDefaultUserId} from '../../../../api/api.constants'
import { AppDispatch, RootState } from '../../../../app/redux/store'
import {CCompany, CUser, CUserProfile, userProfileState} from "./userProfile.type";
import {useCookies} from "react-cookie";

const initialState: userProfileState = {
  profile: new CUserProfile(),
  selected_company_id: '',
  selected_user_id: '',
  is_visible: false,
  data_login: undefined,
};

export const userProfileSelector = (state: RootState) => state.userProfile;

export const userProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserProfile (state, action) {
      state.profile = {...action.payload}
    },
    setSelectedCompanyId (state, action) {
      state.selected_company_id = action.payload
    },
    clearUserProfile (state) {
      state.profile = new CUserProfile();
      state.selected_company_id = '';
      state.is_visible = false;
      state.data_login = undefined;
    },
    setVisibleUserProfileForm(state, action){
      state.is_visible = action.payload
    },
    setSelectedUserId(state, action){
      state.selected_user_id = action.payload
    },
    setDataLogin(state, action){
      state.data_login = action.payload
    }

  }
});
export const {
  setUserProfile,
  setSelectedCompanyId,
  clearUserProfile,
  setVisibleUserProfileForm,
  setSelectedUserId,
  setDataLogin
} = userProfileSlice.actions;

export const loadProfileByIdThunk = async ( clientId: string, dispatch: AppDispatch, selectedId:string|undefined, full_name: string = "" ) => {
  console.log(`loadProfileByIdThunk clientId=${clientId} selectedId=${selectedId} full_name=${full_name}`);
  if (!clientId) return;
  dispatch(clearUserProfile());

  const response = await fetch( `${apiOrderUrl}/clients?client_id=${clientId}`).then(res => res.json());
  if (response.status == 'error') console.log('ошибка загрузки данных клиента');
  else {
    response.client_id = clientId;
    dispatch(setUserProfile(response));
    if (!selectedId && full_name) {
      let tmp = response.users.find((x:CUser) => x.full_name == full_name);
      if (tmp) selectedId = tmp.id;
      else
      tmp = response.companies.find((x:CCompany) => x.name == full_name);
      if (tmp)  selectedId = tmp.id;
      console.log('selectedId = ', selectedId)
    }
    if (!selectedId) selectedId = response.users.length >0 ? response.users[0].id:  response.companies.length > 0 ?  response.companies[0].id : '';
    console.log('selectedId = ', selectedId)
    if (!selectedId) return '';

    if (response.companies.some((x:any) => x.id== selectedId)) {
      let c = response.companies.find((x:any) => x.id == selectedId);
      dispatch(setSelectedCompanyId(selectedId))
      dispatch(setDataLogin({id:selectedId, is_user: false}));
    } else
    if (response.users.some((x:any) =>x.id== selectedId)) {
      dispatch(setSelectedUserId(selectedId))
      dispatch(setDataLogin({id:selectedId, is_user: true}));
    }
  }
  return selectedId;
};

export default userProfileSlice.reducer
