
export const checkIsValidGuid = (guid: string) => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    guid
  )
}

export const groupBy = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem)
    if (!previous[group]) previous[group] = []
    previous[group].push(currentItem)
    return previous
  }, {} as Record<K, T[]>)

export const getFile = (response: any) => {
  if (response.file) {
    let buffer = atob(response.file)
    let n = buffer.length
    let arr = new Uint8Array(n)
    while (n--) {
      arr[n] = buffer.charCodeAt(n)
    }
    return new File([arr], 'file.jpg', { type: 'image/jpg' })
  }

  if (response && response.status === 'error') {
    console.log(response.errormessage)
    return null
  }
  if (!response.file) {
    console.log('wrong file format')
    return null
  }
  return null
}
