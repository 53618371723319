import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import { ArrowRightIcon } from '../../../../assets/icons/ArrowRightIcon'
import { Point } from '../../../../assets/icons/Point'
import { IOrderListFilterType } from '../OrderList/OrderList.types'
import { orderListSelector } from '../OrderList/orderListSlice'
import { getCategorySvg } from './categoryHelper'
import { closeModal } from '../../../../components/ModalFull/modalFullSlice'
import {
  StyledOrderItem,
  StyledOrderItemAddress,
  StyledOrderItemAmount,
  StyledOrderItemFooter,
  StyledOrderItemHeader,
  StyledOrderItemPeriod,
  StyledOrderItemStatus
} from './OrderItem.styled'
import { IOrderItemProp } from './OrderItem.types'
import { setOrderId } from '../Order/orderSlice'
export const OrderItem = ({
  id,
  from,
  to,
  carTypeId,
  amount,
  status,
  timeFrom,
  timeTo,
  filterStatus
}: IOrderItemProp) => {
  const { categories } = useAppSelector(orderListSelector)
  const category = getCategorySvg(categories, carTypeId)
  const emptyAddress = 'Адрес не указан'
  const dispatch = useAppDispatch()
  const onClickOrder = () => {

    dispatch(setOrderId(id))
    // dispatch(
    //   openModalFullWithTitle({
    //     children: 'Order',
    //     title: `Выполненный заказ \n  ${status} `body_option_characteristics
    //   })
    // )
    dispatch(closeModal());
  }
  return (
    <StyledOrderItem onClick={e => onClickOrder()}>
      <StyledOrderItemHeader>
        <StyledOrderItemPeriod filterStatus={filterStatus}>
          {`c ${moment(timeFrom).format('HH:mm')} до ${moment(timeTo).format(
            'HH:mm'
          )}`}
        </StyledOrderItemPeriod>
        <Point
          color={
            filterStatus !== IOrderListFilterType.now
              ? 'var(--grey)'
              : undefined
          }
        />
        <StyledOrderItemAddress>{from || emptyAddress}</StyledOrderItemAddress>
        <ArrowRightIcon />

        <StyledOrderItemAddress>{to || emptyAddress}</StyledOrderItemAddress>
      </StyledOrderItemHeader>
      <StyledOrderItemFooter>
        <div>{category}</div>
        <StyledOrderItemStatus status={status} filterStatus={filterStatus}>
          {status}
        </StyledOrderItemStatus>
        <StyledOrderItemAmount filterStatus={filterStatus}>
          {`${amount.toLocaleString('ru').replace(',', '.')} ₽`}
        </StyledOrderItemAmount>
      </StyledOrderItemFooter>
    </StyledOrderItem>
  )
}

export default OrderItem;
