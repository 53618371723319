import React, { FC, memo, useCallback } from 'react'
import s from './AddressBlock.module.scss'
import AddNewOrderAddress from '../../../../../assets/icons/AddNewOrderAddress.svg'
import FromInputIcon from '../../../../../assets/icons/FromInputIcon.svg'
import ToInputIcon from '../../../../../assets/icons/ToInputIcon.svg'
import LastAddressArrow from '../../../../../assets/svg/LastAddressArrow.svg'

import {RouteAddress} from "../CreateOrderType";

type propType = {
    address: RouteAddress
    lastAddress: boolean
    from: boolean
    onClick:()=>void
    onAddClick:()=>void
}

export const NewOrderAddressItem: FC<propType> = memo(({ address, lastAddress, from, onClick, onAddClick }) => {

    return (
        <div className={s.newOrderAddressItemWrapper}>
            <div className={s.newOrderAddressItem} onClick={onClick}>
                <div>
                    {from ? <img alt='Адресс отправления' src={FromInputIcon} /> : <img alt='Адресс доставки' src={ToInputIcon} />}
                </div>
                <div>
                    <div>
                        {address.adress.split(',').toString()}
                    </div>
                    <div className={s.timeFromTo}>
                        {(address.working_hours.time_from !== ':00' && address.working_hours.time_to !== ':00' && address.working_hours.time_from !== '' && address.working_hours.time_to !== '')
                         && `с ${address.working_hours.time_from} до ${address.working_hours.time_to}`}
                    </div>
                </div>
                {lastAddress && <img src={LastAddressArrow} />}
            </div>
            {lastAddress && <img alt='Добавить адрес доставки' src={AddNewOrderAddress} onClick={onAddClick} />}
        </div>
    )
})



