import styled from 'styled-components'

export const StyledAddress = styled.div`
  position: relative;
  & > div {
    border: none;
  }
  & > svg {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
`
