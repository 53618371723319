import { useAppSelector } from '../../app/redux/hooks'
import { StyledLoader, StyledLoaderContent } from './Loader.styled'
import { loaderSelector } from './loaderSlice'

export const Loader = () => {
  const { isOpened } = useAppSelector(loaderSelector)
  return (
    <>
      {isOpened && (
        <StyledLoader>
          <StyledLoaderContent />
        </StyledLoader>
      )}
    </>
  )
}
