import {useAppDispatch, useAppSelector} from "../../../../app/redux/hooks";
import {setUserProfile, userProfileSelector,  setSelectedCompanyId} from "./userProfileSlice";
import ProfileMenuItem from "./ProfileMenuItem";
import './UserProfile.css'
import {CCompany, CEditOneRecordData, CUser, CUserProfile, EnumEditOneRecordType} from "./userProfile.type";
import {useEffect, useState} from "react";
import ProfileEditOneRecord from "./ProfileEditOneRecord";
import {useClientsProfileMutation} from "../../../../api/orderApi";

/*
  phone: {
    smallText: 'Моб. телефон пользователя',
    text: 'Номер телефона',
    editClientsFormTitle: 'Мобильный телефон пользователя'
  },
  workPhone: {
    smallText: 'Рабочий телефон',
    text: 'Добавить рабочий телефон',
    editClientsFormTitle: 'Рабочий телефон'
  },
  email: {
    smallText: 'Email',
    text: 'Добавить Email',
    editClientsFormTitle: 'Email пользователя'
  },
  position: {
    smallText: 'Должность',
    text: 'Указать должность',
    editClientsFormTitle: 'Должность'
  },


  tin: {
    smallText: 'ИНН компании',
    text: 'Введите ИНН',

  },
  contract: {
    smallText: '',
    text: 'Договор с компанией',

  },
  requisites: {
    smallText: '',
    text: 'Реквизиты компании',
    },
  address: {
    smallText: 'Почтовый адрес компании',
    text: 'Добавить почтовый адрес компании',
    value: 'г Томск, ул Мичурина, д 88',
    editClientsFormTitle: 'Почтовый адрес компании'
  },

  companyPhone: {
    smallText: 'Телефон компании',
    text: 'Добавить телефон компании',
    editClientsFormTitle: 'Телефон компании'
  },
  companyEmail: {

    smallText: 'Email компании',
    text: 'Добавить Email компании',
    value: '',
    type: ProfileItemType.company,
    editClientsFormTitle: 'Email компании'
  },
  site: {
    key: 'site',
    editClientsFormField: EditClientsFormField.site,
    smallText: 'Сайт компании',
    text: 'Добавить сайт компании',
    value: '',
    type: ProfileItemType.company,
    editClientsFormTitle: 'Сайт компании'
  },
  addEmployee: {
    key: EditClientsFormField.addEmployee,
    editClientsFormField: EditClientsFormField.addEmployee,
    smallText: '',
    text: 'Добавить сотрудника',
    value: '',
    type: ProfileItemType.addButton,
    isNotEditable: true
  }
 */
interface IProps {
    userIndex:number;
}
const ProfileUserInfo : React.FC<IProps> = ({userIndex}) =>{

    const dispatch = useAppDispatch();
    const userProfile = useAppSelector(userProfileSelector);

    const user : CUser = userProfile.profile.users.length ? userProfile.profile.users[userIndex] :  new CUser();

    const [isEditItem, setEditItem] = useState<CEditOneRecordData|undefined>(undefined);
    const [isLoadingItem, setLoadingItem] = useState<EnumEditOneRecordType>(EnumEditOneRecordType.none);
    const [isErrorText, setErrorText] = useState<{text:string, type: EnumEditOneRecordType} | undefined>(undefined);
    const [profile] = useClientsProfileMutation();




    useEffect(()=>{

    }, [userProfile.profile]);

    const updateData = (data : CUserProfile, loadingType: EnumEditOneRecordType, company_id="") => {
        setEditItem(undefined);
        setLoadingItem(loadingType);
        profile(data)
            .unwrap()
            .then((res)=> {
                setLoadingItem(EnumEditOneRecordType.none);
                console.log('updateData res', res);
                if (!res || typeof res != 'object' ) return setErrorText({text: 'Ошибка сохранения данных', type:loadingType});
                if (res.status=='error') return setErrorText({text: res.error_message, type:loadingType});
                dispatch( setUserProfile( res) );
                if (loadingType == EnumEditOneRecordType.add_company) {
                    dispatch( setSelectedCompanyId( company_id) );
                }
            })
            .catch(e=>{ setLoadingItem(EnumEditOneRecordType.none); return setErrorText({text: 'Ошибка сохранения данных', type:loadingType});});
    };

    const getProfileNewUser = (new_user_data: CUser) => {
        let profile : CUserProfile = {...userProfile.profile, users:[...userProfile.profile.users], companies:[...userProfile.profile.companies]};
        profile.users = [...profile.users.filter((x,index)=>index != userIndex), new_user_data];
        return profile;
    };

    const onSetData = (value:any,type:EnumEditOneRecordType) =>{
        if (value !== undefined) {
            switch (type) {
                case EnumEditOneRecordType.email: updateData(getProfileNewUser({...user, email: value}), type ); break
                case EnumEditOneRecordType.phone:  updateData( getProfileNewUser({...user, phone:value}) , type); break
                case EnumEditOneRecordType.name:  updateData( getProfileNewUser({...user, full_name:value}) , type); break
            }
        }
        setEditItem(undefined);
    };


    const onSetINN = (value:any,type:EnumEditOneRecordType) =>{
        console.log('onSetINN', value);
        if (value !== undefined) {
            let company = new CCompany(value.inn, value.name);
            let profile : CUserProfile = {...userProfile.profile, users:[...userProfile.profile.users], companies:[...userProfile.profile.companies, company]};
            updateData(profile, EnumEditOneRecordType.add_company, value.id);
        }
        setEditItem(undefined);
    };



    return (
        <>
        <div className="user_and_company_info user_info">
            <div className='name'>{user.full_name}</div>
            <div className='menu-items'>
                <ProfileMenuItem name={'ФИО пользователя'} value={user.full_name} is_arrow={true} append_class={'no-top-border'}
                                 is_loading={isLoadingItem == EnumEditOneRecordType.name}
                                 onClick={()=>{
                                     setEditItem(new CEditOneRecordData('ФИО пользователя аккаунта', 'Иванов Иван Иванович', user.full_name, onSetData, EnumEditOneRecordType.name ))}
                                 } />
                <ProfileMenuItem name={'Моб. телефон'} value={user.phone} is_arrow={true}
                                 is_loading={isLoadingItem == EnumEditOneRecordType.phone}
                                 onClick={()=>{
                                     setEditItem(new CEditOneRecordData('Мобильный телефон пользователя', '+79006498996', user.phone, onSetData, EnumEditOneRecordType.phone))}

                                 } />
                <ProfileMenuItem name={'Email'} value={user.email} is_arrow={true}
                                 is_loading={isLoadingItem == EnumEditOneRecordType.email}
                                 onClick={()=>{
                                        setEditItem(new CEditOneRecordData('Email пользователя', 'Введите свой Email', user.email, onSetData, EnumEditOneRecordType.email))}
                                 } />
                {
                    userProfile.profile.companies.map(x=>  <ProfileMenuItem key={x.id} name={'ИНН: ' + x.tin} value={x.name} is_arrow={true} append_class={'no-bottom-border'}
                                                                            onClick={()=>{
                                                                                console.log('x', x)
                                                                                dispatch(setSelectedCompanyId(x.id))
                                                                            }}/>
                                                     )
                }
                <ProfileMenuItem name={''} value={'Создать профиль компании'} is_arrow={false} append_class={'no-bottom-border'}
                                 is_loading={isLoadingItem == EnumEditOneRecordType.add_company}
                                 onClick={()=>{
                                     setEditItem(new CEditOneRecordData('Создание профиля компании', 'ИНН компании', '', onSetINN, EnumEditOneRecordType.add_company))}
                                 }/>
            </div>
        </div>
            { isEditItem && <ProfileEditOneRecord value={isEditItem} />}
        </>
    )
}

export default ProfileUserInfo;
