import styled from 'styled-components'

export const StyledArticle = styled.div`
  margin-top: 37px;
`
export const StyledArticleContent = styled.div`
  font-size: 15px;
  font-weight: 700;
  margin: 0 24px;
`
