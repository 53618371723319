import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import moment from 'moment'
import { ICategory } from '../modules/Orders/components/OrderItem/OrderItem.types'
import { IArticleItem } from '../modules/Help/components/ArticleItem/ArticleItem.types'
import {
  ICompany,
  IProfile,
  IUser
} from '../modules/Home/components/Profile/Profile.types'
import {
  OrderContactsType,
  PaymentTypes
} from '../modules/Home/components/NewUserOrder/NewUserOrderSlicer'
import { apiOrderUrl } from './api.constants'
import { IRouteByOrderId } from '../modules/Orders/components/Order/Order.types'
import {
  CargoInfo,
  CPackInfo,
  CTariffData, TypeLonLat,
  SendOrderInfo
} from "../modules/Home/components/NewUserOrder/CreateOrderType";
import {CUserProfile} from "../modules/Home/components/UserProfile/userProfile.type";


export type OrderRouteDestinationPointResponse = {
  driver_id: string,
  order_id:  string,
  status: string,
  point: number,
  time:  string //"2022-11-16T19:48:04"
}

export type OrderCarPositionsResponse = {
  lat: number,
  lon: number,
  date: string,
  date_utc:  string
}

export type CarTypeQuery = { id: number; name: string }[]

export type ErrorAnswer = {
  errormessage: string
  status: string
}

export interface IBOCharacteristicsValue {
  id: string,
  name: string,
  selected:boolean,
  body_option_characteristics_id: string
}

export type BodyOptionsCharacteristicsType = {
  name: string
  type: CharacteristicTypeEnum
  body_option_characteristics_values: IBOCharacteristicsValue[]
  id: string
  value?:string
}
export type BodyOptionsType = {
  name: string
  body_type_id: number
  body_option_characteristics: BodyOptionsCharacteristicsType[]
  id: string
}
export type CarTypeTreeQueryResponse = {
  name: string
  length_from: number
  length_to: number
  width_from: number
  width_to: number
  height_from: number
  height_to: number
  weight_from: number
  weight_to: number
  area_from: number
  area_to: number
  volume_from: number
  volume_to: number
  pallets_from: number
  pallets_to: number
  pass_sk: boolean
  pass_ttk: boolean
  pass_mkad: boolean
  ramp: boolean
  tail_lift: boolean
  board: boolean
  refrigerator: boolean
  body_options: BodyOptionsResponse[]
  id: string
}
export type PalletTypesQuery = {
  id: string
  name: string
  length: number
  width: number
  manual: boolean
}
export type PackageTypesQuery = {
  id: string
  name: string
}
export type SendSmsQuery = { phone: string; TIN?: string }
export type SendSmsQueryResponse = { status: string }
export type CheckSmsMutation = { phone: string; TIN?: string; code: string }
export type GetOrderListdMutation = {
  clientId: string
  startFrom?: string
  startTo?: string
  count?: number
}

export type GetCarPointsMutation = {
  order_id: string
  date_utc?: number
}

export type SaveOrdersRatingMutation = {
  clientId: string
  orderId: string
  mistakes: string[]
}
export type AuthMutation = { phone: string; code: string }
export type ClientMutation = {
  phone: string
  fullName: string
  email?: string
  inn?: string
  workPhone?:string
}

export interface IResult {
  client_id: string
  full_name: string
  status: string
  error_message: string
  users: IUser[]
  companies: ICompany[]
}
export interface IErorrResult {
  errormessage: string
  status: string
}
export type CheckPhoneMutation = { phone: string; TIN?: string }
export type OrderRoute = {
  id: number
  adress: string
  adress_comment: string
  adress_longitude: number
  adress_latitude: number
  company: string
  contact_persons: [
    {
      full_name: string
      phone: string
      phone_ext: string
      email: string | null
    }
  ]
  what_to_do: string
  working_hours: {
    time_from: string
    time_to: string
    lunch_from: string
    lunch_to: string
    no_lunch: boolean
    max_landing_time: string
  }
  action_documents: boolean
  action_loading: boolean
  action_unloading: boolean
  action_forwarder: boolean
  files_ids: string[]
}
export type CargoPlaceType = {
  quantity?: number
  size: {
    length: number
    width: number
    height: number
    weight: number
  }
}
export const createCargoPlace = () => ({  size: { length: 0, width: 0, height: 0, weight: 0}})
export type CargoPalletsType = {
  pallet_type_id: string
  quantity: number
  size: {
    length: number
    width: number
    height: number
    weight: number
  }
}
export type CargoPackegesType = {
  package_type_id: string
  quantity: number
  size: {
    length: number
    width: number
    height: number
    weight: number
  }
}


export type CargoType = {
  name: string
  price: number
  places: CargoPlaceType[] | []
  pallets: CargoPalletsType[] | []
  packages: CargoPackegesType[] | []
  max_dimensions: boolean
}

export type BodyOptionsCharacteristicsPostOrderType = {
  id: string
  value: string
}

export type AdditionalRequirementsPostOrderType = {
  id: string
  value: boolean
}

export interface SendOrderType {
  id: string
  date: string
  body_type_id: number
  body_option_id: string
  body_option_characteristics: BodyOptionsCharacteristicsPostOrderType[]
  additional_requirements: AdditionalRequirementsPostOrderType[]
  routes: OrderRoute[]
  cargo: CargoType
  tariff_type_id: string
  contacts: OrderContactsType
  payment_type: PaymentTypes
  TIN: string
  comment: string
  car_type_id: string
  draft: boolean
  max_dimensions: boolean
}
export interface SendCalcOrder {
  id: string
  date: string
  body_type_id: number
  body_option_id: string
  body_option_characteristics: BodyOptionsCharacteristicsPostOrderType[]
  additional_requirements: AdditionalRequirementsPostOrderType[]
  routes: OrderRoute[]
  cargo: CargoType
  tariff_type_id: string
  contacts: OrderContactsType
  payment_type: PaymentTypes
  TIN: string
  comment: string
  car_type_id: string
  draft: boolean
  max_dimensions: boolean
}
export type routePoint = {
  arrivalDate?: string
  departureDate?: string
  lat: number
  speed: number
  timeToNextPoint: number
  lon: number
  originalIndex: number
  lengthToNextPoint: number
  name: string
}
export interface OrderCalcResponse {
  points: routePoint[]
  service_information: string
  items_by_route: [
    {
      cost: number
      name: string
    }
  ]
  hours: number
  min_hours: number
  rate: string
  min_cost: number | null
  items: [
    {
      cost: number
      name: string
    }
  ]
  cost_by_hour: number | null
  cost: number | null
  tariff_type_id: string
  errormessage?: string
  status?: string
}
export type OrderPostResponseType = {
  id: string
  order_number: number
  error?:string
  errormessage?: string
}
export interface BodyTypesResponse {
  id: number
  name: string
}
export interface BodyOptionsResponse {
  id: string
  body_type_id: number
  name: string
  body_option_characteristics: BodyOptionCharacteristicsResponse[]
}
export interface BodyOptionCharacteristicsResponse {
  id: string,
  name: string,
  type: CharacteristicTypeEnum,
  selected:boolean,
  value:any,
  body_option_characteristics_values: IBOCharacteristicsValue[]
}

export enum CharacteristicTypeEnum {
  Boolean = "Boolean", Ref = "ref", Number = "number"
}

export interface BodyOptionCharacteristicsValuesResponse {
  id: string
  body_option_characteristics_id: string
  name: string
}
export interface TariffTypesResponse {
  id: string
  name: string
}
export interface AditionalRequirementsTypes {
  id: string
  name: string
  type: string
  value?: string
}
export type PackedItemType = {
  width: number
  height: number
  x: number
  y: number
  item: {
    name: string
  }
  bin: number
}
export interface PackResponseType {
  error?: boolean
  error_description?: string
  packed_items: PackedItemType[] | []
  car_type_id: string
  height: number
  width: number
  load_by_weight: number
  load_by_volume: number
  load_by_area: number
  total_weight: number
  total_volume: number
  total_area: number
}
export interface PackRequestType {
  cargo: CargoInfo
  body_option_id: string
  body_option_characteristics: BodyOptionsCharacteristicsPostOrderType[]
}

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiOrderUrl
  }),
  endpoints: builder => ({
    getCarTypesTree: builder.mutation<CarTypeTreeQueryResponse[], null>({
      query: () => 'car-types-tree'
    }),
    getBodyTypes: builder.mutation<BodyTypesResponse[], null>({
      query: () => 'body-types'
    }),
    getBodyOptions: builder.mutation<BodyOptionsResponse[], null>({
      query: () => 'body-options'
    }),
    getBodyOptionCharacteristics: builder.mutation<BodyOptionCharacteristicsResponse[], null>({
      query: () => 'body-option-characteristics'
    }),
    getBodyOptionCharacteristicsValues: builder.mutation<BodyOptionCharacteristicsValuesResponse[], string>({
      query: id =>
          `body-option-characteristics-values?body_option_characteristics_id=${id}`
    }),
    getTariffTypes: builder.mutation<TariffTypesResponse[], null>({
      query: () => 'tariff-types'
    }),
    getAdditionalRequirements: builder.mutation<AditionalRequirementsTypes[], null>({
      query: () => `additional-requirements`
    }),
    getCarTypeById: builder.query<CarTypeQuery, string>({
      query: id => `body-types?car_type_id=${id}`
    }),
    getPalletTypes: builder.mutation<PalletTypesQuery[], string>({
      query: () => 'pallet-types'
    }),
    getOrderFilesById: builder.mutation<string, string>({
      query: id => `order-files?id=${id}`
    }),
    getRoutesByOrderId: builder.mutation<IRouteByOrderId, string>({
      query: orderId => `routes?order_id=${orderId}`
    }),
    getClients: builder.mutation<IProfile, string>({
      query: clientId => ({
        url: 'clients',
        method: 'GET',
        params: {
          client_id: clientId
        }
      })
    }),
    getPackagesTypes: builder.mutation<PackageTypesQuery[], string>({
      query: () => 'package-types'
    }),
    sendSmsCode: builder.mutation<SendSmsQueryResponse, string>({
      query: phone => ({
        url: `send-sms-code`,
        method: 'POST',
        body: {
          phone: phone
        }
      })
    }),
    checkSms: builder.mutation<{ success?: boolean; valid: boolean }, CheckSmsMutation>({
      query: ({phone, code, TIN}) => ({
        url: `check-sms-code`,
        method: 'GET',
        params: {phone, code, TIN}
      })
    }),
    auth: builder.mutation<IResult, AuthMutation>({
      query: ({phone, code}) => ({
        url: `auth`,
        method: 'POST',
        body: {
          phone_number: phone,
          sms_code: code
        }
      })
    }),
    clients: builder.mutation<IResult, ClientMutation>({
      query: ({phone, fullName, email, inn, workPhone}) => ({
        url: `clients`,
        method: 'POST',
        body: {
          users: [
            {
              id: '',
              phone: phone,
              full_name: fullName,
              email: email,
              position: '',
              workPhone: workPhone ? workPhone : ''
            }
          ],
          companies: inn
              ? [
                {
                  id: '',
                  phone: phone,
                  site: '',
                  email: '',
                  tin: inn
                }
              ]
              : []
        }
      })
    }),
    clientsProfile: builder.mutation<IResult, CUserProfile>({
      query: profile => ({
        url: `clients`,
        method: 'POST',
        body: profile
      })
    }),
    checkPhone: builder.mutation<{ success: boolean; exist: boolean }, CheckPhoneMutation>({
      query: ({phone, TIN}) => ({
        url: `check-tin-phone`,
        method: 'GET',
        params: {phone, TIN}
      })
    }),
    getOrder: builder.mutation<any, string>({
      query: id => ({
        url: `orders`,
        method: 'GET',
        params: {id}
      })
    }),
    getCategories: builder.mutation<ICategory[], null>({
      query: () => ({
        url: `car-types`,
        method: 'GET'
      })
    }),
    calcOrder: builder.query<OrderCalcResponse, SendCalcOrder>({
      query: orderData => ({
        url: `calc`,
        method: 'POST',
        body: orderData
      })
    }),
    saveOrdersRating: builder.mutation<unknown, SaveOrdersRatingMutation>({
      query: ({clientId, orderId, mistakes}) => ({
        url: `orders-rating`,
        method: 'POST',
        body: {
          client_id: clientId,
          order_id: orderId,
          mistakes: mistakes,
          date: new Date()
        }
      })
    }),
    getOrderList: builder.mutation<unknown, GetOrderListdMutation>({
      query: ({clientId, startFrom, startTo, count}) => ({
        url: `client-order-list`,
        method: 'GET',
        params: {
          client_id: clientId,
          start_from: startFrom ? moment(startFrom).format('yyyy.MM.DD') : '',
          start_to: startTo ? moment(startTo).format('yyyy.MM.DD') : '',
          count: count
        }
      })
    }),
    calcOrderRequest: builder.mutation<OrderCalcResponse, SendCalcOrder>({
      query: orderData => ({
        url: `calc`,
        method: 'POST',
        body: orderData
      })
    }),
    calcOrderRequestAll: builder.mutation<CTariffData[], SendOrderInfo>({
      query: orderData => ({
        url: `calc?all=true`,
        method: 'POST',
        body: orderData
      })
    }),
    postOrder: builder.mutation<OrderPostResponseType, SendOrderInfo>({
      query: order => ({
        url: 'orders',
        method: 'POST',
        body: order
      })
    }),
    getArticleById: builder.query<IArticleItem[], string>({
      query: id => ({
        url: `client-articles`,
        method: 'GET',
        params: {id}
      })
    }),
    getArticles: builder.query<IArticleItem[], string>({
      query: () => 'client-articles'
    }),
    pack: builder.mutation<CPackInfo, PackRequestType>({
      query: body => ({
        url: 'pack',
        method: 'POST',
        body
      })
    }),
    getCalcOrder: builder.query<OrderCalcResponse[] | {
      errormessage: string
      status: string
    }, SendCalcOrder>({
      query: orderData => ({
        url: `calc?all=true`,
        method: 'POST',
        body: orderData
      })
    }),

    getCarPointPosition: builder.mutation< { error_message: string, status: string} | OrderCarPositionsResponse, string>(
        {
          query: (order_id) => ({
            url:   `car-position`,
            method: 'GET',
            params: { order_id }
          })
        }),
    getCarPointPositionCurrent: builder.mutation< { error_message: string, status: string} | OrderCarPositionsResponse, GetCarPointsMutation>(
        {
          query: ({order_id, date_utc}) => ({
            url:  `car-position-current`,
            method: 'GET',
            params: { order_id, date_utc }
          })
        }),

    deleteOrder: builder.mutation< { error_message: string, status: string}, string>(
        {
          query: id => ({
            url: `/cancel-order`,
            method: 'POST',
            body: { id }
          })
        }),

    getNextRoutePoint: builder.mutation< { error_message: string, status: string} | OrderRouteDestinationPointResponse, string>(
        {
          query: order_id => ({
            url: `order-execution`,
            method: 'GET',
            params: { order_id }
          })
        }),
  })
});

export const {
  useGetCarTypesTreeMutation,
  useGetBodyTypesMutation,
  useGetCarTypeByIdQuery,
  useGetBodyOptionsMutation,
  useGetBodyOptionCharacteristicsMutation,
  useGetBodyOptionCharacteristicsValuesMutation,
  useGetRoutesByOrderIdMutation,
  useGetPalletTypesMutation,
  useGetOrderFilesByIdMutation,
  useGetClientsMutation,
  useGetPackagesTypesMutation,
  useSendSmsCodeMutation,
  useAuthMutation,
  useClientsMutation,
  useClientsProfileMutation,
  useCheckSmsMutation,
  useGetOrderMutation,
  useGetCategoriesMutation,
  useCheckPhoneMutation,
  useCalcOrderQuery,
  useSaveOrdersRatingMutation,
  useGetOrderListMutation,
  useCalcOrderRequestMutation,
  useCalcOrderRequestAllMutation,
  useGetTariffTypesMutation,
  useGetAdditionalRequirementsMutation,
  usePostOrderMutation,
  useGetArticleByIdQuery,
  useGetArticlesQuery,
  usePackMutation,
  useGetCalcOrderQuery,
  useGetCarPointPositionMutation,
  useGetCarPointPositionCurrentMutation,
  useDeleteOrderMutation,
  useGetNextRoutePointMutation
} = orderApi;


export const test_order = {
  "id": "86c1840b-e357-44f1-895f-7ce27aaace60",
  "date": "2022-03-01T21:58:00T00:30:24",
  "body_type_id": 0,
  "body_option_id": "ae606bfa-1df7-11e4-9a8e-e41f13c2b943",
  "body_option_characteristics": [
    {
      "id": "ccedba36-bf88-4784-8ed4-d34774ea1759",
      "value": "52b30bdd-49d5-11e7-9696-e41f13c2b942"
    },
    {
      "id": "22dba867-4bd4-11df-ab17-005056000008",
      "value": "false"
    },
    {
      "id": "22dba865-4bd4-11df-ab17-005056000008",
      "value": "false"
    },
    {
      "id": "60bdcf2e-ae63-11e2-90ae-001a64b04e42",
      "value": "true"
    },
    {
      "id": "9cebde1b-25e2-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "9cebde18-25e2-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "9cebde16-25e2-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "9cebde10-25e2-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "f61aa4e2-5fe5-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "d8c67cfe-40df-11ea-a9c6-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "b891966b-5fe5-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "e7736c7c-5fe5-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "905644c4-86e2-11ea-a9c9-00155d8e4e03",
      "value": "47.841"
    },
    {
      "id": "e59064a9-40df-11ea-a9c6-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "e59064aa-40df-11ea-a9c6-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "7a03cb82-5ee8-4095-acc0-fb352c48cf44",
      "value": "false"
    },
    {
      "id": "51fed432-fb5e-11e7-9ae7-e41f13c2b942",
      "value": "false"
    },
    {
      "id": "9a05e373-21df-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "aa507685-21df-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "c01ac72c-fcf4-11e7-9ae7-e41f13c2b942",
      "value": "false"
    },
    {
      "id": "8cd783f4-7d03-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "4aebf0f5-7f95-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "c985b210-7f99-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "4cf93c35-9788-11eb-8e9d-00155d010f05",
      "value": "false"
    }
  ],
  "additional_requirements": [
    {
      "id": "ccedba36-bf88-4784-8ed4-d34774ea1759",
      "value": "52b30bdd-49d5-11e7-9696-e41f13c2b942"
    },
    {
      "id": "22dba867-4bd4-11df-ab17-005056000008",
      "value": "false"
    },
    {
      "id": "22dba865-4bd4-11df-ab17-005056000008",
      "value": "false"
    },
    {
      "id": "60bdcf2e-ae63-11e2-90ae-001a64b04e42",
      "value": "true"
    },
    {
      "id": "9cebde1b-25e2-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "9cebde18-25e2-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "9cebde16-25e2-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "9cebde10-25e2-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "f61aa4e2-5fe5-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "d8c67cfe-40df-11ea-a9c6-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "b891966b-5fe5-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "e7736c7c-5fe5-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "905644c4-86e2-11ea-a9c9-00155d8e4e03",
      "value": "47.841"
    },
    {
      "id": "e59064a9-40df-11ea-a9c6-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "e59064aa-40df-11ea-a9c6-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "7a03cb82-5ee8-4095-acc0-fb352c48cf44",
      "value": "false"
    },
    {
      "id": "51fed432-fb5e-11e7-9ae7-e41f13c2b942",
      "value": "false"
    },
    {
      "id": "9a05e373-21df-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "aa507685-21df-11ea-a9ad-00155d8e4e05",
      "value": "false"
    },
    {
      "id": "c01ac72c-fcf4-11e7-9ae7-e41f13c2b942",
      "value": "false"
    },
    {
      "id": "8cd783f4-7d03-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "4aebf0f5-7f95-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "c985b210-7f99-11ea-a9c9-00155d8e4e03",
      "value": "false"
    },
    {
      "id": "4cf93c35-9788-11eb-8e9d-00155d010f05",
      "value": "false"
    }
  ],
  "routes": [
    {
      "id": 0,
      "adress": "г Москва, ул Удальцова, д 22 к 2",
      "adress_comment": "",
      "adress_longitude": 37.500782,
      "adress_latitude": 55.682396,
      "company": "",
      "what_to_do": "",
      "working_hours": {
        "time_from": "",
        "time_to": "",
        "lunch_from": "",
        "lunch_to": "",
        "max_landing_time": "",
        "no_lunch": true
      },
      "action_forwarder": false,
      "action_unloading": false,
      "action_loading": true,
      "action_documents": false,
      "contact_persons": [],
      "files_ids": []
    },
    {
      "id": 1,
      "adress": "г Москва, ул Пруд-Ключики, д 12 стр 1",
      "adress_comment": "",
      "adress_longitude": 37.7324268,
      "adress_latitude": 55.7440176,
      "company": "",
      "what_to_do": "",
      "working_hours": {
        "time_from": "",
        "time_to": "",
        "lunch_from": "",
        "lunch_to": "",
        "max_landing_time": "",
        "no_lunch": true
      },
      "action_forwarder": false,
      "action_unloading": true,
      "action_loading": false,
      "action_documents": false,
      "contact_persons": [],
      "files_ids": []
    }
  ],
  "cargo": {
    "name": "",
    "price": "",
    "places": [
      {
        "size": {
          "weight": 1001,
          "height": 1.6,
          "length": 2.8,
          "width": 1.6
        }
      }
    ],
    "pallets": [],
    "packages": [],
    "max_dimensions": false
  },
  "tariff_type_id": "bdc31826-7d68-11ea-a9c9-00155d8e4e03",
  "contacts": {
    "full_name": "Пользователь Для Теста",
    "phone": "79000000000",
    "email": "tr1@mail.ru"
  },
  "payment_type": "paymentcash",
  "max_dimensions": false,
  "car_type_id": "52b30bdd-49d5-11e7-9696-e41f13c2b942",
  "TIN": "7825706086",
  "company_name": "",
  "comment": "",
  "draft": false,
  "client_id": "69d8ec25-496b-46fb-87c2-b53a14f6ea8a",
  "vehicle": null,
  "driver": {full_name:'Иванов ИИ', phone: '+700000000',passport:{series:'4004',number:'788888',issue_date:'2000.12.12',issued_by:'2022.12.12'},
              license:{issue_date:'2000.12.12',issued_by:'2022.12.12', brand:'ВАЗ', model:'2106', number:'a900aa198', max_weight:1500}
            },
  "number": "926",
  "status": "Подбираем водителя",
  "status_id": 0,
  "amount": 3095,
  "distance": 47.83
}

