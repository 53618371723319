import {
  SideBarField,
  SideBarFieldIcon,
  SideBarWrapper
} from './SideBar.styled'
import { useAppSelector } from '../../../../app/redux/hooks'
import { sideBarSelector } from './sideBarSlice'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../../../../app/redux/store'
import { setNonAuth } from '../../../Authorization/authorizationSlice'
import BurgerItemDetails from '../../../../assets/icons/BurgerItemDetails.svg'
import { FC } from 'react'
import {
  openModal as openModalFull,
  openModalWithTitle as openModalFullWithTitle
} from '../../../../components/ModalFull/modalFullSlice'
import { clear as clearCode } from '../Code/codeSlice'
import { clear as clearAuthorization } from '../AuthorizationForm/authorizationFormSlice'
import { clear as clearRegistration } from '../RegistrationForm/registrationFormSlice'
import { logout } from '../../../../app/redux/reducers/user'
import { useCookies } from 'react-cookie'
import { cookiesClientId } from '../../../../api/api.constants'
import { openLoader } from '../../../../components/Loader/loaderSlice'
import { setVisibleUserProfileForm } from '../UserProfile/userProfileSlice'

export const SideBar: FC<{ onBurgerOverlayClick: () => void }> = ({
  onBurgerOverlayClick
}) => {
  const { isSideBarOpened } = useAppSelector(sideBarSelector)
  const [cookies, setCookie, removeCookie] = useCookies([cookiesClientId])
  const dispatch = useDispatch()
  const openProfile = () => {
    dispatch(setVisibleUserProfileForm(true))
  }
  const openRegistrationForm = () => {
    dispatch(openModalFull('RegistrationForm'))
    dispatch(clearCode())
    dispatch(clearRegistration())
  }
  const openAuthorizationForm = () => {
    dispatch(openModalFull('AuthorizationForm'))
    dispatch(clearCode())
    dispatch(clearAuthorization())
  }
  const openOrderList = () => {
    // dispatch(openLoader())
    dispatch(
      openModalFullWithTitle({ children: 'OrderList', title: 'Журнал заказов' })
    )
  }

  const openBalance = () => {
    // dispatch(openLoader())
    dispatch(
      openModalFullWithTitle({ children: 'Balance', title: 'Баланс' })
    )
  }

  const openHelp = () => {
    dispatch(openLoader())
    dispatch(
      openModalFullWithTitle({ children: 'Help', title: 'Помощь'})
    )
  }
  const history = useHistory()

  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth)

  // console.log('isAuth', isAuth);

  const onExitClick = () => {
    dispatch(setNonAuth())
    dispatch(logout())
    removeCookie(cookiesClientId, { path: '/' })
  }

  return (
    <div
      style={
        isSideBarOpened
          ? {
              background: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%',
              zIndex: 9902
            }
          : undefined
      }
      onClick={onBurgerOverlayClick}
    >
      <SideBarWrapper isSideBarOpened={isSideBarOpened}>
        {!isAuth && (
          <SideBarField onClick={openRegistrationForm}>
            Регистрация
            <SideBarFieldIcon>
              <img alt='Подробнее' src={BurgerItemDetails} />
            </SideBarFieldIcon>
          </SideBarField>
        )}
        {!isAuth && (
          <SideBarField onClick={openAuthorizationForm}>
            Войти
            <SideBarFieldIcon>
              <img alt='Подробнее' src={BurgerItemDetails} />
            </SideBarFieldIcon>
          </SideBarField>
        )}
         {isAuth && (
          <SideBarField onClick={openProfile}>
            Профиль
            <SideBarFieldIcon>
              <img alt='Подробнее' src={BurgerItemDetails} />
            </SideBarFieldIcon>
          </SideBarField>
        )}

        {isAuth && (
          <SideBarField onClick={openOrderList}>
            Журнал заказов
            <SideBarFieldIcon>
              <img alt='Подробнее' src={BurgerItemDetails} />
            </SideBarFieldIcon>
          </SideBarField>
        )}
        {isAuth && (
          <SideBarField onClick={openBalance}>
            Баланс
            <SideBarFieldIcon>
              <img alt='Подробнее' src={BurgerItemDetails} />
            </SideBarFieldIcon>
          </SideBarField>
        )}

        <SideBarField onClick={openHelp}>
          Помощь
          <SideBarFieldIcon>
            <img alt='Подробнее' src={BurgerItemDetails} />
          </SideBarFieldIcon>
        </SideBarField>
        {isAuth && (
            <SideBarField onClick={onExitClick}>
              Выход
              <SideBarFieldIcon>
                <img alt='Подробнее' src={BurgerItemDetails} />
              </SideBarFieldIcon>
            </SideBarField>
        )}
      </SideBarWrapper>
    </div>
  )
}
