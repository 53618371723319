import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import { setTitle } from '../../../../components/ModalFull/modalFullSlice'
import ArticleItem from '../ArticleItem'
import { StyledArticleList } from './ArticleList.styled'
import { articleListSelector } from './articleListSlice'

export const ArticleList = () => {
  const { articles } = useAppSelector(articleListSelector)
  const { title } = useAppSelector(articleListSelector)
  const dispatch = useAppDispatch()
  const constructTitle = () => {
    return `<span>${title} <span><CloseIcon onClick={closeModal}/>`
  }

  useEffect(() => {
    dispatch(setTitle(constructTitle()))
  }, [title])

  return (
    <StyledArticleList>
      {articles &&
        articles.map(article => (
          <ArticleItem key={article.id} article={article} />
        ))}
    </StyledArticleList>
  )
}
