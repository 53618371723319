import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/redux/store'

const MAX_SECONDS_CODE_VALID = 50

export interface CodeState {
  code: string
  isHideCode: boolean
  isValidCode?: boolean | null
  counter: number
  disabledSendRepeateCode: boolean,
  disabledRegistration: boolean
}

const initialState: CodeState = {
  code: '',
  isHideCode: true,
  isValidCode: null,
  counter: MAX_SECONDS_CODE_VALID,
  disabledSendRepeateCode: true,
  disabledRegistration: true
}

export const codeSelector = (state: RootState) => state.code

export const codeSlice = createSlice({
  name: 'code',
  initialState,
  reducers: {
    setCode (state, action) {
      const code = action.payload;
      state.code = code;
      if(code === ""){
        state.isValidCode = null;
      }
    },
    setIsHideCode (state, action) {
      state.isHideCode = action.payload
    },
    setIsValidCode (state, action) {
      state.isValidCode = action.payload
    },
    setCounter (state, action) {
      if (action.payload >= 0) {
        state.counter = action.payload
      }
    },
    setMaxCounter (state) {
      state.counter = MAX_SECONDS_CODE_VALID
    },
    setDisabledSendRepeateCode (state, action) {
      state.disabledSendRepeateCode = action.payload
    },
    setDisabledRegistration (state, action) {
      state.disabledRegistration = action.payload
    },
    clear (state) {
      state.code = ''
      state.isHideCode = true
      state.isValidCode = null
      state.counter = MAX_SECONDS_CODE_VALID
      state.disabledSendRepeateCode = true
      state.disabledRegistration = true
    }
  }
})

export const {
  setCode,
  setIsHideCode,
  setIsValidCode,
  setMaxCounter,
  setCounter,
  setDisabledSendRepeateCode,
  setDisabledRegistration,
  clear
} = codeSlice.actions

export default codeSlice.reducer
