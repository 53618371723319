import { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { FailIcon } from '../../assets/icons/FailIcon'
import { OkIcon } from '../../assets/icons/OkIcon'
import { IPhoneInputProps } from './PhoneInput.types'
import style from './style.module.css'
import cn from 'classnames'
import { useAppDispatch, useAppSelector } from '../../app/redux/hooks'
import { phoneInputSelector, setValue, clear } from './phoneInputSlice'
export const PhoneInput = ({
  onChange,
  isNeedIcon,
  placeHolder
}: IPhoneInputProps) => {
  const { value, isValid } = useAppSelector(phoneInputSelector)
  const dispatch = useAppDispatch()
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value
    dispatch(setValue(phone))
    onChange(phone)
  }
  useEffect(() => {
    dispatch(clear())
  }, [dispatch])
  return (
    <div>
      <InputMask
        className={cn(
          style.phoneInput,
          !isNeedIcon &&
            (isValid !== undefined && !isValid
              ? style['phoneInput-notValid']
              : '')
        )}
        mask='+7\9999999999'
        value={value}
        onChange={onChangeInput}
        placeholder={placeHolder || '+79999990000'}
        name='phone'
      />

      {isNeedIcon &&
        isValid !== undefined &&
        (isValid === true ? <OkIcon /> : <FailIcon />)}
    </div>
  )
}
