import React, { FC, memo, useCallback } from 'react'
import s from './VehicleBlockManual.module.scss'
import ActiveCategoryMarker from '../../../../../assets/svg/ActiveCategoryMarker.svg'
import UnactiveCategoryMarker from '../../../../../assets/svg/UnactiveCategoryMarker.svg'
import SliderRightArrow from '../../../../../assets/svg/SliderRightArrow.svg'
import SliderLeftArrow from '../../../../../assets/svg/SliderLeftArrow.svg'
import SliderRightGrad from '../../../../../assets/svg/SliderRightGrad.svg'
import SliderLeftGrad from '../../../../../assets/svg/SliderLeftGrad.svg'

import { CarTypeItem } from './CarTypeItem'
import { CarTypeTreeQueryResponse } from '../../../../../api/orderApi'



export const CategoriesSelect: FC<{ categories: CarTypeTreeQueryResponse[], onClick: (item:CarTypeTreeQueryResponse)=>void, selectedID:string }
        >= memo(({ categories, onClick, selectedID }) => {

    const currentCategory = selectedID ? categories.filter(x => x.id === selectedID)[0] : '',
        volumeFrom = currentCategory && currentCategory.length_from * currentCategory.width_from * currentCategory.height_from,
        volumeTo = currentCategory && currentCategory.length_to * currentCategory.width_to * currentCategory.height_to,
        element = document.getElementById('element')

    const onLeftClick = useCallback(() => {
        element && (element.scrollLeft -= 100)
    }, [element])

    const onRightClick = useCallback(() => {
        element && (element.scrollLeft += 100)
    }, [element])

    return (
        <div className={s.categories}>
            <div className={s.slider}>
                <img className={s.leftArrow} alt="Листать категорию влево" src={SliderLeftArrow} onClick={onLeftClick} />
                <img className={s.rightArrow} alt="Листать категорию вправо" src={SliderRightArrow} onClick={onRightClick} />
                <img className={s.leftGrad} alt="Листать категорию влево" src={SliderLeftGrad} />
                <img className={s.rightGrad} alt="Листать категорию вправо" src={SliderRightGrad} />
                <div className={s.carCategoryContainer} id="element">
                    {categories.map(type => <CarTypeItem key={type.id} data={type}  selectedID={selectedID} onClick={ ()=> onClick(type)} />)}
                </div>
            </div>
            <div className={s.paramsWrapper}>
                {currentCategory && <>
                    <div>
                        <div>
                            Длина
                        </div>
                        <div>
                            {currentCategory.length_from === currentCategory.length_to ? `${currentCategory.length_from} м` : `${currentCategory.length_from} - ${currentCategory.length_to} м`}
                        </div>
                    </div>
                    <div>
                        <div>
                            Ширина
                        </div>
                        <div>
                            {currentCategory.width_from === currentCategory.width_to ? `${currentCategory.width_from} м` : `${currentCategory.width_from} - ${currentCategory.width_to} м`}
                        </div>
                    </div>
                    <div>
                        <div>
                            Высота
                        </div>
                        <div>
                            {currentCategory.height_from === currentCategory.height_to ? `${currentCategory.height_from} м` : `${currentCategory.height_from} - ${currentCategory.height_to} м`}
                        </div>
                    </div>
                    <div>
                        <div>
                            Объем
                        </div>
                        <div>
                            {`${volumeFrom && volumeFrom.toFixed(2).replace(/[.]?0+$/, '')} - ${volumeTo && volumeTo.toFixed(2).replace(/[.]?0+$/, '')} м`}
                        </div>
                    </div>
                </>}
            </div>
            <div className={s.marbles}>
                {categories.map(el => el.id === selectedID ? <img key={el.id} alt="Выбранный элемент" src={ActiveCategoryMarker} /> : <img key={el.id} alt="Выбранный элемент" src={UnactiveCategoryMarker} />)}
            </div>
        </div>
    )
})
