import React, {FC, useState, useCallback, memo, useEffect, useMemo} from 'react'
import s from './VehicleSelect.module.scss'
import ToTarifsArrow from '../../../../../../assets/svg/ToTarifsArrow.svg'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/hooks'
import {
    filterCategory, getActiveCharacteristics, getBOCharacteristics, getBodyOptionsList, getCharacteristicValueList,

    newUserOrderSelector,

} from '../../NewUserOrderSlicer'

import { CategoriesSelect } from '../../VehicleBlockManual/CategoriesSelect'

import {
    BodyOptionCharacteristicsResponse, BodyOptionsResponse, CarTypeTreeQueryResponse, CharacteristicTypeEnum,
    SendCalcOrder,
    useCalcOrderRequestAllMutation
} from '../../../../../../api/orderApi'
import moment from 'moment'
import {AutoDropDownGreyList} from "../../AutoDropDownGreyList/AutoDropDownGreyList";
import {EnumOrderMode, CTariffData, SendOrderInfo} from "../../CreateOrderType";
import {
    currentOrderData,
    setOrderData,
    wait_before_update,
    setTariffsData,
    getTariffs,
    getCost,
    setTariffLoading,
    getTariffLoading
} from "../../CreateOrderSlicer";
import Overlay from "../../../Overlay/Overlay";
import {VehicleGrayButton} from "../../VehicleGrayButton/VehicleGrayButton";
import {ButtonTariff} from "../../ButtonTariff/ButtonTariff";
import {StyledLinkBack} from "../../../../../../components/Button/Button.styled";
import HeaderWithAddresses from "../HeaderWithAddresses/HeaderWithAddresses";
import VehicleBlockManual from "../../VehicleBlockManual/VehicleBlockManual";

var cnt = 0;
interface IProps {
    onClose: ()=>void;
    onNext: ()=> void;
}
export const VehicleSelectManual: FC<IProps> = ({onClose, onNext}) => {
    // console.log('VehicleSelectManual');
    const dispatch = useAppDispatch();
    const currentOrder : SendOrderInfo = useAppSelector(currentOrderData);
    const tarrifs : CTariffData[] = useAppSelector(getTariffs);
    const is_tariff_loading : boolean = useAppSelector(getTariffLoading);
    const { carTypesTree, tariffTypes } = useAppSelector(newUserOrderSelector);
    let  categories : CarTypeTreeQueryResponse[]  = carTypesTree ? carTypesTree : [];
    let selectedCategoryID  =  currentOrder.car_type_id;
    let selectedBodyID  = currentOrder.body_option_id ;
    const cost =  getCost(tarrifs, currentOrder.tariff_type_id);
    const [  calcOrderRequestAll  ] = useCalcOrderRequestAllMutation();

    const onVehicleParamChange = ()=>{
        if ( selectedCategoryID && selectedBodyID) getTariffInfo();
    }

    const getTariffInfo = async () => {
        dispatch(setTariffLoading(true));
        let a = cnt++;
        let res = await wait_before_update(3000);
        console.log('getTariffInfo cnt res',  a, res)
        if (res) {

            let data = {...currentOrder};
            console.log('getTariffInfo data.car_type_id = ', data.car_type_id);
            if (!data.date)
                data.date='2022-01-24T15:24:00T15:39:00';

            if (!selectedCategoryID)
                data.car_type_id = categories.length ? categories[0].id : '';
            console.log('getTariffInfo data.car_type_id = ', data.car_type_id);
            calcOrderRequestAll(data)
                .unwrap()
                .then(x => {
                    dispatch(setTariffLoading(false));
                    dispatch(setTariffsData({data: x, names:tariffTypes}))
                }).catch(e=> dispatch(setTariffLoading(false)))
        }
        console.log('getTariffInfo selectedCategoryID ',  selectedCategoryID);
    };

    return (
        <>
            <div className={s.vehicleSelectWrapper}>
                <HeaderWithAddresses type={true} />
                <VehicleBlockManual onChange={onVehicleParamChange}/>
                {selectedCategoryID && selectedBodyID &&
                    <ButtonTariff onNext={onNext} onBack={onClose} disabled={tarrifs.length ==0} cost={cost+''} type={'blue'} isLoading={is_tariff_loading}/>
                }
                {
                    (!selectedCategoryID || !selectedBodyID) &&
                    <div className={s.needMoreData}>
                        <div className={s.akaButton}>Для продолжения необходимо выбрать категорию и тип кузова</div>
                        <StyledLinkBack onClick={onClose}>вернуться назад</StyledLinkBack>
                    </div>
                }

            </div>
            <Overlay onClick={()=>onClose()}/>
        </>
    )
}
