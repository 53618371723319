import React, {FC, memo, useEffect, useState} from 'react'
import {useCalcOrderRequestAllMutation, useCalcOrderRequestMutation} from '../../../../../../api/orderApi'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/hooks'
import {
    DropDownListItem,
    newUserOrderSelector,
    orderDataSelector,
    setNewOrderCalcResponse
} from '../../NewUserOrderSlicer'

import s from './OrderCompletion.module.scss'
import {SendOrderInfo} from "../../CreateOrderType";
import {currentOrderData, setOrderAdditional, setTariffsData, wait_before_update} from "../../CreateOrderSlicer";
import {VehicleGrayButton} from "../../VehicleGrayButton/VehicleGrayButton";
import {Tariffs} from "./Tariffs";
import {AutoDropDownGreyList} from "../../AutoDropDownGreyList/AutoDropDownGreyList";

export const AdditionalRequirements: FC<{onChange:()=>void}> = ({onChange}) => {

    const dispatch = useAppDispatch()
    const CargoManList : DropDownListItem[] = [
        {id:'', text:'не нужны'},
        {id:'1', text:'водитель-грузчик'},
        {id:'2', text:'2'},
        {id:'3', text:'3'},
        {id:'4', text:'4'},
    ]

    const { aditionalRequirements, tariffTypes } = useAppSelector(newUserOrderSelector),
          currentOrder :SendOrderInfo = useAppSelector(currentOrderData);

    const getCargoManValue = () => {
         let v = currentOrder.additional_requirements.find(z=> z.id == 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942');
         if (v && v.id!=='') return '' + (+v.id + 1);
         return '-1';
    }
    const onSelectCargoMan = (item: DropDownListItem) => {

        let additional = currentOrder.additional_requirements.filter(x => x.id != 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942');
            additional = [...additional];

        console.log('item', item, additional);
        if (item.id != '') {
            if (item.id != '1') {
                additional = [...additional, {id: 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942', value:  +(+item.id - 1)}]
            }
            additional = additional.filter(x => x.id != 'aa507685-21df-11ea-a9ad-00155d8e4e05');
            additional = [...additional, {id: 'aa507685-21df-11ea-a9ad-00155d8e4e05', value: true}]
        }
        console.log('item', item, additional);
        dispatch(setOrderAdditional(additional));
        if (onChange) onChange();
    };

    const updateAdditional = (item: {id:string, value: any}) => {
        let index = currentOrder.additional_requirements.findIndex(z=> z.id == item.id);
        let additional = index!=-1 ? currentOrder.additional_requirements.filter(x=> x.id != item.id) : [... currentOrder.additional_requirements, item];
        if (item.id == 'aa507685-21df-11ea-a9ad-00155d8e4e05' && index!=-1) {
            additional =  additional.filter(x=> x.id != 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942')
        }
        dispatch(setOrderAdditional(additional));
        if (onChange) onChange();
    };

    console.log('aditionalRequirements', aditionalRequirements);
    console.log('currentOrder.additional_requirements ', currentOrder.additional_requirements);
    return (
        <>
            <div className={s.additionalRequirements}>
                <AutoDropDownGreyList type={'gray'} onSelect={onSelectCargoMan} list={CargoManList} title={'Грузчики'}
                                       value={ getCargoManValue()}
                                       isSelected={currentOrder.additional_requirements.some(z=> z.id == 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942')} />

                {aditionalRequirements.filter(x=> x.id!='c01ac72c-fcf4-11e7-9ae7-e41f13c2b942')
                    .map(x =>
                    <VehicleGrayButton key={x.id} text={x.name}
                                       isSelected={currentOrder.additional_requirements.findIndex(z=> z.id == x.id) != -1}
                                       onClick={() => updateAdditional({id:x.id, value: true})} />)}
            </div>

        </>
    )
}
