import React, {FC, memo, useCallback, useState} from 'react'
import s from './OrderIsReady.module.scss'
import ShowOrderDetailsIcon from '../../../../../../assets/icons/ShowOrderDetailsIcon.svg'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/hooks'
import {
    currentOrderData,
    getCost,
    getTariffs,
    setClearData,
    setOrderMode,
    setOrderDataManual,
    setOrderRoutes,
    setTariffsData,
    setOrderAdditional,
    setTariffLoading,
    setContactInformation,
    setPackInfo
} from "../../CreateOrderSlicer";
import {CContactInformation, CPackInfo, CTariffData, EnumOrderMode, SendOrderInfo} from "../../CreateOrderType";
import {PaymentDetails} from "../PaymentDetails/PaymentDetails";
import {cookiesOrderId} from "../../../../../../api/api.constants";
import {useCookies} from "react-cookie";

export const OrderIsReady: FC<{onClose:()=>void}> =  ({onClose}) => {

    const dispatch = useAppDispatch();
    let currentOrder : SendOrderInfo = useAppSelector(currentOrderData);
    let tariffs  = useAppSelector(getTariffs);

    const cost = getCost(tariffs, currentOrder.tariff_type_id);
    const [isShowPaymentDetails, setShowPaymentDetails] = useState<boolean>(false);
    const [cookies, setCookie] = useCookies([cookiesOrderId])

    const onDetailsClick = useCallback(() => {
        setShowPaymentDetails(true)
    }, []);

    const onOKClick = useCallback(() => {
        setCookie(cookiesOrderId, '', { path: '/' });
        dispatch(setOrderMode(EnumOrderMode.None));
        dispatch(setOrderDataManual(new SendOrderInfo()));
        dispatch(setTariffsData({data:[], names:[]}));
        dispatch(setTariffLoading(false));

        onClose();
    }, []);

    return (
        <>
            <div className={s.orderIsReadyWrapper}>
                <div className={s.orderIsReadyOverlay}/>
                <div className={s.orderIsReadyBody}>
                    <div className={s.orderCoast}>
                        <div>
                            Заказ оформлен
                        </div>
                        <div>
                            {`${cost}`} &#8381;
                        </div>
                    </div>
                    <div className={s.orderDetails}  onClick={()=>onDetailsClick()}>
                        Детализация заказа
                        <img alt="Перейти к детализации заказа" src={ShowOrderDetailsIcon} />
                    </div>
                    <div className={s.orderDetailsText}>
                        <p>В течении дня заказ будет назначен водителю.</p>
                        <p>До назначения вы можете частично изменить заказ или отменить.</p>
                    </div>
                    <div className={s.bottomWrapper}>
                        <button onClick={()=>onOKClick()}>
                            OK
                        </button>
                    </div>
                </div>
            </div>
            {isShowPaymentDetails && <PaymentDetails onClose={()=>setShowPaymentDetails(false)} />}
        </>
    )
};
