import styled from 'styled-components'

const StyledSvg = styled.svg`
  cursor: pointer;
`

interface IPointProp {
  color?: string
  width?: string
  height?: string
}

export const Point = ({ color, width, height }: IPointProp) => {
  return (
    <StyledSvg
      width={width || '10%'}
      height={height || '10%'}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='8' cy='8' r='6' stroke={color || '#FF9900'} strokeWidth='4' />
    </StyledSvg>
  )
}
