import { IOrderListFilterType } from '../OrderList/OrderList.types'

export interface IOrderItemProp {
  id: string
  timeFrom: string
  timeTo: string
  from: string
  to: string
  status: string
  amount: number
  carTypeId: string
  filterStatus: IOrderListFilterType
}

export enum EnumOrderStatusID {
  DriverSearching = 0, DriverFound = 1,Executing = 2, Completed = 3
}
export enum IOrderItemStatusType {
  // onPoint = 'На точке',
  // isCompleted = 'Выполнен',
  // isAccepted = 'Заказ принят',
  // isProcessed = 'Заказ обрабатывается',
  // isWaitingDocuments = 'Ожидание документов',
  // isPayd = 'Оплачен',
  // isNotPayd = 'Не оплачен'
  isNew = 'Новый',
  isApproved = 'Утвержден',
  isDistributed = 'Распределен',
  isCompleted = 'Выполнен',
  isCanceled = 'Отменен',
  isNotDone = 'Не Выполнен',
  isDistributedPre = 'Распределен Предварительно',
  isCanceledApplication = 'Отменена Заявка',
  isPayd = 'Оплачен',
  isExecuting = 'Заказ в работе'
}

export interface IOrderItemStatusProps {
  status?: string
  filterStatus?: string
}

export interface IOrder {
  [index: string]: string | number | string[]
  id: string
  date_from: string
  date_to: string
  car_type_id: string
  car_body_option_id: string
  status: string
  amount: number
  adress: string[]
}

export interface ICategory {
  name: string
  id: string
  length_from: number
  length_to: number
  weight_from: number
  weight_to: number
  width_from: number
  width_to: number
  height_from: number
  height_to: number
}
