import {IErorrResult, orderApi, useGetOrderListMutation, useGetTariffTypesMutation} from "./api/orderApi";
import {IOrder} from "./modules/Orders/components/OrderItem/OrderItem.types";
import { setCurrentOrdersBeingExecuted } from "./modules/Orders/components/OrderList/orderListSlice";


const testCurrentTimeInInterval = (date_from: string, date_to: string) : boolean => {
    const today = new Date();
    let dateFrom = new Date(date_from);
    let dateTo = new Date(date_to);
    let diffTimeFrom = Math.abs(today.getTime() - dateFrom.getTime())/1000;
    let diffTimeTo = Math.abs(today.getTime() - dateTo.getTime())/1000;
    return (diffTimeFrom < 1800 || diffTimeTo < 1800 ||  (today.getTime() > dateFrom.getTime() && today.getTime() < dateTo.getTime() ));
};

export const redrawCarsOnMap = async (clientId: string, current_orders: IOrder[], dispatch: any) => {

};

export const checkOrderExecuted = async (clientId: string, current_orders: IOrder[], dispatch: any, getOrderList: any) => {

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    let params = {
        clientId: clientId  ,
        startFrom: today.toDateString(),
        startTo: tomorrow.toDateString(),
        count: 1000,
    };
    let res = await getOrderList(params).unwrap();

    const errorResult = res as IErorrResult;
    let orderList = res as IOrder[];
    if (errorResult && errorResult.status)  return ;
    orderList = orderList.filter(order=> testCurrentTimeInInterval(order.date_from, order.date_to));
    if ( orderList.filter(order=> !current_orders.some(x=> x.id === order.id) ).length > 0)
        dispatch(setCurrentOrdersBeingExecuted( orderList));
};

export const requestWakeLock = async () => {
    try {
        //@ts-ignore
        const wakeLock = await navigator.wakeLock.request('screen');
    } catch (err) {
        // the wake lock request fails - usually system related, such low as battery
        console.log(`${err.name}, ${err.message}`);
    }
};
