import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InfoMessageType } from "./InfoMessage.types";
import { RootState } from "../../app/redux/store";

export interface InfoMessageState {
  isOpened: boolean;
  type: InfoMessageType;
  title: string;
  text: string;
}

const initialState: InfoMessageState = {
  isOpened: false,
  type: InfoMessageType.SUCCESSESED,
  title: "",
  text: "",
};

export const showErrorMessage = (message:string, dispatch:any, title: string = "") => {
  dispatch(openInfoMessage({ type: InfoMessageType.FAILIED,  title: title, text: message }));
  return showMessageInfoPromise();
}

export const showInfoMessage = (message:string, dispatch:any, title: string = "") => {
  dispatch(openInfoMessage({ type: InfoMessageType.SUCCESSESED,  title: title, text: message }));
  return showMessageInfoPromise();
}
export const  showMessageInfoPromise = () => {
   //@ts-ignore
   window.message_is_open = true;
   return new Promise(resolve => {
    let timer = setInterval(()=>{
      //@ts-ignore
      if (window.message_is_open) return ;
      clearInterval(timer);
      resolve(true);
    }, 100)
  })
};

export const infoMessageSlice = createSlice({
  name: "infoMessage",
  initialState,
  reducers: {
    openInfoMessage(
      state,
      action: PayloadAction<Omit<InfoMessageState, "isOpened">>
    ) {
      state.isOpened = true;
      state.title = action.payload.title;
      state.text = action.payload.text;
      state.type = action.payload.type;
    },
    closeInfoMessage(state) {
      state.isOpened = false;
      state.type = InfoMessageType.SUCCESSESED;
      state.title = "";
      state.text = "";
    },
  },
});

export const infoMessageSelector = (state: RootState) => state.infoMessage;

export const { openInfoMessage, closeInfoMessage } = infoMessageSlice.actions;

export default infoMessageSlice.reducer;
