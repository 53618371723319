import React, { FC, useCallback, memo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/hooks'
import { newUserOrderSelector, setSelectedTariff, TariffTypesCost } from '../../NewUserOrderSlicer'
import s from './OrderCompletion.module.scss'
import SelectedTariffRow from '../../../../../../assets/svg/SelectedTariffRow.svg'
import UnselectedTariffRow from '../../../../../../assets/svg/UnselectedTariffRow.svg'
import {CTariffData} from "../../CreateOrderType";
import { getTariffs, setOrderTariff} from "../../CreateOrderSlicer";
import {Rings} from "react-loader-spinner";

export const TariffRow: FC<{ data: CTariffData, PM: boolean, selected: boolean, isLoading:boolean }> = memo(({ data, PM, selected, isLoading}) => {

    const dispatch = useAppDispatch()

    const tariffs = useAppSelector(getTariffs)

    const onRowClick = useCallback(() => {
        dispatch(setOrderTariff(data))
    }, [data])

    return (
        <div className={`${s.tariffRow} ${selected ? PM ? s.PMRow : s.selectedRow : ''} ${!selected && PM && s.tarifPMRow}`} onClick={onRowClick}>
            <img alt={selected ? 'выбранный тариф' : 'невыбранный тариф'} src={selected ? SelectedTariffRow : UnselectedTariffRow} />
            <div className={s.tariffName}>
                <span>{PM ? data.tariff_name.split(' ')[0] : data.tariff_name}</span>
                {selected && <a href="https:\\google.com" target="_blank">Подробные условия</a>}
            </div>
            {!isLoading &&
            <div className={`${s.cost} ${PM && !selected && s.costPM}`}>
                <span>{PM && `${tariffs.filter(x => x.tariff_type_id === data.tariff_type_id).length ? tariffs.filter(x => x.tariff_type_id === data.tariff_type_id)[0].cost : 0} ${String.fromCodePoint(0x20BD)}`}</span>
                <span>{tariffs.filter(x => x.tariff_type_id === data.tariff_type_id).length ? tariffs.filter(x => x.tariff_type_id === data.tariff_type_id)[0].cost : 0} &#8381;</span>
            </div>}
            {isLoading && <Rings   color={selected ? "#fff" : "#777"} width={70} height={70} /> }
        </div>
    )
})
