import styled from 'styled-components'
import { DisabledDivForButton } from "../../modules/Home/components/Code/Code.styled"
import { StyledButtonBottomBlock } from '../Button/Button.styled'
import { StyledModal, StyledModalContent } from '../Modal/Modal.styled'
import { IStyledModalProps } from '../Modal/Modal.types'

export const StyledRatingWindow = styled(StyledModal)<IStyledModalProps>`
  background: none;
  bottom: 0;
  overflow: auto;
  max-height: 100%;
  min-height: 557px;
  max-width:100%;
  width:100%;
  left:0;
  position: fixed;
  height:auto;
  top:unset;
`
export const StyledRatingWindowContent = styled(StyledModalContent)`
  height: 557px;
  top: unset;
  border: 1px solid var(--medium-grey);
`

export const StyledRaitinHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--medium-grey);
  padding: 24.5px 20px 24.5px 26px;
  font-size: 24px;
`
export const StyledRaitinHeaderAmount = styled.div`
  font-weight: 700;
`
export const StyledRaitinDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--medium-grey);
  padding: 21px 27px 21px 26px;
  font-size: 17px;
`
export const StyledRatingMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 57.5px 50px 56px;
  font-size: 17px;
  text-align: center;
`
export const StyledRatingExecutor = styled.div`
  font-size: 14px;
  color: var(--grey);
  margin-bottom: 4px;
`

export const StyledRaitinStars = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 5px;
  margin-top: 16px;
  margin-bottom: 27px;
`
export const StyledRaitinMistakes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 70px;
  margin-top: 28px;
  margin-bottom: 50px;
  font-size: 14px;
  font-weight: 500;
  color: var(--grey);
  white-space: nowrap;
  align-items: center;
`
