const host = window.location.host
  .replace("www.", "")
  .split(".")
  .reverse()
  .splice(0, 2)
  .reverse()
  .join(".");
const apiHost = host.indexOf("localhost") >= 0 ? "citycarrier.ru" : host;
export const apiOrderUrl = `https://api2.${apiHost}/PublicOrdersAPI`;
export const apiDriverUrl = `https://api2.${apiHost}/PublicDriversAPI`;
export const daDataToken = "4907ed3e0ba286c611e621c3db1588fe3ce7f53c";
export const hereMapApiKey = host.indexOf("localhost") >= 0  ? "MiWaYuPKblM-f_Ih7SIJ07tcDkQJvZt13fSxOKdxy6E" : "CaK4qiOemHTkZvAJH6zv2wObp4KFkq7eEbtL4hI_T24";
export const daDataUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs';

export const cookiesClientId = 'clientId';
export const cookiesOrderId = 'orderId';
export const cookiesDefaultUserId = 'selectedUserId';
export const cookiesClientPhone = 'clientPhone';
export const  IS_DEBUG_MODE = false;
export const  CONST_TIME_1C = 62135596800595;
// export const  DEBUG_ORDER_ID =  "e6ca65eb-65cd-11ed-8ebe-00155d010f1b";
// export const  DEBUG_ORDER_ID =  "248ebdd9-1c36-4b11-89bc-bdeff33c0db7";
export const  DEBUG_ORDER_ID =  "c306bcb2-7f7d-4bbb-96ea-3527acf26a4a";
export const  DEBUG_PHONE =  "79031842469";

export const  COUNT_MAX_DISTANCE = 6;
export const  MAX_DISTANCE_FOR_NEW_ROUTE_IN_METERS = 300;
export const  NAVIGATION_GET_COORDINATE_TIME_IN_MS = 966;
export const  TIME_FOR_ROUTE_CACHE_SECONDS = 10;
export const  NEED_NEW_ROUTE :{max_distance_in_meters:number, count:number}[] =
                                [{max_distance_in_meters:300, count: 6}, {max_distance_in_meters:15, count: 30}];
