import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import {CallIcon, ChatIcon} from '../../../../assets/icons/ChatIcon '
import Button from '../../../../components/Button'
import {StyledButtonBottomBlock, StyledLinkBack} from '../../../../components/Button/Button.styled'
import {
  ButtonBackgroundColor,
  ButtonColor,
  ButtonHeight,
  ButtonTypeEvent
} from '../../../../components/Button/Button.types'
import OrderCar from '../OrderCar/OrderCar'
import Driver from '../Driver/Driver'
import OrderAmount from '../OrderAmount/OrderAmount'
import {getBodyName, getCategoryName, getCategorySvg} from '../OrderItem/categoryHelper'
import { orderListSelector } from '../OrderList/orderListSlice'
import {
    StyledVihecleArchive, StyledVihecleChatButtons, StyledVihecleModalWindow, StyledVihecleModalWindowTopDecor,
    StyledVihecleParametersColumn,
    StyledVihecleParametersRow,
    StyledVihecleSup
} from './Vehicle.styled'
import { vehicleWindowSelector } from './vehicleWindowSlice'
import Overlay from "../../../Home/components/Overlay/Overlay";
import React, {FC, useState} from "react";
import {EnumOrderStatusID} from "../OrderItem/OrderItem.types";
import {IOrderFull} from "../Order/Order.types";
import {newUserOrderSelector} from "../../../Home/components/NewUserOrder/NewUserOrderSlicer";
import OrderAddressList from "../OrderAddressList/OrderAddressList";
import OrderCargoBlock from "../OrderCargoBlock/OrderCargoBlock";
interface IProps {
    onClose:()=>void,
    order:IOrderFull | undefined,
    onAddressClick:()=>void,
    onAmountClick:()=>void,
}
export const VehicleWindow : FC<IProps> = ({onClose, order, onAddressClick, onAmountClick}) => {
  const { vehicle, driver, amount, carTypeId, tariffTypeId } = useAppSelector(vehicleWindowSelector)
  const { model, number, height, width, max_weight, length, volume } = vehicle
  const dispatch = useAppDispatch()
  const { categories } = useAppSelector(orderListSelector)
  const {carBodyOptions} = useAppSelector(newUserOrderSelector);

  return (
      <>
        <StyledVihecleModalWindow className="vehicle_window_order_created">
            <StyledVihecleModalWindowTopDecor>
              {order && <OrderAddressList order={order} isNotClickable={true} onClick={onAddressClick}/>}
              {order && <OrderCargoBlock order={order}/> }
              <OrderCar
                model={model || ''}
                number={number || ''}
                height={height}
                width={width}
                length={length}
                bodyName={getBodyName(carBodyOptions, order ? order.body_option_id : '')}
                categoryName={getCategoryName(categories, carTypeId)}
                category={getCategorySvg(categories, carTypeId)}
              />
              <StyledVihecleParametersRow>
                <StyledVihecleParametersColumn>
                  <span>Длина</span>
                  <span>{length} м</span>
                </StyledVihecleParametersColumn>
                <StyledVihecleParametersColumn>
                  <span>Ширина</span>
                  <span>{width} м</span>
                </StyledVihecleParametersColumn>
                <StyledVihecleParametersColumn>
                  <span>Высота</span>
                  <span>{height} м</span>
                </StyledVihecleParametersColumn>
                <StyledVihecleParametersColumn>
                  <span>Объём</span>
                  <span>
                    {volume} м<StyledVihecleSup>3</StyledVihecleSup>
                  </span>
                </StyledVihecleParametersColumn>
              </StyledVihecleParametersRow>
              {driver && <Driver driver={driver} />}
              {!order || order.status_id != EnumOrderStatusID.Executing ?
                <StyledVihecleArchive>
                    <ChatIcon/>
                    <span> Архив чата</span>
                </StyledVihecleArchive> :
                <StyledVihecleChatButtons>
                    <StyledVihecleArchive style={{width:'50%'}}>
                        <ChatIcon/>
                        <span> Открыть чат</span>
                    </StyledVihecleArchive>
                    <StyledVihecleArchive  style={{width:'50%', background:'#00BB61', color:'#fff'}}>
                        <CallIcon/>
                        <span> Позвонить</span>
                    </StyledVihecleArchive>
                </StyledVihecleChatButtons>
              }

              <OrderAmount amount={amount} isGrey={true} isFullHeightAmount={false} tariffTypeId={tariffTypeId} onClick={onAmountClick} />
              <StyledButtonBottomBlock style={{background:'#ecedef', paddingBottom:'33px'}}>
                <Button
                  buttonTypeEvent={ButtonTypeEvent.BUTTON}
                  buttonBackgroundColor={ButtonBackgroundColor.RED_GRADIENT}
                  buttonColor={ButtonColor.WHITE}
                  buttonHeight={ButtonHeight.MID_HEIGHT}
                  children='Закрыть'
                  onClick={  onClose}
                />

              </StyledButtonBottomBlock>
            </StyledVihecleModalWindowTopDecor>
        </StyledVihecleModalWindow>
        <Overlay is_visible={true} onClick={onClose}/>
      </>
  )
}
