import React, {FC} from "react";

import ToTarifsArrow from "../../../../../assets/svg/ToTarifsArrow.svg";
import './ButtonTariff.css'
import {Rings} from "react-loader-spinner";


interface IProps {
    onNext:()=>void
    onBack:()=>void
    cost:string
    disabled:boolean
    isLoading:boolean
    type:string
}
export const ButtonTariff : FC<IProps> = ({onNext,onBack, cost,disabled,isLoading,type}) => {

    const onClickNext = () =>{
        if (disabled || isLoading) return;
        onNext();
    }

    return (
        <div className={'button_tariff ' + type}>
            <div className={`button ${disabled || isLoading ? 'disabled' : ''}`} onClick={onClickNext}>
                <div>
                    Тарифы
                </div>
                {isLoading ? <Rings   color="#777" width={80} height={80} /> :
                <div className={'coast'}>
                    {cost} &#8381;
                    <img src={ToTarifsArrow} />
                </div> }

            </div>
            <div className={'linkWrapper'} onClick={()=>onBack()}>вернуться назад</div>
        </div>
    )
}
