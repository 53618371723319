import styled from 'styled-components'
import Checkbox from '../../assets/svg/Checkbox.svg'
import CheckboxChecked from '../../assets/svg/CheckboxChecked.svg'

export const StyledCheckbox = styled.div`
  display: flex;
  text-align: initial;
  input {
    margin-right: 15px;
    margin-left: 15px;
  }
  input[type='checkbox'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  label{
    display: flex;
    flex-direction: row;
  }
  
  label:before {
    content: ' ';
    background: url(${Checkbox}) no-repeat 0 0;
    color: #000;
    display: inline-block;
    position: relative;
    width: 46px;
    height: 24px;
    margin: 0 16px;
  }
  input[type='checkbox']:checked + label:before {
    background: url(${CheckboxChecked}) no-repeat 0 0;
    border: none;
  }
`
