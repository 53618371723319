import styled from "styled-components";

const StyledSvg = styled.svg`
  cursor: pointer;
`;
interface ILateIconProps {
  color?: string;
}
export const LateIcon = ({ color }: ILateIconProps) => {
  return (
    <StyledSvg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="18"
        stroke={color ?? "#888E99"}
        strokeWidth="4"
      />
      <path
        d="M28.75 25L18.75 20V10"
        stroke={color ?? "#888E99"}
        strokeWidth="4"
      />
    </StyledSvg>
  );
};
