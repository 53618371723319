import styled from 'styled-components'
import { TextAlignType } from '../Form/Form.types'
import {
  IStyledModalFullArrowLeftProps,
  IStyledModalFullProps
} from './ModalFull.types'

export const StyledModalFull = styled.div<IStyledModalFullProps>`
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-color: ${props => props.background || 'var(--white)'};
  z-index: 1000;
  top: 0;
`

export const StyledModalFullArrowLeft = styled.div<  IStyledModalFullArrowLeftProps>`
  padding-top: 35px;
  padding-left: ${props => props.paddingLeft || '17px'};
  display: flex;
  background: ${props => props.background || 'var(--white)'};
  z-index: 999;
  width: 100vw;
  position: ${props => (props.isAbsolute ? 'absolute' : 'relative')};
  border-radius: ${props => props.borderRadius || ''};
  align-items: center;
  padding-bottom: ${props => (props.isAbsolute ? '16px' : '')};
`

export const StyledModalFullTitle = styled.div<IStyledModalFullProps>`
  width: 100%;
  text-align: ${props => props.textAlignTitle || TextAlignType.CENTER};
  font-weight: 500;
  font-size: ${props => props.fontSizeTitle || '20px'};
  align-items: center;

  & > .title-order-number {
    font-size: 17px;
  }

  & > .title-order-status {
    font-size: 14px;
  }

  & > .title-order-status-green {
    color: var(--green);
  }
  & > .title-order-status-red {
    color: var(--red);
  }
`

export const StyledModalFullCloseIcon = styled.div`
  position: absolute;
  right: 24px;
`
