import { EditClientsFormField } from '../EditClientsForm/editClientsFormSlice'

export interface IProfileItem {
  key: string
  editClientsFormField: EditClientsFormField
  type: ProfileItemType
  text: string
  smallText?: string
  value?: string | null  
  editClientsFormTitle?: string
  isNotNeedBorder?: boolean
  isRequire?: boolean
  isNotEditable?: boolean
}

export enum ProfileItemType {
  user,
  company,
  contract,
  requisites,
  addButton,
  logout,
  empty
}

export interface IStyledProfileListItemProp {
  isNotNeedBorder?: boolean,
  isNotEditable?: boolean
}

export interface IProfile {
  users: IUser[]
  companies: ICompany[]
}

export const createNewCompany = ({ tin, id }: ICompany) => {
  const newCompany: ICompany = {
    id: id,
    tin: tin,
    // contract: {
    //   id: '',
    //   date: '',
    //   details: '',
    //   number: ''
    // },
    address: '',
    email: '',
    phone: '',
    site: ''
  }
  return newCompany
}

export interface IUser {
  [index: string]: string | undefined
  id: string
  client_id?: string
  phone: string
  full_name: string
  email: string
  position: string
  workPhone?: string
}

export interface ICompany {
  [index: string]: string | undefined //| {}
  id: string
  name?: string
  tin: string
  // contract?: {
  //   id: string
  //   number: string
  //   date: string
  //   details: string
  // }
  address?: string
  email?: string
  phone?: string
  site?: string
}
export const getCompanyByInn = (profile: IProfile, inn: string) => {
  const { companies } = profile
  const findIndex = companies.findIndex(el => el.tin === inn)
  return companies[findIndex]
}
export const getCompanyById = (profile: IProfile, id: string) => {
  const { companies } = profile
  const findIndex = companies.findIndex(el => el.id === id)
  return companies[findIndex]
}
export const getCompanyIndexById = (profile: IProfile, id: string) => {
  const { companies } = profile
  return companies.findIndex(el => el.id === id)
}
export const getFirstUser = (profile: IProfile) => {
  let { users } = profile

  if (!users.length) {
    const user: IUser = {
      email: '',
      phone: '',
      full_name: '',
      id: '',
      position: ''
    }
    users = [user]
  }
  return users[0]
}
export const addNewCompany = (profile: IProfile, company: ICompany) => {
  profile.companies.push(company)
}
export const updateInn = (profile: IProfile, id: string, newInn: string) => {
  const oldCompanyIndex = getCompanyIndexById(profile, id)
  let { companies } = profile
  const { tin } = companies[oldCompanyIndex]
  if (tin !== newInn) {
    const newCompany = createNewCompany({ tin: newInn, id: '' })
    const before = companies.slice(0, oldCompanyIndex)
    const after = companies.slice(oldCompanyIndex + 1)
    profile.companies = [...before, ...after, newCompany]
  }
}
export const updateCompaniesIds = (profile: IProfile, companiesWithId: ICompany[]) => {
  const updatedCompanies: ICompany[] = []
  companiesWithId.map(newCompany => {
    const oldCompany = getCompanyByInn(profile, newCompany.tin)
    oldCompany.id = newCompany.id
    updatedCompanies.push(oldCompany)
  })
  profile.companies = updatedCompanies
}
export const updateProfile = (
  profile: IProfile,
  editClientsFormField: string,
  id: string,
  value: string,
  type: ProfileItemType,
  selectedCompany?: string
) => {
  const user = getFirstUser(profile)

  switch (type) {
    case ProfileItemType.user:
      user[id] = value
      break
    case ProfileItemType.company:
      switch (editClientsFormField) {
        case EditClientsFormField.inn:
          updateInn(profile, id, value)
          break
        case EditClientsFormField.addCompany:
          const newCompany = createNewCompany({ tin: value, id: '' })
          addNewCompany(profile, newCompany)
          break
        default:
          const company = selectedCompany
            ? getCompanyByInn(profile, selectedCompany)
            : null
          if (company) company[editClientsFormField] = value
          break
      }
  }
}
