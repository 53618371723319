import React, {useState} from "react";
import {CloseIcon} from "../../../../assets/icons/CloseIcon";
import {CUser, CUserProfile, editingChars} from "./userProfile.type";
import InputMask from "react-input-mask";
import {ThreeDots} from "react-loader-spinner";
import {useAppDispatch, useAppSelector} from "../../../../app/redux/hooks";
import {setUserProfile, userProfileSelector} from "./userProfileSlice";
import {showErrorMessage} from "../../../../components/InfoMessage/infoMessageSlice";
import {useClientsProfileMutation} from "../../../../api/orderApi";

interface IProps{
    user:CUser
    company_id:string
    onClose : ()=>void
}

const ProfileAddUser : React.FC<IProps> = ({user, company_id, onClose}) =>{
    const dispatch = useAppDispatch();
    const userProfile = useAppSelector(userProfileSelector);

    const [isSaving, setSaving] = useState(false);
    const [localState, setLocalState] = useState<CUser>(user);
    const [profile] = useClientsProfileMutation();

    const saveData = () => {

        let data : CUserProfile = {...userProfile.profile, users: [...userProfile.profile.users.filter(x=> x.id != user.id)]};
        data.users.push({...localState, company_id: company_id});
        setSaving(true);
        profile(data)
            .unwrap()
            .then((res)=> {
                setSaving(false);
                if (!res || typeof res != 'object' ) return showErrorMessage( 'Ошибка сохранения данных', dispatch);
                if (res.status=='error') return showErrorMessage( 'Ошибка сохранения данных: ' + res.error_message, dispatch);
                dispatch( setUserProfile( data) );
                onClose();
            })
            .catch(e=>{ setSaving(false); return showErrorMessage( 'Ошибка сохранения данных', dispatch);});
    };

    const canSave = () => {
        return localState.full_name != '' && localState.phone!='';
    }
    return (
        <div className="profile_edit_one_record">
            <div className="profile_edit_one_record_window">
                <div className="title">
                    <div className="inner-text">
                        <div>{user.full_name ? 'Данные' : 'Добавление'} сотрудника компании</div>
                        <CloseIcon onClick={onClose}/>
                    </div>
                </div>
                <div className="content">
                    <div >
                        <input  placeholder="ФИО сотрудника" type="text" value={localState.full_name}
                                onChange={(e)=> setLocalState((s)=>({...s, full_name: e.target.value}))}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                    if ((!/[а-яА-Я ]/.test(e.key) && !editingChars.includes(e.key))) {
                                        e.preventDefault();
                                    }
                                }}
                        />
                    </div>
                    <div>
                        <InputMask className={''} mask='+7\9999999999' value={localState.phone}
                                   onChange={(e)=> setLocalState((s)=>({...s, phone: e.target.value}))}
                                   placeholder={'Телефон для входа в аккаунт'} name='phone' />
                    </div>
                    <div>
                        <InputMask className={''} mask='+7\9999999999' value={localState.workPhone}
                                   onChange={(e)=> setLocalState((s)=>({...s, workPhone: e.target.value}))}
                                   placeholder={'Рабочий телефон'} name='phone' />
                    </div>
                    <div >
                        <input  placeholder="Email сотрудника" type="text" value={localState.email}
                                onChange={(e)=> setLocalState((s)=>({...s, email: e.target.value}))}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                    if ((!/[a-zA-Z\@\.-0-9]/.test(e.key) && !editingChars.includes(e.key))) {
                                        e.preventDefault();
                                    }
                                }}
                        />
                    </div>
                    <div >
                        <input  placeholder="Должность сотрудника" type="text" value={localState.position}
                                onChange={(e)=> setLocalState((s)=>({...s, position: e.target.value}))}
                                onKeyDown={(e: React.KeyboardEvent) => {
                                    if ((!/[а-яА-Я0-9]/.test(e.key) && !editingChars.includes(e.key))) {
                                        e.preventDefault();
                                    }
                                }}
                        />
                    </div>
                    <div className="button-area small-padding-up">
                        {!isSaving && <button onClick={saveData} disabled={!canSave()}> Сохранить </button>}
                        {isSaving && <div className='saving-button'><div style={{marginRight:10}}>Сохранение данных</div><ThreeDots  color={  "#999" } width={40} height={40}  /></div>}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProfileAddUser;
