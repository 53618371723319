import React from "react";
import "./QuestionWindow.css";
import Button from "./Button/Button";
import Overlay from "../../Overlay/Overlay";


interface IProps {
  title:string;
  onYes: ()=>void;
  onNo: ()=>void;
}

const QuestionWindow: React.FunctionComponent<IProps> = (props) => {

    return (
        <>
          <div className={'question_window'}>
            <div className={'question_window_title'} dangerouslySetInnerHTML={{__html:props.title}}/>
            <div className={'question_window_buttons'}>
                <Button type={"green"} onClick={props.onYes} size={"middle"}   > ДА</Button>
                <Button type={"error"} onClick={props.onNo} size={"middle"}   > НЕТ</Button>
            </div>
          </div>
            <Overlay zIndex={1032} onClick={props.onNo}/>
        </>
    );

}


export default QuestionWindow;
