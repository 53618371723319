

import styled from 'styled-components'

export const StyledPhoneWithImage = styled.div`
position: relative;
margin: 0 32px;
input{
    text-align: center;
}
 svg {
    position: absolute;
    right: 0.5rem;
    pointer-events: none;
    width: 2rem;
    height: 2rem;
    top: 2.5rem;
}
`