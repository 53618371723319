import styled from 'styled-components'


export const StyledVihecleModalWindow = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    left: 0;
    padding-top: 20px;
    z-index:900
`;
export const StyledVihecleModalWindowTopDecor = styled.div`
    background: #fff;
    border-radius: 10px 10px 0 0;
    width: 100%;
`

export const StyledVihecleParametersRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: var(--grey);
  grid-gap: 10px;
  padding: 12px 0;
  border-bottom: 1px solid var(--medium-grey);

`
export const StyledVihecleParametersColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledVihecleChatButtons = styled.div`
  display:flex;
`
export const StyledVihecleArchive = styled.div`
  color: var(--black);
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  padding: 21px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  border-bottom: 1px solid var(--medium-grey);
`

export const StyledVihecleSup = styled.sup`
  line-height: 0;
  font-size: 0.83em;
  vertical-align: super;
`
