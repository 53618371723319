import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/redux/store'

export interface orderAddressListState {
  isFullAddress: boolean
}

const initialState: orderAddressListState = {
  isFullAddress: false
}
export const orderAddressListSelector = (state: RootState) => state.orderAddressList

export const orderAddressListSlice = createSlice({
  name: 'orderAddressList',
  initialState,
  reducers: {
    setIsFull (state, action) {
      const isFullAddress = action.payload
      state.isFullAddress = isFullAddress
    },
    
    clear (state) {
      state.isFullAddress = false;
    }
  }
})

export const {
  setIsFull,
  clear,
} = orderAddressListSlice.actions



export default orderAddressListSlice.reducer
