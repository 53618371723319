import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/redux/store'
import { checkIsValid } from './phoneHelper'

export interface phoneInputState {
    value: string,
    isValid?: boolean | undefined
}

const initialState: phoneInputState = {
  value: '',
  isValid: undefined
}
export const phoneInputSelector = (state: RootState) => state.phoneInput
export const phoneInputSlice = createSlice({
  name: 'phoneInput',
  initialState,
  reducers: {
    setValue (state, action) {
      const value = action.payload
      state.value = value;
      state.isValid = checkIsValid(value)
    },
    clear (state) {
      state.value = ''
      state.isValid = undefined
    }
  }
})

export const { setValue, clear } = phoneInputSlice.actions

export default phoneInputSlice.reducer
