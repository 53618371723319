import React, { FC, memo, useCallback } from 'react'
import { useAppDispatch } from '../../../../../app/redux/hooks'
import { setIsHouseHas } from '../NewUserOrderSlicer'
import s from './InputAddressModal.module.scss'

export const AddressVariantItem: FC<propTypes> = memo(({ address, house, setInputValue }) => {

    const dispatch = useAppDispatch()

    const onAddressClick = useCallback(() => {
        setInputValue(address.split(',').slice(1).toString())
        house && dispatch(setIsHouseHas(true))
    }, [address, house])
    
    return (<div className={s.inputAddressVariant} onClick={onAddressClick}>{address.split(',').slice(1)}</div>
    )
})


type propTypes = {
    address: string
    house: string | null
    setInputValue: (address: string) => void
}