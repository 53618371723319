import { createSlice } from '@reduxjs/toolkit'
import { CarTypeTreeQueryResponse } from '../../../../api/orderApi'
import { RootState } from '../../../../app/redux/store'
import {useState} from "react";
import {IOrderFull, IRouteByOrderId} from "./Order.types";
import {CTariffData} from "../../../Home/components/NewUserOrder/CreateOrderType";

export interface orderState {
  id: string | undefined
  carTypesTreeQuery: CarTypeTreeQueryResponse[]
  isShowBack: boolean
  order:IOrderFull | undefined
  tariffs:CTariffData[] | undefined
  orderRouteWithTime: IRouteByOrderId | undefined
}

const initialState: orderState = {
  id: undefined,
  carTypesTreeQuery: [],
  isShowBack: false,
  order:undefined,
  tariffs:undefined,
  orderRouteWithTime: undefined,
};

export const orderSelector = (state: RootState) => state.selectedOrder

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderId (state, action) {
      state.id = action.payload
    },
    setSelectedOrder (state, action) {
      state.order = {...action.payload}
    },
    setSelectedTariffs (state, action) {
      state.tariffs = [...action.payload]
    },
    setCarTypesTree (state, action) {
      state.carTypesTreeQuery = action.payload
    },
    setIsShowBack (state, action) {
      state.isShowBack = action.payload
    },
    clear (state) {
      state.id = undefined
      state.isShowBack = false
    },
    setOrderRouteWithTime(state, action) {
      state.orderRouteWithTime = action.payload
    },
  }
})


export const { setOrderId, setCarTypesTree, setIsShowBack,clear, setSelectedOrder, setSelectedTariffs, setOrderRouteWithTime } = orderSlice.actions;

export default orderSlice.reducer
