import styled from 'styled-components'
import { IPaymentTypesItemProp, IStyledAmountText } from './AmountWindow.types'
export const StyledAmountModalWindow = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    left: 0;
    padding-top: 20px;
    z-index:900
`;
export const StyledAmountModalWindowTopDecor = styled.div`
    background: var(--light-grey);
    border-radius: 10px 10px 0 0;
    padding-top: 20px;
    width: 100%;
`

export const StyledAmountWindowBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--medium-grey);
`

export const StyledAmountWindowRow = styled.div`
  display: flex;
  font-size: 17px;
  font-weight: 400;
  justify-content: space-between;
  margin: 21px 26px 0 26px;
  &:last-child {
    margin-bottom: 21px;
  }
`
export const StyledAmountWindowRowBlue = styled(StyledAmountWindowRow)`
  background-color: var(--blue);
  color: var(--white);
  padding: 12px;
  margin: 21px 10px 0 10px;
  border-radius: 8px;
`

export const StyledAmountWindowConditions = styled.span`
  font-size: 14px;
  font-weight: 500;
  & > a {
    color: var(--white);
    text-decoration: none;
  }
  & > a:hover,
  & > a:active {
    color: var(--white);
  }
`

export const StyledAmountWindowColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
`

export const StyledAmountPaymentTypeList = styled.ul`
  list-style-type: none;
  padding: 26px 26px 10px 16px;
  display: flex;
  flex-direction: row;
`
export const StyledAmountPaymentTypeItem = styled.li<IPaymentTypesItemProp>`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 8px;
  width: 105px;
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
  color: ${props => (props.selected ? 'var(--white)' : 'var(--grey)')};
  background-color: ${props => (props.selected ? 'var(--blue)' : '')};
  margin-right: 11.5px;
  white-space: nowrap;
  &:first-child {
    margin-right: 21px;
    width: 120px;
  }
`
export const StyledAmountCount = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 14px;
  font-weight: 700;
  align-items: center;
  & > :first-child {
    opacity: 0.5;
    text-decoration: line-through;
    font-size: 17px;
  }
  & > :last-child {
    font-size: 20px;
  }
`
export const StyledAmountText = styled.div<IStyledAmountText>`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  color: ${props => props.color || 'var(--blue)'};
  padding: ${props => props.padding || '16px 0'};
`
