import styled from 'styled-components'

export const StyledArticleItem = styled.div`
  width: 100vw;
  height: 186px;
  font-size: 15px;
  font-weight: 500;
  padding: 8px 24px;
`
export const StyledArticleItemContent = styled.div`
  width: 100%;
  height: 100%;
  background: var(--white);
  border-radius: 8px;
  padding: 12px 12px 15px 12px;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;

`

export const StyledArticleItemTitle = styled.div`
  font-size: 18px;
`

export const StyledArticleItemDescription = styled.div`
  font-weight: 400;
  height: 80px;
  word-break: break-word;
`
export const StyledArticleItemLine = styled.div`
  margin: 5px -12px 0 -12px;
  border-bottom: 1px solid var(--medium-grey);
  padding: 0;
`

export const StyledArticleItemMoreDetails = styled.div`
  color: var(--blue);
`
