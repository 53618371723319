import moment from "moment";
import React, {   useState } from "react";
import {useAppSelector} from "../../../../app/redux/hooks";
import {userSelector} from "../../../../app/redux/reducers/user";
import {
    StyledOrderListFiler,
    StyledOrderListFilerItem,
    StyledOrderListFilteredContent
} from "../../../Orders/components/OrderList/OrderList.styled";


export enum IBalanceListFilterType{
    orders ="Заказы",
    bills = "Счета",
    acts = "Акты"
}

export const Balance = () => {
    const { clientId } = useAppSelector(userSelector);

    const [filter, setFilter] = useState('');

    const onClickFilter = (type: IBalanceListFilterType) => {
        setFilter(type) ;

    };



    return (
        <>
            <StyledOrderListFiler>
                {Object.values(IBalanceListFilterType).map((item, index) => (
                    <StyledOrderListFilerItem
                        key={index}
                        onClick={(e) => onClickFilter(item)}
                        selected={filter === item}
                    >
                        {item}
                    </StyledOrderListFilerItem>
                ))}
            </StyledOrderListFiler>
            <StyledOrderListFilteredContent>


            </StyledOrderListFilteredContent>
        </>
    );
};
