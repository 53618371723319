import React, {FC} from "react";
import './VehicleGrayButton.css'

interface IProps {
    isSelected: boolean;
    onClick:()=>void;
    text:string;
    isUpperCase?:boolean;
    bgColor?:string
}
export const VehicleGrayButton: React.FC<IProps> = ({isSelected,  onClick, text, isUpperCase = false,bgColor})=> {
    return (
        <div className={"vehicle_gray_button "+ ( bgColor?'vehicle_gray_button--'+bgColor : ' ') + (isSelected ? ' selected' : '')}
             onClick={()=> onClick()}
        >
            {isUpperCase ? text.toUpperCase() : text}
        </div>
    );
}
