import React, { useEffect } from 'react'
import { useState } from 'react'
import { PartySuggestions } from 'react-dadata'
import { DaDataSuggestion, DaDataParty } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'
import './react-suggestion.css'
import { daDataToken } from '../../api/api.constants'
import { useAppDispatch, useAppSelector } from '../../app/redux/hooks'
import { ClearIcon } from '../../assets/icons/ClearIcon'
import { StyledInnInput } from '../Input/Input.styled'
import { StyledInn, StyledInnList } from './Inn.styled'
import { IInnProps } from './Inn.types'
import { innSelector, setValue as setValueInSlice} from './innSlice'
export const Inn = ({ onChange }: IInnProps) => {
  const [value, setValue] = useState<DaDataSuggestion<DaDataParty> | undefined>(
    undefined
  ) 
  const { inn, companyName } = useAppSelector(innSelector)
  const dispatch = useAppDispatch()
  let ref = React.createRef<PartySuggestions>()

  useEffect(() => {
    ref.current?.setInputValue(inn)
  }, [inn, ref])

  const onChangeInn = (selected: DaDataSuggestion<DaDataParty> | undefined) => {
    const inn = selected?.data.inn;
    const companyName = selected?.data.name.short_with_opf;
    onChange(inn, companyName);
    setValue(selected)
    dispatch(setValueInSlice({inn:inn, companyName: companyName}))
    ref.current?.setInputValue(inn)
  }

  const clear = () => {
    onChangeInn(undefined)
  }

  const render = (item: DaDataSuggestion<DaDataParty> | undefined) => {
    return (
      <StyledInnList key={item?.data.ogrn}>
        <span style={{minWidth:'130px'}}>{item?.data.inn}</span>
        <span>{item?.data.name.short_with_opf}</span>
      </StyledInnList>
    )
  }

  return (
    <StyledInn>
      <PartySuggestions
        ref={ref}
        token={daDataToken}
        value={value}
        defaultQuery={inn}
        onChange={onChangeInn}
        count={5}
        inputProps={{ placeholder: 'ИНН компании' }}
        customInput={StyledInnInput}
        renderOption={render}
      />
      <span className='name' onClick={()=>ref && ref.current && ref.current.focus()}>{companyName}</span>
      <ClearIcon onClick={() => clear()} />
    </StyledInn>
  )
}
