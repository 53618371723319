import './UserProfile.css'
import {ArrowLeftIcon} from "../../../../assets/icons/ArrowLeftIcon";
import {useAppDispatch, useAppSelector} from "../../../../app/redux/hooks";
import {userProfileSelector,setVisibleUserProfileForm} from "./userProfileSlice";
import {authorizationSelector} from "../../../Authorization/authorizationSlice";
import ProfileUserInfo from "./ProfileUserInfo";
import ProfileCompanyInfo from "./ProfileCompanyInfo";
import {useCookies} from "react-cookie";
import {cookiesClientId, cookiesClientPhone, cookiesDefaultUserId, cookiesOrderId} from "../../../../api/api.constants";
import {useEffect, useState} from "react";
interface IProps {

}

const UserProfile : React.FC<IProps> = ({}) =>{

    const dispatch = useAppDispatch();
    const userProfile = useAppSelector(userProfileSelector);
    const { isAuth } = useAppSelector(authorizationSelector);
    const [isCompanyDefault, setCompanyDefault] = useState<number>(0);
    const [defaultUserIndex, setDefaultUserIndex] = useState<number>(0);
    //@ts-ignore
    window.profile = userProfile

    useEffect(()=>{

       if (userProfile.data_login?.is_user){
           let user_index = userProfile.profile.users.findIndex(x=> x.id == userProfile.data_login?.id);
           setDefaultUserIndex(user_index);
       }

        if (userProfile.data_login?.is_user === false){
            let user_index = userProfile.profile.companies.findIndex(x=> x.id == userProfile.data_login?.id);
            setCompanyDefault(user_index);
        }

    }, [userProfile.data_login, userProfile.profile.companies, userProfile.profile.users])

    return (
        <div className='user_profile'>
            <div className='back_arrow' onClick={()=> dispatch(setVisibleUserProfileForm(false))}>
                <ArrowLeftIcon onClick={()=> dispatch(setVisibleUserProfileForm(false))}/>
            </div>
            {!isAuth && <div className='attention'>Для просмотра профиля необходимо авторизоваться</div>}
            {isAuth && !userProfile.selected_company_id && <ProfileUserInfo userIndex={defaultUserIndex}/>}
            {isAuth && userProfile.selected_company_id  && <ProfileCompanyInfo userIndex={defaultUserIndex} companyId={userProfile.selected_company_id}/>}
        </div>
    )
}

export default UserProfile;

