import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/redux/store'
import { TextAlignType } from '../Form/Form.types'

export interface ModalFullState {
  isModalOpened: boolean
  children: string
  title: string
  background?: string
  isArrowClose: boolean
  isButtonClose: boolean
  textAlignTitle?: TextAlignType
  fontSizeTitle?: string
  paddingLeft?: string
}

const initialState: ModalFullState = {
  isModalOpened: false,
  children: '',
  title: '',
  isArrowClose: true,
  isButtonClose: false
}

export const modalFullSelector = (state: RootState) => state.modalFull

export const modalFullSlice = createSlice({
  name: 'modalFull',
  initialState,
  reducers: {
    openModal (state, action) {
      state.isModalOpened = true
      state.children = action.payload
      state.title = ''
      state.background = ''
      state.isArrowClose = true
      state.isButtonClose = false
    },
    openModalWithTitle (state, action) {
      const {
        children,
        title,
        background,
        isArrowClose,
        isButtonClose,
        textAlignTitle,
        fontSizeTitle,
        paddingLeft
      } = action.payload
      state.isModalOpened = true
      state.children = children
      state.title = title
      state.textAlignTitle = textAlignTitle
      state.background = background
      state.isArrowClose = isArrowClose === undefined ? true : isArrowClose
      state.isButtonClose = isButtonClose
      state.fontSizeTitle = fontSizeTitle
      state.paddingLeft = paddingLeft
    },
    setTitle (state, action) {
      state.title = action.payload
    },
    closeModal (state) {
      state.isModalOpened = false
    }
  }
})

export const {
  openModal,
  openModalWithTitle,
  closeModal,
  setTitle
} = modalFullSlice.actions

export default modalFullSlice.reducer
