import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../../app/redux/hooks";
import {userProfileSelector} from "../../../UserProfile/userProfileSlice";

import {CCompany} from "../../../UserProfile/userProfile.type";
import {currentOrderData, setOrderINN} from "../../CreateOrderSlicer";
import {Inn} from "../../../../../../components/Inn/Inn";
import './SelectCompany.css'
import {useGetAddressByNameQuery, useGetCompanyByTINMutation} from "../../../../../../api/daDataApi";

interface IProps {

}

const SelectCompany :React.FC<IProps> = ({}) => {
    const userProfile  = useAppSelector(userProfileSelector);
    const dispatch = useAppDispatch()
    const currentOrder = useAppSelector(currentOrderData)

    const [ getCompanyByTIN ] = useGetCompanyByTINMutation();
    const [company, setCompany] = useState< CCompany | undefined>(  userProfile.profile.companies.find(x=> x.tin == currentOrder.TIN) );
    const [isShowInnField, setShowInnField] = useState< boolean>(false);
    const [isRowsVisible, setRowsVisible] = useState(false);


    const setTIN = (value:string) => {
        dispatch(setOrderINN(value));
        setRowsVisible(false);

    }
    const toggleRowsVisible = () => {
        setRowsVisible(!isRowsVisible)
    }


    useEffect(() => {

    },[]);

    const setTimWhenEmptyValue = () => {

        if (currentOrder.TIN == '') {
            if (userProfile.selected_company_id && userProfile.profile.companies.some(x=> x.id == userProfile.selected_company_id)) {
                let cmp : any = userProfile.profile.companies.find(x=> x.id == userProfile.selected_company_id);
                setTIN(cmp.tin)
                setCompany( new CCompany(cmp.tin, cmp.name));
            }
            else
            if (userProfile.profile.companies.length) {
                setTIN(userProfile.profile.companies[0].tin)
                setCompany( new CCompany(userProfile.profile.companies[0].tin, userProfile.profile.companies[0].name));
            }
            else
                setShowInnField(true)
            return;
        }
        if (company) return;
        getCompanyByTIN(currentOrder.TIN)
            .unwrap()
            .then((res)=>{
                console.log('res = ',res)
                if (res && Array.isArray(res.suggestions) && res.suggestions.length){
                    setCompany( new CCompany(currentOrder.TIN, res.suggestions[0].value));
                    if (userProfile.profile.companies.length == 0)
                        setShowInnField(true);
                }
            });
    };

    useEffect(() => { setTimWhenEmptyValue(); },[])
    // useEffect(() =>  { },[currentOrder.TIN])

     return (
             <div className='select-company-block'>
                 {isShowInnField &&
                     <div className={'select-company-input select-company-input-inn'}>
                         <Inn  onChange={(inn, companyName) => {

                             if (currentOrder.TIN == '' && !inn) {
                                 setShowInnField(false);
                                 setTimWhenEmptyValue();
                             } else
                                 dispatch(setOrderINN(inn ? inn : ''))

                         }} />
                     </div>
                 }
                 {company && !isShowInnField &&
                    <div className={'select-company-input'} onClick={toggleRowsVisible}>
                         <div >{company.name} (<span>{company.tin}</span>)</div>
                    </div>
                 }
                 <div className={'select-company-rows ' + (isRowsVisible ? 'visible' : '')}>
                    {
                        userProfile.profile.companies.map(x=>
                        <div key={'cp_'+x.id} className={'select-company-item ' + (company && company.id == x.id ? 'selected' : '')} onClick={()=>setTIN(x.tin)}>{x.name} (<span>{x.tin}</span>)</div>
                         )
                    }
                     {userProfile.profile.companies.length > 0 &&
                        <div  className={'select-company-item ' + (!company ? 'selected' : '')} onClick={()=>{setTIN(''); setShowInnField(true);}}>Выбрать другую компанию</div>
                     }
                 </div>
            </div>
     )
}

export default SelectCompany;
