import { StyledLabel } from './Label.styled'
import { ILabelProps } from './Label.types'

export const Label = ({
  htmlFor,
  children,
  fontSize,
  fontWeight
}: ILabelProps) => {
  return (
    <StyledLabel htmlFor={htmlFor} fontSize={fontSize} fontWeight={fontWeight}>
      {children}
    </StyledLabel>
  )
}
