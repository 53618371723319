import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/redux/store";

export interface SideBarState {
  isSideBarOpened: boolean;
}

const initialState: SideBarState = {
  isSideBarOpened: false,
};

export const sideBarSelector = (state: RootState) => state.sideBar;

export const sideBarSlice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    openSideBar(state) {
      state.isSideBarOpened = true;
    },
    closeSideBar(state) {
      state.isSideBarOpened = false;
    },
  },
});

export const { openSideBar, closeSideBar } = sideBarSlice.actions;

export default sideBarSlice.reducer;
