import styled from 'styled-components'
import { IOrderListFilterType } from '../OrderList/OrderList.types'
import { IOrderItemStatusProps, IOrderItemStatusType } from './OrderItem.types'

export const StyledOrderItem = styled.div<IOrderItemStatusProps>`
  background-color: var(--white);
  border-radius: 8px;
  font-size: 15px;
  font-weight: 400;
  color: ${prop =>
    prop.filterStatus === IOrderListFilterType.now
      ? 'var(--black)'
      : 'var(--grey)'};
  margin: 0 12px;
`
export const StyledOrderItemHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  & > svg {
    max-width: 16px;
    min-width: 16px;
    margin-left: 10px;
  }
`
export const StyledOrderItemPeriod = styled.span<IOrderItemStatusProps>`
  color: ${prop =>
    prop.filterStatus === IOrderListFilterType.now
      ? 'var(--blue)'
      : 'var(--grey)'};
  font-size: 15px;
  font-weight: 500;
  margin:  17px 12px;
  max-width: 100px;
`
export const StyledOrderItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 0;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`

export const StyledOrderItemAddress = styled.div`
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px 0 12px;
`
export const StyledOrderItemStatus = styled.div<IOrderItemStatusProps>`
  color: var(--blue);
  color: ${prop =>
    prop.status !== IOrderItemStatusType.isCompleted &&
    prop.filterStatus === IOrderListFilterType.now
      ? 'var(--blue)'
      : prop.status === IOrderItemStatusType.isCanceled ||
        prop.status === IOrderItemStatusType.isNotDone
      ? 'var(--red)'
      : 'var(--grey)'};
  font-size: 15px;
  font-weight: 500;
`

export const StyledOrderItemAmount = styled.div<IOrderItemStatusProps>`
  font-size: 20px;
  font-weight: 700;
  color: ${prop =>
    prop.filterStatus === IOrderListFilterType.now
      ? 'var(--black)'
      : 'var(--grey)'};
`

