import { StyledForm } from './Form.styled'
import { IForm } from './Form.types'

export const Form = ({
  onSubmit,
  height,
  display,
  gridGap,
  justifyContent,
  flexDirection,
  children,
  textAlign,
  margin
}: IForm) => {
  return (
    <StyledForm
      height={height}
      onSubmit={onSubmit}
      display={display}
      gridGap={gridGap}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      textAlign={textAlign}
      margin={margin}
    >
      {children}
    </StyledForm>
  )
}
