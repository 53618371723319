import { useAppDispatch } from '../../../../app/redux/hooks'
import { setArticle } from '../Article/articleSlice'
import {
  StyledArticleItem,
  StyledArticleItemContent,
  StyledArticleItemDescription,
  StyledArticleItemLine,
  StyledArticleItemMoreDetails,
  StyledArticleItemTitle
} from './ArticleItem.styled'
import { IArticleItemProps } from './ArticleItem.types'
import { openModalWithTitle as openModalFullWithTitle } from '../../../../components/ModalFull/modalFullSlice'
import { TextAlignType } from '../../../../components/Form/Form.types'
import { useHistory } from 'react-router'
import { close } from '../../../../components/ModalFull/modalFullHelper'

export const ArticleItem = ({ article }: IArticleItemProps) => {
  const { description, title } = article
  const dispatch = useAppDispatch()
  const history = useHistory()

  const moreDetails = () => {
    dispatch(setArticle(article.id))
    dispatch(
      openModalFullWithTitle({
        children: 'Article',
        title: article.title,
        background: 'var(--white)',
        isArrowClose: false,
        isButtonClose: true,
        textAlignTitle: TextAlignType.LEFT,
        fontSizeTitle: '24px',
        paddingLeft: '24px'
      })
    )
  }
  return (
    <>
      {article && (
        <StyledArticleItem>
          <StyledArticleItemContent>
            <StyledArticleItemTitle>{title}</StyledArticleItemTitle>
            <StyledArticleItemDescription>
              {description}
            </StyledArticleItemDescription>
            <StyledArticleItemLine></StyledArticleItemLine>
            <StyledArticleItemMoreDetails onClick={moreDetails}>
              Подробнее
            </StyledArticleItemMoreDetails>
          </StyledArticleItemContent>
        </StyledArticleItem>
      )}
    </>
  )
}
