import styled from 'styled-components'

const StyledSvg = styled.svg`
  cursor: pointer;
`

export const ChatIcon = () => {
  return (
    <StyledSvg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.5 0H2.5C1.4 0 0.5 0.9 0.5 2V20L4.5 16H18.5C19.6 16 20.5 15.1 20.5 14V2C20.5 0.9 19.6 0 18.5 0ZM18.5 14H3.7L2.5 15.2V2H18.5V14Z'
        fill='black'
      />
    </StyledSvg>
  )
}


export const CallIcon = () => {
  return (
      <StyledSvg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.75 12.5C16.55 12.5 15.25 12.3 14.15 11.9C14.05 11.9 13.95 11.9 13.85 11.9C13.55 11.9 13.35 12 13.15 12.2L10.95 14.4C8.15 12.9 5.75 10.6 4.35 7.8L6.55 5.6C6.85 5.3 6.95 4.9 6.75 4.6C6.45 3.5 6.25 2.2 6.25 1C6.25 0.5 5.75 0 5.25 0H1.75C1.25 0 0.75 0.5 0.75 1C0.75 10.4 8.35 18 17.75 18C18.25 18 18.75 17.5 18.75 17V13.5C18.75 13 18.25 12.5 17.75 12.5ZM2.75 2H4.25C4.35 2.9 4.55 3.8 4.75 4.6L3.55 5.8C3.15 4.6 2.85 3.3 2.75 2ZM16.75 16C15.45 15.9 14.15 15.6 12.95 15.2L14.15 14C14.95 14.2 15.85 14.4 16.75 14.4V16Z" fill="white"/>
      </StyledSvg>
  )
}
