import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/redux/store'
import {
  checkIsValid,
} from '../../../../components/PhoneInput/phoneHelper'
import {
  checkIsValidEmail,
  checkIsValidFullName,
  checkIsValidInn,
  MAX_LENGTH_INN
} from './registrationFormHelper'

export interface RegistrationFormState {
  fullName: string
  isValidFullName: boolean
  phone: string
  isValidPhone?: boolean
  email?: string
  isValidEmail: boolean
  inn?: string
  isValidInn: boolean
  isAgreement: boolean
}

const initialState: RegistrationFormState = {
  fullName: '',
  isValidFullName: true,
  phone: '',
  isValidPhone: undefined,
  email: '',
  isValidEmail: true,
  inn: '',
  isValidInn: true,
  isAgreement: false
}

export const registrationFormSelector = (state: RootState) =>
  state.registrationForm

export const registrationFormSlice = createSlice({
  name: 'registrationForm',
  initialState,
  reducers: {
    setFullName (state, action) {
      const fullName = action.payload
      state.fullName = fullName
      state.isValidFullName = checkIsValidFullName(fullName)
    },
    setPhone (state, action) {
      const phone = action.payload
      state.phone = phone
      state.isValidPhone = checkIsValid(phone)
    },
    setEmail (state, action) {
      const email = action.payload
      state.email = email
      state.isValidEmail = checkIsValidEmail(email)
    },
    setInn (state, action) {
      const inn = action.payload
      if (inn.length <= MAX_LENGTH_INN) {
        state.inn = inn
        state.isValidInn = checkIsValidInn(inn)
      }
    },
    setIsAgreement (state, action) {
      state.isAgreement = action.payload
    },

    clear (state) {
      state.fullName = ''
      state.isValidFullName = true
      state.phone = ''
      state.isValidPhone = undefined
      state.email = ''
      state.isValidEmail = true
      state.inn = ''
      state.isValidInn = true
      state.isAgreement = false
    }
  }
})

export const {
  setFullName,
  setPhone,
  setEmail,
  setInn,
  setIsAgreement,
  clear
} = registrationFormSlice.actions

export default registrationFormSlice.reducer
