import React, { createRef, useEffect } from 'react'
import ReactDOM from 'react-dom'

import {
  StyledModal,
  StyledModalHeader,
  StyledModalContent,
  StyledStyledModalHeaderContent
} from './Modal.styled'
import { CloseIcon } from '../../assets/icons/CloseIcon'
import { closeModal, modalSelector } from './modalSlice'
import { useAppSelector } from '../../app/redux/hooks'
import { Provider, useDispatch } from 'react-redux'
import { IModalProps } from './Modal.types'
import EditClientsForm from '../../modules/Home/components/EditClientsForm'

import { store } from '../../app/redux/store'

export const Modal = ({ child }: IModalProps) => {
  const { isModalOpened, title, children, isNotNeedTitle } = useAppSelector(
    modalSelector
  )
  const dispatch = useDispatch()
  const refTitle = createRef<HTMLDivElement>()

  const getTitle = () => {
    if (refTitle && refTitle.current) {
      refTitle.current.innerHTML = title
    }
  }
  const clearModalTitle = () => {
    ReactDOM.render(
      <Provider store={store}>
        <></>
      </Provider>,
      document.getElementById('modal-title')
    )
  }

  useEffect(() => {
    if (!isNotNeedTitle) {
      clearModalTitle()
      getTitle()
    }
  }, [title, isModalOpened, isNotNeedTitle])

  const getChild = () => {
    switch (children) {
      case 'EditClientsForm':
        return <EditClientsForm />

      default:
        return <div></div>
    }
  }
  return (
    <StyledModal isOpen={isModalOpened}>
      <StyledModalContent>
        <StyledModalHeader>
          {!isNotNeedTitle && (
            <StyledStyledModalHeaderContent>
              <div ref={refTitle}></div>
              <CloseIcon onClick={() => dispatch(closeModal())} />
            </StyledStyledModalHeaderContent>
          )}
          <div id='modal-title'></div>
        </StyledModalHeader>
        {child ? child : getChild()}
      </StyledModalContent>
    </StyledModal>
  )
}
