import styled from 'styled-components'
import {
  ButtonBackgroundColor,
} from '../../../../components/Button/Button.types'
import { DEVICE, WINDOW_SIZE } from '../../../../components/Modal/Modal.types'
import { IDisabledDiv } from './Code.types'
export const StyledCode = styled.div`
  background: #ecedef;
  padding: 32px;
`

export const Title = styled.div`
  color: #000;
  font-weight: 500;
  text-align: center;
`

export const RepeatCode = styled.div`
  color: #b6b9c0;
  margin: 12px 0 26px 0;
  text-align: left;
`
export const DisabledDiv = styled.div<IDisabledDiv>`
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: default;
  width: ${props => props.width || '100%'};
  margin: ${props => (props.margin ? props.margin : 'auto')};
   a {
    text-decoration-color: ${props => (props.disabled ? '#b6b9c0' : '#000')};
    color: ${props => (props.disabled ? '#b6b9c0' : '#000')};
  }
  button {
    background-color: ${props =>
      props.disabled
        ? '#ECEDEF'
        : props.backgroundColor || ButtonBackgroundColor.BLUE};
    background-image: ${props =>
      props.disabled
        ? '#ECEDEF'
        : props.backgroundColor || ButtonBackgroundColor.BLUE};
  }
`
export const DisabledDivCenter = styled(DisabledDiv)`
  text-align: center;
`

export const DisabledDivForButton = styled(DisabledDiv)<IDisabledDiv>`
  padding: 50px 24px;
  position: absolute;
  bottom: 0px;
`

export const DisabledDivForButtonMaxWidth = styled(DisabledDivForButton)<IDisabledDiv>`
  width: 600px;
  @media ${DEVICE.MOBILE_S} {
    width: ${WINDOW_SIZE.MOBILE_S};
  }

  @media ${DEVICE.MOBILE_M} {
    width: ${WINDOW_SIZE.MOBILE_M};
  }

  @media ${DEVICE.MOBILE_L} {
    width: ${WINDOW_SIZE.MOBILE_L};
  }
`
