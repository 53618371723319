import styled from "styled-components";

const StyledSvg = styled.svg`
  cursor: pointer;
`;

export const OkIcon = () => (
    <StyledSvg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 4.16675C13.4937 4.16675 4.16666 13.4938 4.16666 25.0001C4.16666 36.5063 13.4937 45.8334 25 45.8334C36.5062 45.8334 45.8333 36.5063 45.8333 25.0001C45.8333 13.4938 36.5062 4.16675 25 4.16675ZM36.8896 20.223L22.3062 34.8063C21.9 35.2126 21.3667 35.4168 20.8333 35.4168C20.3 35.4168 19.7667 35.2126 19.3604 34.8063L13.1104 28.5563C12.2958 27.7417 12.2958 26.4251 13.1104 25.6105C13.925 24.7959 15.2417 24.7959 16.0562 25.6105L20.8333 30.3876L33.9437 17.2772C34.7583 16.4626 36.075 16.4626 36.8896 17.2772C37.7042 18.0917 37.7042 19.4084 36.8896 20.223Z" fill="#00BB61"
      />
    </StyledSvg>
);