import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/redux/store'
import { checkIsValid } from '../../../../components/PhoneInput/phoneHelper'

export interface AuthorizationFormState {
  phone: string
  isValidPhone?: boolean
}

const initialState: AuthorizationFormState = {
  phone: ' ',
  isValidPhone: undefined,
}

export const authorizationFormSelector = (state: RootState) =>
  state.authorizationForm

export const authorizationFormSlice = createSlice({
  name: 'authorizationForm',
  initialState,
  reducers: {
    setPhone (state, action) {
      const phone = action.payload.trim()
      state.phone = phone
      state.isValidPhone = checkIsValid(phone)
    },
    clear (state) {
      state.phone = ''
      state.isValidPhone = undefined
    }
  }
})

export const { setPhone, clear } = authorizationFormSlice.actions

export default authorizationFormSlice.reducer
