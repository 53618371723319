import Input from '../Input'
import { InputType } from '../Input/Input.types'
import Label from '../Label'
import { StyledCheckbox } from './Checkbox.styled'
import { ICheckboxProp } from './Checkbox.types'
export const Checkbox = ({ name, value, children, onChange, fontWeight, fontSize }: ICheckboxProp) => {
  return (
    <StyledCheckbox>
      <Input id={name} name={name} type={InputType.checkbox} onChange={onChange} checked={value} />
      <Label htmlFor={name} fontWeight="400" fontSize="15px">
        <span>{children && children}</span>
      </Label>
    </StyledCheckbox>
  )
}
