import React, {FC, useState} from 'react'
import s from './hereMap.module.scss'
import { useEffect } from 'react'
import {
  calculateRoute, clearRouteInfo, createDomMarker, drawDriverRouteToMap,
  drawWayOnMap, getBearing,
  hereMarker,
  is_way_point_changed,
  removeObjectFromMap,
  setDraggable
} from '../HereMap/hereFunctions'
import {mapMarker, mapRoute, MARKER_4} from './hereMapTypes'
import {hereMapApiKey, TIME_FOR_ROUTE_CACHE_SECONDS} from '../../api/api.constants'
import {TypeLonLat} from "../../modules/Home/components/NewUserOrder/CreateOrderType";
import {useAppSelector} from "../../app/redux/hooks";
import {hereMapSelector, setCarPositionPop, setLeftDistance, setLeftTime} from "./hereMapSlice";
import {HereMarkerIconCar} from "./hereMarkerIcon";
import { useGetRouteMutation } from '../../api/here-api'
import {Navigator} from "./Navigator";
import moment from "moment";
import {orderSelector} from "../../modules/Orders/components/Order/orderSlice";
import {EnumOrderStatusID} from "../../modules/Orders/components/OrderItem/OrderItem.types";
import {useDispatch} from "react-redux";
import {IRouteByOrderId} from "../../modules/Orders/components/Order/Order.types";

var tikInterval : any = undefined;
var is_executed_order = false;
var order_route_with_time : IRouteByOrderId | undefined = undefined;
var var_point_index : number =0;
var var_driver_route : number[][] = [];
export var hereMap: H.Map;
let
    platform: H.service.Platform


export const HereMap: FC<propTypes> = ({ onClick, onMarkerClick, markers, routs }) => {

  const dispatch = useDispatch();
  const mapRef = React.useRef<HTMLHeadingElement>(null)
  const [lastCarPosition, setLastCarPosition] = useState<TypeLonLat>({lat:0, lon:0});
  const {car_position, driver_route, point_index, left_time, full_time, full_distance, left_distance } = useAppSelector(hereMapSelector);
  const [getRoute] = useGetRouteMutation();
  const { id, isShowBack, order, orderRouteWithTime } = useAppSelector(orderSelector);
  const [lastPoint, setLastPoint] = useState<TypeLonLat|undefined>(undefined);
  const [debugData, setDebugData] = useState<{index:number|string, timestamp:number|string, bad_points: string}|undefined>(undefined);
  const [timeValueUpdated, setTimeValueUpdated] = useState<{distance:number}>({distance:0});

  useEffect(() => {
    Navigator.getRoute = getRoute;
    const H = window.H
    platform = new H.service.Platform({
      "apikey": hereMapApiKey
    })
    const defaultLayers = platform.createDefaultLayers({
      lg: 'ru_RU'
    })

    if (!mapRef.current) return;

    hereMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: 55.754638, lng: 37.621633 },
      zoom: 12,
      pixelRatio: window.devicePixelRatio || 1
    })
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hereMap))
    const ui = H.ui.UI.createDefault(hereMap, defaultLayers, 'ru-RU')

    const zoom = ui.getControl('zoom')
    const mapSettings = ui.getControl('mapsettings')
    ui.removeControl('scalebar')

    mapSettings.setAlignment('right-middle')
    zoom.setAlignment('right-middle')

    window.addEventListener('resize', () => hereMap.getViewPort().resize())

    // добавляем обработчик нажатия на карту

    hereMap.addEventListener('tap', (evt: any) => {
      const target = evt.target
      const coord = hereMap.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY)
      if (target instanceof H.map.DomMarker) {
        // возвращаем индекс маркера
        onMarkerClick(target.getData())
      } else {
        // возвращаем координаты
        onClick(coord.lng, coord.lat)
      }
    }, true)

    hereMap.addEventListener('pointerdown', function() {
      Navigator.lastTimeMapHandMove =  moment(new Date());
    });
    hereMap.addEventListener('pointerup', function() {
      Navigator.lastTimeMapHandMove =  moment(new Date());
    });

    // добавляем поведение перетаскивания маркеров

    // setDraggable(hereMap, behavior, onMarkerClick, onClick)

    // проход отрисовывает массив маршрутов

  }, [mapRef])

  // эфект отрисовывает маркеры

  useEffect(() => {

     //console.log('HEREMAP markers ', markers);
      hereMap.removeObjects(hereMap.getObjects().filter((obj: H.map.Object) => {
        // return !(obj instanceof window.H.map.Group)
       return  (obj instanceof window.H.map.DomMarker || obj instanceof window.H.map.Marker)
      }))

    markers.forEach((marker: mapMarker) => {
      if (!marker.lat) {
        return
      }
      const markerItem = hereMarker([marker.lat, marker.lon], marker)
      hereMap.addObject(markerItem)
    })

  }, [markers])

  // эфект отрисовывает маршруты

  useEffect(() => {

    if (routs.length == 0 ) {
      clearRouteInfo(hereMap);
      var_driver_route = [];
      Navigator.stop();
    }
    //console.log('HEREMAP routs removeObjects');

    routs.forEach((element,index) => {
      //console.log('HEREMAP routs element', element);
      if (element.type == 0) {
        let cc = element.coordinate.map(el => `${el.lat},${el.lon}`);
        if (is_way_point_changed(cc)) {
          if (index==0) clearRouteInfo(hereMap);
          calculateRoute(platform, cc, hereMap, element.color, element.width)
        }
      }
      else {
        if (index==0) clearRouteInfo(hereMap);
        drawWayOnMap(element.coordinate, element.color, element.width, hereMap);
        var_driver_route = [...var_driver_route, ... element.coordinate.map(el => [el.lat,el.lon])];
      }
    })

  }, [routs])

  // useEffect(() => {
  //   removeObjectFromMap(hereMap, MARKER_4);
  //   if (!car_position) return;
  //   let bearing = 0 ;
  //   if (car_position.lat != lastCarPosition?.lat || car_position.lon != lastCarPosition?.lon){
  //     if (lastCarPosition)
  //        bearing = getBearing(lastCarPosition, car_position);
  //     setLastCarPosition(car_position)
  //   }
  //
  //   hereMap.addObject(createDomMarker([car_position.lat, car_position.lon], 0, HereMarkerIconCar(bearing), MARKER_4,));
  //
  //
  // }, [car_position])

  useEffect(()=>{  Navigator.clear(); setDebugData(undefined); is_executed_order = (order !=undefined && order.status_id == EnumOrderStatusID.Executing);     }, [order, order?.status_id]);

  useEffect(()=>{ order_route_with_time = orderRouteWithTime;    }, [orderRouteWithTime]);

  useEffect(()=>{
    var_point_index = point_index;
  }, [point_index]);

  useEffect(()=>{
    var_driver_route = driver_route;
  }, [driver_route]);
  // useEffect(()=>{
  //   if ( !isExecuteOrder()  || !car_position.length) return;
  //   // if (car_position.lat != lastPoint?.lat || car_position.lon != lastPoint?.lon){
  //   //
  //   //   setLastPoint(car_position)
  //   // }
  //
  //   if (orderRouteWithTime && orderRouteWithTime.points.length > (point_index-1)){
  //     let pp = point_index - 1;
  //     if (pp < 0) pp = 0;
  //     let point = orderRouteWithTime.points[pp];
  //     Navigator.process(car_position, driver_route as [number[]],  point , drawDriverRouteToMap, setDebugData, dispatch);
  //   }
  //
  // }, [car_position]);


  useEffect(()=>{
    let isMounted = true;
    var isCarMoving = false;
    if (tikInterval) clearInterval(tikInterval);
    tikInterval = setInterval(async () => {
      if (!isMounted)  return clearInterval(tikInterval);
      if (isCarMoving || !is_executed_order || !var_driver_route.length) return;

      //@ts-ignore
      if (!window.car_position || !window.car_position.length ){
        Navigator.index++;
        setDebugData({index: Navigator.index , bad_points: 'пришел пустой массив', timestamp: "координат с сервера"});
        return ;
      }

      isCarMoving = true;

      try{
          if (order_route_with_time && order_route_with_time.points.length > (point_index-1)){
            let pp = var_point_index <= 0 ? 0 :  var_point_index;
            let point = order_route_with_time.points[pp];
            //@ts-ignore
            if (window.car_position.length > 0) {
              //@ts-ignore
              console.log('220:HereMap.useEffect',window.car_position.length)
              //@ts-ignore
              let arrayCarPosition : any = [...window.car_position[0]];
              dispatch(setCarPositionPop());
              let distance_in_meters = await Navigator.process(arrayCarPosition, var_driver_route as [number[]], point, drawDriverRouteToMap, setDebugData, dispatch);

              setTimeValueUpdated({distance: distance_in_meters});
            }
          }
      } catch (ex) {
        console.error(ex);
      }
      isCarMoving = false;
    },   500);
    return () => { isMounted = false; isCarMoving = false; };
  }, []);

  useEffect(()=>{
    let distance_in_meters = timeValueUpdated.distance;
    if (distance_in_meters <= 0 ) return;
    let timeLeft = full_time - Math.round(full_time / full_distance * (left_distance + distance_in_meters) );
    dispatch(setLeftDistance(left_distance + distance_in_meters));
    dispatch(setLeftTime(timeLeft));
    // let [point, d, index ] = getNearestPointOnLine( [pos.lat, pos.lon], testRouteData.map(x=>[+x.lat,+x.lon]) as [number[]]);
    // let distanceLeft = getDistanceLine(point as  number[] , testRouteData.map(x=>[+x.lat,+x.lon])  as [number[]]);
    // let distanceFull = orderRouteWithTime.distance == 0 ? 1 : orderRouteWithTime.distance;
    // let timeFull = orderRouteWithTime.traffic_time;
    // let timeLeft = Math.round(timeFull / distanceFull * distanceLeft );
    // setLeftTime(timeLeft);
    // console.log('timeLeft', timeLeft, Math.ceil(timeLeft/60), timeLeft - Math.ceil(timeLeft/60)*60, distanceFull, distanceLeft)

  }, [timeValueUpdated])

  return <><div className={s.map} ref={mapRef} />
            {debugData &&
                <div className={s.debug_info}>
                  <div>{debugData?.index}</div>
                  <div dangerouslySetInnerHTML={{__html: debugData?.timestamp + ''} } />
                  <div dangerouslySetInnerHTML={{__html: debugData?.bad_points + ''} }/>
                </div>
            }
  </>
}



type propTypes = {
  onClick: (lon: number, lat: number) => void
  onMarkerClick: (index: number) => void
  markers: Array<mapMarker>
  routs: Array<mapRoute>
}
