export interface IModalProps {
  closeModal?(): void
  child?: JSX.Element[] | JSX.Element | any
}


export interface IStyledModalProps{
    isOpen: boolean,
}

export const WINDOW_SIZE = {
  MOBILE_S: '100%',
  MOBILE_M: '100%',
  MOBILE_L: '100%',
  TABLET: '100%',
  LAPTOP: '100%',
  LAPTOP_L: '100%',
  DESCTOP: '100%'
}

export const DEVICE = {
  MOBILE_S: `(min-width: ${WINDOW_SIZE.MOBILE_S})`,
  MOBILE_M: `(min-width: ${WINDOW_SIZE.MOBILE_M})`,
  MOBILE_L: `(min-width: ${WINDOW_SIZE.MOBILE_L})`,
  TABLET: `(min-width: ${WINDOW_SIZE.TABLET})`,
  LAPTOP: `(min-width: ${WINDOW_SIZE.LAPTOP})`,
  LAPTOP_L: `(min-width: ${WINDOW_SIZE.LAPTOP_L})`,
  DESCTOP: `(min-width: ${WINDOW_SIZE.DESCTOP})`,
  DESCTOP_L: `(min-width: ${WINDOW_SIZE.DESCTOP})`
};
