import React, {FC, useEffect, useMemo, useState} from "react";
import s from './VehicleBlockManual.module.scss'
import {VehicleGrayButton} from "../VehicleGrayButton/VehicleGrayButton";
import {
    BodyOptionCharacteristicsResponse, BodyOptionsResponse,
    CarTypeTreeQueryResponse,
    CharacteristicTypeEnum, useCalcOrderRequestAllMutation
} from "../../../../../api/orderApi";
import {AutoDropDownGreyList} from "../AutoDropDownGreyList/AutoDropDownGreyList";
import {
    filterCategory,
    getActiveCharacteristics, getBOCharacteristics, getBodyOptionsList,
    getCharacteristicValueList,
    newUserOrderSelector
} from "../NewUserOrderSlicer";
import {useAppDispatch, useAppSelector} from "../../../../../app/redux/hooks";
import {CTariffData, SendOrderInfo} from "../CreateOrderType";
import {currentOrderData, getCost, getTariffLoading, getTariffs, setOrderData} from "../CreateOrderSlicer";
import {CategoriesSelect} from "./CategoriesSelect";
interface IProps {
    onChange:()=>void,
    isTab?:boolean,
    isShown?:boolean,
}
const VehicleBlockManual : FC<IProps> = ({onChange,isTab,isShown}) =>{

    const dispatch = useAppDispatch();
    const currentOrder : SendOrderInfo = useAppSelector(currentOrderData);
    const { carTypesTree } = useAppSelector(newUserOrderSelector);

    let  categories : CarTypeTreeQueryResponse[]  = carTypesTree ? carTypesTree : [];

    let selectedCategoryID  =  currentOrder.car_type_id;
    const setSelectedCategoryID = (value:string) =>  dispatch( setOrderData({...currentOrder, car_type_id: value}) );

    let selectedBodyID  = currentOrder.body_option_id ;
    const setSelectedBodyID = (value:string) =>  dispatch( setOrderData({...currentOrder, body_option_id: value}) );

    let  isMaxDimensions = currentOrder.max_dimensions;
    const setMaxDimensions = (value:boolean) =>  dispatch( setOrderData({...currentOrder, max_dimensions: value}) );



    let  activeCharacteristics  = useMemo( () => getActiveCharacteristics(currentOrder.body_option_characteristics, categories), [currentOrder.body_option_characteristics]);

    const setActiveCharacteristics = (value: BodyOptionCharacteristicsResponse[]) => {
        if (value.length)
            dispatch( setOrderData({...currentOrder, body_option_characteristics: value.map(x=> ({id:x.id, value:x.value}))}));
        else
            dispatch( setOrderData({...currentOrder, body_option_characteristics: []}));
    }

    let [bodyOptions, setBodyOptions] = useState<BodyOptionsResponse[]>([]);
    let [characteristics, setCharacteristics] = useState<BodyOptionCharacteristicsResponse[]>([]);

    let [categoryList, setCategoryList] = useState<CarTypeTreeQueryResponse[]>([]);

    const onCategorySelect = async (cat: CarTypeTreeQueryResponse) => {
        await setSelectedCategoryID(cat.id === selectedCategoryID ? '' : cat.id );
    };

    const onBodySelect = (bo: BodyOptionsResponse) => {
        setSelectedBodyID(bo.id === selectedBodyID ? '' : bo.id);
    };

    const [  calcOrderRequestAll  ] = useCalcOrderRequestAllMutation();




    const onCharacteristicSelect = (ch: BodyOptionCharacteristicsResponse, value : boolean|string) => {
        characteristics.filter(x=> x.id === ch.id).forEach(x=> {
            if (ch.type === CharacteristicTypeEnum.Boolean && typeof value === "boolean") {
                x.selected = value;
            }
            if (ch.type === CharacteristicTypeEnum.Number && typeof value === "string") {
                x.selected = +value > 0;
            }

            if (ch.type === CharacteristicTypeEnum.Ref && typeof value === "string") {
                x.selected = value !== '';
            }
            ch.value = value;
        });
        console.log(characteristics)
        setActiveCharacteristics(characteristics.filter(x=>x.selected ));
        setCharacteristics([...characteristics]);
    };

    useEffect(()=>{
        categoryList = filterCategory(categories, "", selectedBodyID, activeCharacteristics);
        setCategoryList(categoryList);
        console.log('here 0');
        if (categoryList.length) {
            console.log('here', getBodyOptionsList(categoryList, selectedCategoryID));
            setBodyOptions(getBodyOptionsList(categoryList, selectedCategoryID));
            let chs = getBOCharacteristics(categoryList, selectedCategoryID, selectedBodyID);
            chs.forEach(x=> {
                let ch = activeCharacteristics.find(a=> a.id === x.id);
                if (!ch)  return;
                x.value = ch.value;
                x.selected = ch.selected;
            });
            setCharacteristics(chs.sort( (a,b)=> {
                if (a.name > b.name ) return  -1;
                return 1;
            }).sort( (a,b)=> {
                if (a.type > b.type ) return  1;
                return -1;
            }));

        }
        if (onChange) onChange();

    },[ selectedCategoryID, selectedBodyID, activeCharacteristics, isMaxDimensions]);

    return (
        <div className={ (isTab ? s.VehicleBlockManualTab : '') + ' ' + (isShown ? s.active : '') + ' ' + s.VehicleBlockManual + " vehicle_manual_block "   }>
            <CategoriesSelect categories={categoryList} onClick={onCategorySelect} selectedID={selectedCategoryID} />
            <div className={s.body}>

                { bodyOptions.map( opt=>  <VehicleGrayButton key={opt.id} bgColor={isTab ? 'white' : ''} text={opt.name}  isSelected={opt.id===selectedBodyID} onClick={() => onBodySelect(opt)} />)}
            </div>
            <div className={s.bodyType}>
                {characteristics.filter(x=>    x.type === CharacteristicTypeEnum.Boolean )
                    .map(char => <VehicleGrayButton
                        bgColor={isTab ? 'white' : ''}
                        key={char.id}
                        text={char.name}
                        isSelected={char.selected}
                        onClick={() => {onCharacteristicSelect(char, !char.selected)}}
                    />)}
            </div>
            <div className={s.bodyConditions}>
                {characteristics.filter(x=>   x.type !== CharacteristicTypeEnum.Boolean )
                    .map(x =>  {
                            if (x.type === CharacteristicTypeEnum.Ref)
                                return  <AutoDropDownGreyList  type = {isTab ?"white" : "gray"} list={getCharacteristicValueList(categoryList, x, selectedBodyID, selectedCategoryID)}
                                                               key={"ch_"+x.id} title={x.name} isSelected={x.selected}
                                                               value={x.value}
                                                               add_class={s.dropDown}
                                                               onSelect={(item) => onCharacteristicSelect(x, item.id)} />;
                            if (x.type === "number")
                                return <AutoDropDownGreyList   type= { isTab ?"white" : "gray"} list={ [{id:'', text :'Любая'},{id:'1', text :'до 1 м.'},{id:'2', text :'до 2 м.'},{id:'3', text :'до 3 м.'},{id:'4', text :'до 4 м.'}]}
                                                              value={x.value}
                                                              add_class={s.dropDown}
                                                              key={"ch_"+x.id} title={x.name}
                                                              isSelected={x.selected}
                                                              canEnterValue={x.name == 'Тип пандуса' || x.name == 'Высота ТС'}
                                                              onSelect={(item) => onCharacteristicSelect(x, item.id)} />;
                        }

                    )}
            </div>
            <div className={s.bodySize } onClick={()=> setMaxDimensions(!isMaxDimensions)} >
                <div className={isMaxDimensions ? s.selectedMaxSize : (isTab ? s.bodySizeWhite : '')}>
                    Максимальный размер кузова
                </div>
            </div>
        </div>
    )
}

export default VehicleBlockManual;
