import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import Button from '../../../../components/Button'
import {
  ButtonBackgroundColor,
  ButtonColor,
  ButtonHeight,
  ButtonTypeEvent
} from '../../../../components/Button/Button.types'
import Form from '../../../../components/Form'

import { authorizationFormSelector, setPhone } from './authorizationFormSlice'
import { codeSelector, setIsHideCode } from '../Code/codeSlice'
import Code from '../Code'
import {
  FlexDirectionType,
  JustifyContentType,
  TextAlignType
} from '../../../../components/Form/Form.types'
import { Label } from '../../../../components/Label/Label'
import { useSendSmsCodeMutation } from '../../../../api/orderApi'
import { StyledPhoneWithImage } from './AuthorizationForm.styled'
import { DisabledDivForButton } from '../Code/Code.styled'
import PhoneInput from '../../../../components/PhoneInput'

export const AuthorizationForm = () => {
  const dispatch = useAppDispatch()
  const { isValidPhone, phone } = useAppSelector(
    authorizationFormSelector
  )
  const { isHideCode } = useAppSelector(codeSelector)
  const [sendSmsCode] = useSendSmsCodeMutation()

  const sendSmsCodeHandler = () => sendSmsCode(phone)
  const onSubmitFormHandler = () => {
    if (isValidPhone) {
      dispatch(setIsHideCode(false))
      sendSmsCodeHandler()
    }
  }

  return (
    <>
      {isHideCode && (
        <>
          <Form
            flexDirection={FlexDirectionType.COLUMN}
            textAlign={TextAlignType.CENTER}
            justifyContent={JustifyContentType.SPACE_BETWEEN}
            margin='90px 0 0 0'
          >
            <>
              <StyledPhoneWithImage>
                <Label htmlFor='phone'>Введите свой номер телефона</Label>
                <PhoneInput
                  inputValue={phone}
                  isNeedIcon={true}
                  onChange={value => {
                    dispatch(setPhone(value))
                  }}
                  
                />
              </StyledPhoneWithImage>
            </>
          </Form>
          <DisabledDivForButton>
            <Button
              buttonTypeEvent={ButtonTypeEvent.BUTTON}
              buttonBackgroundColor={ButtonBackgroundColor.BLUE}
              buttonColor={ButtonColor.WHITE}
              buttonHeight={ButtonHeight.MID_HEIGHT}
              children='Далее'
              onClick={onSubmitFormHandler}
            />
          </DisabledDivForButton>
        </>
      )}
      {!isHideCode && <Code isAuth={true} />}
    </>
  )
}
