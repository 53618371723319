import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/redux/store'
import { IDriver, IVehicle } from '../Order/Order.types'

export interface vehicleWindowState {
  vehicle: IVehicle
  driver: IDriver
  amount: number
  carTypeId: string,
  tariffTypeId: string
}

const initialState: vehicleWindowState = {
  vehicle: {
    height: 0,
    length: 0,
    max_weight: 0,
    width: 0
  },
  driver: {
    full_name: '',
    phone: '',
    passport: {
      series: '',
      number: '',
      issued_by: '',
      issue_date: '',
      files: []
    },
    license: {
      series: '',
      number: '',
      issue_date: '',
      issued_by: '',
      files: []
    }
  },
  amount: 0,
  carTypeId: '',
  tariffTypeId: ''
}
export const vehicleWindowSelector = (state: RootState) => state.vehicleWindow
export const vehicleWindowSlice = createSlice({
  name: 'vehicleWindow',
  initialState,
  reducers: {
    initializeVehicle (state, action) {
      const { vehicle, driver, amount, carTypeId,tariffTypeId } = action.payload
      state.vehicle = vehicle
      state.driver = driver
      state.amount = amount
      state.carTypeId = carTypeId
      state.tariffTypeId = tariffTypeId
    },
    setVehicle (state, action) {
      state.vehicle = action.payload
    },
    setDriver (state, action) {
      state.driver = action.payload
    },
    setAmount (state, action) {
      state.amount = action.payload
    },
    clear (state) {
      state.vehicle = {
        height: 0,
        length: 0,
        max_weight: 0,
        width: 0
      }
      state.driver = {
        full_name: '',
        phone: '',
        passport: {
          series: '',
          number: '',
          issued_by: '',
          issue_date: '',
          files: []
        },
        license: {
          series: '',
          number: '',
          issue_date: '',
          issued_by: '',
          files: []
        }
      }
      state.carTypeId = '';
      state.tariffTypeId = '';

    }
  }
})
export const { setDriver, setVehicle, setAmount,clear, initializeVehicle } = vehicleWindowSlice.actions

export default vehicleWindowSlice.reducer
