import React, {FC, FormEvent, memo, useCallback, useEffect, useState} from 'react'
import s from './InputAddressModal.module.scss'
import ClearAddressInputIcon from '../../../../../assets/icons/ClearAddressInputIcon.svg'
import AcceptAddressInputIcon from '../../../../../assets/icons/AcceptAddressInputIcon.svg'
import SelectFromAddressOnMap from '../../../../../assets/icons/SelectFromAddressOnMap.svg'
import SelectToWhereAddressOnMap from '../../../../../assets/icons/SelectToWhereAddressOnMap.svg'
import ToInputIcon from '../../../../../assets/icons/ToInputIcon.svg'
import FromInputIcon from '../../../../../assets/icons/FromInputIcon.svg'
import {daDataApi, useGetAddressByNameQuery} from '../../../../../api/daDataApi'
import {AddressVariantItem} from './AddressVariantItem'
import {useAppDispatch, useAppSelector} from '../../../../../app/redux/hooks'
import {setClickableMap, setCurrentCoords, setShowInputModal} from '../NewUserOrderSlicer'
import {AdvancedSettings} from './AdvancedSettings'
import {EnumAddressMode, EnumShowMapMode, RouteAddress} from "../CreateOrderType";
import {
    currentOrderData,
    getShowMapMode,
    getStreetValue,
    setAddressMode,
    setOrderRoutes,
    setShowMapMode
} from "../CreateOrderSlicer";
import SelectPointOnMap from "../SelectPointOnMap/SelectPointOnMap";

interface IProps {
   address:RouteAddress,
   onAddressChanged :(address:RouteAddress)=>void
    onAddressRemove? :(address:RouteAddress)=>void
   onCancel?:()=>void
   useAdvanceMode:boolean,
}
export const InputAddressModal: FC<IProps> = memo(({address, onAddressChanged, onAddressRemove, onCancel, useAdvanceMode = false}) => {

    const dispatch = useAppDispatch();
    const  currentOrder = useAppSelector(currentOrderData);
    let  map_mode = useAppSelector(getShowMapMode);

    const [advancedSettings, setAdvancedSettings] = useState<boolean>(useAdvanceMode);
      let [currentAddress, setCurrentAddress] = useState<RouteAddress>(address ? address : new RouteAddress(currentOrder.routes.length));
    const { data } = useGetAddressByNameQuery(currentAddress.adress);

    const onCleanClick = () => {
        if (currentAddress.adress != '' )
            setCurrentAddress({...currentAddress, adress:''})
        else
            if (onCancel) onCancel();

    }

    const onAcceptClick = useCallback(() => {
       dispatch(setShowMapMode( {mode:EnumShowMapMode.doAppendPoint}))
    }, [])



    const submitAddress = useCallback((e: FormEvent<HTMLFormElement>) => {
        setAdvancedSettings(true)
        e.preventDefault()
    }, [])

    useEffect(() => {        
        if (data?.suggestions.length)
        dispatch(setCurrentCoords({lon: data?.suggestions[0].data.geo_lon ? +data?.suggestions[0].data.geo_lon : 0, lat: data?.suggestions[0].data.geo_lat ? +data?.suggestions[0].data.geo_lat : 0}))
    }, [data])



    const onAddressSelect = (selectedAddress: any) => {
        console.log(selectedAddress);
        console.log(currentAddress);
        if (!selectedAddress || !selectedAddress.data) return;
        let ad = {...currentAddress}
        ad.adress =  selectedAddress.value;
        ad.adress_latitude = selectedAddress.data.geo_lat;
        ad.adress_longitude = selectedAddress.data.geo_lon;
        ad.house = selectedAddress.data.house;
        setCurrentAddress({...ad});
        if (ad.house)
            setAdvancedSettings(true)
    }

    const deleteAddress = () =>{
        if (onAddressRemove)    onAddressRemove(address);
    }
    const saveAdvancedSettings = (value : RouteAddress) =>{
        setCurrentAddress({...value});
        onAddressChanged({...value})
    }


    return (
        <>
            <div className={`${s.inputAddressModalWrapper} ${advancedSettings ? s.transeformToAdvanced : ''} ${map_mode.mode != EnumShowMapMode.None && s.hideElement}`}>
            {!advancedSettings && <div className={s.inputAddressOverlay} onClick={() => {if (onCancel) onCancel()}}/>}
            <div className={`${s.inputAddressModal} ${advancedSettings ? s.transeformToAdvancedModal : ''}`}>
                <div className={s.inputAddressModalHeader}>
                    <div className={s.inputDirection} hidden={advancedSettings}>
                        {+currentAddress.id == 0  ? 'Откуда' : 'Куда'}
                    </div>
                    <div className={s.inputRow}>
                        {advancedSettings &&
                         (+currentAddress.id == 0  ? <img className={s.advancedIMG} alt="Адрес отправки" src={FromInputIcon} /> : <img className={s.advancedIMG} alt="Адрес доставки" src={ToInputIcon} />)
                        }
                        <form onSubmit={submitAddress}>
                            <input name='address' type='textarea' id='addressInput' placeholder='Введите адрес' autoComplete='off'
                                   value={currentAddress.adress}
                                   onChange={(e)=> setCurrentAddress({...currentAddress, adress: e.target.value})}
                                   onClick={()=>setAdvancedSettings(false)} />
                        </form>
                        {advancedSettings && <div className={s.timeToPoint}>{``}</div>}
                        {!advancedSettings && <img alt='Очистить ввод' src={ClearAddressInputIcon} onClick={onCleanClick} />}
                        {!advancedSettings && <img alt='Выбрать адресс на карте' src={data?.suggestions.length || (!data?.suggestions.length && !currentAddress) ? AcceptAddressInputIcon : +currentAddress.id == 0 ? SelectFromAddressOnMap : SelectToWhereAddressOnMap} onClick={onAcceptClick} />}
                    </div>
                </div>
                <div className={s.inputAddressModalBody}>
                    {advancedSettings 
                        ? <AdvancedSettings onSave={saveAdvancedSettings} address={currentAddress}
                                            onDelete={ onAddressRemove ? deleteAddress: undefined}  />
                        : data?.suggestions.length
                            ? data?.suggestions.filter(address => address.value.split(',').slice(1).length)
                                    .map(address => <AddressVariantItem key={address.value}
                                                                        address={address.value}
                                                                        house={address.data.house}
                                                                        setInputValue={(val) => onAddressSelect(address)} />)
                            : currentAddress.adress && <div style={{ color: '#FF3131', width: '100%', textAlign: 'center', marginTop: '17px' }}>Адрес не найден. Укажите номер дома или выберите точку на карте</div>
                    }
                </div>
            </div>
        </div>
            {map_mode.mode != EnumShowMapMode.None &&
                <SelectPointOnMap address={currentAddress}
                                  onChange={(address)=>setCurrentAddress(address)}/> }
        </>
    )
})
