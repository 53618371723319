import { DidNotHelpIcon } from "../../../assets/icons/Mistakes/DidNotHelpIcon";
import { LateIcon } from "../../../assets/icons/Mistakes/LateIcon";
import { WasRudeIcon } from "../../../assets/icons/Mistakes/WasRudeIcon";
import {
  EnumRatingMessage,
  IRatingWindowMistakeListItemProps,
} from "../RatingWindow.types";
import { StyledRatingWindowMistakeListItem } from "./RatingWindowMistakeListItem.styled";

const RatingWindowMistakeListItem = ({
  message,
  onClickMistake,
  color,
}: IRatingWindowMistakeListItemProps) => {
  const getSvgForEnumRatingMessage = (type: EnumRatingMessage) => {
    switch (type) {
      case EnumRatingMessage.late:
        return <LateIcon color={color} />;
      case EnumRatingMessage.didNotHelp:
        return <DidNotHelpIcon color={color} />;
      case EnumRatingMessage.wasRude:
        return <WasRudeIcon color={color} />;
    }
  };
  return (
    <StyledRatingWindowMistakeListItem color={color} onClick={onClickMistake}>
      <div>{getSvgForEnumRatingMessage(message)}</div>
      <div>{message}</div>
    </StyledRatingWindowMistakeListItem>
  );
};
export default RatingWindowMistakeListItem;
