import React, { FC, memo } from 'react'
import s from '../InputAddressModal.module.scss'

export const StartedButton: FC<PropTypes> = memo(({ onStartedButtonClick, submit }) => {
    return (
        <div className={s.startedButtonContainer}>
            <button onClick={onStartedButtonClick}>{submit ? 'Сохранить' : 'Далее'}</button>
        </div>
    )
})


type PropTypes = {
    onStartedButtonClick: () => void
    submit: boolean
}