import React, { FC, memo, useCallback, useState } from 'react'
import s from './VehicleBlockAuto.module.scss'
import { useAppSelector } from '../../../../../app/redux/hooks'
import { newUserOrderSelector } from '../NewUserOrderSlicer'
import { CarTypeItem } from './CarTypeItem'
import PackageIcon from '../../../../../assets/svg/PackageIcon.svg'
import DropDown from '../../../../../assets/svg/DropDown.svg'
import { PackedItemType } from '../../../../../api/orderApi'
import { CargoMapModal } from '../EditNewOrder/CargoMapModal/CargoMapModal'
import {currentOrderData, getPackInfo} from "../CreateOrderSlicer";



export const SelectedCategoryInfo: FC<{onCargoClick?:()=>void}> = ({onCargoClick }) => {

    const { carTypesTree } = useAppSelector(newUserOrderSelector);
    const packResponse = useAppSelector(getPackInfo);
    // console.log('packResponse', packResponse);
    const [showCargoMapModal, setShowCargoMapModal] = useState<boolean>(false);

    const currentCategory = packResponse.car_type_id ? carTypesTree.filter(x => x.id === packResponse.car_type_id)[0] : carTypesTree[0],
        volumeFrom = currentCategory.length_from * currentCategory.width_from * currentCategory.height_from,
        volumeTo = currentCategory.length_to * currentCategory.width_to * currentCategory.height_to,
        items: PackedItemType[] = packResponse.packed_items,
        ratio = 92 / packResponse.width

    const onCargoMapClick = useCallback(() => {
        setShowCargoMapModal(true)
    }, [])

    return (
        <div className={s.categories}>
            <div className={s.cargoDetails}>
                {packResponse.error
                    ? <div className={s.errorDescription}>{packResponse.error_description}</div>
                    : <div className={s.infoPanel}>
                        <img alt="Данные о грузе" src={PackageIcon} />
                        <div className={s.cargoParam}>
                            <div>
                                Общий объем
                            </div>
                            <div>
                                {packResponse.total_volume.toFixed(2).replace(/[.]?0+$/, '')} М<sup>3</sup>
                            </div>
                        </div>
                        <div className={s.cargoParam}>
                            <div>
                                Общая площадь
                            </div>
                            <div>
                                {packResponse.total_area.toFixed(2).replace(/[.]?0+$/, '')} М<sup>2</sup>
                            </div>
                        </div>
                        <div className={s.cargoParam}>
                            <div>
                                Общий вес
                            </div>
                            <div>
                                {packResponse.total_weight.toFixed(2).replace(/[.]?0+$/, '')} КГ
                            </div>
                        </div>
                    </div>
                }
            </div>
            {packResponse.error
                ? ''
                : <>
                    <img alt="Карта расположения груза" src={DropDown} />
                    <div className={s.slider}>
                        <div className={s.carCategoryContainer} id="element">
                            <CarTypeItem data={currentCategory} />
                        </div>
                        <div className={s.sizesBlock} style={{ minWidth: parseFloat(packResponse.height.toString()) * ratio + 'px' }} onClick={onCargoClick}>
                            {items.length ? items.map((block: PackedItemType, index: number) => {
                                return (
                                    <div key={index} className={s.cargoSizeBlock} style={{
                                        width: parseFloat(block.height.toString()) * ratio + 'px',
                                        height: parseFloat(block.width.toString()) * ratio + 'px',
                                        left: parseFloat(block.y.toString()) * ratio + 'px',
                                        top: parseFloat(block.x.toString()) * ratio + 'px'
                                    }}>
                                        <div className={s.sizeBlock}></div>
                                    </div>
                                );
                            }) : ''}
                        </div>

                    </div>
                    {onCargoClick && <div className={s.changeCargo}>изменить параметры груза</div>}
                    <div className={s.paramsWrapper}>
                        <div>
                            <div>
                                Длина
                            </div>
                            <div>
                                {currentCategory.length_from === currentCategory.length_to ? `${currentCategory.length_from} м` : `${currentCategory.length_from} - ${currentCategory.length_to} м`}
                            </div>
                        </div>
                        <div>
                            <div>
                                Ширина
                            </div>
                            <div>
                                {currentCategory.width_from === currentCategory.width_to ? `${currentCategory.width_from} м` : `${currentCategory.width_from} - ${currentCategory.width_to} м`}
                            </div>
                        </div>
                        <div>
                            <div>
                                Высота
                            </div>
                            <div>
                                {currentCategory.height_from === currentCategory.height_to ? `${currentCategory.height_from} м` : `${currentCategory.height_from} - ${currentCategory.height_to} м`}
                            </div>
                        </div>
                        <div>
                            <div>
                                Объем
                            </div>
                            <div>
                                {`${volumeFrom && volumeFrom.toFixed(2).replace(/[.]?0+$/, '')} - ${volumeTo && volumeTo.toFixed(2).replace(/[.]?0+$/, '')} м`}
                            </div>
                        </div>
                    </div>
                </>
            }
            {showCargoMapModal && <CargoMapModal setShowCargoMapModal={setShowCargoMapModal} />}
        </div>
    )
}
