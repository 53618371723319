import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/redux/store'
import {ICategory, IOrder} from '../OrderItem/OrderItem.types'
import { IOrderListFilterType } from './OrderList.types'

export interface orderListState {
  categories: ICategory[],
  filter: IOrderListFilterType,
  executing_orders : IOrder[],
}

const initialState: orderListState = {
  categories: [],
  filter: IOrderListFilterType.now,
  executing_orders:[],
};

export const orderListSelector = (state: RootState) => state.orderList;

export const orderListSlice = createSlice({
  name: 'orderList',
  initialState,
  reducers: {
    setCategories (state, action) {
      const categories = action.payload;
      state.categories = categories;
    },
    setFilter (state, action){
      state.filter = action.payload;
    },
    setCurrentOrdersBeingExecuted (state, action){
      state.executing_orders = action.payload;
    },
    clear (state) {
      state.categories = [];
      state.filter = IOrderListFilterType.now
    }
  }
})

export const {
  setCategories,
  setFilter,
  setCurrentOrdersBeingExecuted,
  clear,
} = orderListSlice.actions



export default orderListSlice.reducer
