import React, { FC, useCallback, memo } from 'react'
import s from './VehicleBlockAuto.module.scss'
import Category1 from '../../../../../assets/svg/Category1.svg'
import Category1Plus from '../../../../../assets/svg/Category1Plus.svg'
import Category2 from '../../../../../assets/svg/Category2.svg'
import Category2Plus from '../../../../../assets/svg/Category2Plus.svg'
import Category3 from '../../../../../assets/svg/Category3.svg'
import Category4 from '../../../../../assets/svg/Category4.svg'
import Category5 from '../../../../../assets/svg/Category5.svg'
import Category6 from '../../../../../assets/svg/Category6.svg'
import Category7 from '../../../../../assets/svg/Category7.svg'
import { CarTypeTreeQueryResponse } from '../../../../../api/orderApi'

export const CarTypeItem: FC<propTypes> = memo(({ data }) => {
    
    const categoryIcon = useCallback((category: string) => {
        switch (category) {
            case "Категория №1":
                return Category1

            case "Категория №1+":
                return Category1Plus

            case "Категория №2 ":
                return Category2

            case "Категория №2+":
                return Category2Plus

            case "Категория №3":
                return Category3

            case "Категория №4":
                return Category4

            case "Категория №5":
                return Category5

            case "Категория №6":
                return Category6

            case "Категория №7":
                return Category7

            default:
                break;
        }
    }, [data.name])

    return (
        <div className={s.carTypeWrapper}>
            <div>
                <img alt={data.name} src={categoryIcon(data.name)} />
            </div>
            <div className={s.textContainer}>
                <div className={s.text}>
                    {data.name.replace(/№/g, '')}
                </div>
                <div className={s.weight}>
                    {data.weight_to} кг
                </div>
            </div>
        </div>
    )
})


type propTypes = {
    data: CarTypeTreeQueryResponse
}
