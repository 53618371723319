import styled from 'styled-components'
import {
  InputBackgroundColor,
  InputColor,
  IStyledInputProps
} from './Input.types'

export const StyledInput = styled.input<IStyledInputProps>`
  width: ${props => props.width || 'calc(100% - 20px)'};
  height: ${props => props.height};
  color: ${props => props.color || InputColor.BLACK};
  background: ${props => props.background || InputBackgroundColor.LIGHT_GREY};
  font-size: ${props => props.fontSize || '17px'};
  font-weight: ${props => props.fontWeight || 'normal'};
  border-radius: ${props => props.borderRadius || '8px'};
  border: ${props => props.border || 'none'};
  padding:  ${props => props.padding || '14px 16px'};
  text-align: ${props => props.textAlign};
  margin:  ${props => props.margin || 'auto'};
  font-family: 'Fira Sans', sans-serif;
  margin:0 10px;
  &:active,
  &:focus {
    border: ${props => props.border || 'none'};
    outline-color: #ecedef;
  }
  &::placeholder {
    color: #888e99;
  }
 
`;

export const StyledInnInput = styled.input`
  width: 100%;
  font-family: 'Fira Sans', sans-serif;
  color: ${InputColor.BLACK};
  background: ${InputBackgroundColor.WHITE};
  font-size: 17px;
  font-weight: normal;
  border: none;
  padding: 14px 16px;

  &:active,
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
`;
