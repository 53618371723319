import styled from 'styled-components'

const StyledSvg = styled.svg`
  cursor: pointer;
`

export const GreenArrow = () => {
  return (
    <StyledSvg
      width='15'
      height='11'
      viewBox='0 0 15 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1 4.75L5.5 9.25L14 0.75' stroke='#00BB40' strokeWidth='2' />
    </StyledSvg>
  )
}
