import styled from "styled-components";
import { IContentCardWrapper } from "./ContentCard.types";

export const ContentCardWrapper = styled.div<IContentCardWrapper>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0px 0px;
  padding-top: 30px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
  position: fixed;
  transition: all 0.3s;
  min-height: 30%;
  /*overflow: hidden auto;*/
  /*z-index: 1;*/
  bottom: 0;
  align-items: center;
  width: 100%;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ${({isLocationDetectOpened}) => isLocationDetectOpened && `
    :before{
      content: '';
      position: absolute;
      width: 100vw;
      height: 100vh;
      background: #000000;
      opacity: 0.5;
      top: -100vh;
    }
  `}
`;

export const ContentCardWrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
`;

export const ContentCardWrapperHeaderCol = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentCardWrapperHeaderColHeading = styled.h1`
  font-size: 20px;
`;

export const ContentCardWrapperHeaderDate = styled.p`
  color: #5364ff;
  margin-left: 31px;
`;

export const ContentCardWrapperHeaderProductName = styled.p`
  font-weight: normal;
  font-size: 17px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #888e99;
  margin-bottom: 23px;
  margin-top: 24px;
`;

export const ContentCardWrapperFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
