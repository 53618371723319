import {
  StyledOrderCar,
  StyledOrderCarModel,
  StyledOrderCarModelRight,
  StyledOrderCarNumber,
  StyledOrderRowCar
} from './OrderCar.styled'


export interface IOrderCarProps{
  isGrey? : boolean;
  model: string,
  number: string,
  length: number,
  width: number,
  height: number,
  bodyName: string,
  category: JSX.Element,
  categoryName?: string,
  isNeedBorderBottom?: boolean
  onClick?: ()=>void;
}

export const OrderCar = ({
  isGrey,
  model,
  number,
  length,
  width,
  height,
  bodyName,
  category,
  categoryName,
  isNeedBorderBottom,
  onClick
}: IOrderCarProps) => {
  return (
    <StyledOrderRowCar isGrey={isGrey} isNeedBorderBottom={isNeedBorderBottom} onClick={()=>{if (onClick) onClick()}}>
      <StyledOrderCar>{category}</StyledOrderCar>
      <StyledOrderCarModel>
        <span>{model}</span>
        <StyledOrderCarNumber>{number}</StyledOrderCarNumber>
      </StyledOrderCarModel>

      <StyledOrderCarModelRight>
        <span>{bodyName}</span>
        <StyledOrderCarNumber>
          {categoryName ? categoryName.replace('№','') : `${length} x ${width} x ${height} м`}
        </StyledOrderCarNumber>
      </StyledOrderCarModelRight>
    </StyledOrderRowCar>
  )
};
export default OrderCar;
