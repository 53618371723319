import React, { FC, memo, useCallback, useState, Dispatch, SetStateAction, useRef, useEffect } from 'react'
import { PackedItemType } from '../../../../../../api/orderApi'
import { useAppSelector } from '../../../../../../app/redux/hooks'
import { newUserOrderSelector } from '../../NewUserOrderSlicer'
import s from './CargoMapModal.module.scss'
import CloseAutoselect from '../../../../../../assets/svg/CloseDetailing.svg'

export const CargoMapModal: FC<{ setShowCargoMapModal: Dispatch<SetStateAction<boolean>> }> = memo(({ setShowCargoMapModal }) => {

    const {
        packResponse
    } = useAppSelector(newUserOrderSelector)

    const cargoMapRef = useRef<HTMLDivElement>(null)

    const [mapHeight, setMapHeight] = useState<number>(0)

    const items: PackedItemType[] = packResponse.packed_items,
        ratio = mapHeight / packResponse.height

    const onCloseClick = useCallback(() => {
        setShowCargoMapModal(false)
    }, [])

    useEffect(() => {
        cargoMapRef.current && setMapHeight(cargoMapRef.current?.offsetHeight)
    }, [])

    return (
        <div className={s.cargoMapWrapper}>
            <div className={s.layout} onClick={onCloseClick}></div>
            <div className={s.body}>
                <div className={s.header}>
                    <div>
                        Схема загрузки
                    </div>
                    <img alt='Закрыть автоподбор ТС' src={CloseAutoselect} onClick={onCloseClick} />
                </div>
                <div className={s.text}>
                    Проверьте правильно ли уложен груз
                </div>
                <div className={s.cargo} ref={cargoMapRef}>
                    <div className={s.sizesBlockModal} style={{ width: parseFloat(packResponse.width.toString()) * ratio + 4 + 'px' }}>
                        {items.length ? items.map((block: PackedItemType, index: number) => {
                            return (
                                <div key={index} className={s.cargoSizeBlock} style={{
                                    width: parseFloat(block.width.toString()) * ratio + 'px',
                                    height: parseFloat(block.height.toString()) * ratio + 'px',
                                    left: parseFloat(block.x.toString()) * ratio + 'px',
                                    top: parseFloat(block.y.toString()) * ratio + 'px'
                                }}>
                                    <div className={s.sizeBlock}>
                                        <div className={s.sizeBlockValue} style={{ marginRight: '-10px' }}><div>{block.height >= 0.3 ? block.height * 100 : ''} {block.height > 0.8 ? 'см' : ''}</div></div>
                                        <div className={s.sizeBlockValue}>{block.width >= 0.3 ? block.width * 100 : ''} {block.width > 0.8 ? 'см' : ''}</div>
                                    </div>
                                </div>
                            );
                        }) : ''}
                    </div>
                </div>
            </div>
        </div>
    )
})