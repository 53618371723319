import React, {FC, useEffect, memo, useState} from 'react'
import s from './NewUserOrder.module.scss'
import { NewOrder } from './EditNewOrder/NewOrder'
import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import { newUserOrderSelector, setAditionalRequirements, setCarBodyOptionCharacteristics, setCarBodyOptionCharacteristicsValues, setCarBodyOptions, setCarBodyTypes, setCarTypesTree, setCurrentAddress, setCurrentCoords, setFromAddress, setIsHouseHas, setOnMapClick, setPackageTypes, setPalletTypes, setSelectedCarCategory, setSelectedTariff, setTariffTypes, setToAddress } from './NewUserOrderSlicer'
import {  usePostOrderMutation } from '../../../../api/orderApi'
import { OrderIsReady } from './EditNewOrder/OrderIsReady/OrderIsReady'


import {currentOrderData, getOrderIsChanged,   setOrderChanged, setOrderID} from "./CreateOrderSlicer";
import {SendOrderInfo} from "./CreateOrderType";

import {authorizationSelector} from "../../../Authorization/authorizationSlice";
import {userSelector} from "../../../../app/redux/reducers/user";
import {cookiesClientId, cookiesOrderId} from "../../../../api/api.constants";
import {useCookies} from "react-cookie";
import OrderCompletion from "./EditNewOrder/OrderCompletion/OrderCompletion";

export const NewUserOrder: FC<{}> = ({  }) => {
    const [ postOrder  ] = usePostOrderMutation();

    const dispatch = useAppDispatch()

    const {  currentCoords,   } = useAppSelector(newUserOrderSelector);
    const is_changed = useAppSelector(getOrderIsChanged);
    const currentOrder= useAppSelector(currentOrderData);
    const { isAuth } = useAppSelector(authorizationSelector);
    const { clientId } = useAppSelector(userSelector);


    const [isOrderCompletion, setOrderCompletion] = useState<boolean>(false);

    const [isShowOrderIsReady, setOrderIsReady] = useState<boolean>(false);




    // useEffect(() => {
    //     if(!isFetching  && data?.suggestions.length){
    //         travers === 'from'
    //             ? dispatch(setFromAddress(data.suggestions[0].value.split(',').slice(1).toString()))
    //             : dispatch(setToAddress(data.suggestions[0].value.split(',').slice(1).toString()))
    //         if (onMapClick) {
    //             dispatch(setCurrentAddress(data.suggestions[0].value.split(',').slice(1).toString()))
    //             data.suggestions[0].data.house && dispatch(setIsHouseHas(true))
    //             dispatch(setOnMapClick(false))
    //         }
    //     } else if ((currentCoords.lat && currentCoords.lon) && !data?.suggestions.length) {
    //         travers === 'from'
    //             ? dispatch(setFromAddress(''))
    //             : dispatch(setToAddress(''))
    //     }
    // }, [isFetching])

    useEffect(() => {
        //refetch()
    }, [currentCoords])

    const [cookies, setCookie] = useCookies([cookiesOrderId])

    useEffect(() => {
        if (is_changed) {
            dispatch(setOrderChanged(false));
            console.log('was changed');
            let data : SendOrderInfo = {...currentOrder};
            data.draft = true;
            if (isAuth && clientId) data.client_id = clientId;
            postOrder(data)
                .unwrap()
                .then(x => {
                    if (!x.error && x.id) {
                        if (!data.id) dispatch(setOrderID(x.id))
                        setCookie(cookiesOrderId, x.id, { path: '/' });
                    }
                })
        }
    }, [is_changed]);

    return (
        <div className={s.newUserOrderWrapper}>
            {!isOrderCompletion && !isShowOrderIsReady &&
                <NewOrder onComplete={()=> setOrderCompletion(true)} onCancelOrder={()=>{ setOrderCompletion(false) }}/>}

            {isOrderCompletion &&
                <OrderCompletion onBack={()=> setOrderCompletion(false)}
                                 onOrderComplete={()=> {setOrderCompletion(false); setOrderIsReady(true)}}
                                 onCancelOrder={()=>{ setOrderCompletion(false) }}
                />}

            {isShowOrderIsReady && <OrderIsReady onClose={()=>{
                console.log('onClose');
                setOrderCompletion(false)
                setOrderIsReady(false)
            }} />}
        </div>
    )
}
