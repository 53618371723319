import React, { FC, memo } from 'react'
import s from '../InputAddressModal.module.scss'

export const ControllButtons: FC<PropTypes> = memo(({ onSaveControllButtonClick, onDeleteControllButtonClick }) => {
    return (
        <div className={s.controllButtonContainer}>
            <button onClick={onSaveControllButtonClick}>Сохранить</button>
            <button onClick={onDeleteControllButtonClick}>Удалить</button>
        </div>
    )
})


type PropTypes = {
    onSaveControllButtonClick: () => void
    onDeleteControllButtonClick: () => void
}