import Profile from '../../modules/Home/components/Profile'
import RegistrationForm from '../../modules/Home/components/RegistrationForm'
import AuthorizationForm from '../../modules/Home/components/AuthorizationForm'
import OrderList from '../../modules/Orders/components/OrderList'
import Order from '../../modules/Orders/components/Order'
import { setIsFull } from '../../modules/Orders/components/OrderAddressList/orderAddressListSlice'
import { setFilter } from '../../modules/Orders/components/OrderList/orderListSlice'
import { IOrderListFilterType } from '../../modules/Orders/components/OrderList/OrderList.types'
import { openLoader } from '../Loader/loaderSlice'
import Help from '../../modules/Help/components/Help'
import ArticleList from '../../modules/Help/components/ArticleList'
import Article from '../../modules/Help/components/Article'
import { AppDispatch, RootState } from '../../app/redux/store'
import {
  closeModal,
  modalFullSelector,
  openModalWithTitle as openModalFullWithTitle
} from './modalFullSlice'
import { clear as clearProfile } from '../../modules/Home/components/Profile/profileSlice'
import { articleListSelector } from '../../modules/Help/components/ArticleList/articleListSlice'
import {Balance} from "../../modules/Home/components/Balance/Balance";

export const getChildren = (children: string) => {
  switch (children) {

    case 'RegistrationForm':
      return <RegistrationForm />
    case 'AuthorizationForm':
      return <AuthorizationForm />
    case 'OrderList':
      return <OrderList />

    case 'Help':
      return <Help />
    case 'ArticleList':
      return <ArticleList />
    case 'Article':
      return <Article />
    case 'Balance':
      return <Balance />
    default:
      return <div></div>
  }
}

export const getTitle = (refTitle: React.RefObject<HTMLDivElement>, title: string) => {
  if (refTitle && refTitle.current) {
    refTitle.current.innerHTML = title
  }
}

export const close = (dispatch: AppDispatch, children: string) => {
  dispatch(closeModal())
  switch (children) {
    case 'Profile':
      dispatch(clearProfile())
      break
    case 'Order':
      dispatch(openLoader())
      dispatch(
        openModalFullWithTitle({
          children: 'OrderList',
          title: 'Журнал заказов'
        })
      )
      dispatch(setIsFull(false))
      break
    case 'OrderList':
      dispatch(setFilter(IOrderListFilterType.now))
      break
    case 'ArticleList':
      dispatch(openModalFullWithTitle({ children: 'Help', title: 'Помощь' }))
      break
    case 'Article':
      dispatch(
        openModalFullWithTitle({
          children: 'ArticleList',
          background: '#E5E5E5'
        })
      )
      break
    default:
      dispatch(closeModal())
      break
  }
}
