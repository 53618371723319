import { useClientsProfileMutation } from '../../../../api/orderApi'
import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import Button from '../../../../components/Button'
import {
  ButtonBackgroundColor,
  ButtonColor,
  ButtonTypeEvent
} from '../../../../components/Button/Button.types'
import Form from '../../../../components/Form'
import {
  FlexDirectionType,
  JustifyContentType
} from '../../../../components/Form/Form.types'
import Input from '../../../../components/Input'
import {
  InputBackgroundColor,
  InputType
} from '../../../../components/Input/Input.types'
import { closeModal } from '../../../../components/Modal/modalSlice'
import { profileSelector, setProfileById } from '../Profile/profileSlice'
import {
  EditClientsFormField,
  editClientsFormSelector,
  setValue,
  setSmallText,
  setIsEditCompanyProfile
} from './editClientsFormSlice'
import { openInfoMessage } from '../../../../components/InfoMessage/infoMessageSlice'
import { InfoMessageType } from '../../../../components/InfoMessage/InfoMessage.types'
import Inn from '../../../../components/Inn'
import { StyledEditClientsFormDiv } from './EditClientsForm.styled'
import { userSelector } from '../../../../app/redux/reducers/user'
import {
  updateProfile
} from '../Profile/Profile.types'
import PhoneInput from '../../../../components/PhoneInput'
import { setValue as setPhone } from '../../../../components/PhoneInput/phoneInputSlice'
import { setValue as setInnData } from '../../../../components/Inn/innSlice'
import { DisabledDivForButtonMaxWidth } from '../Code/Code.styled'
import { useEffect } from 'react'
import Address from '../../../../components/Address'

export const EditClientsForm = () => {
  const { profile, selectedCompanyInn } = useAppSelector(profileSelector)
  const editableProfile = JSON.parse(JSON.stringify(profile))

  const {
    id,
    editClientsFormField,
    value,
    placeholder,
    isValidValue,
    maxLength,
    smallText,
    type,
    isEditCompanyProfile
  } = useAppSelector(editClientsFormSelector)

  const { clientId } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()
  const [clients] = useClientsProfileMutation()

  useEffect(() => {
    if (editClientsFormField === EditClientsFormField.phone) {
      dispatch(setPhone(value))
    }
    if (
      editClientsFormField === EditClientsFormField.inn ||
      editClientsFormField === EditClientsFormField.addCompany
    ) {
      dispatch(
        setInnData({ inn: value, companyName: value ? smallText : undefined })
      )
    }
  }, [value])

  const onSubmitHandler = () => {
    if (!isValidValue) return
    updateProfile(
      editableProfile,
      editClientsFormField,
      id,
      value,
      type,
      selectedCompanyInn
    )

    clients(editableProfile)
      .unwrap()
      .then(res => {
        if (res.status === 'success') {
          dispatch(closeModal())
          if (isShowInn) {
            dispatch(setIsEditCompanyProfile(true))
            setProfileById(clientId, dispatch, value)
          } else {
            setProfileById(
              clientId,
              dispatch,
              isEditCompanyProfile ? undefined : selectedCompanyInn
            )
          }
        } else {
          openErrorMessage(res.error_message)
        }
      })
  }

  const onChangeHandler = (value: string) => {
    dispatch(setValue(value))
  }

  const onChangeInnHandler = (
    inn: string | undefined,
    companyName: string | undefined
  ) => {
    dispatch(setValue(inn))
    dispatch(setSmallText(companyName))
  }

  const onChangeAddress = (value: string|undefined)=>{
    dispatch(setValue(value))
  }

  const openErrorMessage = (text = 'Перепроверьте введенные данные') => {
    dispatch(
      openInfoMessage({
        title: 'Ошибка',
        text: text,
        type: InfoMessageType.FAILIED
      })
    )
  }

  const ERROR_BORDER = '1px solid red'
  const isShowInn =
    editClientsFormField === EditClientsFormField.inn ||
    editClientsFormField === EditClientsFormField.addCompany
  const isShowPhone = editClientsFormField === EditClientsFormField.phone
  const isShowAddress = editClientsFormField === EditClientsFormField.address
  return (
    <>
      <Form
        flexDirection={FlexDirectionType.COLUMN}
        justifyContent={JustifyContentType.FLEX_START}
        margin='12px 0px'
      >
        {!isShowInn && !isShowPhone && !isShowAddress && (
          <Input
            id={id}
            name={editClientsFormField}
            type={InputType.text}
            placeholder={placeholder}
            background={InputBackgroundColor.WHITE}
            value={value}
            onChange={e => onChangeHandler(e.target.value)}
            border={`${isValidValue ? '1px solid #FFF' : ERROR_BORDER}`}
            maxLength={maxLength}
            margin='0'
          />
        )}
        {isShowPhone && (
          <PhoneInput
            inputValue={value}
            onChange={value => {
              onChangeHandler(value)
            }}
          />
        )}
        {isShowInn && (
          <>
            <Inn
              onChange={(inn, companyName) =>
                onChangeInnHandler(inn, companyName)
              }
            />
            <StyledEditClientsFormDiv>
              {'Создавая компанию, вы становитесь её представителем'}
            </StyledEditClientsFormDiv>
          </>
        )}
        {isShowAddress && <Address onChange={onChangeAddress} placeholder={placeholder} defaultValue={value}/>}
      </Form>
      <DisabledDivForButtonMaxWidth disabled={!isValidValue}>
        <Button
          buttonBackgroundColor={ButtonBackgroundColor.BLUE}
          buttonColor={ButtonColor.WHITE}
          buttonTypeEvent={ButtonTypeEvent.BUTTON}
          onClick={onSubmitHandler}
        >
          Сохранить
        </Button>
      </DisabledDivForButtonMaxWidth>
    </>
  )
}
