import styled from 'styled-components'

const StyledSvg = styled.svg`
  cursor: pointer;
`

export const ArrowBottomIcon = () => {
  return (
    <StyledSvg
      width='14'
      height='9'
      viewBox='0 0 14 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 1L7 7L13 1'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </StyledSvg>
  )
}
