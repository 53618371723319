import React, { useEffect, useState} from 'react'
import {
  ContentBackdrop,
  ContentWrapper
} from './components/ContentWrapper/ContentWrapper.styled'
import { Burger } from './components/Burger/Burger'
import { HereMap } from './components/HereMap/HereMap'
import { useAppSelector } from './app/redux/hooks'
import {
  setDetectedLocation
} from './modules/Home/components/LocationDetect/locationDetectSlice'
import { useAppDispatch } from './app/redux/hooks'
import {
  sideBarSelector,
  openSideBar,
  closeSideBar
} from './modules/Home/components/SideBar/sideBarSlice'
import SideBar from './modules/Home/components/SideBar'
import Modal from './components/Modal'
import InfoMessage from './components/InfoMessage'

import Loader from './components/Loader'
import ModalFull from './components/ModalFull'
import { setClientId, userSelector } from './app/redux/reducers/user'
import { useCookies } from 'react-cookie'
import {cookiesClientId, cookiesClientPhone, cookiesDefaultUserId, cookiesOrderId} from './api/api.constants'
import { checkIsValidGuid } from './helper'
import { HeaderOrdersButton } from './modules/Home/components/HeaderOrdersButton/HeaderOrdersButton'
import { NewUserOrder } from './modules/Home/components/NewUserOrder/NewUserOrder'
import {
  newUserOrderSelector, setAditionalRequirements,
  setCarBodyOptionCharacteristics,
  setCarBodyOptionCharacteristicsValues,
  setCarBodyOptions,
  setCarBodyTypes,
  setCarTypesTree,
  setCurrentCoords, setPackageTypes, setPalletTypes, setSelectedTariff, setTariffTypes,

} from './modules/Home/components/NewUserOrder/NewUserOrderSlicer'
import {
  authorizationSelector,
  setIsAuth
} from './modules/Authorization/authorizationSlice'
import { useGetIsAuthQuery } from './api/homeApi'

import {EnumShowMapMode, IMapShowMode } from "./modules/Home/components/NewUserOrder/CreateOrderType";

import {
  setShowMapMode,
  setOrderDataAuto,
  setOrderDataManual,  getOrderReducer
} from "./modules/Home/components/NewUserOrder/CreateOrderSlicer";

import {
  useGetAdditionalRequirementsMutation,
  useGetBodyOptionCharacteristicsMutation,
  useGetBodyOptionCharacteristicsValuesMutation,
  useGetBodyOptionsMutation,
  useGetBodyTypesMutation,
  useGetCarTypesTreeMutation,
  useGetOrderMutation,
  useGetPackagesTypesMutation,
  useGetPalletTypesMutation,
  useGetTariffTypesMutation
} from "./api/orderApi";
import UserProfile from "./modules/Home/components/UserProfile/UserProfile";
import {loadProfileByIdThunk, userProfileSelector} from "./modules/Home/components/UserProfile/userProfileSlice";
import {userProfileState} from "./modules/Home/components/UserProfile/userProfile.type";
import AppLoadingForm from "./modules/Home/components/AppLoadingForm/AppLoadingForm";
import {hereMapSelector} from "./components/HereMap/hereMapSlice";
import {Order} from "./modules/Orders/components/Order/Order";
import {orderSelector} from "./modules/Orders/components/Order/orderSlice";
import { redrawCarsOnMap, requestWakeLock} from "./AppFunctions";
import {orderListSelector} from "./modules/Orders/components/OrderList/orderListSlice";
import RouteToGPX from "./components/RouteToGPX/RouteToGPX";

var tmp_map_mode : IMapShowMode = {mode:0};


const App = () => {
  const dispatch = useAppDispatch();

  const { isSideBarOpened } = useAppSelector(sideBarSelector);
  const selectedOrder = useAppSelector(orderSelector);
  const { clientId, fullName } = useAppSelector(userSelector);
  const [cookies, setCookie] = useCookies([ cookiesClientId, cookiesOrderId, cookiesDefaultUserId, cookiesClientPhone ]);
  const { isAuth } = useAppSelector(authorizationSelector); // выводит кнопку для отображения всех заказов
  const [tikTak, setTickTack] = useState<boolean>(false);
  const { executing_orders } = useAppSelector(orderListSelector);

  const   userProfile : userProfileState = useAppSelector(userProfileSelector);

  const { markers, routes } = useAppSelector(hereMapSelector);
  const { clickableMap } = useAppSelector(newUserOrderSelector);

  const { isFetching, data } = useGetIsAuthQuery(cookies.clientId); // запрос регистрации

  const [getOrder] = useGetOrderMutation();
  const [isLoadingData, setLoadingData] = useState(false);

  const toggleSidebar = () =>  isSideBarOpened ? dispatch(closeSideBar()) : dispatch(openSideBar()); // открывает сайдбар

  const [coords, setCoords] = useState<{ lon: number; lat: number }>({ lon: 0, lat: 0  });

  //const [getAddressByCoords, setGetAddressByCoords] = useState(true);

  let  {map_mode} = useAppSelector(getOrderReducer);
  tmp_map_mode = map_mode;
//  console.log('map_mode', map_mode);
  const onMapClick =   (lon: number, lat: number) => {
      console.log('onMapClick map_mode=', map_mode)
      // обработка клика по карте
      if ( tmp_map_mode.mode != EnumShowMapMode.None)
        dispatch(setShowMapMode({mode: EnumShowMapMode.wasClickOnMap, coordinate: { lon, lat }}))
     }

  const settingClientId = () => {
    if (!clientId) {
      const id = cookies.clientId;
      const isValidGuid = checkIsValidGuid(id);
      if (isValidGuid) {
        dispatch(setClientId(id))
      }
    }
  }

  const [carTypesTreeResponse] = useGetCarTypesTreeMutation(),
        [carBodyTypesResponse] = useGetBodyTypesMutation(),
        [carBodyOptionsResponse] = useGetBodyOptionsMutation(),
        [carBodyOptionCharacteristicsResponse] = useGetBodyOptionCharacteristicsMutation(),
        [carBodyOptionCharacteristicsValuesResponse] = useGetBodyOptionCharacteristicsValuesMutation(),
        [tariffTypes] = useGetTariffTypesMutation(),
        [additionalRequirements] = useGetAdditionalRequirementsMutation(),
        [palletTypesResponse] = useGetPalletTypesMutation(),
        [packageTypesResponse] = useGetPackagesTypesMutation();
        // [getOrderList] = useGetOrderListMutation();

  useEffect(() => {
    settingClientId()
  }, [clientId, cookies.clientId]);

  const loadData = async () => {
    setLoadingData(true);
    let response : any = null;
    response = await carTypesTreeResponse(null).unwrap();
    if (response && response?.length)   dispatch(setCarTypesTree(response));

    response = await carBodyTypesResponse(null).unwrap();
    if (response && response?.length)   dispatch(setCarBodyTypes(response));

    response = await carBodyOptionsResponse(null).unwrap();
    if (response && response?.length)   dispatch(setCarBodyOptions(response));

    response = await carBodyOptionCharacteristicsResponse(null).unwrap();
    if (response && response?.length)   dispatch(setCarBodyOptionCharacteristics(response));

    response = await carBodyOptionCharacteristicsValuesResponse('').unwrap();
    if (response && response?.length)   dispatch(setCarBodyOptionCharacteristicsValues(response));

    response = await additionalRequirements(null).unwrap();
    if (response && response?.length)   dispatch(setAditionalRequirements(response));

    response = await palletTypesResponse('').unwrap();
    if (response && response?.length)   dispatch(setPalletTypes(response));

    response = await packageTypesResponse('').unwrap();
    if (response && response?.length)   dispatch(setPackageTypes(response));

    response = await tariffTypes(null).unwrap();
    if (response && response?.length) {
      response = response.map((x:any) => ({...x, tariff_name: x.name}));
      dispatch(setTariffTypes(response));
      dispatch(setSelectedTariff(response.filter((y:any) => y.name.slice(-2) !== 'РМ')[0]))
    }

    if (!cookies.orderId) return setLoadingData(false);

    let res = await getOrder(cookies.orderId).unwrap();
    setLoadingData(false);
    if (!res || typeof res != 'object' ) return;

    if (res.cargo.places.length || res.cargo.packages.length || res.cargo.pallets.length) {
      dispatch(setOrderDataAuto(res))
    } else
      dispatch(setOrderDataManual(res))
  };

  useEffect(() => {
    loadData().then();
    let interval : any = setInterval(async () =>  requestWakeLock(), 1000);
    let intervalTick : any = setInterval(() =>  setTickTack(!tikTak)  , 60000);
    return () => {
      if (interval) clearInterval(interval);
      if (intervalTick) clearInterval(intervalTick);
    };
  }, []);

  // useEffect( () =>  {
  //  if (clientId)
  //     checkOrderExecuted(clientId, executing_orders, dispatch, getOrderList);
  // }, [tikTak, clientId]);
  useEffect( () =>  { redrawCarsOnMap(clientId? clientId:'', executing_orders, dispatch)}, [executing_orders]);

  const addMarker = (lon: number, lat: number) => {
    setCoords({
      lon: Number(lon.toFixed(3)) as number,
      lat: Number(lat.toFixed(3)) as number
    });
    //setGetAddressByCoords(false)
  };

  useEffect(() => {
    // обработка ответа запроса на авторизацию
    if (data?.status && data?.status!='error'){
      dispatch(setIsAuth())
      if (clientId) {
       loadProfileByIdThunk(clientId, dispatch, cookies.selectedUserId, fullName).then( (selectedUserId)=>{
         console.log(`setCookie selectedUserId "${selectedUserId}"`)
         setCookie(cookiesDefaultUserId, selectedUserId, { path: '/' });
       });

      }
    }
  }, [isFetching]);



  useEffect(() => {
    if (clickableMap) {
      dispatch(setCurrentCoords(coords));
      dispatch(setDetectedLocation({ lat: 0, lon: 0 }))
    }
  }, [coords])


  return (
    <>
      <ContentBackdrop>
        <ContentWrapper>
          <Burger onClick={toggleSidebar} />
          <SideBar onBurgerOverlayClick={toggleSidebar}/>
        </ContentWrapper>
      </ContentBackdrop>
      {isAuth && !selectedOrder.id && <HeaderOrdersButton />}
      <HereMap
        markers={markers}
        onClick={onMapClick}
        onMarkerClick={index => console.log(index)}
        routs={routes}

      />
      {(!selectedOrder || !selectedOrder.id) &&  <NewUserOrder />}
      <InfoMessage />
      <Modal />
      <ModalFull />
      {userProfile.is_visible && <UserProfile/>}
      <Loader />
      {isLoadingData && <AppLoadingForm />}
      {selectedOrder && selectedOrder.id && <Order />}
      {/*{<RouteToGPX/>}*/}
    </>
  )
}

export default App
