import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/redux/store'
import { ProfileItemType } from '../Profile/Profile.types'
import {
  checkIsValidValue,
  DEFAULT_MAX_LENGTH,
  getMaxLengthValue
} from './editClientsFormHelper'

export enum EditClientsFormField {
  phone = 'phone',
  email = 'email',
  inn = 'inn',
  position = 'position',
  site = 'site',
  addCompany = 'addCompany',
  addEmployee = 'addEmployee',
  button = 'button',
  empty = 'empty',
  contract = 'contract',
  requisites = 'requisites',
  address = 'address'
}
export interface EditClientsFormState {
  id: string
  type: ProfileItemType
  editClientsFormField: EditClientsFormField | string
  value: string
  placeholder: string
  isValidValue: boolean
  maxLength: number
  smallText?: string,
  isEditCompanyProfile: boolean
}

const initialState: EditClientsFormState = {
  id: '',
  editClientsFormField: '',
  type: ProfileItemType.user,
  value: '',
  placeholder: '',
  isValidValue: true,
  maxLength: DEFAULT_MAX_LENGTH,
  smallText: '',
  isEditCompanyProfile : false
}

export const editClientsFormSelector = (state: RootState) =>
  state.editClientsForm

export const editClientsFormSlice = createSlice({
  name: 'editClientsForm',
  initialState,
  reducers: {
    setEditClientsFormField (state, action) {
      const {
        id,
        editClientsFormField,
        placeholder,
        value,
        smallText,
        type
      } = action.payload
      state.id = id
      state.editClientsFormField = editClientsFormField
      state.placeholder = placeholder
      state.value = value
      state.type = type
      state.isValidValue = checkIsValidValue(value, state.editClientsFormField)
      state.smallText = smallText
    },
    setValue (state, action) {
      const value = action.payload
      state.value = value
      state.isValidValue = checkIsValidValue(value, state.editClientsFormField)
      state.maxLength = getMaxLengthValue(value, state.editClientsFormField)
    },
    setSmallText (state, action) {
      state.smallText = action.payload
    },
    setIsEditCompanyProfile (state, action) {
      state.isEditCompanyProfile = action.payload
    },
    clear (state) {
      state.id = ''
      state.editClientsFormField = ''
      state.value = ''
      state.placeholder = ''
      state.isValidValue = true
      state.smallText = undefined
      state.isEditCompanyProfile = false
    }
  }
})

export const {
  clear,
  setEditClientsFormField,
  setValue,
  setSmallText,
  setIsEditCompanyProfile
} = editClientsFormSlice.actions

export default editClientsFormSlice.reducer
