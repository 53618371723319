import styled from "styled-components";

const StyledSvg = styled.svg`
  cursor: pointer;
`;
interface IDidNotHelpIconProps {
  color?: string;
}

export const DidNotHelpIcon = ({ color }: IDidNotHelpIconProps) => {
  return (
    <StyledSvg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 4C35 4 44 13 44 24C44 35 35 44 24 44C13 44 4 35 4 24C4 13 13 4 24 4ZM24 8C20.2 8 16.8 9.2 14.2 11.4L36.6 33.8C38.6 31 40 27.6 40 24C40 15.2 32.8 8 24 8ZM33.8 36.6L11.4 14.2C9.2 16.8 8 20.2 8 24C8 32.8 15.2 40 24 40C27.8 40 31.2 38.8 33.8 36.6Z"
        fill={color ?? "#888E99"}
      />
    </StyledSvg>
  );
};
