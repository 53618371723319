import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import {
  InputBackgroundColor,
  InputType
} from '../../../../components/Input/Input.types'
import {
  ButtonBackgroundColor,
  ButtonColor,
  ButtonHeight,
  ButtonTypeEvent
} from '../../../../components/Button/Button.types'
import {
  StyledInn,
  StyledInnSubscription,
  StyledRegistrationFormBlock,
  StyledAgreement
} from './RegistrationForm.styled'
import { useAppDispatch, useAppSelector } from '../../../../app/redux/hooks'
import {
  registrationFormSelector,
  setFullName,
  setPhone,
  setEmail,
  setInn,
  setIsAgreement
} from './registrationFormSlice'

import { codeSelector, setIsHideCode } from '../Code/codeSlice'
import Form from '../../../../components/Form'
import {
  FlexDirectionType,
  JustifyContentType
} from '../../../../components/Form/Form.types'
import { useSendSmsCodeMutation } from '../../../../api/orderApi'
import Code from '../Code'

import { MAX_LENGTH_FULLNAME } from './registrationFormHelper'
import { Checkbox } from '../../../../components/Checkbox/Checkbox'
import { DisabledDivForButton } from '../Code/Code.styled'
import Link from '../../../../components/Link'
import { TextDecorationType } from '../../../../components/Link/Link.types'
import PhoneInput from '../../../../components/PhoneInput'

export const RegistrationForm = () => {
  const {
    fullName,
    isValidFullName,
    phone,
    email,
    isValidEmail,
    inn,
    isValidPhone,
    isValidInn,
    isAgreement
  } = useAppSelector(registrationFormSelector)
  const { isHideCode } = useAppSelector(codeSelector)
  const [sendSmsCode] = useSendSmsCodeMutation()
  const dispatch = useAppDispatch()

  const sendSmsCodeHandler = () => sendSmsCode(phone)

  const onSubmitFormHandler = () => {
    if (isValidFullName && isValidPhone && isValidEmail && isValidInn) {
      dispatch(setIsHideCode(false))
      sendSmsCodeHandler()
    }
  }

  const ERROR_BORDER = '1px solid red'
  return (
    <>
      {isHideCode && (
        <>
          <Form
            flexDirection={FlexDirectionType.COLUMN}
            justifyContent={JustifyContentType.FLEX_START}
          >
            <>
              <StyledRegistrationFormBlock>
                <Input
                  name='fullName'
                  type={InputType.text}
                  placeholder='Ваши ФИО'
                  background={InputBackgroundColor.WHITE}
                  value={fullName}
                  onChange={e => {
                    dispatch(setFullName(e.target.value))
                  }}
                  required={true}
                  border={`${isValidFullName ? '' : ERROR_BORDER}`}
                  maxLength={MAX_LENGTH_FULLNAME}
                />
                <PhoneInput
                  inputValue={phone}
                  placeHolder={'Ваш телефон'}
                  onChange={value => dispatch(setPhone(value))}
                />
                <Input
                  name='email'
                  type={InputType.email}
                  placeholder='Ваш Email (не обязательно)'
                  background={InputBackgroundColor.WHITE}
                  value={email}
                  onChange={e => dispatch(setEmail(e.target.value))}
                  border={`${isValidEmail ? '' : ERROR_BORDER}`}
                />
                <StyledInn>
                  <Input
                    name='inn'
                    type={InputType.text}
                    placeholder='ИНН компании'
                    background={InputBackgroundColor.WHITE}
                    value={inn}
                    onChange={e => dispatch(setInn(e.target.value))}
                    border={`${isValidInn ? '' : ERROR_BORDER}`}
                  />
                  {!inn && (
                    <StyledInnSubscription>
                      Если вы являетесь представителем компании
                    </StyledInnSubscription>
                  )}
                </StyledInn>
                <StyledAgreement>
                  <Checkbox
                    name='agreement'
                    value={isAgreement}
                    onChange={e => {
                      dispatch(setIsAgreement(e.target.checked))
                    }}
                  >
                    <span>Я принимаю </span>
                    <Link
                      href='#'
                      textDecoration={TextDecorationType.underline}
                    >
                      пользовательское соглашение
                    </Link>
                    <span> и </span>
                    <Link
                      href='#'
                      textDecoration={TextDecorationType.underline}
                    >
                      политику конфидециальности
                    </Link>
                  </Checkbox>
                </StyledAgreement>
              </StyledRegistrationFormBlock>
            </>
          </Form>
          <DisabledDivForButton
            disabled={!isAgreement}
            backgroundColor={ButtonBackgroundColor.BLUE}
          >
            <Button
              buttonTypeEvent={ButtonTypeEvent.SUBMIT}
              buttonBackgroundColor={ButtonBackgroundColor.BLUE}
              buttonColor={ButtonColor.WHITE}
              buttonHeight={ButtonHeight.MID_HEIGHT}
              children='Далее'
              onClick={onSubmitFormHandler}
            />
          </DisabledDivForButton>
        </>
      )}
      {!isHideCode && <Code isAuth={false} />}
    </>
  )
}
