import { TextAlignType } from "../Form/Form.types";

export interface IInputProps {
  id?: string
  name: string
  type: InputType
  color?: string
  background?: string
  fontWeight?: string
  fontSize?: string
  placeholder?: string
  pattern?: string
  value?: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  border?: string,
  maxLength? : number,
  min?: number,
  max?: number,
  textAlign?: TextAlignType,
  title?: string,
  checked? : boolean,
  width? : string
  height? : string
  padding?: string
  margin? : string
}

export enum InputType {
  text = 'text',
  password = 'password',
  email = 'email',
  phone = 'tel',
  number = 'number',
  checkbox = 'checkbox'
}

export interface IStyledInputProps {
  color?: string
  background?: string
  fontWeight?: string
  fontSize?: string
  borderRadius?: string
  border?: string
  value?: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  textAlign?: TextAlignType
  title?: string
  checked? : boolean
  width? : string
  height? : string,
  padding?: string,
  margin? : string
}

export enum InputColor {
  LIGHT_GREY = '#888E99',
  BLACK = '#000000'
}

export enum InputBackgroundColor {
  LIGHT_GREY = '#ECEDEF',
  WHITE = '#FFF'
}
